import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import AppContext from "./AppContext";
import { heapIO } from "./utils/heap";
import getProfilePercentage from "./components/Profile/Sidebar/ProfileCalculate";
import { GET_USER_FOR_APP_PROVIDER } from "./graphql/queries";

export default function AppProvider({ children }) {
  const [state, setState] = useState({
    isAuth: false,
    status: "pending",
    user: null,
    profilePercentage: 0,
  });
  const [getUserData, { error }] = useLazyQuery(GET_USER_FOR_APP_PROVIDER, {
    fetchPolicy: "network-only",
  });

  const history = useHistory();

  const getUser = async () => {
    const result = await getUserData();
    // eslint-disable-next-line
    if (!result || error) {
      return;
    }
    const { data } = result;
    if (!data && !data?.me) {
      setState({
        ...state,
        status: "ready",
        user: null,
        profilePercentage: 0,
      });
      history.push("/login");
      return false;
    }

    const newState = {
      ...state,
      status: "ready",
      isAuth: true,
      user: data.me,
      profilePercentage: getProfilePercentage(data.me),
    };
    if (process.env.REACT_APP_HOST_ENV === "production") {
      const { default: LogRocket } = await import("logrocket");
      LogRocket.identify(newState.user.email);
    }
    // Setting heap.io by user identification
    heapIO.identify(newState.user.id);

    // add properties to heap user
    heapIO.addUserProperties(newState.user.id, {
      name: newState.user.name,
      email: newState.user.email,
    });

    setState(newState);
  };

  useEffect(() => {
    const prepare = async () => {
      getUser();
    };

    const isAuth = localStorage.getItem("isAuth");
    if (isAuth) {
      prepare();
    } else {
      setState({
        ...state,
        status: "ready",
      });
    }
    // eslint-disable-next-line
  }, []);

  const logout = () => {
    setState({
      ...state,
      isAuth: false,
      status: "ready",
      user: null,
      profilePercentage: 0,
    });
  };

  const refresh = () => {
    setState({
      ...state,
      status: "pending",
    });
    getUser();
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        logout,
        refresh,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
