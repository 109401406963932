import React from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Expandable, CheckList, CheckListLink } from "tg-design";
import Percentage from "./Percentage";
import getProfilePercentage from "./ProfileCalculate";
import QuickEdit from "./QuickEdit";
import { GET_USER_IMPORT_COUNT } from "./queries";
import PartialError from "../../../pages/ErrorPage/PartialError";

const CHECKLIST_ITEMS = [
  {
    title: "Profile Import",
    link: "profile/edit/import",
    max: 1,
    getCompletedCount: (user, importCount) => {
      if (importCount > 0) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: "Basic info",
    link: "profile/edit/info",
    max: 9,
    getCompletedCount: (user) => {
      const fields = [
        "avatarURL",
        "bio",
        "name",
        "role",
        "experience",
        "birthyear",
        "livingCity",
        "phone",
      ];
      let count = 0;
      fields.forEach((field) => {
        if (user[field]) {
          count++;
        }
      });

      // Checking any social link
      const socialLinks = Object.keys(user.social)
        .filter((i) => i !== "__typename")
        .filter((key) => user.social[key]);

      return count + (socialLinks.length > 0 ? 1 : 0);
    },
  },
  {
    title: "Skills & Experience",
    link: "profile/edit/skills",
    max: 2,
    getCompletedCount: (user) => {
      let count = 0;

      if ((user.skills || []).length > 0) {
        count++;
      }

      if ((user.otherSkills || []).length > 0) {
        count++;
      }

      return count;
    },
  },
  {
    title: "Preferences & Criteria",
    link: "profile/edit/criterias",
    max: 1,
    getCompletedCount: (user) => {
      const hasActiveCriteria = Object.keys(user?.criteria || {})
        .filter(
          (key) => ["__typename", "updatedAt", "other"].includes(key) === false
        )
        .some((key) => {
          const section = (user?.criteria || {})[key] || {};
          return section.open;
        });
      return hasActiveCriteria ? 1 : 0;
    },
  },
  {
    title: "Education",
    link: "profile/edit/education",
    max: 2,
    getCompletedCount: (user) => {
      let count = 0;

      if ((user.education || []).length > 0) {
        count++;
      }

      if ((user.languages || []).length > 0) {
        count++;
      }

      return count;
    },
  },
  {
    title: "Work History",
    link: "profile/edit/work-history",
    max: 1,
    getCompletedCount: (user) => {
      const workHistoryCount = (user.workHistory || []).length;
      return workHistoryCount > 0 ? 1 : 0;
    },
  },
];

const isCompleted = (step, user, importCount) => {
  if (typeof step.getCompletedCount !== "function") {
    return false;
  }

  return step.max === step.getCompletedCount(user, importCount);
};

const getProgressText = (step, user) => {
  if (typeof step.max !== "number" || step.max <= 1) {
    return "";
  }

  const completed = step.getCompletedCount(user);

  if (completed === 0) {
    return "";
  }

  return `(${completed}/${step.max} filled)`;
};

export default function ProfileSidebar({ user, isLinkedinSaved }) {
  const history = useHistory();

  const { data, loading, error } = useQuery(GET_USER_IMPORT_COUNT, {
    fetchPolicy: "network-only",
  });

  const updatedUser = { ...user, isLinkedinSaved };

  const handleStepClick = ({ event, step }) => {
    event.preventDefault();
    if (step.link) {
      history.push(step.link);
    }
  };

  const percent = getProfilePercentage(user) || 0;

  if (loading) return "Loading...";
  if (error) return <PartialError />;

  return (
    <div
      style={{
        position: "sticky",
        top: 10,
        paddingRight: "5%",
        marginTop: "8px",
      }}
    >
      <Expandable header={<Percentage percent={percent} />}>
        <CheckList title="Profile Checklist">
          {CHECKLIST_ITEMS.map((step) => (
            <CheckListLink
              key={step.title}
              checked={isCompleted(step, user, data.findImportCountByUserId)}
              onClick={(event) => handleStepClick({ event, step })}
              progressText={getProgressText(step, user)}
            >
              {step.title}
            </CheckListLink>
          ))}
        </CheckList>
      </Expandable>
      <QuickEdit user={updatedUser} isLinkedinSaved={isLinkedinSaved} />
    </div>
  );
}
