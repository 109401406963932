import React, { useState, useEffect } from "react";
import { useHistory, useParams, Switch, Route } from "react-router-dom";

import NavList from "./NavList";
import LayoutWithTabs from "../Layout/LayoutWithTabs";
import { isMobile } from "../../helper";

import Companies from "./Companies";
import Reports from "./Reports";
import Social from "./Social";

const routes = [
  {
    path: "/discover/companies",
    component: Companies,
    exact: true,
  },
  {
    path: "/discover/reports",
    component: Reports,
    exact: true,
  },
  {
    path: "/discover/socials",
    component: Social,
  },
];

export default function Discover({ reportData, reportRefetch, user }) {
  const [active, setActive] = useState("");
  const history = useHistory();
  const { sectionName } = useParams();

  useEffect(() => {
    setActive(sectionName);
  }, [history, sectionName]);

  const handleClick = (section) => {
    history.push(`/discover/${section}`);
  };

  return (
    <LayoutWithTabs
      sectionName={active}
      NavList={() => (
        <NavList
          handleClick={handleClick}
          active={active}
          mobileDetail={isMobile() && sectionName}
        />
      )}
    >
      <Switch>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            render={(props) => (
              <route.component
                {...props}
                reports={reportData.allDocuments.documents}
                user={user}
                routes={route.routes}
                reportRefetch={reportRefetch}
              />
            )}
          />
        ))}
      </Switch>
    </LayoutWithTabs>
  );
}
