import React from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import message from "../../utils/message";
import { RowBlock } from "../BlockUi";
import { Button } from "../FormUi";
import { ClipBoardIcon } from "../Icon";

const Description = styled.div`
  font-family: ${(props) => props.theme.family.regular};
  font-size: 16px;
  line-height: 22px;
  color: #979797;
`;

const ReferralLinkContainer = styled.div`
  margin-top: 30px;
`;
const ReferralLinkTitle = styled.span`
  display: block;
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 19px;
  color: #444444;
  margin-bottom: 3px;
`;
const ReferralLinkBox = styled.div`
  border: 1px dashed #c0c0c0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797;
  width: 100%;
  height: 78px;
  font-family: ${(props) => props.theme.family.regular};
  font-size: 16px;
  line-height: 22px;

  &:hover {
    border: 2px dashed #8fdeac;
  }
`;

const handleCopy = (value, closeModal) => {
  try {
    const textarea = document.createElement("textarea");
    textarea.textContent = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    message.success("Copied to clipboard");
  } catch (error) {
    message.error("An error occurred");
  }
  closeModal();
};

export default function ReferralModal({ open, inviteLink, handleClose }) {
  return (
    <>
      {open && (
        <Modal handleClose={handleClose}>
          <ModalHeader>Refer a friend for this role</ModalHeader>
          <ModalBody>
            <Description>
              Help your network access this opportunity, earn rewards if they
              get the job!
            </Description>
            <ReferralLinkContainer>
              <ReferralLinkTitle>
                Your unique referral link for this position:
              </ReferralLinkTitle>
              <ReferralLinkBox>{inviteLink}</ReferralLinkBox>
            </ReferralLinkContainer>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ maxWidth: "160px" }}
              onClick={() => handleCopy(inviteLink, () => handleClose())}
            >
              <RowBlock
                style={{ justifyContent: "space-evenly", alignItems: "center" }}
              >
                <ClipBoardIcon />
                Copy Link
              </RowBlock>
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
