/* eslint-disable no-console */

import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { update as updateIntercom } from "../utils/Intercom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuth = localStorage.getItem("isAuth");
  useEffect(() => {
    updateIntercom();
  }, [rest]);
  if (isAuth) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  return (
    <Route
      render={() => (
        <Redirect
          to={{
            pathname: "/login",
            search: `?returnUrl=${rest.location.pathname}`,
            state: { referrer: rest.location.pathname },
          }}
        />
      )}
    />
  );
};

export default PrivateRoute;
