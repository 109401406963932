import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { space } from "styled-system";
import Switch from "../Form/ToggleSwitch";

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #eeeeee;
  ${space}
`;

const Heading = styled.div`
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.isPointer ? "pointer" : "auto")};
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  display: flex;
  justify-content: space-between;
  ${space}
  font-size: 18px;
  color: ${(props) =>
    props.active ? props.theme.colors.main[0] : props.theme.colors.font[1]};
`;

const ChildBlock = styled.div`
  ${space}
  border-top: 1px solid #eeeeee;
  overflow: ${(props) => (props.active ? "revert" : "auto")};
  transition: ${(props) =>
    props.active ? "min-height 0.3s ease" : "max-height 0.3s ease"};
`;

const Body = styled.div`
  ${space}
`;

export default function Toggle({
  title,
  open,
  handleOnOff,
  sectionName,
  children,
  autoMinHeight,
  showSwitch = true,
  ...props
}) {
  const [active, setActive] = useState(!!open);
  const [contentHeight, setContentHeight] = useState("0px");
  const contentRef = useRef();

  const handleToggle = (e) => {
    e.preventDefault();
    if (handleOnOff) {
      handleOnOff(sectionName, !active);
    }
    setActive(!active);
    setContentHeight(
      active ? "0px" : `${contentRef.current.scrollHeight + 5}px`
    );
  };

  useEffect(() => {
    if (open) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
      setActive(open);
    } else {
      setContentHeight("0px");
      setActive(false);
    }
  }, [open]);

  const handleOnClick = (e) => {
    if (showSwitch) {
      handleToggle(e);
    }
  };

  return (
    <Wrapper>
      <Heading onClick={handleOnClick} isPointer={showSwitch}>
        <Title active={active}>{title}</Title>
        {showSwitch && <Switch name={sectionName} active={active} />}
      </Heading>
      <ChildBlock
        ref={contentRef}
        style={
          active
            ? { minHeight: autoMinHeight ? "auto" : `${contentHeight}` }
            : { maxHeight: `${contentHeight}` }
        }
        active={active}
      >
        <Body py="25px" px={{ mobile: "20px", tablet: "25px" }} {...props}>
          {children}
        </Body>
      </ChildBlock>
    </Wrapper>
  );
}
