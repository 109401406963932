import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, TabItem } from "tg-design";
import { LinkedinIcon, DocumentIcon } from "../../Icon";
import LinkedinImport from "./Linkedin";
import CvImport from "./CvImport";
import { ACTION_BUTTON_TITLES } from "../../../utils/constants";

export default function ProfileImport({ user, refetch }) {
  const [currentTab, setCurrentTab] = useState("linkedin-import");
  const [linkedinLoading, setLinkedinLoading] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);

  const history = useHistory();

  const isLinkedinBlocked = useRef(linkedinLoading);

  const isResumeBlocked = useRef(resumeLoading);

  const changeTab = (value) => {
    if (!linkedinLoading && !resumeLoading) {
      setCurrentTab(value);
    }
  };

  useEffect(() => {
    if (
      history?.location?.state?.from ===
      ACTION_BUTTON_TITLES.IMPORT_RESUME_BUTTON
    ) {
      setCurrentTab("cv-import");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  isLinkedinBlocked.current = linkedinLoading;
  isResumeBlocked.current = resumeLoading;

  useEffect(() => {
    history.block(() => {
      if (isLinkedinBlocked.current || isResumeBlocked.current)
        return "There are unsaved changes. Are you sure you want to change the page?";
    });

    return () => {
      isLinkedinBlocked.current = false;
      isResumeBlocked.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tabs currentTab={currentTab} setCurrentTab={(value) => changeTab(value)}>
        <TabItem
          style={{ cursor: "none" }}
          name="linkedin-import"
          title={
            <>
              <LinkedinIcon color={resumeLoading ? "#aaa" : "#0072B1"} />
              <span style={{ color: resumeLoading && "#aaa" }}>
                Import from Linkedin
              </span>
            </>
          }
          disabled={resumeLoading}
          component={
            <LinkedinImport
              user={user}
              refetch={refetch}
              loading={linkedinLoading}
              setLoading={setLinkedinLoading}
            />
          }
        />
        <TabItem
          name="cv-import"
          title={
            <>
              <DocumentIcon color={linkedinLoading ? "#aaa" : "#444"} />
              <span style={{ color: linkedinLoading && "#aaa" }}>
                Import from CV
              </span>
            </>
          }
          disabled={linkedinLoading}
          component={
            <CvImport
              user={user}
              refetch={refetch}
              loading={resumeLoading}
              setLoading={setResumeLoading}
            />
          }
        />
      </Tabs>
    </>
  );
}
