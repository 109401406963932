import React from "react";
import styled from "styled-components";
import { space, width, position, layout } from "styled-system";
import spinner from "../../images/spinner.gif";
import tgLoader from "../../images/talentgrid_loading.gif";

const Loading = styled.img`
  vertical-align: middle;
  ${(props) => props.fullScreen && `position: absolute; top: 40%;`}
  ${(props) => props.center && `display:block; margin: 0 auto;`}
  ${position}
  ${space}
  ${width}
  ${layout}
`;

export default ({ fullScreen, center, ...props }) => (
  <Loading
    src={fullScreen ? tgLoader : spinner}
    fullScreen={fullScreen}
    center={center}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props} // for styled-system
    alt=""
  />
);
