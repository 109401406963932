import React, { useState, useEffect } from "react";
import { useHistory, useParams, Switch, Route } from "react-router-dom";
import dayjs from "dayjs";
import styled from "styled-components";
import { space, display } from "styled-system";
import device from "../../styles/device";
import { ColumnBlock, RowBlock } from "../BlockUi";
import EditList from "./EditList";
import BasicInfos from "./BasicInfos";
import TalentAndExperiance from "./Talent-Experience";
import Criterias from "./Criterias";
import Education from "./Education";
import WorkHistoryView from "./WorkHistory";
import ProfileImport from "./ProfileImport";

const DetailWrap = styled.div`
  ${space}
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  background-color: #ffffff;
  align-items: left;
  @media ${device.tablet} {
    border-radius: 10px 10px 0 0;
  }
`;

const Line = styled.div`
  border-bottom: solid 2px #0c084c;
  flex: 1;
  margin-right: 15px;
`;

const DateSpan = styled.span`
  opacity: ${(props) => (props.opacity ? props.opacity : 0)};
  transition: opacity 0.5s;
`;

const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #0c084c;
  margin-bottom: 30px;
  ${display};
  font-family: ${(props) => props.theme.family.medium};
`;

const routes = [
  {
    path: "/profile/edit/info",
    component: BasicInfos,
    exact: true,
    name: "info",
  },
  {
    path: "/profile/edit/skills",
    component: TalentAndExperiance,
    name: "skills",
  },
  {
    path: "/profile/edit/criterias",
    component: Criterias,
    name: "criterias",
  },
  {
    path: "/profile/edit/education",
    component: Education,
    name: "education",
  },
  {
    path: "/profile/edit/work-history",
    component: WorkHistoryView,
    name: "workHistory",
  },
  {
    path: "/profile/edit/import",
    component: ProfileImport,
    name: "import",
  },
];

const StyledRowBlock = styled(RowBlock)`
  @media only screen and (min-width: 992px) {
    height: calc(100vh - 20px);
    overflow: hidden;
  }
`;

const StyledColumnBlock = styled(ColumnBlock)`
  @media only screen and (min-width: 992px) {
    overflow-y: scroll;
    height: 100%;
    background-color: white;
    border-radius: 10px;
  }
`;

export default function ProfileEdit({ user, refetch }) {
  const [active, setActive] = useState("");
  const [criteriaUpdateTime, setCriteriaUpdateTime] = useState(
    user.criteria ? user.criteria.updatedAt : ""
  );
  const [userUpdateTime, setUserUpdateTime] = useState(user.updatedAt);
  const [updated, setUpdated] = useState(false);
  const history = useHistory();
  const { sectionName } = useParams();

  useEffect(() => {
    if (!sectionName) {
      history.push(`/profile/edit/info`);
    }
    setActive(sectionName || "info");
    // eslint-disable-next-line
  }, [sectionName]);

  useEffect(() => {
    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 300);
  }, [userUpdateTime, criteriaUpdateTime]);

  const handleClick = (section) => {
    history.push(`/profile/edit/${section}`);
  };

  const onUpdate = ({ criteria, user }) => {
    if (criteria) setCriteriaUpdateTime(criteria);
    if (user) setUserUpdateTime(user);
    refetch();
  };

  return (
    <StyledRowBlock width="100%" justify="space-between" align="start">
      <EditList handleClick={handleClick} active={active} />
      <StyledColumnBlock
        pl={{ mobileS: "0px", tablet: "24px", laptop: 0 }}
        width={{
          mobileS: "100%",
          tablet: "70%",
          laptop: "65%",
          laptopL: "61%",
        }}
      >
        <DetailWrap
          px={{ mobileS: 3, tablet: 4, laptop: 5 }}
          py={{
            mobileS: "44px",
            tablet: 4,
          }}
          pb={{ mobileS: "80px" }}
        >
          <DetailHeader display={{ mobileS: "none", tablet: "flex" }}>
            <Line />
            {sectionName === "criterias" && user.criteria && (
              <DateSpan opacity={updated ? 0 : 1}>
                Last updated:
                {dayjs(criteriaUpdateTime).format("DD.MM.YYYY,  HH:mm:ss")}
              </DateSpan>
            )}
            {sectionName !== "criterias" && (
              <DateSpan opacity={updated ? 0 : 1}>
                Last updated:
                {dayjs(userUpdateTime).format("DD.MM.YYYY,  HH:mm:ss")}
              </DateSpan>
            )}
          </DetailHeader>

          <Switch>
            {routes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                render={(props) => (
                  <route.component
                    {...props}
                    user={user}
                    onUpdate={onUpdate}
                    routes={route.routes}
                    refetch={refetch}
                  />
                )}
              />
            ))}
          </Switch>
        </DetailWrap>
      </StyledColumnBlock>
    </StyledRowBlock>
  );
}
