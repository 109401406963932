import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProfileAvatar from "./ProfileAvatar";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import ProfileSocial from "./ProfileSocial";
import HoveredEmptyView from "../../Modules/HoveredEmptyView";
import { update as updateIntercom } from "../../../utils/Intercom";
import { ExperienceIcon, LocationIcon, EditIcon } from "../../Icon";
import {
  ProfileName,
  ProfileHeadlineItem,
  ProfileRole,
  ProfileHeadlineTitle,
  SectionTitle,
  ProfileAboutContent,
  ProfileActivelyLooking,
  Status,
  Circle,
} from "../CommonStyles";
import ReadMore from "../../ReadMore";

export default function ProfileHeader({ user }) {
  const history = useHistory();

  const handleEdit = (hash) => {
    let url = `profile/edit/info`;
    if (typeof hash === "string") {
      url = `profile/edit/info#${hash}`;
    }
    history.push(url);
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const getCityDetail = async () => {
      if (user?.livingCity) {
        updateIntercom({
          livingCity: user?.livingCity?.city,
          livingCounty: user?.livingCity?.country,
        });
      }
    };
    getCityDetail();
  }, [user]);

  const getUserExperience = () => {
    if (user?.experience === null || user?.experience === undefined) {
      return "N/A";
    }

    if (user?.experience === 0) {
      return "< 1";
    }
    if (user?.experience > 10) {
      return "10+ years";
    }
    return `${user?.experience} year`;
  };

  return (
    <RowBlock justifyContent="flex-end">
      <RowBlock mb={4} display={{ mobileS: "flex", laptop: "none" }}>
        <EditIcon onClick={handleEdit} />
      </RowBlock>
      <RowBlock
        justifyContent={{ mobileL: "center", laptop: "space-between" }}
        align="center"
        width="100%"
      >
        <ColumnBlock align="center">
          <ProfileAvatar avatar={user?.avatarURL} />
          {user?.name && <ProfileName>{user?.name}</ProfileName>}
          {!user?.name && (
            <ProfileName mt={2} aria-labelledby="dontknow">
              Unknown Name{" "}
              <span role="img" aria-label="unknown">
                🤷‍♂️
              </span>{" "}
            </ProfileName>
          )}
          <ProfileRole mt={2} mb={2}>
            {" "}
            {user?.role?.title || "Missing Role"}
          </ProfileRole>
          {user?.preferences?.openToWork && (
            <RowBlock ml="-15px">
              <ProfileActivelyLooking>
                <Status>
                  <Circle size="11px" color="#abe6c1" />
                </Status>
                Actively looking
              </ProfileActivelyLooking>
            </RowBlock>
          )}
        </ColumnBlock>
        <ColumnBlock alignItems={{ mobileL: "center", laptop: "flex-end" }}>
          <RowBlock mb={4} display={{ mobileS: "none", laptop: "flex" }}>
            <EditIcon onClick={handleEdit} />
          </RowBlock>
          <RowBlock align="center" justify="center" my={3}>
            <ExperienceIcon />
            <ProfileHeadlineTitle mx={2}>
              Professional experience:
            </ProfileHeadlineTitle>
            <ProfileHeadlineItem>{getUserExperience()}</ProfileHeadlineItem>
          </RowBlock>
          {user?.livingCity && (
            <RowBlock align="center" justify="center">
              <LocationIcon size={{ x: 12, y: 17 }} color="#444" />
              <ProfileHeadlineTitle mx={2}>Living in:</ProfileHeadlineTitle>
              <ProfileHeadlineItem>
                {user?.livingCity?.city}, {user?.livingCity?.country}
              </ProfileHeadlineItem>
            </RowBlock>
          )}
          <RowBlock mt={3}>
            <ProfileSocial
              social={user?.social}
              resume={user?.resume?.resumeURL}
            />
          </RowBlock>
        </ColumnBlock>
      </RowBlock>

      <RowBlock
        width="100%"
        mt={4}
        mb={3}
        justifyContent={{ mobileL: "flex-start" }}
      >
        <ColumnBlock width={{ mobileS: "100%", tablet: "100%" }}>
          <SectionTitle>About</SectionTitle>
        </ColumnBlock>
      </RowBlock>
      <RowBlock width="100%" justifyContent={{ mobileL: "space-between" }}>
        <ColumnBlock width={{ mobileS: "100%", tablet: "100%" }}>
          {user?.bio ? (
            <ProfileAboutContent>
              <ReadMore text={user?.bio} limit={300} />
            </ProfileAboutContent>
          ) : (
            <RowBlock onClick={() => handleEdit("bio")}>
              <HoveredEmptyView text="Add" />
            </RowBlock>
          )}
        </ColumnBlock>
      </RowBlock>
    </RowBlock>
  );
}
