import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Timer = ({
  initialMinute = 0,
  initialSeconds = 0,
  handleTimesUp,
  clear,
}) => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (clear) {
      setMinutes(initialMinute);
      setSeconds(initialSeconds);
    }
  }, [clear, initialMinute, initialSeconds]);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          handleTimesUp(true);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      {minutes === 0 && seconds === 0 ? null : (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      )}
    </>
  );
};

Timer.defaultProps = {
  initialMinute: PropTypes.isRequired,
  handleTimesUp: PropTypes.string.isRequired,
};

export default Timer;
