import React, { useState } from "react";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import letterIcon from "../../../images/noun-letter.svg";
import CriteriaViewCard from "./Card";
import { NumberSpan, ViewCardSubTitle } from "../CommonStyles";
import { ShowIcon } from "../../Icon";

const LABELS = {
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  GROSS: "Gross",
  NET: "Net",
};

export default function SalaryViewCard({ salary, currency }) {
  const [showSalary, setShowSalary] = useState(false);

  return (
    <CriteriaViewCard title="Salary" image={letterIcon}>
      <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
        <RowBlock>
          <ColumnBlock justifyContent={{ mobileS: "flex-start" }}>
            <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
              <ViewCardSubTitle>
                {LABELS[salary.period]} {LABELS[salary.income]} Salary
                Expectation:
              </ViewCardSubTitle>
              {showSalary && (
                <RowBlock alignItems="center">
                  <ColumnBlock width="100px">
                    <NumberSpan>
                      {salary.expected} {currency}
                    </NumberSpan>
                  </ColumnBlock>
                  <ColumnBlock style={{ marginLeft: "5px" }}>
                    <ShowIcon onClick={() => setShowSalary(false)} />
                  </ColumnBlock>
                </RowBlock>
              )}
              {!showSalary && (
                <RowBlock alignItems="center">
                  <ColumnBlock width="100px">• • • • • • •</ColumnBlock>
                  <ColumnBlock style={{ marginLeft: "5px" }}>
                    <ShowIcon onClick={() => setShowSalary(true)} />
                  </ColumnBlock>
                </RowBlock>
              )}
            </ColumnBlock>
          </ColumnBlock>
        </RowBlock>
      </ColumnBlock>
    </CriteriaViewCard>
  );
}
