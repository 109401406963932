import React, { useEffect } from "react";
import styled from "styled-components";
import { space } from "styled-system";
import { ColumnBlock, RowBlock } from "../BlockUi";
import { heapIO } from "../../utils/heap";

import device from "../../styles/device";
import { REPORT_LANGUAGES } from "../../utils/constants";
import { ExternalLinkIcon } from "../Icon";

const reportsSentToHeap = [];

const AnimatedLinkContainer = styled.span`
  padding-top: 2px;
  animation: bounceRight 0.75s;

  @keyframes bounceRight {
    0% {
      width: 0;
      opacity: 0;
    }
    50% {
      width: auto;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &.invisible {
    display: none;
  }
`;

const Wrapper = styled.div`
  ${space}
`;

const Logo = styled.img`
  object-fit: contain;
  flex: 1;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 18px;
  font-weight: 600;
  color: #0c084c;
  margin-bottom: 20px;
`;

const DescriptionParagraph = styled.p`
  font-family: ${(props) => props.theme.family.medium};
  line-height: 20px;
  color: #444444;

  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

const SubTitle = styled.p`
  font-family: ${(props) => props.theme.family.demi};
  color: #000000;
  margin: 10px 0;

  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

const SubText = styled.p`
  font-family: ${(props) => props.theme.family.medium};
  color: #444444;

  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

const FlagContainer = styled.span`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  border: 2px solid #0c084c;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  &.flag-container:hover .invisible {
    display: inline;
  }
  :hover {
    background: #e9d8f2;
  }
`;

const Seperator = styled.hr`
  background: #0c084c;
  border: 2px solid #0c084c;
  border-radius: 4px;
  ${space};
`;

export default function Reports({ reports, user }) {
  const filteredReports = reports.filter((report) => report.visible);

  const getReportTitle = (report) => {
    return report.title;
  };

  const getReportDescription = (report) => {
    return report.description;
  };

  const handleClick = (report, url) => {
    window.open(url, "_blank");

    heapIO.createCustomEvent("Resources Report Clicked", {
      reportId: report.id,
      userId: user.id,
      timeOfEvent: new Date(Date.now()).toISOString(),
    });
  };

  const checkVisible = (element) => {
    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const report = {
      reportId: element.id,
      isVisible: !(rect.bottom < 0 || rect.top - viewHeight >= 0),
    };
    const reportArray = [];

    reportArray.push(report);
    reportArray.forEach((i) => {
      if (i.isVisible && !reportsSentToHeap.includes(i.reportId)) {
        reportsSentToHeap.push(i.reportId);

        heapIO.createCustomEvent("Resources Report Seen", {
          reportId: i.reportId,
          userId: user?.id,
          timeOfEvent: new Date(Date.now()).toISOString(),
        });
      }
    });
  };

  const scrollHandler = () => {
    const element = document.getElementsByClassName("seperator");
    const elementArray = Array.prototype.slice.call(element);
    elementArray.map((item) => checkVisible(item));
  };

  const getLanguage = (languageKey) => {
    const language = REPORT_LANGUAGES.find((item) => item.code === languageKey);
    return (
      <span>
        {language.flag} {language.title}
      </span>
    );
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {reports &&
        filteredReports.map(
          (report) =>
            report.coverImageURL &&
            report.files.length !== 0 && (
              <Wrapper key={report.id}>
                <RowBlock justify="flex-start">
                  <Title>{getReportTitle(report)}</Title>
                </RowBlock>
                <RowBlock mb={2} justify="flex-start" align="flex-start">
                  <ColumnBlock
                    width={{
                      mobileS: "100%",
                      mobileL: "90%",
                      tablet: "100%",
                    }}
                    pb={2}
                  >
                    <Logo src={report.coverImageURL} />
                  </ColumnBlock>

                  <ColumnBlock width={{ mobileS: "100%", laptop: "100%" }}>
                    <DescriptionParagraph
                      dangerouslySetInnerHTML={{
                        __html: getReportDescription(report),
                      }}
                    />
                  </ColumnBlock>

                  <ColumnBlock width={{ mobileS: "100%", laptop: "60%" }}>
                    <SubTitle>Published in:</SubTitle>
                    <SubText>
                      {report.publishedAt ? report.publishedAt : "-"}
                    </SubText>

                    <ColumnBlock>
                      <SubTitle>Available in:</SubTitle>
                      <RowBlock justify="start" gridGap="5px">
                        {report.files.map(
                          (file) =>
                            REPORT_LANGUAGES.map(
                              (item) => item.code === file.fileLanguage
                            ) &&
                            file.fileURL &&
                            file.fileLanguage && (
                              <FlagContainer
                                className="flag-container"
                                key={file.fileLanguage}
                                onClick={() =>
                                  handleClick(report, file.fileURL)
                                }
                              >
                                {getLanguage(file.fileLanguage)}
                                <AnimatedLinkContainer className="invisible">
                                  <ExternalLinkIcon />
                                </AnimatedLinkContainer>
                              </FlagContainer>
                            )
                        )}
                      </RowBlock>
                    </ColumnBlock>
                  </ColumnBlock>
                </RowBlock>
                <Seperator mt={4} mb={4} className="seperator" id={report.id} />
              </Wrapper>
            )
        )}
    </>
  );
}
