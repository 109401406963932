import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import { SelectField } from "../../Form/SelectField";
import {
  rangeOfYears,
  getMonthOptions,
  captureErrorWithData,
} from "../../../helper";
import { Input, Label, Button, IsRequired } from "../../FormUi";
import { UPDATE_USER } from "./queries";
import { schema } from "./WorkHistoryValidationSchema";
import Textarea from "../../Form/Textarea";
import { CheckboxField } from "../../Form/CheckboxField";
import { FormTitle, FormErrorMessages } from "../CommonStyles";

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth() + 1;

export const createYearsList = () => {
  const START_YEAR = 1950;
  return rangeOfYears(START_YEAR, CURRENT_YEAR)
    .map((year) => {
      return { label: year, value: year };
    })
    .sort((a, b) => b.value - a.value);
};

const years = createYearsList();

const setMonthAndYear = (item, key) => {
  if (item[`${key}Date`]) {
    item[`${key}Month`] = new Date(item[`${key}Date`]).getMonth() + 1;
    item[`${key}Year`] = new Date(item[`${key}Date`]).getFullYear();
  }
};

const isAcceptableEndMonth = (updatedItem) => {
  if (
    !updatedItem.endMonths.some((month) => month.value === updatedItem.endMonth)
  ) {
    return false;
  }

  if (
    Number.isInteger(updatedItem.startYear) &&
    Number.isInteger(updatedItem.endYear) &&
    updatedItem.startYear === updatedItem.endYear &&
    updatedItem.endMonth < updatedItem.startMonth
  ) {
    return false;
  }
  return true;
};

const getPossibleEndMonths = (updatedItem, monthOptions) => {
  let possibleEndMonths = [...monthOptions];
  if (updatedItem.endYear === CURRENT_YEAR) {
    possibleEndMonths = possibleEndMonths.filter(
      (month) => month.value <= CURRENT_MONTH
    );
  }

  if (
    Number.isInteger(updatedItem.startYear) &&
    Number.isInteger(updatedItem.endYear) &&
    updatedItem.startYear === updatedItem.endYear &&
    updatedItem.startMonth
  ) {
    possibleEndMonths = possibleEndMonths.filter(
      (month) => month.value >= updatedItem.startMonth
    );
  }
  return possibleEndMonths;
};

const getPossibleStartMonths = (updatedItem, monthOptions) => {
  if (updatedItem.startYear === CURRENT_YEAR) {
    return monthOptions.filter((month) => month.value <= CURRENT_MONTH);
  }
  return [...monthOptions];
};

const getPossibleEndYears = (updatedItem) => {
  return years.filter((year) => year.value >= updatedItem.startYear);
};

export const setFullDate = (item, key) => {
  if (item[`${key}Month`] && item[`${key}Year`]) {
    item[`${key}Date`] = new Date(item[`${key}Year`], item[`${key}Month`] - 1);
  }
};

export const EMPTY_WORK_HISTORY_FIELDS = {
  company: "",
  position: "",
  startMonth: null,
  startYear: null,
  startDate: null,
  endMonth: null,
  endYear: null,
  endDate: null,
  description: "",
  present: false,
  startMonths: getMonthOptions(),
  endMonths: getMonthOptions(),
  endYears: [...years],
};

export default function WorkHistoryForm({
  workHistory = [],
  selectedWork,
  handleClose,
  onUpdate,
}) {
  const monthOptions = getMonthOptions();

  if (!workHistory) {
    workHistory = [];
  }

  const newWork = JSON.parse(JSON.stringify(selectedWork.work));

  setMonthAndYear(newWork, "start");
  setMonthAndYear(newWork, "end");
  newWork.endYears = getPossibleEndYears(newWork);
  newWork.startMonths = getPossibleStartMonths(newWork, monthOptions);
  newWork.endMonths = getPossibleEndMonths(newWork, monthOptions);

  const [workState, setWorkState] = useState(newWork);
  const [state, setState] = useState(workHistory);

  const [savingStatus, setSavingStatus] = useState(false);
  const [updateUser] = useMutation(UPDATE_USER);

  const insertNewWorkHistory = () => {
    const newState = state.slice();
    newState.push(selectedWork.work);
    selectedWork.index = state.length;
    setState(newState);
  };

  const handlePresentChange = ({ event }) => {
    const newList = {
      ...workState,
      present: event.target.checked,
    };

    if (event.target.checked) {
      newList.endMonth = null;
      newList.endYear = null;
      newList.endDate = null;
    }

    const newState = state.slice();
    newState[selectedWork.index] = newList;

    setWorkState(newList);
    setState(newState);
  };

  const handleOptionChange = ({ type, value }) => {
    const newList = {
      ...workState,
      [type]: value,
    };

    setFullDate(newList, "start");
    setFullDate(newList, "end");

    newList.endYears = getPossibleEndYears(newList);

    if (
      Number.isInteger(newList.startYear) &&
      Number.isInteger(newList.endYear) &&
      newList.endYear < newList.startYear
    ) {
      newList.endYear = null;
    }

    newList.startMonths = getPossibleStartMonths(newList, monthOptions);
    newList.endMonths = getPossibleEndMonths(newList, monthOptions);

    if (
      newList.startYear === CURRENT_YEAR &&
      newList.startMonth > CURRENT_MONTH
    ) {
      newList.startMonth = null;
    }
    if (!isAcceptableEndMonth(newList)) {
      newList.endMonth = null;
      newList.endDate = null;
    }

    const newState = state.slice();
    newState[selectedWork.index] = newList;

    setWorkState(newList);
    setState(newState);
  };

  const itemHandler = ({ type, event }) => {
    const newList = {
      ...workState,
      [type]: event.target.value,
    };

    const newState = state.slice();
    newState[selectedWork.index] = newList;

    setWorkState(newList);
    setState(newState);
  };

  const handleSubmit = async () => {
    const variables = state.map((item) => {
      return {
        company: item.company,
        position: item.position,
        startDate: item.startDate
          ? dayjs(item.startDate).format("YYYY-MM-DD")
          : null,
        endDate: item.endDate ? dayjs(item.endDate).format("YYYY-MM-DD") : null,
        description: item.description,
        present: item.present === null ? !item.endDate : item.present,
      };
    });

    setSavingStatus(true);

    try {
      await schema.validate({ workHistory: variables });
      await updateUser({ variables: { workHistory: variables } });
      onUpdate({ user: new Date() });
      setSavingStatus(false);
      handleClose(state);
    } catch (error) {
      if (error?.name === "ValidationError") {
        return;
      }
      captureErrorWithData(error);
    }
  };
  const WorkHistoryRow = (initialFields, index) => {
    return (
      <>
        <FormTitle>
          {initialFields.__typename
            ? "Edit work experience"
            : "Add new work experience"}
        </FormTitle>
        <RowBlock mb={3} key={index}>
          <RowBlock
            pt={3}
            pb={5}
            justify="flex-start"
            width={{ mobileS: "95%", tablet: "95%", laptop: "100%" }}
          >
            <ColumnBlock width={{ mobileS: "100%", tablet: "70%" }} flex="1">
              <RowBlock my={3}>
                <ColumnBlock width="100%">
                  <Label direction="row" mb={1}>
                    Company name
                    <IsRequired />
                  </Label>
                  <Input
                    textAlign="left"
                    width="100%"
                    value={initialFields.company}
                    onChange={(event) =>
                      itemHandler({ type: "company", event })
                    }
                  />
                  {savingStatus && !workState.company && (
                    <FormErrorMessages>Company is required.</FormErrorMessages>
                  )}
                </ColumnBlock>
              </RowBlock>
              <RowBlock my={3}>
                <ColumnBlock width="100%">
                  <Label direction="row" mb={1}>
                    Position
                    <IsRequired />
                  </Label>
                  <Input
                    textAlign="left"
                    width="100%"
                    value={initialFields.position}
                    onChange={(event) =>
                      itemHandler({ type: "position", event })
                    }
                  />
                  {savingStatus && !workState.position && (
                    <FormErrorMessages>Position is required.</FormErrorMessages>
                  )}
                </ColumnBlock>
              </RowBlock>
              <RowBlock my={3} justifyContent="flex-start">
                <ColumnBlock
                  width={{ mobileS: "100%", tablet: "50%" }}
                  pr={{ tablet: 2 }}
                >
                  <SelectField
                    label="Month started"
                    isRequired
                    placeholder=""
                    isSearchable
                    justifyLabel="left"
                    options={initialFields.startMonths}
                    value={
                      initialFields.startMonth
                        ? initialFields.startMonths.find(
                            (item) => item.value === initialFields.startMonth
                          )
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleOptionChange({
                        ...selectedOption,
                        type: "startMonth",
                      })
                    }
                    width="100%"
                  />
                </ColumnBlock>
                <ColumnBlock
                  width={{ mobileS: "100%", tablet: "50%" }}
                  pl={{ tablet: 2 }}
                  mt={{ mobileS: 3, tablet: 0 }}
                >
                  <SelectField
                    label="Year started"
                    isRequired
                    placeholder=""
                    isSearchable
                    justifyLabel="left"
                    options={years}
                    width="100%"
                    value={
                      initialFields.startYear
                        ? years.find(
                            (item) => item.value === initialFields.startYear
                          )
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleOptionChange({
                        ...selectedOption,
                        type: "startYear",
                      })
                    }
                  />
                </ColumnBlock>
                {savingStatus && !workState.startDate && (
                  <FormErrorMessages>Start date is required.</FormErrorMessages>
                )}
              </RowBlock>
              <RowBlock my={3}>
                <ColumnBlock width="100%">
                  <CheckboxField
                    checked={initialFields.present}
                    name="present"
                    label="I am still working in this position."
                    onChange={(event) => handlePresentChange({ event })}
                    value={initialFields.present}
                    containerStyles={{
                      fontSize: "14px",
                      color: "#444444",
                    }}
                    checkboxStyles={{
                      height: "20px",
                      width: "20px",
                      marginTop: "-1px",
                      marginRight: "10px",
                    }}
                  >
                    I am still working in this position.
                  </CheckboxField>
                </ColumnBlock>
              </RowBlock>
              {!initialFields.present && (
                <RowBlock my={3} justifyContent="flex-start">
                  <ColumnBlock
                    width={{ mobileS: "100%", tablet: "50%" }}
                    pr={{ tablet: 2 }}
                  >
                    <SelectField
                      label="Month finished"
                      isRequired
                      justifyLabel="left"
                      placeholder=""
                      isSearchable
                      options={initialFields.endMonths}
                      value={
                        initialFields.endMonth
                          ? initialFields.endMonths.find(
                              (item) => item.value === initialFields.endMonth
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleOptionChange({
                          ...selectedOption,
                          type: "endMonth",
                        })
                      }
                      width="100%"
                    />
                  </ColumnBlock>
                  <ColumnBlock
                    width={{ mobileS: "100%", tablet: "50%" }}
                    pl={{ tablet: 2 }}
                    mt={{ mobileS: 3, tablet: 0 }}
                  >
                    <SelectField
                      label="Year finished"
                      isRequired
                      justifyLabel="left"
                      placeholder=""
                      isSearchable
                      options={initialFields.endYears}
                      value={
                        initialFields.endYear
                          ? initialFields.endYears.find(
                              (item) => item.value === initialFields.endYear
                            )
                          : null
                      }
                      width="100%"
                      onChange={(selectedOption) =>
                        handleOptionChange({
                          ...selectedOption,
                          type: "endYear",
                        })
                      }
                    />
                  </ColumnBlock>
                  {savingStatus && !workState.endDate && !workState.present && (
                    <FormErrorMessages>End date is required.</FormErrorMessages>
                  )}
                </RowBlock>
              )}
              <Textarea
                label="Details (Optional)"
                otherStyle={{ marginTop: 16 }}
                value={initialFields.description}
                onChange={(event) =>
                  itemHandler({ type: "description", event })
                }
              />
              <RowBlock mt={4}>
                <Button onClick={() => handleSubmit()} width="100%">
                  Save
                </Button>
              </RowBlock>
            </ColumnBlock>
          </RowBlock>
        </RowBlock>
      </>
    );
  };

  if (selectedWork.index === null) {
    insertNewWorkHistory();
  }

  return <div>{WorkHistoryRow(workState)}</div>;
}
