import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $name: String
    $bio: String
    $role: ID
    $experience: Int
    $birthyear: Int
    $livingCity: ID
    $phone: String
    $social: SocialInputType
    $preferences: UserPreferenceInputType
  ) {
    updateUser(
      name: $name
      bio: $bio
      role: $role
      experience: $experience
      birthyear: $birthyear
      livingCity: $livingCity
      phone: $phone
      social: $social
      preferences: $preferences
    ) {
      id
      preferences {
        currency
      }
      criteria {
        salary {
          expected
        }
      }
    }
  }
`;

export const UPDATE_CRITERIA = gql`
  mutation updateDeveloperCriteria(
    $salary: DeveloperSalaryInputType
    $benefits: DeveloperBenefitsInputType
    $technologies: DeveloperTechnologiesInputType
    $location: DeveloperLocationInputType
    $companySize: DeveloperCompanySizeInputType
    $other: DeveloperOtherCriteriaInputType
    $freelance: DeveloperFreelanceInputType
  ) {
    updateDeveloperCriteria(
      salary: $salary
      benefits: $benefits
      technologies: $technologies
      location: $location
      companySize: $companySize
      other: $other
      freelance: $freelance
    ) {
      updatedAt
    }
  }
`;

export const FETCH_FIELD_FROM_LINKEDIN = gql`
  query fetchFieldFromLinkedin($uuid: ID, $field: String, $username: String) {
    fetchFieldFromLinkedin(uuid: $uuid, field: $field, username: $username)
  }
`;
