import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Bubbles from "../../../images/bubbles.svg";
import DefaultAvatar from "../../../images/default-avatar.png";

import { EditIcon } from "../../Icon";

const ProfileAvatarWrapper = styled.div`
  background-image: url(${() => Bubbles});
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
`;

const IconWrap = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  box-shadow: 0 3px 4px 0 #cccccc;
  background-color: #ffffff;
`;

const Avatar = styled.div`
  display: flex;
  width: 110px;
  height: 110px;
  background-size: contain;
  border-radius: 50%;
  background-image: url(${(props) =>
    props.avatar ? props.avatar : DefaultAvatar});
  justify-content: flex-end;
  &:hover ${IconWrap} {
    display: flex;
  }
  :hover {
    cursor: pointer;
  }
`;

export default function ProfileAvatar({ avatar }) {
  const history = useHistory();
  const handleEdit = () => {
    history.push("profile/edit/info");
  };

  return (
    <ProfileAvatarWrapper>
      <Avatar avatar={avatar}>
        <IconWrap>
          <EditIcon color="#aaa" onClick={handleEdit} />
        </IconWrap>
      </Avatar>
    </ProfileAvatarWrapper>
  );
}
