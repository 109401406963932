import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxField } from "../Form/CheckboxField";
import { RadioCheckMark, RadioLabel, RadioTextWrapper } from "./styles";
import {
  setMatchFilter,
  setMatchSort,
  setMatchType,
} from "../../store/slices/matchFilter";
import { MATCH_FILTERS } from "../../utils/constants";

const Radio = ({
  handleChange = () => {},
  width,
  height,
  containerStyles,
  textStyles,
  checked,
  ...r
}) => {
  return (
    <RadioLabel
      paddingLeft={width}
      style={containerStyles}
      onClick={handleChange}
    >
      <RadioTextWrapper style={textStyles}>{r.label}</RadioTextWrapper>
      <input type="radio" name={r.name} value={r.value} checked={checked} />
      <RadioCheckMark width={width} height={height} className="checkmark" />
    </RadioLabel>
  );
};

const FilterTitle = styled.span`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #444444;
`;
const FilterTitleBorder = styled.div`
  margin: 5px 0 6px 0;
  border: 1px solid #444444;
  width: 100%;
`;

const MatchFilterOptions = () => {
  const { checkboxFilters, sortFilter, typeFilter } = useSelector(
    (state) => state.matchFilter
  );
  const dispatch = useDispatch();

  const handleCheckbox = (e) => {
    const { checked, name } = e.target;
    dispatch(setMatchFilter({ checked, name }));
  };

  const handleRadio = (e) => {
    const { value } = e.target;
    dispatch(setMatchSort({ value }));
  };

  const handleType = (e) => {
    const { value } = e.target;
    dispatch(setMatchType({ value }));
  };

  return (
    <div>
      <FilterTitle>Filter Type:</FilterTitle>
      <FilterTitleBorder />
      {MATCH_FILTERS.type.map((t) => (
        <Radio
          key={t.value}
          handleChange={handleType}
          containerStyles={{ marginBottom: 8 }}
          textStyles={{ marginLeft: 8 }}
          checked={t.value === typeFilter}
          width={12}
          height={12}
          {...t}
        />
      ))}
      <FilterTitle>Show:</FilterTitle>
      <FilterTitleBorder />
      {MATCH_FILTERS.checkbox.map((c) => {
        return (
          <CheckboxField
            key={c.name}
            name={c.name}
            label={c.label}
            onChange={handleCheckbox}
            checked={checkboxFilters[c.name]}
            value={checkboxFilters[c.name]}
            containerStyles={{
              fontSize: "12px",
              color: "#444444",
              fontWeight: 500,
              lineHeight: 1.6,
              marginBottom: 8,
            }}
            checkboxStyles={{
              height: "12px",
              width: "12px",
              position: "relative",
              top: "3px",
              marginRight: "8px",
            }}
            iconStyles={{
              position: "relative",
              bottom: 2,
            }}
          >
            {c.label}
          </CheckboxField>
        );
      })}
      <FilterTitle>Sort By:</FilterTitle>
      <FilterTitleBorder />
      {MATCH_FILTERS.radio.map((r) => (
        <Radio
          key={r.value}
          handleChange={handleRadio}
          containerStyles={{ marginBottom: 8 }}
          textStyles={{ marginLeft: 8 }}
          checked={r.value === sortFilter}
          width={12}
          height={12}
          {...r}
        />
      ))}
    </div>
  );
};

export default MatchFilterOptions;
