import React from "react";
import styled from "styled-components";
import { space, layout } from "styled-system";

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  ${layout}
  ${space}
`;

const FormHeading = styled.div`
  display: flex;
  flex-direction: row;
`;

const Status = styled.div`
  min-width: 37px;
  max-width: 37px;
  padding-top: 4px;
`;

const Circle = styled.span`
  width: 15px;
  height: 15px;
  background-color: ${(props) => (props.color ? props.color : "#cccccc")};
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s;
`;

const CheckIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  width: 15px;
  height: 15px;
  padding: 2px;
`;

const FormTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const Label = styled.label`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  color: #444444;
  line-height: 1.79;
  ${space}
`;

const Explanation = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #aaaaaa;
  margin-bottom: 5px;
`;

const FormBody = styled.div`
  ${space}
`;

export default function FormScaffold({
  label,
  status,
  explanation,
  children,
  options = {},
  ...props
}) {
  const STATUS_COLORS = {
    success: "#abe6c1",
    loading: "#fbdd80",
    error: "#ff9898",
    pending: "#aaaaaa",
  };
  const {
    showTitle = true,
    showStatus = true,
    showDescription = true,
  } = options;
  return (
    <FormGroup {...props}>
      <FormHeading>
        {showStatus && (
          <Status>
            <Circle color={STATUS_COLORS[status]}>
              {status === "success" && (
                <CheckIcon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </CheckIcon>
              )}
            </Circle>
          </Status>
        )}
        <FormTitle>
          {showTitle && <Label>{label}</Label>}

          {showDescription && explanation && (
            <Explanation>{explanation}</Explanation>
          )}
        </FormTitle>
      </FormHeading>
      <FormBody pl={{ mobileS: "10px", tablet: "37px" }}>{children}</FormBody>
    </FormGroup>
  );
}
