import React, { useState, useEffect, useContext } from "react";
import "url-search-params-polyfill";
import { useHistory } from "react-router-dom";
import { SingleHeader } from "../../components/Header";
import { Container, OnboardingFormBox } from "../../components/BlockUi";
import EmailStep from "./steps/EmailStep";
import PinStep from "./steps/PinStep";
import { handleHeadTag } from "../../helper";
import { boot as bootIntercom } from "../../utils/Intercom";
import { WebSocketContext } from "../../WebSocket";
import AppContext from "../../AppContext";

function updateLocalStorage(state) {
  localStorage.setItem("loginFormState", JSON.stringify(state));
}

function readLocalStorageState() {
  let state;
  const localStorageItem = localStorage.getItem("loginFormState");

  if (localStorageItem) {
    state = JSON.parse(localStorageItem);
  } else {
    state = null;
  }
  return state;
}

export default function Login() {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [formState, setFormState] = useState({
    email: "",
    pin: null,
    expireMinute: 0,
  });
  const [stepName, setStepName] = useState("email");
  const ws = useContext(WebSocketContext);

  const applyChange = (changes) => {
    const newState = {
      ...formState,
      ...changes,
    };

    setFormState(newState);
    const { email, profile, ...state } = newState;
    updateLocalStorage(state);
  };

  const goToStep = (stepName) => {
    setStepName(stepName);
    window.history.pushState({}, "", `login?step=${stepName}`);
  };

  const setCurrentStepFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get("step");

    if (step) {
      setStepName(step);
    } else {
      setStepName("email");
    }
  };

  useEffect(() => {
    const state = readLocalStorageState();
    if (state) {
      setFormState({
        ...state,
        email: formState.email,
        profile: formState.profile,
      });
    }

    setCurrentStepFromURL();

    window.addEventListener("popstate", setCurrentStepFromURL);
    // eslint-disable-next-line
  }, []);

  const handleEmailSubmit = ({ email, expireMinute }) => {
    applyChange({ email, expireMinute });
    goToStep("pin");
  };

  const handleDigitSubmit = ({ login, publicPositionToken }) => {
    const { userId } = login;
    bootIntercom({ id: userId });
    localStorage.setItem("isAuth", true);
    ws.boot();
    if (publicPositionToken) {
      history.push("/matches");
    } else {
      history.push(history.location.state?.referrer || "/profile");
    }
    appContext.refresh();
  };

  handleHeadTag("login");
  let step;

  switch (stepName) {
    case "email":
      step = (
        <EmailStep email={formState.email} handleSubmit={handleEmailSubmit} />
      );
      break;
    case "pin":
      step = (
        <PinStep
          email={formState.email}
          expireMinute={formState.expireMinute}
          handleSubmit={handleDigitSubmit}
        />
      );
      break;
    default:
      break;
  }
  return (
    <>
      <SingleHeader />
      <Container>
        <OnboardingFormBox
          withCover
          textAlign="center"
          pt={{ mobileS: 4, laptop: 5, tablet: 4 }}
          px={{ mobileS: 1, laptop: 5, tablet: 4 }}
          mt={{ mobileS: 4, laptop: 4, tablet: 4 }}
        >
          {step}
        </OnboardingFormBox>
      </Container>
    </>
  );
}
