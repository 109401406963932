import styled from "styled-components";
import { space, variant, layout, fontSize, flexbox } from "styled-system";

export const btnSize = {
  large: "280px",
  mediumLarge: "240px",
  medium: "180px",
  small: "120px",
};

/**
 *
 * @param {string} variant - primary || secondary || link
 * @param {string} size - large || medium || small
 */
export const Button = styled("button")(
  {
    fontFamily: "Avenir Next LT Pro Bold",
    height: "40px",
    borderRadius: "5px",
    border: "none",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "1.6",
    position: "relative",
  },
  fontSize,
  space,
  layout,
  flexbox,
  variant({
    variants: {
      primary: {
        color: "#fff",
        bg: "#8fdeac",
        boxShadow: "0 10px 12px 0 #ededed",
        "&:hover:enabled": {
          boxShadow: "none",
        },
        "&:focus:enabled": {
          bg: "#8dd2a6",
          boxShadow: "none",
        },
        "&:disabled": {
          bg: "#ddd",
          cursor: "default",
          boxShadow: "none",
        },
      },
      secondary: {
        color: "#444",
        bg: "#fff",
        border: "solid 1px #444",
        "&:hover:enabled": {
          borderWidth: "2px",
        },
        "&:focus:enabled": {
          borderWidth: "2px",
        },
        "&:disabled": {
          borderColor: "#aaa",
          color: "#aaa",
          cursor: "default",
        },
      },
      transparent: {
        color: "#444",
        bg: "transparent",
        border: "solid 1px #444",
        "&:hover:enabled": {
          borderWidth: "2px",
        },
        "&:focus:enabled": {
          borderWidth: "2px",
        },
        "&:disabled": {
          borderColor: "#aaa",
          color: "#aaa",
          cursor: "default",
        },
      },
      danger: {
        color: "#fff",
        bg: "#ed5249",
        boxShadow: "0 10px 12px 0 #ededed",
        "&:hover:enabled": {
          boxShadow: "none",
        },
        "&:focus:enabled": {
          borderWidth: "2px",
        },
      },
      oauth: {
        color: "#444",
        bg: "#fff",
        border: "solid 1px #444",
        "&:disabled": {
          borderColor: "#aaa",
          color: "#aaa",
          cursor: "default",
        },
      },
      link: {
        bg: "transparent",
        color: "#aaaaaa",
        fontSize: fontSize || "14px",
        fontFamily: "Avenir Next LT Pro Medium",
        fontWeight: "500",
        "&:hover:enabled": {
          textDecoration: "underline",
        },
      },
      linkedin: {
        bg: "#fff",
        color: "#0072B1",
        border: "solid 1px #0072B1",
        fontSize: "16px",
        "&:hover:enabled": {
          borderWidth: "2px",
        },
        "&:focus:enabled": {
          borderWidth: "2px",
        },
      },
    },
  }),
  variant({
    prop: "size",
    variants: {
      large: {
        width: btnSize.large,
      },
      mediumLarge: {
        width: btnSize.mediumLarge,
      },
      medium: {
        width: btnSize.medium,
      },
      small: {
        width: btnSize.small,
      },
    },
  })
);
