import React from "react";
import styled from "styled-components";
import { Badge } from "tg-design";
import DataGroup from "../Modules/DataGroup";
import {
  POSITION_HIGHLIGHT_CONTENT,
  POSITION_HIGHLIGHT_COLOR,
} from "../../utils/constants";
import device from "../../styles/device";

const HighlightRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: calc(704px - 32px);
  width: calc(100vw - 32px);
  overflow: auto;
  @media ${device.tablet} {
    flex-wrap: wrap;
    max-width: unset;
    width: unset;
    overflow: unset;
  }
`;

const HighlightWrapper = styled.div`
  margin: 12px 16px 8px 0;
`;

const PositionHighlights = ({ publicPosition, swipeableHandlers }) => {
  const handleHighlightRowTouchMove = (e) => {
    if (swipeableHandlers) {
      swipeableHandlers.ref();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  if (!publicPosition.highlights || publicPosition.highlights.length === 0) {
    return null;
  }

  return (
    <DataGroup title="Highlights">
      <HighlightRow onTouchMove={handleHighlightRowTouchMove}>
        {publicPosition.highlights.map(({ value, type }) => (
          <HighlightWrapper key={value}>
            <Badge
              {...POSITION_HIGHLIGHT_CONTENT[value]}
              {...POSITION_HIGHLIGHT_COLOR[type]}
            />
          </HighlightWrapper>
        ))}
      </HighlightRow>
    </DataGroup>
  );
};

export default PositionHighlights;
