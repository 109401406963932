import React from "react";
import {
  CriteriaFieldDescription,
  CriteriaFieldTitle,
  ColumnBlock,
} from "../BlockUi";
import { COMPANY_SIZES } from "../../utils/constants";
import { BlockCheckboxField } from "../Form/CheckboxField";

const CompanySizeCriteria = ({ selectedValues, handleOnChange }) => {
  const filteredValues = selectedValues.expected.filter((e) => e);

  const handleOnSelection = (id) => {
    if (filteredValues.find((s) => s.id === id)) {
      handleOnChange(filteredValues.filter((s) => s.id !== id));
    } else {
      handleOnChange([...filteredValues, { id }]);
    }
  };

  return (
    <>
      <ColumnBlock>
        <CriteriaFieldTitle>
          What is the size of the company you would like to work for?
        </CriteriaFieldTitle>
        <CriteriaFieldDescription>
          You can make multiple selections.
        </CriteriaFieldDescription>
        {COMPANY_SIZES.map(({ id, label }, index) => (
          <BlockCheckboxField
            label={label}
            key={index}
            id={id}
            checked={filteredValues.find((s) => s.id === id)}
            name="expected"
            value={id}
            onChange={() => handleOnSelection(id)}
            icon
          />
        ))}
      </ColumnBlock>
    </>
  );
};

export default CompanySizeCriteria;
