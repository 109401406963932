import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import ErrorPage from "../../pages/ErrorPage";
import { GET_USER_BY_INVITER_CODE } from "../../pages/queries";
import {
  PositionTabIcon,
  CompanyTabIcon,
  AboutTgTabActiveIcon,
  AboutTgTabPassiveIcon,
} from "../Icon";
import { TgTabContent } from "../TgHeaderTabs";
import PositionDetailHeader from "./PositionDetailHeader";
import ReferrerModal from "./ReferrerModal";
import CompanyTab from "./tabs/AboutTheCompanyTab";
import RoleTab from "./tabs/AboutTheRoleTab";
import TalentGridTab from "./tabs/AboutTheTGTab";

export default function PublicPosition({
  publicPosition,
  forceMatchToken,
  isUserAuth,
  userData,
  referrerCode,
  matchCreationType,
}) {
  const [currentTab, setCurrentTab] = useState("position");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inviter, setInviter] = useState(null);

  const [getInviter, { error, loading }] = useLazyQuery(
    GET_USER_BY_INVITER_CODE,
    {
      fetchPolicy: `network-only`,
      onCompleted: (data) => {
        if (data?.positionInviter) {
          setInviter(data.positionInviter);
        }
      },
    }
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (referrerCode) {
      getInviter({ variables: { inviteCode: referrerCode } });
      setIsModalOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  const tabs = [
    {
      name: "position",
      title: "About the role",
      icon: PositionTabIcon,
    },
    {
      name: "company",
      title: "About the company",
      icon: CompanyTabIcon,
    },
    {
      name: "talentgrid",
      title: "About TalentGrid",
      icon:
        currentTab === "talentgrid"
          ? AboutTgTabActiveIcon
          : AboutTgTabPassiveIcon,
    },
  ];

  const handleLeft = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    if (currentIndex === tabs.length - 1) {
      return;
    }
    setCurrentTab(tabs[currentIndex + 1].name);
  };

  const handleRight = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    if (currentIndex < 1) {
      return;
    }

    setCurrentTab(tabs[currentIndex - 1].name);
  };

  const swipeableHandlers = useSwipeable({
    onSwiped: (event) => {
      if (event.dir === "Left") {
        handleLeft();
      } else if (event.dir === "Right") {
        handleRight();
      }
    },
  });

  const getTab = () => {
    let Component = "position";
    switch (currentTab) {
      case "position":
        Component = <RoleTab publicPosition={publicPosition} />;
        break;
      case "company":
        Component = <CompanyTab publicPosition={publicPosition} />;
        break;
      case "talentgrid":
        Component = <TalentGridTab />;
        break;
      default:
        throw new Error(`Undefined tab: ${currentTab}`);
    }

    return Component;
  };

  if (loading) return "Loading...";
  if (error) return <ErrorPage error={error} />;

  return (
    <>
      {inviter && (
        <ReferrerModal
          open={isModalOpen}
          handleClose={closeModal}
          inviter={inviter}
          buttonText="Let's see"
          descriptionTitle="thought you could be a great match for this
          role."
          descriptionText="Were they right?"
        />
      )}

      <PositionDetailHeader
        forceMatchToken={forceMatchToken}
        referrerCode={referrerCode}
        matchCreationType={matchCreationType}
        publicPosition={publicPosition}
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        isUserAuth={isUserAuth}
        userData={userData}
      />
      <TgTabContent heapInfo="" swipeableHandlers={swipeableHandlers}>
        {getTab()}
      </TgTabContent>
    </>
  );
}
