import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Wrapper } from "./CommonStyles";
import { RowBlock, StickyBottom } from "../BlockUi";
import { Button } from "../FormUi";
import CriteriaGreeting from "../CriteriaGreeting";
import { ErrorField } from "../Modules/ErrorField";
import {
  SalaryCritieria,
  BenefitsCriteria,
  TechnologiesCriteria,
  LocationCriteria,
  CompanySizeCriteria,
  FreelanceCriteria,
  OtherCriteria,
} from "../Criterias/index";
import { captureErrorWithData } from "../../helper";
import { boot as bootIntercom } from "../../utils/Intercom";
import { ADD_CRITERIA } from "./queries";
import CriteriaSelectionCard from "../CriteriaSelectionCard";

export default function CriteriaStep({ handleCriteria, userInfo, step }) {
  const [addCriteria] = useMutation(ADD_CRITERIA);
  const [signal, setSignal] = useState(true);
  const [isCityEmpty, setIsCityEmpty] = useState(false);
  const [formState, setFormState] = useState({
    salary: {
      expected: "",
      toggle: false,
      period: null,
      income: null,
      currency: null,
    },
    benefits: {
      expected: [],
      toggle: false,
    },
    technologies: {
      expected: [],
      toggle: false,
    },
    location: {
      expected: [],
      remote: "",
      toggle: false,
      istanbulRegion: null,
    },
    freelance: {
      availableWeeklyHour: null,
      hourlyRate: null,
      hourlyRateCurrency: null,
      toggle: false,
    },
    other: {
      expected: "",
      toggle: false,
    },
    companySize: {
      toggle: false,
      expected: [],
    },
  });

  const applyChange = (changes) => {
    const newState = {
      ...formState,
      ...changes,
    };

    setFormState(newState);
  };

  const onSubmitForm = async () => {
    try {
      const { data } = await addCriteria({
        variables: {
          salary: {
            open: true,
            expected: formState?.salary?.expected || 0,
            period: formState?.salary?.period,
            income: formState.salary.income,
            currency: formState.salary.currency,
          },
          technologies: {
            open: formState.technologies.toggle,
            expected: formState.technologies.expected.map(({ id }) => id),
          },
          location: {
            open: formState.location.toggle,
            remote: formState.location.remote,
            expected: formState.location.expected.map(({ id }) => id),
            istanbulRegion: formState.location.istanbulRegion,
          },
          other: {
            open: formState.other.toggle,
            expected: formState.other.expected,
          },
          benefits: {
            open: formState.benefits.toggle,
            expected: formState.benefits.expected.map(({ id }) => id),
          },
          companySize: {
            open: formState.companySize.toggle,
            expected: formState.companySize.expected.map(({ id }) => id),
          },
          freelance: {
            open: formState.freelance.toggle,
            availableWeeklyHour: formState.freelance.availableWeeklyHour,
            hourlyRate: formState.freelance.hourlyRate,
            hourlyRateCurrency: formState.freelance.hourlyRateCurrency,
          },
        },
      });
      if (data) {
        bootIntercom({ user_id: userInfo.me.id, status: "MEMBER" });
        handleCriteria();
      }
    } catch (error) {
      captureErrorWithData(error);
    }
  };

  useEffect(() => {
    setSignal(false);

    if (!formState.salary.toggle) {
      setSignal(true);
    }
  }, [formState.salary.toggle]);

  useEffect(() => {
    setFormState({
      ...formState,
      freelance: {
        hourlyRateCurrency: userInfo?.me?.preferences?.currency || "USD",
      },
    });
    // eslint-disable-next-line
  }, [userInfo]);

  const handleOnToggle = (criteria) => {
    formState[criteria].toggle = !formState[criteria].toggle;
    applyChange(formState);
  };

  const handleSalaryChange = (salary) => {
    applyChange({ salary: { ...formState.salary, ...salary } });
  };

  const handleBenefitsChange = (values) => {
    applyChange({
      benefits: { ...formState.benefits, expected: values || [] },
    });
  };

  const handleCompanySizeChange = (expected) => {
    applyChange({ companySize: { ...formState.companySize, expected } });
  };

  const handleOnTechnologiesChange = (values) => {
    applyChange({
      technologies: { ...formState.technologies, expected: values || [] },
    });
  };

  const handleOnLocationChange = (values) => {
    setIsCityEmpty(false);
    if (!values.expected) {
      setIsCityEmpty(true);
    }
    applyChange({
      location: { ...formState.location, ...values },
    });
  };

  const handleOnOtherChange = (values) => {
    applyChange({
      other: { ...formState.other, ...values },
    });
  };

  const handleOnFreelanceChange = (e) => {
    const key =
      e.target?.name ||
      (e.key === "expected" ? "hourlyRate" : "hourlyRateCurrency");
    const value = e.target?.value || e.value;
    const freelance = Object.assign(formState.freelance, {
      [key]: key === "hourlyRateCurrency" ? value : parseInt(value, 10),
    });
    applyChange({ freelance });
  };

  return (
    <>
      <RowBlock mt={4}>
        <Wrapper mx={{ mobileS: 1, laptop: 7, tablet: 5 }}>
          <CriteriaGreeting />
        </Wrapper>
      </RowBlock>
      <RowBlock mt={4} mb={5}>
        <RowBlock width="100%">
          <CriteriaSelectionCard
            id="salary"
            active={formState?.salary?.toggle}
            label="Salary"
            handleToggle={handleOnToggle}
            key="salary"
            signal={signal}
          >
            <SalaryCritieria
              selectedValues={formState?.salary}
              handleOnChange={handleSalaryChange}
              user={userInfo?.me}
            />
          </CriteriaSelectionCard>
        </RowBlock>
        <RowBlock width="100%" mt={4}>
          <CriteriaSelectionCard
            label="Work Location"
            id="location"
            active={formState?.location?.toggle}
            handleToggle={handleOnToggle}
          >
            <LocationCriteria
              selectedValues={formState?.location}
              handleOnChange={handleOnLocationChange}
              user={userInfo ? userInfo.me : {}}
              hasError={isCityEmpty}
            />
            {isCityEmpty && (
              <ErrorField
                position="static"
                errorText="Please select at least one city or country"
              />
            )}
          </CriteriaSelectionCard>
        </RowBlock>
        <RowBlock width="100%" mt={4}>
          <CriteriaSelectionCard
            label="Side Benefits"
            id="benefits"
            active={formState?.benefits?.toggle}
            handleToggle={handleOnToggle}
            key="benefits"
          >
            <BenefitsCriteria
              selectedValues={formState?.benefits}
              handleOnChange={handleBenefitsChange}
            />
          </CriteriaSelectionCard>
        </RowBlock>
        <RowBlock width="100%" mt={4}>
          <CriteriaSelectionCard
            label="Tech Stack"
            id="technologies"
            active={formState?.technologies?.toggle}
            handleToggle={handleOnToggle}
          >
            <TechnologiesCriteria
              selectedValues={formState?.technologies}
              handleOnChange={handleOnTechnologiesChange}
            />
          </CriteriaSelectionCard>
        </RowBlock>
        <RowBlock width="100%" mt={4}>
          <CriteriaSelectionCard
            label="Freelance"
            id="freelance"
            active={formState?.freelance?.toggle}
            handleToggle={handleOnToggle}
          >
            <FreelanceCriteria
              selectedValues={formState?.freelance}
              handleOnChange={handleOnFreelanceChange}
              currency={formState?.freelance?.hourlyRateCurrency}
            />
          </CriteriaSelectionCard>
        </RowBlock>
        <RowBlock width="100%" mt={4}>
          <CriteriaSelectionCard
            label="Company Size"
            id="companySize"
            active={formState?.companySize?.toggle}
            handleToggle={handleOnToggle}
          >
            <CompanySizeCriteria
              selectedValues={formState?.companySize}
              handleOnChange={handleCompanySizeChange}
            />
          </CriteriaSelectionCard>
        </RowBlock>
        <RowBlock width="100%" mt={4} mb={4}>
          <CriteriaSelectionCard
            label="Other"
            id="other"
            active={formState?.other?.toggle}
            handleToggle={handleOnToggle}
          >
            <OtherCriteria
              selectedValues={formState?.other}
              handleOnChange={handleOnOtherChange}
            />
          </CriteriaSelectionCard>
        </RowBlock>
      </RowBlock>
      <StickyBottom mt={3}>
        <Button
          disabled={
            formState.salary.expected === "" ||
            !formState.salary.toggle ||
            (isCityEmpty && formState?.location?.toggle)
          }
          my={3}
          onClick={onSubmitForm}
          className="register-completed"
        >
          {step.buttonText}
        </Button>
      </StickyBottom>
    </>
  );
}
