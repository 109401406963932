import React, { useRef } from "react";
import styled from "styled-components";

const StyledUl = styled.ul`
  list-style: none;
  padding: 0px;
  display: flex;
  margin: 0px;
  overflow-x: auto;
  scroll-behavior: smooth;
  position: relative;
  z-index: 0;

  ::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: "";
    display: block;
    background-color: #e3e3e3;
    height: 4px;
    width: 100%;
    left: 0px;
    position: absolute;
    bottom: 0px;
  }
`;

const getScrollPosition = (currentIndex, ulRef) => {
  if (currentIndex === 0) {
    return 0;
  }

  if (currentIndex) {
    let previousElementsWidth = 0;
    for (let index = 0; index <= currentIndex; index++) {
      previousElementsWidth +=
        ulRef.current.children[index].getBoundingClientRect().width;
    }

    const newPosition = parseInt(
      (ulRef.current.offsetWidth * previousElementsWidth) /
        ulRef.current.scrollWidth,
      10
    );

    return newPosition;
  }

  return ulRef.current.scrollLeft;
};

export default function TgHeaderTabs({
  currentTab,
  currentIndex,
  handleChange,
  children,
  ...props
}) {
  const ulRef = useRef();

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { currentTab, handleChange });
  });

  if (ulRef.current) {
    ulRef.current.scrollLeft = getScrollPosition(currentIndex, ulRef);
  }

  return (
    <StyledUl ref={ulRef} {...props}>
      {childrenWithProps}
    </StyledUl>
  );
}
