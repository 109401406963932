import React, { useState, useEffect, useContext } from "react";
import "url-search-params-polyfill";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import styled from "styled-components";
import { ButtonLink } from "tg-design";
import {
  sleep,
  uuidGenerate,
  handleHeadTag,
  captureErrorWithData,
} from "../../helper";
import {
  boot as bootIntercom,
  update as updateIntercom,
} from "../../utils/Intercom";
import { SingleHeader } from "../../components/Header";
import { Container, OnboardingFormBox } from "../../components/BlockUi";
import { BasicLink } from "../../components/FormUi";
import profileValidationSchema from "./profileValidationSchema";
import ProfessionSelectionStep from "./steps/ProfessionSelectionStep";
import RoleSelectionStep from "./steps/RoleSelectionStep";
import ExperienceSelectionStep from "./steps/ExperienceSelectionStep";
import SkillSelectionStep from "./steps/SkillSelectionStep";
import EmailSelectionStep from "./steps/EmailSelectionStep";
import ProfileSelectionStep from "./steps/ProfileSelection";
import LanguageSelection from "./steps/LanguageSelection";
import ProgressBar from "../../components/ProgressBar";
import { Button } from "../../components/Button";
import { WebSocketContext } from "../../WebSocket";
import {
  CREATE_USER,
  LOGIN,
  GET_USER_BY_INVITER_CODE,
  UPDATE_USER,
  UPDATE_LANGUAGE,
} from "../queries";
import { LANGUAGE_LEVEL_TYPES_WITH_KEYS } from "../../utils/constants";
import message from "../../utils/message";
import {
  GetUTMParamsFromSession,
  RemoveUTMParamsFromSession,
  SetUTMParamsToSession,
} from "../../utils/utms";
import { ArrowLeft } from "../../components/Icon";
import { useURLQuery } from "../../utils/hooks";
import ReferrerModal from "../../components/PublicPosition/ReferrerModal";
import ErrorPage from "../ErrorPage";

const LinkButton = styled(ButtonLink)`
  display: none;

  @media only screen and (min-width: 992px) {
    display: unset;
    position: absolute;
    left: calc(50% - 500px);
    top: 100px;
    text-decoration-line: underline;
    display: flex;
    gap: 4px;
    align-items: center;

    & svg {
      height: 12px;
    }
  }
`;

const OnlyMobile = styled.div`
  display: none;

  @media only screen and (max-width: 992px) {
    display: unset;
  }
`;

function updateLocalStorage(state) {
  localStorage.setItem("registrationFormState", JSON.stringify(state));
}

function readLocalStorageState() {
  let state;
  const localStorageItem = localStorage.getItem("registrationFormState");

  if (localStorageItem) {
    state = JSON.parse(localStorageItem);
  } else {
    state = null;
  }
  return state;
}

const DEFAULT_LANGUAGES = [
  {
    id: "",
    label: null,
    level: null,
  },
];

export default function Registration() {
  const ws = useContext(WebSocketContext);
  const history = useHistory();
  const queryString = useURLQuery();

  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateUserLanguage] = useMutation(UPDATE_LANGUAGE);
  const [sessionID] = useState(() => {
    const session = localStorage.getItem("session");
    if (!session) {
      const id = uuidGenerate();
      localStorage.setItem("session", id);
      return id;
    }
    return session;
  });

  const [login] = useMutation(LOGIN);
  const [profileValid, setProfileValid] = useState(false);
  const [inviter, setInviter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formState, setFormState] = useState({
    skills: [
      { experience: null, id: "", title: "", isShow: true, isFocus: true },
      { experience: null, id: "", title: "", isShow: true, isFocus: false },
      { experience: null, id: "", title: "", isShow: false, isFocus: false },
      { experience: null, id: "", title: "", isShow: false, isFocus: false },
      { experience: null, id: "", title: "", isShow: false, isFocus: false },
    ],
    roles: [
      { experience: null, id: "", title: "", isShow: true, isFocus: true },
      { experience: null, id: "", title: "", isShow: true, isFocus: false },
      { experience: null, id: "", title: "", isShow: false, isFocus: false },
    ],
    email: {
      email: "",
      // apide tek kvkk gonderilecek sekilde duzenleme yapilacak
      kvkkRead: true,
      kvkkAccept: false,
      localKvkkRead: true,
      localKvkkAccept: false,
      pin: null,
      country: null,
    },
    profile: {
      fullName: "",
    },
    languages: DEFAULT_LANGUAGES,
    profession: 0,
  });

  const states = {
    profession: 0,
    experience: 1,
    role: 2,
    skill: 3,
    email: 4,
    profile: 5,
    language: 6,
  };
  const [stepName, setStepName] = useState("profession");

  const [getInviter, { error }] = useLazyQuery(GET_USER_BY_INVITER_CODE, {
    fetchPolicy: `network-only`,
    onCompleted: (data) => {
      if (data?.positionInviter) {
        setInviter(data.positionInviter);
      }
    },
  });

  useEffect(() => {
    const invitationCode = queryString.get("invite");

    if (invitationCode) {
      getInviter({ variables: { inviteCode: invitationCode } });
      setIsModalOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const validateForm = async () => {
      try {
        const profileValidResponse = await profileValidationSchema.validate({
          profile: formState.profile,
        });
        setProfileValid(profileValidResponse);
      } catch (err) {
        setProfileValid(false);
      }
    };

    validateForm();
  }, [formState.profile]);

  const applyChange = (changes) => {
    const newState = {
      ...formState,
      ...changes,
    };
    setFormState(newState);
    const { email, profile, ...state } = newState;
    updateLocalStorage(state);
    return state;
  };

  const goToStep = (stepName) => {
    setStepName(stepName);
    window.history.pushState({}, "", `register?step=${stepName}`);
  };

  const prevState = () => {
    const stepNumber = states[stepName];
    goToStep(Object.keys(states)[stepNumber - 1]);
  };

  const nextStep = () => {
    const stepNumber = states[stepName];
    goToStep(Object.keys(states)[stepNumber + 1]);
  };

  const handleRegisterForm = async () => {
    const userSkills = formState.skills
      .filter((s) => s.id !== "")
      .map(({ id, experience }) => {
        return { _id: id, experience };
      });

    const userRoles = (formState.roles || [])
      .filter((s) => s.id !== "")
      .map(({ id, experience }) => {
        return { role: id, experience };
      });

    const utms = GetUTMParamsFromSession();

    try {
      const publicPositionInfo = JSON.parse(sessionStorage.getItem("ppi"));

      const { data } = await createUser({
        variables: {
          sessionID,
          email: formState.email.email,
          kvkkAccept: formState.email.kvkkAccept,
          kvkkRead: formState.email.kvkkRead,
          localKvkkRead: formState.email.localKvkkRead,
          localKvkkAccept: formState.email.localKvkkAccept,
          pin: formState.email.pin,
          role: userRoles[0]?.role || null,
          roles: userRoles,
          profession: formState.profession.id || 0,
          skills: userSkills,
          experience: Number(formState.experience),
          inviter:
            sessionStorage.getItem("inviteCode") ||
            publicPositionInfo?.inviteCode,
          forceMatchToken: sessionStorage.getItem("fmt"),
          creatorToken: sessionStorage.getItem("creatorToken"),
          publicPositionToken: publicPositionInfo?.ppt,
          matchCreationType: publicPositionInfo?.type,
          inviteeEmail: sessionStorage.getItem("inviteeEmail"),
          utms,
        },
      });

      if (data) {
        applyChange({ userId: data.addUser.id });
        const payload = await login({
          variables: {
            email: formState.email.email,
            digit: formState.email.pin,
          },
        });
        if (payload.data) {
          localStorage.setItem("isAuth", true);
        }
        bootIntercom(data.addUser);
        updateIntercom({
          utm_campaign: utms?.campaign,
          utm_content: utms?.content,
          utm_medium: utms?.medium,
          utm_source: utms?.source,
          utm_term: utms?.term,
        });
        RemoveUTMParamsFromSession();
        nextStep();
      }
    } catch (error) {
      message.error("Something went wrong.");
      captureErrorWithData(error);
    }
  };

  const handleProfileUpdate = async () => {
    try {
      await updateUser({
        variables: {
          livingCity: formState.profile.livingCity
            ? formState.profile.livingCity.id
            : null,
          name: formState.profile.fullName,
          birthyear: formState.profile.birthyear,
        },
      });
      nextStep();
    } catch (error) {
      message.error("Something went wrong.");
      captureErrorWithData(error);
    }
  };

  const handleLanguageUpdate = async () => {
    const nativeLanguage = formState.languages.filter(
      (language) =>
        language.id &&
        language.level ===
          LANGUAGE_LEVEL_TYPES_WITH_KEYS.sort((a, b) => {
            return b.value - a.value;
          })[0].value
    );
    if (nativeLanguage.length > 0) {
      try {
        const variables = formState.languages
          .filter((language) => language.id && language.level)
          .map((language) => {
            return { language: language.id, level: language.level };
          });

        const { data } = await updateUserLanguage({
          variables: { languages: variables },
        });

        if (data) {
          const forceMatchToken = sessionStorage.getItem("fmt");
          const publicPositionInfo = JSON.parse(sessionStorage.getItem("ppi"));
          bootIntercom(data.updateUser);
          localStorage.setItem("isAuth", true);
          ws.boot();
          if (forceMatchToken || publicPositionInfo?.token) {
            history.push("/matches");
          } else {
            history.push("/profile");
          }

          sessionStorage.removeItem("fmt");
          sessionStorage.removeItem("ppi");
          sessionStorage.removeItem("inviteeEmail");
        }
      } catch (error) {
        message.error("Something went wrong.");
        captureErrorWithData(error);
      }
    } else {
      message.error(
        "You haven’t entered a native language.  The more the better."
      );
    }
  };

  const handleRoleSelection = async ({ id, title, experience }, index) => {
    const exp = !id ? null : experience || 1;
    const changes = formState.roles.map((item, i) => {
      if (i === index) {
        return { ...item, id, title, experience: exp, isShow: true };
      }
      // for next row
      if (i - index === 1) {
        return { ...item, isShow: true, isFocus: true };
      }
      return item;
    });

    applyChange({ ...formState, roles: changes });
  };

  const handleProfessionSelection = async (event) => {
    const professionId = parseInt(event.target.id);
    applyChange({
      profession: { id: professionId, title: event.target.name },
    });
    await sleep(200);
    nextStep();
  };

  const handleProfileSelection = ({ value, name }) => {
    applyChange({ profile: { ...formState.profile, [name]: value } });
  };

  const handleExperienceSelection = async (event) => {
    applyChange({ experience: event.target.value });
    await sleep(200);
    nextStep();
  };

  const handleEmailStepSelection = async (event) => {
    applyChange({ email: { ...formState.email, ...event } });
  };

  const handleSkillSelection = async ({ id, title, experience }, index) => {
    const exp = !id ? null : experience || 1;

    const changes = formState.skills.map((item, i) => {
      if (i === index) {
        return { ...item, id, title, experience: exp, isShow: true };
      }
      // for next row
      if (i - index === 1) {
        return { ...item, isShow: true, isFocus: true };
      }
      return item;
    });

    applyChange({ ...formState, skills: changes });
  };

  const setCurrentStepFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get("step");

    if (step) {
      setStepName(step);
    } else {
      setStepName("profession");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCode = urlParams.get("invite");
    const forceMatchToken = urlParams.get("fmt");

    if (forceMatchToken && forceMatchToken !== "") {
      sessionStorage.setItem("fmt", forceMatchToken);
    }
    if (inviteCode && inviteCode !== "") {
      sessionStorage.setItem("inviteCode", inviteCode);
    }
    if (!sessionStorage.getItem("creatorToken")) {
      const creatorToken = uuidGenerate();
      sessionStorage.setItem("creatorToken", creatorToken);
    }
    SetUTMParamsToSession();
  }, []);

  useEffect(() => {
    const state = readLocalStorageState();

    if (state) {
      setFormState({
        ...state,
        languages: state.languages || DEFAULT_LANGUAGES,
        email: formState.email,
        profile: formState.profile,
      });
    }

    setCurrentStepFromURL();

    window.addEventListener("popstate", setCurrentStepFromURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  handleHeadTag("register");

  let step;

  switch (stepName) {
    case "email":
      step = (
        <EmailSelectionStep
          onSelect={handleEmailStepSelection}
          nextStep={handleRegisterForm}
          selectedValues={formState.email}
        />
      );
      break;
    case "profession":
      step = (
        <ProfessionSelectionStep
          onSelect={handleProfessionSelection}
          selectedProfession={formState?.profession?.title || ""}
        />
      );
      break;
    case "role":
      step = (
        <RoleSelectionStep
          onSelect={handleRoleSelection}
          selectedRoles={formState.roles || []}
          selectedProfession={formState?.profession?.id || 1}
          nextStep={nextStep}
        />
      );
      break;
    case "experience":
      step = (
        <ExperienceSelectionStep
          selectedExperience={formState.experience}
          onSelect={handleExperienceSelection}
        />
      );
      break;
    case "skill":
      step = (
        <SkillSelectionStep
          selectedRole={formState.roles[0]}
          selectedSkills={formState.skills}
          onSelect={handleSkillSelection}
          nextStep={nextStep}
        />
      );
      break;
    case "profile":
      step = (
        <ProfileSelectionStep
          handleProfileChange={handleProfileSelection}
          onSubmit={handleProfileUpdate}
          selectedValues={formState.profile}
          formValid={profileValid}
          formState={formState}
          setFormState={setFormState}
        />
      );
      break;
    case "language":
      step = (
        <LanguageSelection
          onSubmit={handleLanguageUpdate}
          selectedValues={formState.languages}
          formState={formState}
          setFormState={setFormState}
        />
      );
      break;
    default:
      break;
  }

  if (error) return <ErrorPage error={error} />;

  return (
    <>
      {inviter && (
        <ReferrerModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          inviter={inviter}
          buttonText="Let's go"
          descriptionTitle="invited you to join Talentgrid."
          descriptionText="If you get hired on the platform, you each get $250 cash reward."
        />
      )}
      <SingleHeader to="register" />
      <ProgressBar
        hideValue
        max={7}
        value={states[stepName] + 1}
        width="100%"
        color="#0a0d4c"
      />
      <Container>
        {states[stepName] !== states.profession &&
          states[stepName] !== states.email && (
            <LinkButton onClick={prevState}>
              <ArrowLeft color="#aaaaaa" />
              Back
            </LinkButton>
          )}
        <OnboardingFormBox
          withCover
          textAlign="center"
          pt={{ mobileS: 4, tablet: 4, laptop: 4 }}
          pb={{ mobileS: 4, tablet: 4, laptop: 4 }}
          mt={{ mobileS: 4, tablet: 4, laptop: 4 }}
          px={{ mobileS: 0, tablet: "110px" }}
        >
          {step}
        </OnboardingFormBox>
        {states[stepName] === states.email && (
          <BasicLink
            color="font.1"
            fontFamily="Avenir Next LT Pro Medium"
            fontSize="12px"
            mt="20px"
            onClick={() => history.push("/login")}
          >
            Have you registered before?
            <u> Log in</u>
          </BasicLink>
        )}
        <OnlyMobile>
          {states[stepName] !== states.profession &&
            states[stepName] !== states.email && (
              <u>
                <Button onClick={prevState} variant="link" fontSize="12px">
                  Back
                </Button>
              </u>
            )}
        </OnlyMobile>
      </Container>
    </>
  );
}
