export const heapIO = {
  identify: (id) => {
    if (window.heap && window.heap.identity !== id) {
      window.heap.identify(id);
    }
  },
  addUserProperties: (id, { ...properties }) => {
    if (window.heap && window.heap.identity === id) {
      window.heap.addUserProperties({
        ...properties,
      });
    }
  },
  createCustomEvent: (heapEvent, info) => {
    if (window.heap) {
      window.heap.track(heapEvent, info);
    }
  },
};
