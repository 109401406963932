import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Button, ButtonLink } from "tg-design";
import dayjs from "dayjs";
import { RowBlock } from "../../../BlockUi";

import {
  ImportLinkedinButton,
  Container,
  SaveButtonContainer,
  Text,
  TipText,
  TipListText,
  Title,
  UrlText,
  Dot,
  PrimaryTitle,
} from "../CommonStyles";
import { WebSocketContext } from "../../../../WebSocket";
import { UPDATE_USER } from "../../queries";
import {
  FETCH_USER_FROM_LINKEDIN,
  UPDATE_USER_FROM_LINKEDIN,
} from "../queries";
import message from "../../../../utils/message";
import {
  uuidGenerate,
  removeTypename,
  captureErrorWithData,
} from "../../../../helper";
import TextField from "../../../Form/TextField";
import { basicInfoValidationSchema } from "../../BasicInfos/validationSchema";
import { ProfilePhotoFromImport } from "../ProfilePhotoFromImport";
import { BioFromImport } from "../BioFromImport";
import { WorkHistoryFromImport } from "../WorkHistoryFromImport";
import { EducationFromImport } from "../EducationFromImport";
import { LanguageFromImport } from "../LanguageFromImport";
import { OtherSkillsFromImport } from "../OtherSkillsFromImport";
import {
  ACTION_BUTTON_TITLES,
  QUICK_EDIT_DATA_SESSION_IDS,
  techCategories,
} from "../../../../utils/constants";
import { PreviousImports } from "../PreviousImports";
import { SAVED_LINKEDINLOG_FOR_USER } from "../../../Profile/Sidebar/queries";
import PartialError from "../../../../pages/ErrorPage/PartialError";

export default function LinkedinImport({ user, refetch, loading, setLoading }) {
  const [linkedinData, setLinkedinData] = useState();
  const [previousLinkedinData, setPreviousLinkedinData] = useState();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSaveActive, setIsSaveActive] = useState(!user.social.linkedin);

  const [value, setValue] = useState("");
  const [isLinkedinSaving, setIsLinkedinSaving] = useState(false);

  const [formState, setFormState] = useState();
  const history = useHistory();

  const [updateUser] = useMutation(UPDATE_USER);
  const [updateUserFromLinkedin] = useMutation(UPDATE_USER_FROM_LINKEDIN);
  const [startLinkedinCrawl] = useLazyQuery(FETCH_USER_FROM_LINKEDIN);
  const {
    data,
    error,
    refetch: logRefetch,
  } = useQuery(SAVED_LINKEDINLOG_FOR_USER, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setPreviousLinkedinData(data.savedLinkedinLogForUser);
    },
  });

  const ws = useContext(WebSocketContext);

  const onSubscribe = ({ uuid }) => ws.socket.emit("subscribe", { uuid });
  const onDisconnect = ({ uuid }) => ws.socket.emit("unsubscribe", { uuid });

  const formatLinkedinData = (linkedinLog) => {
    const userSkills = (user.otherSkills || []).map((i) => i.id);

    const linkedinData = {
      id: user.id,
      bio: { value: linkedinLog.bio || null, isChecked: true },
      avatarURL: { value: linkedinLog.avatarURL || null, isChecked: true },
      workHistory: (linkedinLog.workHistory || []).map((item) => {
        return {
          company: item.company || null,
          position: item.position || null,
          description: item.description || null,
          startDate: item.startDate
            ? dayjs(item.startDate).format("YYYY-MM-DD")
            : null,
          endDate: item.endDate
            ? dayjs(item.endDate).format("YYYY-MM-DD")
            : null,
          present: item.present,
          isChecked: true,
        };
      }),
      education: (linkedinLog.education || [])
        .map((item) => {
          if (item?.suggestions && item.suggestions.length !== 0) {
            return {
              school: {
                id: item.suggestions[0].school.id,
                name: item.suggestions[0].school.name,
              },
              branch: {
                id: item.suggestions[0].branch.id,
                label: item.suggestions[0].branch.label,
              },
              startDate: Number(item.suggestions[0].startDate),
              endDate: Number(item.suggestions[0].endDate),
              type: item.suggestions[0].type || null,
              isChecked: true,
            };
          }
          return null;
        })
        .filter((i) => i),
      languages: (linkedinLog.languages || [])
        .map((item) => {
          if (item?.suggestions && item.suggestions.length !== 0) {
            return {
              language: {
                id: item.suggestions[0]._id,
                label: item.suggestions[0].label,
              },
              level: item.suggestions[0].level,
              isChecked: true,
            };
          }
          return null;
        })
        .filter((i) => i),
      otherSkills: (linkedinLog.skills || [])
        .map((item) => {
          if (
            item?.suggestions &&
            item.suggestions.length !== 0 &&
            techCategories[item.suggestions[0].categoryId] !== "language" &&
            techCategories[item.suggestions[0].categoryId] !== "framework" &&
            !userSkills.includes(item.suggestions[0]._id)
          ) {
            return {
              value: item.suggestions[0]._id,
              title: item.title,
              isChecked: true,
            };
          }
          return null;
        })
        .filter((i) => i),
      uuid: linkedinLog.uuid,
    };

    setFormState(linkedinData);
  };

  const onListen = ({ uuid }) => {
    setLoading(true);
    ws.socket.on("linkedInCrawled", (data) => {
      if (data.error) {
        setLoading(false);

        message.error(`An error has occurred: ${data.error}`);
      } else {
        setLoading(false);
        const linkedinLog = { ...data.linkedin, uuid };
        formatLinkedinData(linkedinLog);
        setLinkedinData(linkedinLog);
        sessionStorage.setItem("linkedin", JSON.stringify(linkedinLog));
      }

      onDisconnect({ uuid });
    });
  };

  const handleClick = async () => {
    const uuid = uuidGenerate();
    const linkedinURL = user.social?.linkedin || null;

    const rawUrl = linkedinURL?.split("?").shift();
    const username = rawUrl?.split("/").filter(Boolean).pop();

    // Eğer Quickbox kısmında eksik dillerin gösterildiği kısım skip edilmişse ve sonra linkedin crawler çalıştırıldığında yine boş dil seviyesi gelmişse
    // quickbox'taki eksik dil bölümünü tekrar aktif etmek için sessionId'yi kaldırıyoruz.
    sessionStorage.removeItem(
      `qip-${QUICK_EDIT_DATA_SESSION_IDS.MISSING_LANGUAGE_LEVEL}`
    );
    history.replace();

    if (!username) {
      return message.error("Username is not valid");
    }
    try {
      startLinkedinCrawl({
        variables: {
          uuid,
          username,
          userId: user.id,
        },
      });
      onSubscribe({ uuid });
      onListen({ uuid });
      history.replace();
    } catch (error) {
      onDisconnect({ uuid });
      captureErrorWithData(error);
      history.replace();
    }
  };

  const handleChange = async (event) => {
    const { value } = event.target;
    setValue(value);

    try {
      const validateResponse =
        await basicInfoValidationSchema.fields.linkedin.validate(value);
      if (validateResponse) {
        setIsFormValid(true);
      }
    } catch (err) {
      setIsFormValid(false);
    }
  };

  const handleSubmit = async (e, value) => {
    e.preventDefault();
    let { social } = user;
    social = removeTypename(social);

    try {
      await updateUser({
        variables: {
          social: { ...social, linkedin: value },
        },
      });
      setIsSaveActive(false);
    } catch (err) {
      captureErrorWithData(err);
      message.error(err);
    }
  };

  const handleStateChange = (tabName, index) => {
    if (index === 0 || index) {
      const newState = {
        ...formState,
        [tabName]: formState[tabName].map((item, i) => {
          if (index === i) {
            return { ...item, isChecked: !item.isChecked };
          }
          return item;
        }),
      };
      setFormState(newState);
    } else {
      const newState = {
        ...formState,
        [tabName]: {
          ...formState[tabName],
          isChecked: !formState[tabName].isChecked,
        },
      };
      setFormState(newState);
    }
  };
  const handleSave = async () => {
    const variables = removeTypename({
      id: formState.id,

      bio: formState.bio.isChecked ? formState.bio.value : null,
      avatarURL: formState.avatarURL.isChecked
        ? formState.avatarURL.value
        : null,
      isLinkedinAvatarUpload: formState?.avatarURL.isChecked,
      workHistory: (formState.workHistory || [])
        .map(
          (item) =>
            item.isChecked && {
              company: item.company || null,
              position: item.position || null,
              description: item.description || null,
              startDate: item.startDate
                ? dayjs(item.startDate).format("YYYY-MM-DD")
                : null,
              endDate: item.endDate
                ? dayjs(item.endDate).format("YYYY-MM-DD")
                : null,
              present: item.present,
            }
        )
        .filter((i) => i),
      education: (formState.education || [])
        .map(
          (i) =>
            i.isChecked && {
              school: i.school.id,
              branch: i.branch.id,
              startDate: i.startDate,
              endDate: i.endDate,
              type: i.type,
            }
        )
        .filter((i) => i),
      languages: (formState.languages || [])
        .map((item) => {
          if (item.isChecked) {
            return {
              language: item.language.id,
              level: item.level,
            };
          }
          return null;
        })
        .filter((i) => i),
      otherSkills: (formState.otherSkills || [])
        .map((item) => {
          if (item.isChecked) {
            return item.value;
          }
          return null;
        })
        .filter((i) => i),
      uuid: formState.uuid,
    });

    if (
      !formState.bio.isChecked &&
      !formState.avatarURL.isChecked &&
      formState.workHistory?.every((i) => !i.isChecked) &&
      formState.education?.every((i) => !i.isChecked) &&
      formState.languages?.every((i) => !i.isChecked) &&
      formState.otherSkills?.every((i) => !i.isChecked)
    ) {
      message.error("You haven't selected anything.");
      return;
    }

    try {
      setIsLinkedinSaving(true);
      await updateUserFromLinkedin({
        variables,
      });
      setIsLinkedinSaving(false);

      sessionStorage.removeItem("linkedin");

      setLinkedinData();
      message.success("User profile has been updated!");
      refetch();
      logRefetch();
    } catch (error) {
      message.error(error);
      captureErrorWithData(error);
      setIsLinkedinSaving(false);
    }
  };

  useEffect(() => {
    const cookie = JSON.parse(sessionStorage.getItem("linkedin"));
    if (cookie) {
      setLinkedinData(cookie);
      formatLinkedinData(cookie);
    }
    if (
      !cookie &&
      !linkedinData &&
      user.social?.linkedin &&
      history?.location?.state?.from ===
        ACTION_BUTTON_TITLES.IMPORT_LINKEDIN_BUTTON
    ) {
      handleClick();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.savedLinkedinLogForUser) {
      setPreviousLinkedinData(data.savedLinkedinLogForUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <PartialError />;

  if (linkedinData) {
    return (
      <>
        <PrimaryTitle>
          <b>Successfully imported LinkedIn profile!</b> ✅
        </PrimaryTitle>
        <TipText>Some quick tips:</TipText>
        <ul style={{ marginBottom: "20px", paddingLeft: "20px" }}>
          <TipListText>
            Simply un-check any info you don’t want to be imported,
          </TipListText>
          <TipListText>
            Once saved, these will override the current info on your profile.
          </TipListText>
        </ul>

        <ProfilePhotoFromImport
          state={formState.avatarURL}
          handleStateChange={(tabName) => handleStateChange(tabName)}
        />
        <BioFromImport
          state={formState.bio}
          handleStateChange={(tabName) => handleStateChange(tabName)}
        />
        <WorkHistoryFromImport
          state={formState.workHistory}
          handleStateChange={(tabName, index) =>
            handleStateChange(tabName, index)
          }
        />
        <EducationFromImport
          state={formState.education}
          handleStateChange={(tabName, index) =>
            handleStateChange(tabName, index)
          }
        />
        <LanguageFromImport
          state={formState.languages}
          handleStateChange={(tabName, index) =>
            handleStateChange(tabName, index)
          }
        />
        <OtherSkillsFromImport
          state={formState.otherSkills}
          handleStateChange={(tabName, index) =>
            handleStateChange(tabName, index)
          }
        />
        <SaveButtonContainer mx={{ mobileS: -3, tablet: -4, laptop: -5 }}>
          <Button
            style={{
              background: isLinkedinSaving ? "#ddd" : "#8FDEAC",
              borderColor: isLinkedinSaving ? "#ddd" : "#8FDEAC",
              margin: "15px auto",
              minWidth: "250px",
            }}
            size="xs"
            disabled={isLinkedinSaving}
            loading={isLinkedinSaving}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </SaveButtonContainer>
      </>
    );
  }

  return (
    <>
      <Container>
        <Title>Your LinkedIn Profile:</Title>
        {!isSaveActive ? (
          <>
            <RowBlock
              gridGap="10px"
              justify="flex-start"
              align="baseline"
              width="100%"
            >
              <UrlText>{user.social.linkedin}</UrlText>
              <ButtonLink
                underline
                disabled={loading}
                onClick={() => setIsSaveActive(true)}
              >
                Edit
              </ButtonLink>
            </RowBlock>

            <div style={{ width: "250px" }}>
              <ImportLinkedinButton
                handleClick={handleClick}
                loading={loading}
                block
              >
                {loading ? (
                  <>
                    <span>Importing</span>
                    <Dot className="dot-one">.</Dot>
                    <Dot className="dot-two">.</Dot>
                    <Dot className="dot-three">.</Dot>
                  </>
                ) : (
                  <span>Import your LinkedIn profile</span>
                )}
              </ImportLinkedinButton>
            </div>
            {loading && (
              <Text style={{ marginTop: "10px" }}>
                This process usually takes a few minutes.. Hang tight
                <span role="img" aria-label="muscle">
                  💪
                </span>
              </Text>
            )}
          </>
        ) : (
          <>
            <TextField
              defaultValue={user.social.linkedin}
              onChange={handleChange}
              width="100%"
              mt="3px"
              textAlign="left"
              placeholder="https://"
              otherStyle={{ minHeight: "54px" }}
            />
            <Button
              style={{
                background: !isFormValid || !value ? "#ddd" : "#8FDEAC",
                borderColor: !isFormValid || !value ? "#ddd" : "#8FDEAC",
              }}
              onClick={(e) => handleSubmit(e, value)}
              disabled={!isFormValid || !value}
              block
            >
              Save
            </Button>
          </>
        )}
      </Container>
      {previousLinkedinData && (
        <PreviousImports previousData={previousLinkedinData} />
      )}
    </>
  );
}
