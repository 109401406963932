/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { heapIO } from "../../utils/heap";

const Container = styled.div`
  min-height: 100px;
  padding: 0px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default function TgTabContent({
  children,
  refetch,
  swipeableHandlers,
  ...props
}) {
  const history = useHistory();
  const containerRef = useRef();

  const { heapInfo } = children.props;

  const { id } = children.props.match ? children.props.match : { id: null };

  useEffect(() => {
    if (id !== null && history.location.pathname.includes(id)) {
      heapIO.createCustomEvent("Matches Match Seen", heapInfo);
    }
    // eslint-disable-next-line
  }, [id]);

  const handleContainerTouchMove = () => {
    if (swipeableHandlers) {
      swipeableHandlers?.ref(containerRef.current);
    }
  };

  return (
    <Container
      {...swipeableHandlers}
      {...props}
      ref={containerRef}
      onTouchMove={handleContainerTouchMove}
    >
      {children}
    </Container>
  );
}
