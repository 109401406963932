import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { removeTypename } from "../../../../helper";
import { UPDATE_LANGUAGE } from "../../../../pages/queries";
import StepContainer from "./StepContainer";
import MissingLanguageLevelStep from "./Steps/MissingLanguageLevelStep";

export default function QuickFillMissingLanguageLevel({ next, user }) {
  const [stepStructure, setStepStructure] = useState([]);
  const [stepCount, setStepCount] = useState(0);
  const [updateLanguage] = useMutation(UPDATE_LANGUAGE);
  const [languageList, setLanguageList] = useState(null);
  const [emptyLanguageLevelList, setEmptyLanguageLevelList] = useState(null);

  useEffect(() => {
    const pureLanguageData = removeTypename(user?.languages)?.map(
      (item, index) => {
        item.index = index;
        return item;
      }
    );

    setLanguageList(pureLanguageData);
    setEmptyLanguageLevelList(
      pureLanguageData?.filter((item) => item.level === null)
    );

    const steps = pureLanguageData
      ?.filter((item) => item.level === null)
      ?.map(() => {
        return {
          component: MissingLanguageLevelStep,
          validation: Yup.object().shape({
            level: Yup.number().required(""),
          }),
        };
      });

    setStepStructure(steps);

    // eslint-disable-next-line
  }, []);

  const handleLevelChange = (changes) => {
    const { index } = changes;
    const tempLanguageList = [...languageList];
    tempLanguageList.find((item) => item.index === index).level = changes.level;
    setLanguageList(tempLanguageList);
  };

  const handleSave = () => {
    const formattedLanguageList = languageList.map((item) => {
      return {
        language: item?.language?.id,
        level: item?.level,
      };
    });

    updateLanguage({ variables: { languages: formattedLanguageList } });

    if (stepCount === emptyLanguageLevelList.length - 1) {
      next();
      return null;
    }
    setStepCount(stepCount + 1);
  };

  if (!languageList) return <></>;

  return (
    <>
      <StepContainer
        title="Add Missing Language Levels"
        state={emptyLanguageLevelList[stepCount]}
        setState={handleLevelChange}
        steps={stepStructure}
        nextQuickBox={next}
        handleSubmit={handleSave}
        showSkipButton
        isEveryStepNeedSave
      />
    </>
  );
}
