import React from "react";
import styled from "styled-components";
import { ColumnBlock, Span } from "../BlockUi";
import { ThumbsUpIcon } from "../Icon";
import { CompanyName } from "../Match/CommonModule";
import { TgHeaderTab, TgHeaderTabs } from "../TgHeaderTabs";
import { CompanyLogo, PositionName, PositionSlogan } from "./CommonModule";
import CreateProfileBarMobile from "./CreateProfileBarMobile";
import ExternalLinksAndCreateProfile from "./ExternalLinksAndCreateProfile";
import device from "../../styles/device";

const MobileView = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const WebView = styled.div`
  @media ${device.mobileMax} {
    display: none;
  }
`;
const InterestCount = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  color: #aaaaaa;
  > svg {
    margin-top: 2px;
  }
`;

const Row = styled.div`
  display: flex;
`;

export default function PositionDetailHeader({
  publicPosition,
  currentTab,
  forceMatchToken,
  setCurrentTab,
  tabs,
  isUserAuth,
  userData,
  referrerCode,
  matchCreationType,
}) {
  return (
    <>
      <WebView>
        <Row style={{ marginBottom: "40px" }}>
          <CompanyLogo src={publicPosition.company.logo} />
          <ColumnBlock pl={20} pr={20}>
            <PositionName>{publicPosition.title}</PositionName>
            <CompanyName>{publicPosition.company.name}</CompanyName>
            <PositionSlogan>{publicPosition.company.slogan}</PositionSlogan>
          </ColumnBlock>
          <ExternalLinksAndCreateProfile
            forceMatchToken={forceMatchToken}
            publicPosition={publicPosition}
            isUserAuth={isUserAuth}
            userData={userData}
            referrerCode={referrerCode}
            matchCreationType={matchCreationType}
          />
        </Row>
      </WebView>
      <MobileView>
        <Row>
          <CompanyLogo
            src={publicPosition.company.logo}
            style={{ marginRight: "3px" }}
          />
          <ColumnBlock pl={20} justifyContent="center">
            <PositionName>{publicPosition.title}</PositionName>
            <Row>
              <CompanyName style={{ marginRight: "10px" }}>
                {publicPosition.company.name}
              </CompanyName>
              <InterestCount style={{ marginRight: "10px" }}>
                <ThumbsUpIcon />
                <Span ml={1}>{publicPosition.interestCount} Interested</Span>
              </InterestCount>
            </Row>
          </ColumnBlock>
        </Row>
        <PositionSlogan>{publicPosition.company.slogan}</PositionSlogan>
        <CreateProfileBarMobile
          forceMatchToken={forceMatchToken}
          publicPosition={publicPosition}
          token={publicPosition.publicPositionToken}
          isUserAuth={isUserAuth}
          userData={userData}
          referrerCode={referrerCode}
          matchCreationType={matchCreationType}
        />
      </MobileView>

      <TgHeaderTabs
        currentTab={currentTab}
        currentIndex={tabs.map((i) => i.name).indexOf(currentTab)}
        handleChange={setCurrentTab}
      >
        {tabs.map((tab) => (
          <TgHeaderTab key={tab.name} name={tab.name} icon={tab.icon}>
            {tab.title}
          </TgHeaderTab>
        ))}
      </TgHeaderTabs>
    </>
  );
}
