import React from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import {
  IconWrap,
  WorkHistoryPosition,
  WorkHistoryPeriod,
  WorkHistoryDescription,
  WorkHistoryCompany,
  SectionTitle,
} from "../CommonStyles";
import { CommandLineIcon } from "../../Icon";
import HoveredEmptyView from "../../Modules/HoveredEmptyView";
import ReadMore from "../../ReadMore";

const EmptyBox = ({ handleEdit }) => {
  return (
    <RowBlock
      mt={2}
      mb={3}
      py={4}
      pl={3}
      pr={4}
      width="100%"
      border="solid 1px #eeeeee"
      borderRadius="5px"
    >
      <ColumnBlock width={{ mobileS: "100%", tablet: "30%" }}>
        <RowBlock>
          <IconWrap>
            <CommandLineIcon />
          </IconWrap>
        </RowBlock>
      </ColumnBlock>
      <ColumnBlock width={{ mobileS: "100%", tablet: "70%" }}>
        <RowBlock justify="flex-start">
          <SectionTitle>Work History</SectionTitle>
        </RowBlock>
        <RowBlock justify="flex-start" onClick={handleEdit}>
          <HoveredEmptyView text="Add" />
        </RowBlock>
      </ColumnBlock>
    </RowBlock>
  );
};

const WorkItem = ({ item }) => {
  return (
    <RowBlock
      mt={2}
      mb={3}
      py={4}
      pl={4}
      width="100%"
      border="solid 1px #eeeeee"
      borderRadius="5px"
    >
      <ColumnBlock
        width={{ mobileS: "100%", tablet: "25%" }}
        justify="flex-start"
      >
        <RowBlock>
          <IconWrap>
            <CommandLineIcon />
          </IconWrap>
        </RowBlock>
      </ColumnBlock>
      <ColumnBlock
        width={{ mobileS: "100%", tablet: "75%" }}
        px={{ mobileS: "10px", tablet: "0px" }}
      >
        <RowBlock justify="flex-start" mr={4}>
          <WorkHistoryCompany>{item.company}</WorkHistoryCompany>
          <WorkHistoryPosition>{item.position}</WorkHistoryPosition>
          {/* TODO */}
          <WorkHistoryPeriod>
            {dayjs(item.startDate).locale("en").format("MMMM YYYY")} - {""}
            {item.endDate
              ? dayjs(item.endDate).locale("en").format("MMMM YYYY")
              : "Present"}
          </WorkHistoryPeriod>
          {item.description && (
            <WorkHistoryDescription style={{ whiteSpace: "pre-wrap" }}>
              <ReadMore
                text={
                  item.description
                    .replace(/[\r\n]{2,}/g, "\n") // Only 1 linebreak is allowed
                    .replace(/\n$/, "") // Remove last line break
                }
                limit={500}
              />
            </WorkHistoryDescription>
          )}
        </RowBlock>
      </ColumnBlock>
    </RowBlock>
  );
};

export default function WorkHistorySection({ workHistory = [] }) {
  if (!workHistory) {
    workHistory = [];
  }
  const history = useHistory();

  const handleEdit = () => {
    history.push("profile/edit/work-history");
  };

  return (
    <div style={{ width: "100%" }}>
      {workHistory.length === 0 && <EmptyBox handleEdit={handleEdit} />}
      {workHistory.length > 0 && (
        <div>
          {workHistory.map((item, index) => (
            <WorkItem item={item} index={index} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}
