import React, { useState } from "react";

import styled from "styled-components";
import { width, space } from "styled-system";
import { CURRENCIES } from "../../../utils/constants";
import { Label } from "../../FormUi";
import { SelectField } from "../SelectField";

const wrapperStyle = {
  display: "flex",
  alignItems: "center",
};

const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 70%;
  height: 40px;
  background-color: #f5f6f8;
  border-radius: 5px;
  padding: 11px 9px;
  border-style: solid;
  font-weight: bold;
  font-family: ${(props) => props.theme.family.demi};
  color: #444444;
  font-size: 14px;
  border-color: ${(props) => (props.hasError ? "#ff7675" : "transparent")};
  :focus {
    border-color: ${(props) => props.theme.colors.green[0]};
  }
  ${space}
  text-align: left;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  ${space}
  ${width}
`;

const CurrencyInput = ({ ...props }) => {
  return (
    <div style={wrapperStyle}>
      <StyledInput mr={2} value={props.value} {...props} />
      <SelectField
        width="30%"
        placeholder="Currency"
        defaultValue={{
          label: props?.currency || "",
          value: props?.currency || "",
        }}
        options={CURRENCIES}
        onChange={({ value }) => props.onChange({ key: "currency", value })}
      />
    </div>
  );
};

const fieldStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const replaceAll = (string, search, replace) => {
  return string.split(search).join(replace);
};

const SalaryField = ({ ...props }) => {
  const { value = 0 } = props;

  const [rate, setRate] = useState(
    value.toLocaleString("en-US", { currency: props.currency })
  );
  const handleOnChange = (e) => {
    if (e.target) {
      const val = replaceAll(e.target.value, ".", "");
      const x = Number(val.replace(/[^\d]/g, ""));
      const finalValue = x.toLocaleString("en-US", {
        currency: props.currency,
      });
      setRate(x.toLocaleString("en-US", { currency: props.currency }));
      props.onChange({ key: "expected", value: finalValue });
    } else {
      props.onChange({ key: e.key, value: e.value });
    }
  };
  const { error } = props;
  return (
    <>
      <div style={fieldStyle}>
        <CurrencyInput {...props} onChange={handleOnChange} value={rate} />
        {error ? (
          <Label mt={1} hasError={error}>
            {error.message}
          </Label>
        ) : null}
      </div>
    </>
  );
};

export default SalaryField;
