/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable func-names */
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import React from "react";
import { Tag } from "./components/PublicPosition/PositionBenefits";
import {
  headData,
  POSITION_STATES,
  MATCH_STATES,
  LANGUAGE_LEVEL_TYPES_WITH_KEYS,
  COMPANY_SIZES,
} from "./utils/constants";

const localeData = require("dayjs/plugin/localeData");

dayjs.extend(localeData);
dayjs().localeData();

export const sleep = async (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const plus = (x, y) => x + y;

export const minus = (x, y) => x - y;

const replaceAll = (string, search, replace) => {
  return string.split(search).join(replace);
};
export const getNumberWithCommas = (n) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const currencyConvertToNumber = (n) => {
  if (!n) {
    return n;
  }
  const _val = replaceAll(n.toString(), ".", "");
  const value = replaceAll(_val, ",", "");
  return Number(value);
};

export const isEmpty = (obj) => {
  if (typeof obj === "object") {
    return obj.constructor === Object && Object.keys(obj).length === 0;
  }
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s
    .toLocaleLowerCase("tr")
    .split(" ")
    .map((str) => str.charAt(0).toLocaleUpperCase("TR") + str.substring(1))
    .join(" ");
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const rangeOfYears = (start, end) =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

export function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const uuidGenerate = () => {
  // Get now time
  const n = Date.now();
  // Generate random
  const r = Math.random();
  // Stringify now time and generate additional random number
  const s = String(n) + String(~~(r * 9e4) + 1e4);
  // Form UUID and return it
  return `${s.slice(0, 8)}-${s.slice(8, 12)}-4${s.slice(12, 15)}-${
    [8, 9, "a", "b"][~~(r * 3)]
  }${s.slice(15, 18)}-${s.slice(s.length - 12)}`;
};

export const uuidParser = (uuid) => {
  // Get current timestamp string length
  let tl = String(Date.now()).length;
  // Strip out timestamp from UUID
  let ts = "";
  let i = -1;
  while (tl--) {
    i++;
    if (i === 8 || i === 13 || i === 14 || i === 18 || i === 19 || i === 23) {
      tl++;
      continue;
    }
    ts += uuid[i];
  }
  return Number(ts);
};

export const deepFindObject = (obj, path) => {
  const paths = path.split(".");
  let i;
  let current = obj;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    }
    current = current[paths[i]];
  }
  return current;
};

export const getType = (o) => {
  return Object.prototype.toString
    .call(o)
    .replace(/\[|object\s|\]/g, "")
    .toLowerCase();
};

export const handleHeadTag = (key) => {
  const { title } = headData[key];
  const { desc } = headData[key];
  const meta = document.getElementsByTagName("meta");

  document.title = title;
  meta.description.content = desc;
};

export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) return match[2];
  return null;
};

export const setCookie = (name, value, seconds = 3600) => {
  document.cookie = `${name}=${value}; max-age=${seconds};Path=/;`;
};
export const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  }
  if (typeof value === "object") {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

export const getPositionLocationFromCompanyLocations = ({
  positionLocation,
  locations,
}) => {
  const location = locations.find((c) => c.id === positionLocation);
  if (!location) {
    return "On site";
  }
  return location.title;
};

export const isPositionOpen = (state) => {
  switch (state) {
    case POSITION_STATES.CREATED:
    case POSITION_STATES.IN_PROGRESS:
      return true;
    case POSITION_STATES.FILLED:
    case POSITION_STATES.CLOSED:
    case POSITION_STATES.ON_HOLD:
    case POSITION_STATES.CANCELED:
      return false;
    default:
      return true;
  }
};

export const isMatchActionActive = (state) => {
  return state === MATCH_STATES.SENT;
};

/**
 * Bu fonksiyonla birlikte geçmiş eğitim bilgileri olarak girilen kayıtlar sıralanır.
 * Başlangıç tarihleri baz alınarak büyükten küçüğe doğru sıralama gerçekleştirilir.
 * Ancak "halen devam ediyor" olarak işaretlenen işler en tepede olmak zorundadır.
 * Bu fonksiyon eğitim geçmişlerinde kullanılmaktadır.
 *
 * @param {Object} a
 * @param {Object} b
 * @returns {null}
 */
export const historySortingStrategy = (a, b) => {
  return b.present - a.present || new Date(b.startDate) - new Date(a.startDate);
};

/**
 * Bu fonksiyonla birlikte geçmiş iş bilgileri olarak girilen kayıtlar sıralanır.
 * Başlangıç tarihleri baz alınarak büyükten küçüğe doğru sıralama gerçekleştirilir.
 * Ancak "halen devam ediyor" olarak işaretlenen işler en tepede olmak zorundadır.
 * Halen devam eden işler ise kendi içlerinde başlangıç tarihlerine göre sıralanır.
 * Bu fonksiyon iş geçmişlerinde kullanılmaktadır.
 *
 * @param {Object[]} workHistories
 * @returns {Object[]}
 */
export const workHistorySortingStrategy = (workHistories) => {
  let presentWorkHistories = [];
  let pastWorkHistories = [];
  let sortedWorkHistory = [];
  // Separate present work histories from past work histories.
  workHistories.map((workHistory) => {
    if (workHistory.present === true) {
      presentWorkHistories.push(workHistory);
    } else {
      pastWorkHistories.push(workHistory);
    }
    return null;
  });

  // Sort present work histories
  presentWorkHistories = presentWorkHistories.sort(
    (firstHistory, secondHistory) =>
      dayjs(firstHistory.startDate).isAfter(dayjs(secondHistory.startDate))
        ? -1
        : 1
  );

  // Sort past work histories
  pastWorkHistories = pastWorkHistories
    .sort((firstHistory, secondHistory) =>
      dayjs(firstHistory.startDate).isAfter(dayjs(secondHistory.startDate))
        ? 1
        : -1
    )
    .reverse();

  sortedWorkHistory = presentWorkHistories.concat(pastWorkHistories);

  return sortedWorkHistory;
};

const turkishCharacterRegex = (keyword) =>
  keyword
    .replace(/[ıİiI]/g, "[ıİiI]")
    .replace(/[şŞsS]/g, "[şŞsS]")
    .replace(/[çÇcC]/g, "[çÇcC]")
    .replace(/[ğĞgG]/g, "[ğĞgG]")
    .replace(/[öÖoO]/g, "[öÖoO]")
    .replace(/[üÜuU]/g, "[üÜuU]");

/**
 * Bu fonksiyon ile birlikte react-select componenti ile dahili olarak yapılan
 * aramalardaki Türkçe dil problemi ortadan kaldırılabilir.
 *
 * <select filterOption={turkishFilterOption}></select>
 *
 * @param {String} option
 * @param {String} searchText
 * @returns {Boolean}
 */
export const turkishFilterOption = (option, searchText) => {
  return turkishCharacterRegex(option.data.label)
    .toLowerCase()
    .includes(turkishCharacterRegex(searchText).toLowerCase());
};

export const getMonthOptions = () => {
  const locale = "en";
  dayjs.locale(locale);
  const monthList = dayjs.months();
  return monthList.map((month, idx) => {
    return { value: idx + 1, label: month };
  });
};

export const getExperienceOptions = () => {
  return [
    { title: "Just getting started 🐣", id: "0" },
    { title: "1", id: "1" },
    { title: "2", id: "2" },
    { title: "3", id: "3" },
    { title: "4", id: "4" },
    { title: "5", id: "5" },
    { title: "6", id: "6" },
    { title: "7", id: "7" },
    { title: "8", id: "8" },
    { title: "9", id: "9" },
    { title: "10", id: "10" },
    { title: "10+", id: "11" },
  ];
};

export const captureErrorWithData = (error, data = {}) => {
  Sentry.withScope((scope) => {
    scope.setExtra("data", JSON.stringify(data));
    Sentry.captureException(error);
  });
};

export const getMatchHeapInfo = (item) => {
  const heapInfo = {
    userId: item?.user?.id,
    matchId: item?.id,
    positionId: item?.position?.id,
    positionTitle: item?.position?.title,
    timeOfEvent: new Date(Date.now()).toISOString(),
  };
  return heapInfo;
};

export const convertStringToNumber = (string = "") => {
  const number = parseInt(string.match(/\d+/)[0]);
  return number;
};

export const getLanguageLevelType = (initialFields) => {
  if (initialFields.level !== null && initialFields.level !== undefined) {
    return LANGUAGE_LEVEL_TYPES_WITH_KEYS.find(
      (level) => level.value === initialFields.level
    );
  }
  return null;
};

export const fixUrl = (url) => {
  if (url.match(/^http?:\/\//i) || url.match(/^https?:\/\//i)) {
    return url;
  }
  url = `http://${url}`;
  return url;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getRemoteWorkTypes = (positionLocation) => {
  const WORKING_TYPES = {
    half: "Hybrid",
    none: "Onsite",
    full: "Remote",
  };

  const workingTypes = positionLocation?.remote?.split(",");

  if (workingTypes && workingTypes.length > 0) {
    return workingTypes.map((workingType, index) => {
      if (index === 0) {
        return <Tag>{WORKING_TYPES[workingType]}</Tag>;
      }

      return (
        <>
          or
          <Tag style={{ marginLeft: "5px" }}>
            {WORKING_TYPES[workingType]}
          </Tag>{" "}
        </>
      );
    });
  }
};

export const getCompanySizeLabel = (id) => {
  const companySize = COMPANY_SIZES.find((cs) => cs.id === id);
  if (companySize) {
    return companySize.label;
  }
  return id;
};
