/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, Prompt } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Container, RowBlock } from "../../components/BlockUi";
import { Header } from "../../components/Header";
import { GET_USER_WITH_LIVING_CITY, CALCULATE_MATCH } from "../queries";
import { CriteriaStep, EducationStep } from "../../components/Onboarding";
import { Wrapper, Description } from "../../components/Onboarding/CommonStyles";
import ProgressBar from "../../components/ProgressBar";
import Loading from "../../components/Modules/Loading";
import { heapIO } from "../../utils/heap";
import ErrorPage from "../ErrorPage";

const steps = [
  {
    name: "criteria",
    buttonText: "Calculate My Matches",
    percent: 0,
    description: "Calculating Matches",
    showProgress: false,
    heapEvent: "Onboarding Criteria Next Clicked",
  },
  {
    name: "education",
    buttonText: "Save and Continue",
    percent: 60,
    description: "Saving your profile information",
    info: "40% of the positions you can match on TalentGrid care about your educational background.",
    showProgress: true,
    heapEvent: "Onboarding Education Next Clicked",
  },
  {
    name: "finish",
    percent: 99,
    description: "Calculating match with position criteria",
    showProgress: true,
  },
];

export default function Onboarding() {
  const [step, setStep] = useState(steps[0]);
  const [loading, setLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [percent, setPercent] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const { data: userInfo, error } = useQuery(GET_USER_WITH_LIVING_CITY);
  const [calculateMatch] = useMutation(CALCULATE_MATCH);
  const history = useHistory();

  const redirectMatches = useCallback(() => {
    setIsFinished(true);
    history.push("/matches");
  }, [history]);

  const sendMatchCalculation = async () => {
    await calculateMatch();
    redirectMatches();
  };

  useEffect(() => {
    if (step.name === "finish") {
      sendMatchCalculation();
    }
  }, [step]);

  const onBeforeUnload = (e) => {
    if (isFinished === false) {
      e.preventDefault();
      e.returnValue =
        "If you are currently leaving the page, your matches will not be calculated because you haven't set your criteria yet. Are you sure you want to leave?";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, []);

  const nextStep = () => {
    setLoading(false);
    let nextStep;
    switch (step.name) {
      case "criteria":
        nextStep = steps.find((s) => s.name === "education");
        setStep(nextStep);
        setPercent(nextStep.percent);
        break;
      case "education":
        nextStep = steps.find((s) => s.name === "finish");
        setStep(nextStep);
        setPercent(nextStep.percent);
        break;
      default:
        break;
    }
  };

  const waitAndNextStep = () => {
    const currentStepIndex = steps.findIndex((s) => s.name === step.name);
    setShowProgress(steps[currentStepIndex + 1].showProgress);
    setLoading(true);
    setPercent(steps[currentStepIndex + 1].percent);
    setTimeout(nextStep, 1000);

    heapIO.createCustomEvent(step.heapEvent, {
      userId: userInfo.me.id,
      timeOfEvent: new Date(Date.now()).toISOString(),
    });
  };

  let stepComponent;
  switch (step.name) {
    case "criteria":
      stepComponent = (
        <CriteriaStep
          userInfo={userInfo}
          handleCriteria={() => waitAndNextStep()}
          step={step}
        />
      );
      break;
    case "education":
      stepComponent = (
        <EducationStep
          step={step}
          handleClick={() => waitAndNextStep()}
          handleSkip={nextStep}
        />
      );
      break;
    case "finish":
      stepComponent = <Loading fullScreen width="80px" />;
      break;
    default:
      break;
  }

  if (error) return <ErrorPage error={error} />;

  return (
    <>
      <Header />
      <Container px={{ mobileS: 3 }} mt={{ mobileS: 3 }}>
        {!isFinished && (
          <Prompt message="If you are currently leaving the page, your matches will not be calculated because you haven't set your criteria yet. Are you sure you want to leave?" />
        )}
        {showProgress && (
          <Wrapper mx={{ mobileS: 1, laptop: 7, tablet: 5 }} py={2} shadow>
            <RowBlock py={3} px={3}>
              <ProgressBar
                hideValue
                max={100}
                value={percent}
                width="100%"
                color="#abe6c1"
              />
            </RowBlock>
            <RowBlock mt={2}>
              <Description>{step.description}</Description>
            </RowBlock>
          </Wrapper>
        )}
        {!loading && stepComponent}
        {loading && <Loading fullScreen width="80px" />}
      </Container>
    </>
  );
}
