import React, { useEffect } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import theme from "./styles/theme";
import { useAppApolloClient } from "./config/apolloClient";
import Login from "./pages/Login/Login";
import Profile from "./pages/Profile";
import ProfileEdit from "./pages/ProfileEdit";
import Match from "./pages/Match";
import Invite from "./pages/Invite";
import Onboarding from "./pages/Onboarding";
import Registration from "./pages/Registration";
import PrivateRoute from "./route/PrivateRoute";
import PublicRoute from "./route/PublicRoute";
import Account from "./pages/Account";
import { load as loadIntercom } from "./utils/Intercom";
import { TGToast } from "./components/ToastComponents";
import WebSocketProvider from "./WebSocket";
import Discover from "./pages/Discover";
import AppProvider from "./AppProvider";
import ErrorBoundary from "./utils/ErrorBoundary";
import PublicPositionPage from "./pages/PublicPosition";
import RedirectPage from "./pages/MatchRedirect";
import Question from "./components/Modules/QuestionModal";
import { store } from "./store";
import NotFoundErrorPage from "./pages/NotFoundErrorPage";
import message from "./utils/message";

function App() {
  const setOnline = () => {
    // close toast message when back online
    message.close();
  };
  const setOffline = () => {
    message.error("Internet connection lost!", { autoClose: false });
  };

  useEffect(() => {
    // Register the event listeners
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    // cleanup at unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  loadIntercom();
  const client = useAppApolloClient();

  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <ApolloProvider client={client}>
              <WebSocketProvider>
                <BrowserRouter>
                  <AppProvider>
                    <Question />
                    <Switch>
                      <PublicRoute
                        restricted
                        component={Login}
                        path="/login"
                        exact
                      />
                      <PublicRoute
                        component={PublicPositionPage}
                        path="/landing/:id"
                        exact
                      />
                      <PublicRoute
                        component={RedirectPage}
                        path="/redirect/match"
                      />
                      <PublicRoute
                        component={PublicPositionPage}
                        path="/m/:id"
                        exact
                      />
                      <PrivateRoute component={Profile} path="/" exact>
                        <Redirect to="/profile" />
                      </PrivateRoute>
                      <PrivateRoute component={Profile} path="/profile" exact />
                      <PublicRoute
                        component={Registration}
                        path="/register"
                        exact
                      />
                      <PrivateRoute
                        component={Match}
                        path="/matches/:matchId?"
                      />
                      <PrivateRoute component={Invite} path="/invite" exact />
                      <PrivateRoute
                        component={ProfileEdit}
                        path="/profile/edit/:sectionName?"
                        exact
                      />
                      <PrivateRoute
                        component={Onboarding}
                        path="/onboarding"
                        exact
                      />
                      <PrivateRoute
                        component={Account}
                        path="/account/:sectionName?"
                        exact
                      />
                      <PrivateRoute
                        component={Discover}
                        path="/discover/:sectionName?"
                        exact
                      />
                      <Route path="*">
                        <NotFoundErrorPage />
                      </Route>
                    </Switch>
                  </AppProvider>
                </BrowserRouter>
              </WebSocketProvider>
            </ApolloProvider>
          </ErrorBoundary>
          <TGToast />
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
