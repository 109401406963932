import { useState } from "react";

const useModal = () => {
  const [showModal, setShow] = useState(false);

  function setShowModal() {
    setShow(!showModal);
  }

  return {
    showModal,
    setShowModal,
  };
};

export default useModal;
