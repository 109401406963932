import React, { useState } from "react";
import { currencyConvertToNumber } from "../../helper";
import { EU_COUNTRY_CODES } from "../../utils/constants";
import {
  CriteriaFieldDescription,
  CriteriaFieldTitle,
  ColumnBlock,
  InlineInputs,
  RowBlock,
} from "../BlockUi";
import { FlatCheckboxField } from "../Form/CheckboxField";
import SalaryField from "../Form/SalaryField";
import { InfoIcon } from "../Icon";
import InlineMessage from "../Modules/InlineMessage";

const PERIOD_OPTIONS = [
  { label: "Yearly", value: "YEARLY" },
  { label: "Monthly", value: "MONTHLY" },
];

const INCOME_OPTIONS = [
  { label: "Gross", value: "GROSS" },
  { label: "Net", value: "NET" },
];

const SalaryCritieria = ({ selectedValues, handleOnChange, user }) => {
  let income;
  let currency;

  const userLivingCountryCode = user?.livingCity?.iso2;

  if (userLivingCountryCode === "TR") {
    currency = "TRY";
    income = "NET";
  }
  if (EU_COUNTRY_CODES.includes(userLivingCountryCode)) {
    currency = "EUR";
  }
  if (user?.criteria?.salary?.currency) {
    currency = user.criteria.salary.currency;
  }
  if (user?.criteria?.salary?.income) {
    income = user.criteria.salary.income;
  }

  const [state, setState] = useState({
    currency: selectedValues?.currency || currency || "USD",
    period: user?.criteria?.salary?.period || "MONTHLY",
    income: income || "GROSS",
    expected: selectedValues?.expected || "",
  });

  const handleOptionChange = (e) => {
    const newState = { ...state, [e.key]: e.checked };
    setState({ ...state, ...newState });
    handleOnChange(newState);
  };

  const handleSalaryChange = (e) => {
    let { value } = e;
    const { key } = e;
    if (key === "expected") {
      value = currencyConvertToNumber(value || 0);
    }

    const newState = { ...state, [key]: value };
    setState({ ...state, ...newState });
    handleOnChange(newState);
  };

  return (
    <>
      <ColumnBlock>
        <CriteriaFieldTitle>
          What is your minimum salary expectation?
        </CriteriaFieldTitle>
        <CriteriaFieldDescription>
          You can change the time interval, gross or net, and currency
        </CriteriaFieldDescription>

        <InlineInputs mt={2}>
          <FlatCheckboxField
            onChange={handleOptionChange}
            icon={false}
            width="100%"
            name="period"
            defaultValue={selectedValues?.period || state.period}
            options={PERIOD_OPTIONS}
          />
          <FlatCheckboxField
            name="income"
            onChange={handleOptionChange}
            width="100%"
            defaultValue={selectedValues?.income || state.income}
            options={INCOME_OPTIONS}
          />
        </InlineInputs>

        <InlineInputs>
          <SalaryField
            value={selectedValues?.expected}
            type="text"
            name="expected"
            placeholder="Enter expected salary"
            autoFocus
            onChange={handleSalaryChange}
            currency={selectedValues?.currency || state.currency}
          />
        </InlineInputs>
        <RowBlock mt={3}>
          <InlineMessage
            type="info"
            message={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <InfoIcon color="#AAAAAA" />
                </div>
                <div>
                  Make sure to enter{" "}
                  <span>
                    <b>
                      {
                        INCOME_OPTIONS.find((i) => i.value === state.income)
                          ?.label
                      }
                    </b>
                  </span>{" "}
                  and{" "}
                  <span>
                    <b>
                      {" "}
                      {
                        PERIOD_OPTIONS.find((i) => i.value === state.period)
                          ?.label
                      }
                    </b>
                  </span>{" "}
                  salary. <br /> Incorrect salary affects your matches.
                </div>
              </div>
            }
          />
        </RowBlock>
      </ColumnBlock>
    </>
  );
};

export default SalaryCritieria;
