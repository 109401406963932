import { rangeOfYears } from "../../../helper";

const START_YEAR = 1950;
const TODAY_YEAR = new Date().getFullYear();

const startDates = rangeOfYears(START_YEAR, TODAY_YEAR)
  .map((year) => {
    return { label: year, value: year };
  })
  .sort((a, b) => b.value - a.value);

export const endDates = rangeOfYears(START_YEAR, TODAY_YEAR + 10)
  .map((year) => {
    return { label: year, value: year };
  })
  .sort((a, b) => b.value - a.value);

export const getPossibleStartYears = (endDates) => {
  if (endDates) {
    return startDates.filter((year) => year.value <= endDates);
  }
  return startDates;
};

export const getPossibleEndYears = (startDates) => {
  if (startDates) {
    return endDates.filter((year) => year.value >= startDates);
  }
  return endDates;
};
