/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useHistory } from "react-router-dom";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import HoverBox from "../../Modules/HoverBox";
import { basicInfoValidationSchema } from "../../ProfileEdit/BasicInfos/validationSchema";
import { PointerDiv } from "../CommonStyles";

import {
  LinkedinIcon,
  GithubIcon,
  StackoverflowIcon,
  PortfolioIcon,
  PlusIcon,
  YoutubeIcon,
  TwitterIcon,
  BlogIcon,
  DocumentIcon,
} from "../../Icon";
import { captureErrorWithData } from "../../../helper";

export default function ProfileSocial({ social, resume }) {
  const history = useHistory();

  const handleOnClick = (hash) => {
    const url = `profile/edit/info#${hash}`;
    history.push(url);
  };

  const handleRedirect = (platform) => {
    try {
      basicInfoValidationSchema.fields[platform]
        .validate(social[platform])
        .then(() => {
          if (!social[platform].match(/^https?:\/\//i)) {
            const url = `http://${social[platform]}`;
            window.open(url, "_blank");
          } else {
            window.open(social[platform], "_blank");
          }
        });
    } catch (error) {
      if (error?.name === "ValidationError") return;
      const data = {
        message: `${platform} is not valid. ${social[platform]}`,
      };
      captureErrorWithData(error, data);
    }
  };

  return (
    <RowBlock gridGap={2} justify="center">
      {social.youtube && (
        <ColumnBlock>
          <PointerDiv onClick={() => handleRedirect("youtube")}>
            <YoutubeIcon color="#444" />
          </PointerDiv>
        </ColumnBlock>
      )}
      {social.twitter && (
        <ColumnBlock>
          <PointerDiv onClick={() => handleRedirect("twitter")}>
            <TwitterIcon color="#444" />
          </PointerDiv>
        </ColumnBlock>
      )}
      {social.blog && (
        <ColumnBlock>
          <PointerDiv onClick={() => handleRedirect("blog")}>
            <BlogIcon color="#444" />
          </PointerDiv>
        </ColumnBlock>
      )}
      {social.linkedin && (
        <ColumnBlock>
          <PointerDiv onClick={() => handleRedirect("linkedin")}>
            <LinkedinIcon color="#444" />
          </PointerDiv>
        </ColumnBlock>
      )}
      {resume && (
        <ColumnBlock>
          <PointerDiv onClick={() => window.open(resume, "_blank")}>
            <DocumentIcon color="#444" />
          </PointerDiv>
        </ColumnBlock>
      )}
      {social.github && (
        <ColumnBlock>
          <PointerDiv onClick={() => handleRedirect("github")}>
            <GithubIcon color="#444" />
          </PointerDiv>
        </ColumnBlock>
      )}
      {social.stackoverflow && (
        <ColumnBlock>
          <PointerDiv onClick={() => handleRedirect("stackoverflow")}>
            <StackoverflowIcon color="#444" />
          </PointerDiv>
        </ColumnBlock>
      )}
      {social.portfolio && (
        <ColumnBlock>
          <PointerDiv onClick={() => handleRedirect("portfolio")}>
            <PortfolioIcon color="#444" />
          </PointerDiv>
        </ColumnBlock>
      )}
      {!social.youtube && (
        <ColumnBlock>
          <HoverBox
            HoveredView={PlusIcon}
            View={YoutubeIcon}
            handleOnClick={() => handleOnClick("youtube")}
            width="40px"
          />
        </ColumnBlock>
      )}
      {!social.twitter && (
        <ColumnBlock>
          <HoverBox
            HoveredView={PlusIcon}
            View={TwitterIcon}
            handleOnClick={() => handleOnClick("twitter")}
            width="40px"
          />
        </ColumnBlock>
      )}
      {!social.blog && (
        <ColumnBlock>
          <HoverBox
            HoveredView={PlusIcon}
            View={BlogIcon}
            handleOnClick={() => handleOnClick("blog")}
            width="40px"
          />
        </ColumnBlock>
      )}
      {!social.linkedin && (
        <ColumnBlock>
          <HoverBox
            HoveredView={PlusIcon}
            View={LinkedinIcon}
            handleOnClick={() => handleOnClick("linkedin")}
            width="40px"
          />
        </ColumnBlock>
      )}
      {!resume && (
        <ColumnBlock>
          <HoverBox
            HoveredView={PlusIcon}
            View={DocumentIcon}
            handleOnClick={() => handleOnClick("cv")}
            width="40px"
          />
        </ColumnBlock>
      )}
      {!social.github && (
        <ColumnBlock>
          <HoverBox
            HoveredView={PlusIcon}
            View={GithubIcon}
            handleOnClick={() => handleOnClick("github")}
            width="40px"
          />
        </ColumnBlock>
      )}
      {!social.stackoverflow && (
        <ColumnBlock>
          <HoverBox
            HoveredView={PlusIcon}
            View={StackoverflowIcon}
            handleOnClick={() => handleOnClick("stackoverflow")}
            width="40px"
          />
        </ColumnBlock>
      )}
      {!social.portfolio && (
        <ColumnBlock>
          <HoverBox
            HoveredView={PlusIcon}
            View={PortfolioIcon}
            handleOnClick={() => handleOnClick("portfolio")}
            width="40px"
          />
        </ColumnBlock>
      )}
    </RowBlock>
  );
}
