import React from "react";
import styled from "styled-components";
import {
  CompanyInfoBox,
  CompanyLogo,
  Container,
  List,
  Item,
} from "../CommonModule";
import TechnologiesUsed from "../TechnologiesUsed";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import CompanyInfo from "../CompanyInfo";
import TgCompanyNotes from "../TgCompanyNotes";
import PhotoGallery from "../../Match/modules/PhotoGallery";
import { getCompanySizeLabel } from "../../../helper";

const CompanyName = styled.span`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #444444;
  margin-right: 20px;
`;

const formatOfficePhotosForGalleryView = (officePhotos) => {
  if (officePhotos.length === 0) {
    return [];
  }
  return officePhotos.map((item) => {
    return {
      src: item.url,
      caption: item.description,
    };
  });
};

export default function CompanyTab({ publicPosition }) {
  return (
    <Container>
      <CompanyInfoBox>
        <CompanyLogo
          width="21px"
          height="21px"
          marginRight="10px"
          src={publicPosition.company.logo}
        />
        <CompanyName>{publicPosition.company.name}</CompanyName>
        <List>
          {publicPosition.company.foundedYear && (
            <Item>Founded: {publicPosition.company.foundedYear}</Item>
          )}
          {publicPosition.company.sector?.length > 0 && (
            <Item>
              Industry:{" "}
              {publicPosition.company.sector.map(
                (i, index) =>
                  `${i.label}${
                    publicPosition.company.sector.length - 1 > index ? ", " : ""
                  }`
              )}
            </Item>
          )}
          {publicPosition.company.teamSize ? (
            <Item>
              Team Size: {getCompanySizeLabel(publicPosition.company.teamSize)}
            </Item>
          ) : (
            ""
          )}
          {publicPosition.company.techTeamSize ? (
            <Item>
              Tech Team:{" "}
              {getCompanySizeLabel(publicPosition.company.techTeamSize)}
            </Item>
          ) : (
            ""
          )}
          {publicPosition.company.investStatus && (
            <Item>Total Funding: {publicPosition.company.investStatus}</Item>
          )}
        </List>
      </CompanyInfoBox>
      <ColumnBlock style={{ justifyContent: "left", width: "100%" }}>
        <CompanyInfo company={publicPosition.company} />
        <TgCompanyNotes notes={publicPosition.company.tgNotes} />
        {publicPosition.company.technologies.length > 0 && (
          <TechnologiesUsed
            technologies={publicPosition.company.technologies}
          />
        )}
      </ColumnBlock>
      <RowBlock>
        {publicPosition.company.officePhotos.length > 0 && (
          <PhotoGallery
            photoContainerStyles={{ maxHeight: "400px" }}
            photos={formatOfficePhotosForGalleryView(
              publicPosition.company.officePhotos
            )}
          />
        )}
      </RowBlock>
    </Container>
  );
}
