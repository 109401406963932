const GetUTMParamsFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {
    source: urlParams.get("utm_source"),
    medium: urlParams.get("utm_medium"),
    campaign: urlParams.get("utm_campaign"),
    term: urlParams.get("utm_term"),
    content: urlParams.get("utm_content"),
    selfId: urlParams.get("utm_id"),
  };
  return params;
};

const GetUTMParamsFromSession = () => {
  return JSON.parse(sessionStorage.getItem("utms"));
};

const SetUTMParamsToSession = () => {
  const utms = GetUTMParamsFromSession();
  if (!utms) {
    const params = GetUTMParamsFromUrl();
    return sessionStorage.setItem("utms", JSON.stringify(params));
  }
};

const RemoveUTMParamsFromSession = () => {
  return sessionStorage.removeItem("utms");
};

module.exports = {
  GetUTMParamsFromUrl,
  SetUTMParamsToSession,
  GetUTMParamsFromSession,
  RemoveUTMParamsFromSession,
};
