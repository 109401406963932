import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 18px;
`;

const Title = styled.h4`
  font-family: ${(props) => props.theme.family.bold};
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  color: #0c084c;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-color: #0c084c;
    height: 3px;
    width: 30px;
    left: 0px;
    bottom: -4px;
    border-radius: 3px;
  }
`;

const Content = styled.div`
  padding: 10px 0px;
`;

export default function DataGroup({ title, children, containerStyles = {} }) {
  return (
    <Container style={containerStyles}>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  );
}
