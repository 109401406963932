import React from "react";
import styled from "styled-components";
import { show, boot } from "../../utils/Intercom";

export const Message = styled.span`
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.font[1]};
`;

const handleClick = () => {
  boot();
  show();
};
export default ({
  title = "Didn't find what you were looking for? Write to us.",
}) => {
  return <Message onClick={handleClick}>{title}</Message>;
};
