import React from "react";
import Avatar from "../../Modules/Avatar";
import { GroupTitle, ProfilePhotoContainer } from "./CommonStyles";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";

export const ProfilePhotoFromImport = ({ state, handleStateChange }) => {
  if (!state.value) return null;

  return (
    <>
      <GroupTitle style={{ marginTop: "0" }}>Profile Photo</GroupTitle>
      <ColumnBlock>
        <ProfilePhotoContainer
          isChecked={state.isChecked}
          onClick={() => handleStateChange("avatarURL")}
        >
          <RowBlock alignItems="center">
            <Avatar size="110px" url={state.value} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flex: "1",
              }}
            >
              {state.isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
            </div>
          </RowBlock>
        </ProfilePhotoContainer>
      </ColumnBlock>
    </>
  );
};
