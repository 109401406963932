import styled from "styled-components";
import { space } from "styled-system";

export const RegisterMessage = styled.div`
  color: #aaaaaa;
  font-size: 14px;
  ${space}
`;

export const RegisterLink = styled.a`
  color: #aaaaaa;
  text-decoration: underline;
  font-size: 14px;
`;
