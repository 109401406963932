import gql from "graphql-tag";

export const GET_USER_WITH_LIVING_CITY = gql`
  {
    me {
      id
      livingCity {
        id
        city
        country
      }
    }
  }
`;

export const ADD_CRITERIA = gql`
  mutation CREATE_CRITERIA(
    $salary: DeveloperSalaryInputType
    $benefits: DeveloperBenefitsInputType
    $technologies: DeveloperTechnologiesInputType
    $location: DeveloperLocationInputType
    $companySize: DeveloperCompanySizeInputType
    $other: DeveloperOtherCriteriaInputType
    $freelance: DeveloperFreelanceInputType
  ) {
    createDeveloperCriteria(
      salary: $salary
      benefits: $benefits
      technologies: $technologies
      location: $location
      companySize: $companySize
      other: $other
      freelance: $freelance
    ) {
      other {
        expected
      }
    }
  }
`;
