import gql from "graphql-tag";

export const FETCH_USER_FROM_LINKEDIN = gql`
  query FetchUserFromLinkedin($uuid: ID, $username: String, $userId: ID) {
    fetchUserFromLinkedin(uuid: $uuid, userId: $userId, username: $username)
  }
`;

export const UPDATE_USER_FROM_LINKEDIN = gql`
  mutation UpdateUserfromLinkedin(
    $id: ID
    $bio: String
    $otherSkills: [ID]
    $languages: [UserLanguageInputType]
    $workHistory: [UserWorkHistoryInputType]
    $education: [UserEducationInputType]
    $isLinkedinAvatarUpload: Boolean
    $avatarURL: String
    $uuid: String
  ) {
    updateUserFromLinkedin(
      id: $id
      isLinkedinAvatarUpload: $isLinkedinAvatarUpload
      avatarURL: $avatarURL
      bio: $bio
      otherSkills: $otherSkills
      languages: $languages
      workHistory: $workHistory
      education: $education
      uuid: $uuid
    ) {
      id
      workHistory {
        position
        description
        company
        startDate
        endDate
        present
      }
      languages {
        level
        language {
          label
        }
      }
      education {
        school {
          name
        }
        branch {
          label
        }
        startDate
        endDate
        type
      }
    }
  }
`;
