import styled from "styled-components";
import { space, width, height } from "styled-system";

const RadioLabel = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  & input:checked ~ .checkmark {
    background-color: #8fdeac;
  }

  & input ~ .checkmark:after {
    display: block;
  }
  ${space}
`;

const RadioCheckMark = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
  ${width}
  ${height}
  ${space}
  background-color: #e5e5e5;
  border-radius: 50%;
  &:after {
    content: "";
    position: absolute;
    display: none;
    top: ${({ height: h }) => h / 4}px;
    left: ${({ width: w }) => w / 4}px;
    width: ${({ width: w }) => w / 2}px;
    height: ${({ height: h }) => h / 2}px;
    border-radius: 50%;
    background: white;
  }
`;

const RadioTextWrapper = styled.span`
  font-weight: 500;
  font-family: "Avenir Next LT Pro Medium";
  font-size: 12px;
  line-height: 1.6;
  color: #444444;
  display: block;
`;

export { RadioLabel, RadioCheckMark, RadioTextWrapper };
