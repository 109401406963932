import React, { useCallback } from "react";
import { useQuery } from "@apollo/client";
import makeAnimated from "react-select/animated";
import { GET_TECHNOLOGIES } from "../../graphql/queries";
import {
  CriteriaFieldDescription,
  CriteriaFieldTitle,
  ColumnBlock,
} from "../BlockUi";
import { AsyncSelectField } from "../Form/SelectField";
import { debounce, captureErrorWithData } from "../../helper";
import message from "../../utils/message";
import PartialError from "../../pages/ErrorPage/PartialError";

const animatedComponents = makeAnimated();

const TechnologiesCriteria = ({ selectedValues, handleOnChange }) => {
  const { error, refetch: searchTechnologies } = useQuery(GET_TECHNOLOGIES, {
    skip: true,
  });

  const handleSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const response = await searchTechnologies({
          search: val,
          limit: 20,
          verified: true,
        });

        callback(response.data?.allTechnology?.technologies || []);
      } catch (err) {
        message.error("Something went wrong.");
        captureErrorWithData(err);
      }
    }, 500),
    []
  );

  if (error) return <PartialError />;

  return (
    <>
      <ColumnBlock>
        <CriteriaFieldTitle>
          What technologies would you want to use for your next role?
        </CriteriaFieldTitle>
        <CriteriaFieldDescription>
          You can add programming languages, frameworks, architectural choices,
          etc.
        </CriteriaFieldDescription>

        <AsyncSelectField
          defaultValue={selectedValues.expected}
          onChange={handleOnChange}
          getOptionLabel={(option) => `${option.title}`}
          getOptionValue={(option) => `${option.id}`}
          loadOptions={handleSearch}
          components={animatedComponents}
          isMulti
          isSearchable
          placeholder="Search technology..."
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
        />
      </ColumnBlock>
    </>
  );
};

export default TechnologiesCriteria;
