import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { space } from "styled-system";
import { EmptyBox } from "tg-design";
import { getMatchHeapInfo } from "../../helper";
import device from "../../styles/device";
import { heapIO } from "../../utils/heap";
import { RowBlock } from "../BlockUi";
import MatchCard from "./MatchCard";
import { MatchFilterSettingsIcon } from "../Icon";
import Popover from "../Popover";
import MatchFilterOptions from "./MatchFilterOptions";
import { resetFilters } from "../../store/slices/matchFilter";

const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: flex-start;
  @media ${device.tablet} {
    border-right: 1px solid #dddddd;
  }
  overflow: scroll;
  height: 100vh;

  @media only screen and (max-width: 768px) {
    overflow: unset;
  }
  ${space}
`;

const Title = styled.h2`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 18px;
  font-weight: bold;
  color: #0c084c;
`;

const ResetFilters = styled.div`
  font-family: ${(props) => props.theme.family.regular};
  text-align: center;
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

export default function MatchCardList({
  matches,
  handleSelect,
  activeMatchId,
  refetchMyMatches,
  isEmpty,
}) {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const { checkboxFilters, isActive, stateFilters, sortFilter, typeFilter } =
    useSelector((state) => state.matchFilter);
  const [isMatchFilterSettingsVisible, setIsMatchFilterSettingsVisible] =
    useState(false);

  const handleClick = (e, item) => {
    e.preventDefault();
    handleSelect(item);
    heapIO.createCustomEvent("Matches Card Clicked", {
      ...getMatchHeapInfo(item),
      matchRate: item.rate,
    });
  };

  const toggleMatchFilterSettings = () => {
    setIsMatchFilterSettingsVisible(!isMatchFilterSettingsVisible);
  };

  const handleResetFilters = () => {
    dispatch(resetFilters());
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsMatchFilterSettingsVisible(false);
    }
  };

  useEffect(() => {
    if (isActive) {
      refetchMyMatches({
        filters: { state: { in: stateFilters } },
        sort: { [sortFilter]: "DESC" },
        typeFilter,
      });
    }
    // eslint-disable-next-line
  }, [isActive, stateFilters, sortFilter, typeFilter]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMatchFilterSettingsVisible]);

  return (
    <>
      <Box pb={5} pr={{ mobileS: 0, tablet: "14px" }}>
        <RowBlock alignItems="center" justifyContent="space-between">
          <Title>Matching Roles</Title>
          <RowBlock alignItems="center" ref={wrapperRef}>
            {Object.values(checkboxFilters).includes(false) && (
              <ResetFilters onClick={handleResetFilters}>Show All</ResetFilters>
            )}
            <Popover
              width={200}
              floatDimensions={{ right: "calc(100% - 21px)", top: "8px" }}
              display={isMatchFilterSettingsVisible ? "block" : "none"}
              body={<MatchFilterOptions />}
              trigger={<MatchFilterSettingsIcon />}
              onTrigger={toggleMatchFilterSettings}
            />
          </RowBlock>
        </RowBlock>
        <RowBlock
          align="start"
          justify="center"
          width="100%"
          my={3}
          style={{ paddingBottom: "100px" }}
        >
          {matches?.length === 0 && (
            <EmptyBox
              containerStyles={{ backgroundColor: "#fff" }}
              content="There are no matches to display"
            />
          )}
          {matches?.length > 0 &&
            matches.map((item) => (
              <MatchCard
                key={item.id}
                active={item.id === activeMatchId}
                matches={matches}
                matchId={item.id}
                positionTitle={item.position.title}
                companyName={item.position.company.name}
                location={item.position.criteria.positionLocation}
                status={item.status}
                history={item.history}
                state={item.state}
                companyLogo={item.position.company.logo}
                handleSelect={(e) => handleClick(e, item)}
                companyLocations={item.position.company.locations}
                positionState={item.position.state}
                isEmpty={isEmpty}
                isExternalPosition={item.position.isExternal}
              />
            ))}
        </RowBlock>
      </Box>
    </>
  );
}
