import React from "react";
import styled from "styled-components";
import Avatar from "../Modules/Avatar";
import { BlackLinkedInIcon, OpenWebSiteIcon } from "../Icon";
import { heapIO } from "../../utils/heap";
import { fixUrl, getCompanySizeLabel } from "../../helper";

const ItemContainer = styled.ul`
  padding-left: 16px;
  margin-top: 10px;
  display: flex;
  flex-flow: row wrap;
`;

const Item = styled.li`
  margin-right: 22px;
  color: #979797;
  font-family: ${(props) => props.theme.family.demi};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  &::before {
    color: #0c084c;
    margin: 0px;
    left: -16px;
  }
`;

const CompanyBox = styled.div`
  margin-top: 15px;
  background: #f6f6f6;
  border-radius: 5px;
  padding: 12px 18px;
  display: flex;
  justify-content: space-between;
`;

const CompanySection = styled.div``;

const CompanyNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const CompanyName = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #444444;
  margin-left: 10px;
`;

const CompanySlogan = styled.div`
  font-family: ${(props) => props.theme.family.medium};
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #444444;
`;

const LinkSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCompanyLink = styled.a`
  background-color: #ebecf0;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  padding: 12px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }
`;

const handleLinkClick = (info, heapEvent) => {
  heapIO.createCustomEvent(heapEvent, info);
};

export const CompanyLink = ({ src, icon, info, heapEvent }) => {
  const url = fixUrl(src);

  return (
    <StyledCompanyLink
      onClick={() => handleLinkClick(info, heapEvent)}
      target="_blank"
      href={url}
      rel="noopener noreferrer"
    >
      {icon}
    </StyledCompanyLink>
  );
};

export default function CompanyBasics({ company, heapInfo }) {
  return (
    <>
      <CompanyBox>
        <CompanySection>
          <CompanyNameBox>
            <Avatar
              url={company.logo}
              name={company.name}
              size="24px"
              fontSize="14px"
            />
            <CompanyName>{company.name}</CompanyName>
          </CompanyNameBox>
          <CompanySlogan>{company.slogan}</CompanySlogan>
        </CompanySection>
        <LinkSection>
          {company.linkedin && (
            <CompanyLink
              src={company.linkedin}
              icon={<BlackLinkedInIcon />}
              info={heapInfo}
              heapEvent="Match Linkedin Clicked"
            />
          )}
          {company.website && (
            <CompanyLink
              src={company.website}
              icon={<OpenWebSiteIcon />}
              info={heapInfo}
              heapEvent="Match External Page Clicked"
            />
          )}
        </LinkSection>
      </CompanyBox>
      <ItemContainer>
        {company.foundedYear && <Item>Founded: {company.foundedYear}</Item>}
        {company.sector?.length > 0 && (
          <Item>
            Industry:{" "}
            {company.sector.map(
              (i, index) =>
                `${i.label}${company.sector.length - 1 > index ? ", " : ""}`
            )}
          </Item>
        )}
        {company.teamSize ? (
          <Item>Team size: {getCompanySizeLabel(company.teamSize)}</Item>
        ) : (
          ""
        )}
        {company.techTeamSize ? (
          <Item>Tech team: {getCompanySizeLabel(company.teamSize)}</Item>
        ) : (
          ""
        )}
        {company.investStatus && (
          <Item>Investment status: {company.investStatus}</Item>
        )}
      </ItemContainer>
    </>
  );
}
