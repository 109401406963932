import React from "react";
import styled from "styled-components";
import { ColumnBlock, RowBlock } from "../../BlockUi";

const ViewCardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px 22px;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 310px;
  height: 220px;
  margin-top: 30px;
`;

const Image = styled.img`
  background-color: #fff;
  position: absolute;
  left: -7px;
  top: -13px;
  padding: 0px 8px 8px 0;
  z-index: 0;
`;

const ViewCardTitle = styled.span`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  color: #0c084c;
  z-index: 1;
`;

export default function CriteriaViewCard({ image, title, children }) {
  return (
    <ViewCardContainer>
      <Image src={image} alt="" />
      <RowBlock>
        <ViewCardTitle>{title}</ViewCardTitle>
      </RowBlock>
      <RowBlock mt={{ mobileS: 4 }}>
        <ColumnBlock justifyContent={{ mobileS: "flex-start" }}>
          {children}
        </ColumnBlock>
      </RowBlock>
    </ViewCardContainer>
  );
}
