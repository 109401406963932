import React from "react";
import { useHistory } from "react-router-dom";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import { SectionTitle, SkillItemRow, SkillItemNumber } from "../CommonStyles";
import Collapse from "../../Modules/Collapse";
import OtherSkills from "./OtherSkills";
import SkillDonut from "../../Modules/SkillDonut";
import HoveredEmptyView from "../../Modules/HoveredEmptyView";

export default function ProfileTechnologiesCard({ skills, otherSkills }) {
  const history = useHistory();
  const skillList = new Array(5).fill({}).map((skill, index) => {
    return skills[index] || {};
  });

  skillList.sort((a, b) => b.experience - a.experience);

  const handleEdit = () => {
    history.push("profile/edit/skills");
  };

  return (
    <RowBlock>
      <Collapse title="Skills & Experience" open edit handleOnEdit={handleEdit}>
        <RowBlock
          width="100%"
          justifyContent={{ mobileL: "flex-start" }}
          mt={3}
          pb={3}
        >
          <SectionTitle>Highlights:</SectionTitle>
        </RowBlock>
        <RowBlock
          width="100%"
          mt={4}
          justifyContent={{ mobileL: "space-between" }}
        >
          <ColumnBlock
            width={{ mobileS: "100%", tablet: "60%" }}
            onClick={handleEdit}
          >
            {skills.length === 0 && <HoveredEmptyView text="Add" />}
            {skills.length > 0 &&
              skillList.map((skill, index) => (
                <SkillItemRow
                  width={{ mobileS: "100%", tablet: "80%", laptopL: "50%" }}
                  key={index}
                  opacity={!skill.experience ? 0.3 : 1}
                >
                  <ColumnBlock mr={2}>
                    <SkillItemNumber>{index + 1}</SkillItemNumber>
                  </ColumnBlock>
                  <ColumnBlock flex="1"> {skill.title}</ColumnBlock>
                  {skill.experience && (
                    <ColumnBlock>
                      {skill.experience < 8
                        ? `${skill.experience} year`
                        : "8+ years"}
                    </ColumnBlock>
                  )}
                </SkillItemRow>
              ))}
          </ColumnBlock>
          {skills.length > 0 && (
            <ColumnBlock
              width={{ mobileS: "100%", tablet: "40%" }}
              alignItems={{ mobileL: "center" }}
              mb={{ mobileS: 2 }}
            >
              <SkillDonut skills={skills} />
            </ColumnBlock>
          )}
        </RowBlock>
        <RowBlock
          width="100%"
          mt={4}
          justifyContent={{ mobileL: "space-between" }}
        >
          <SectionTitle>Other Skills:</SectionTitle>
        </RowBlock>
        <RowBlock
          width="100%"
          justifyContent={{ mobileL: "space-between" }}
          minHeight="80px"
          onClick={handleEdit}
        >
          {otherSkills.length === 0 && <HoveredEmptyView text="Add" />}
          {otherSkills.length > 0 && <OtherSkills otherSkills={otherSkills} />}
        </RowBlock>
      </Collapse>
    </RowBlock>
  );
}
