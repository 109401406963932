import React from "react";
import styled from "styled-components";
import { Container } from "../CommonModule";
import IntroductionVideoSection from "../IntroductionVideo";
import PositionDescription from "../PositionDescription";
import Benefits from "../PositionBenefits";
import PositionRequirements from "../PositionRequirements";
import device from "../../../styles/device";
import PositionHighlights from "../PositionHighlights";

const RequirementsContainer = styled.div`
  border-right: 0.8px solid #e3e3e3;
  height: 100%;
  width: 55%;
  padding-right: 36px;
  margin-right: 36px;
  @media ${device.mobileMax} {
    border-right: none;
    width: 100%;
  }
`;

const BenefitsContainer = styled.div`
  height: 100%;
  width: 50%;
  margin-right: 36px;
  @media ${device.mobileMax} {
    width: 100%;
  }
`;

const RequirementsAndBenefitsSection = styled.div`
  display: flex;
  @media ${device.mobileMax} {
    flex-direction: column;
  }
`;

export default function RoleTab({ publicPosition, swipeableHandlers }) {
  return (
    <Container>
      <IntroductionVideoSection
        assignee={publicPosition.assignee}
        content={publicPosition.content}
      />
      <PositionHighlights
        publicPosition={publicPosition}
        swipeableHandlers={swipeableHandlers}
      />
      <PositionDescription position={publicPosition} />

      <RequirementsAndBenefitsSection>
        <RequirementsContainer>
          <PositionRequirements publicPosition={publicPosition} />
        </RequirementsContainer>
        <BenefitsContainer>
          <Benefits criteria={publicPosition.criteria} />
        </BenefitsContainer>
      </RequirementsAndBenefitsSection>
    </Container>
  );
}
