import { createContext } from "react";

const AppContext = createContext({
  isAuth: false,
  user: {
    id: null,
    email: null,
  },
});

export default AppContext;
