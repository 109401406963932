import React, { useState } from "react";
import styled from "styled-components";
import { PlusIcon } from "../Icon";
import { RowBlock } from "../BlockUi";
import { DashedBox } from "../Profile/CommonStyles";

const AddBtn = styled.span`
  text-overflow: ellipsis;
  margin-left: 8px;
  color: ${(props) => props.color};
`;

export default function HoveredEmptyView({ text = "Add", autoFocus }) {
  const color = autoFocus ? "#8fdeac" : "black";
  const [hoverState, SetHoverState] = useState(false);
  const onMouseEnter = () => {
    SetHoverState(true);
  };
  const onMouseLeave = () => {
    SetHoverState(false);
  };

  return (
    <RowBlock
      justifyContent="center"
      alignItems="center"
      height="60px"
      width="100%"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <DashedBox style={{ border: autoFocus && "solid 3px #8fdeac" }}>
        <PlusIcon color={hoverState ? color : "#aaaaaa"} />
        <AddBtn color={hoverState ? color : "#aaaaaa"}>{text}</AddBtn>
      </DashedBox>
    </RowBlock>
  );
}
