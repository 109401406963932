// professional
// eslint-disable-next-line import/prefer-default-export
import React from "react";
import {
  RelocationIcon,
  StockOptionIcon,
  StackIcon,
  EuroIcon,
  DollarIcon,
  FullRemoteIcon,
  PoundIcon,
} from "tg-design";

export const LANGUAGE_CODES = {
  en: "en-US",
};

export const LINKEDIN_PROFILE_FIELDS = {
  EDUCATION: "education",
  EXPERIENCE: "experience",
  LANGUAGE: "language",
};
export const HttpStatusCodes = Object.freeze({
  NOT_FOUND: 404,
});
export const MATCH_CREATION_TYPES = {
  BATCH: "BATCH",
  REFERRAL: "REFERRAL",
  FORCE_MATCH: "FORCE_MATCH",
  LANDING_PAGE: "LANDING_PAGE",
  MARKETING_PAGE: "MARKETING_PAGE",
};
export const PUBLIC_LINK_TYPES = {
  m: "MARKETING_PAGE",
  landing: "LANDING_PAGE",
};

export const PRIVACY_POLICY_TYPES = {
  TR: "TR",
  GLOBAL: "GLOBAL",
};

export const proExperiences = [
  { name: "0-1 years", value: 0 },
  { name: "1 year", value: 1 },
  { name: "2 years", value: 2 },
  { name: "3 years", value: 3 },
  { name: "4 years", value: 4 },
  { name: "5 years", value: 5 },
  { name: "6 years", value: 6 },
  { name: "7 years", value: 7 },
  { name: "8 years", value: 8 },
  { name: "9 years", value: 9 },
  { name: "10 years", value: 10 },
  { name: "10+ years", value: 11 },
];

export const experience = [
  { value: 1, name: "1 year" },
  { value: 2, name: "2 years" },
  { value: 3, name: "3 years" },
  { value: 4, name: "4 years" },
  { value: 5, name: "5 years" },
  { value: 6, name: "6 years" },
  { value: 7, name: "7 years" },
  { value: 8, name: "8+ years" },
];

export const techCategories = {
  1: "language",
  2: "tool",
  3: "cloud",
  4: "versioning",
  5: "framework",
  6: "architecture",
  7: "database",
  8: "cicd",
  9: "methodologies",
  10: "analytics",
};

export const TECH_CATEGORY_TITLES = {
  language: "Programming language",
  framework: "Framework & libraries",
  tool: "Tools",
  cloud: "Cloud systems",
  versioning: "Versioning",
  architecture: "Architecture",
  database: "Database systems",
  analytics: "Analytics & reporting",
  methodologies: "Methodologies",
  cicd: "CI/CD Tools",
};

export const TECH_CATEGORY_TOOLS = {
  LANGUAGE: 1,
  TOOL: 2,
  CLOUD: 3,
  VERSIONING: 4,
  FRAMEWORK: 5,
  ARCHITECTURE: 6,
  DATABASE: 7,
  CICD: 8,
  METHODOLOGIES: 9,
  ANALYTICS: 10,
};

export const headData = {
  register: {
    title: "Register | Talentgrid",
    desc: "Kişisel tercihlerin ve yeteneklerin ile profilini oluştur, eşleşen pozisyonlardan haberdar ol.",
  },
  login: {
    title: "Log in | Talentgrid",
    desc: "TalentGrid profiline giriş yaparak tercih ve yeteneklerini güncelleyebilir, eşleştiğin pozisyonları görüntüleyebilirsin.",
  },
  profile: {
    title: "Profile | Talentgrid",
    desc: "TalentGrid'deki kapalı profilinde yeteneklerini ve kişisel tercihlerini kaydederek, yalnızca bu filtrelerinle eşleşen pozisyonlardan haberdar olursun.",
  },
  profileEdit: {
    title: "Profile Edit | Talentgrid",
    desc: "TalentGrid'deki kapalı profilinde yeteneklerini ve kişisel tercihlerini güncelleyebilirsin.",
  },
  invite: {
    title: "Invite | Talentgrid",
    desc: "Tanıdığın yazılımcıları TalentGrid’e davet et, hem sen kazan, hem de ekosisteme katkı sağla.",
  },
  match: {
    title: "Matches | Talentgrid",
    desc: "Kişisel tercihlerin ve yeteneklerin doğrultusunda eşleştiğin pozisyonları listele, ilgilendiklerinle görüş.",
  },
  account: {
    title: "Account | Talentgrid",
    desc: "TalentGrid hesap ayarlarını yönet.",
  },
  discover: {
    title: "Discover | Talentgrid",
    desc: "TalentGrid haberlerini kaçırma!",
  },
};

export const POSITION_STATES = {
  ON_HOLD: "ON_HOLD",
  CREATED: "CREATED",
  SUBMITTED: "SUBMITTED",
  IN_PROGRESS: "IN_PROGRESS",
  FILLED: "FILLED",
  CLOSED: "CLOSED",
  CANCELED: "CANCELED",
};

export const MATCH_STATES = {
  CREATED: "CREATED",
  SENT: "SENT",
  INTERESTED: "INTERESTED",
  TG_ASSESSMENT: "TG_ASSESSMENT",
  COMPANY_ASSESSMENT: "COMPANY_ASSESSMENT",
  INTERVIEW: "INTERVIEW",
  SENT_OFFER: "SENT_OFFER",
  HIRED: "HIRED",
  PASSED: "PASSED",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
};

// bu arrayin sirasi onemli. match historydeki son aksiyonu bulmak icin kullaniliyor.
export const MATCH_STATES_ARRAY = [
  MATCH_STATES.CREATED,
  MATCH_STATES.SENT,
  MATCH_STATES.INTERESTED,
  MATCH_STATES.TG_ASSESSMENT,
  MATCH_STATES.COMPANY_ASSESSMENT,
  MATCH_STATES.INTERVIEW,
  MATCH_STATES.SENT_OFFER,
  MATCH_STATES.HIRED,
  MATCH_STATES.PASSED,
  MATCH_STATES.FAILED,
  MATCH_STATES.CANCELED,
];

export const EU_COUNTRY_CODES = [
  "DE",
  "AT",
  "BE",
  "BG",
  "CR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
];

export const CURRENCIES = [
  { label: "USD", value: "USD" },
  { label: "TRY", value: "TRY" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
];

export const COUNTRIES = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
    language: "Pashto",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
    language: "Albanian",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
    language: "Arabic",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1 684",
    code: "AS",
    language: "English",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
    language: "Catalan",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
    language: "Portuguese",
  },
  {
    name: "Anguilla",
    dial_code: "+1 264",
    code: "AI",
    language: "English",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
    language: "English",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
    language: "Spanish",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
    language: "Armenian",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
    language: "Dutch",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
    language: "English",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
    language: "German",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
    language: "Azerbaijani",
  },
  {
    name: "Bahamas",
    dial_code: "+1 242",
    code: "BS",
    language: "English",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
    language: "Arabic",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
    language: "Bengali",
  },
  {
    name: "Barbados",
    dial_code: "+1 246",
    code: "BB",
    language: "English",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
    language: "Belarusian",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
    language: "Dutch",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
    language: "English",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
    language: "French",
  },
  {
    name: "Bermuda",
    dial_code: "+1 441",
    code: "BM",
    language: "English",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
    language: "Dzongkha",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
    language: "Bosnian",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
    language: "English",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
    language: "Portuguese",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
    language: "English",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
    language: "Bulgarian",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
    language: "French",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
    language: "French",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
    language: "Khmer",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
    language: "English",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
    language: "English",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
    language: "Portuguese",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
    language: "English",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
    language: "French",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
    language: "Arabic",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
    language: "Spanish",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
    language: "Mandarin",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
    language: "English",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
    language: "Spanish",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
    language: "Arabic",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
    language: "French",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
    language: "English",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
    language: "Spanish",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
    language: "Croatian",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
    language: "Spanish",
  },
  {
    name: "Cyprus",
    dial_code: "+537",
    code: "CY",
    language: "Greek",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
    language: "Czech",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
    language: "Danish",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
    language: "Arabic",
  },
  {
    name: "Dominica",
    dial_code: "+1 767",
    code: "DM",
    language: "English",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1 849",
    code: "DO",
    language: "Spanish",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
    language: "Spanish",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
    language: "Arabic",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
    language: "Spanish",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
    language: "French",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
    language: "Tigrinya",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
    language: "Estonian",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
    language: "Afar",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
    language: "Faroese",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
    language: "English",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
    language: "Finnish",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
    language: "French",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
    language: "French",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
    language: "French",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
    language: "French",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
    language: "English",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
    language: "Georgian",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
    language: "German",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
    language: "English",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
    language: "Gibraltar",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
    language: "Greek",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
    language: "Greenlandic",
  },
  {
    name: "Grenada",
    dial_code: "+1 473",
    code: "GD",
    language: "English",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
    language: "French",
  },
  {
    name: "Guam",
    dial_code: "+1 671",
    code: "GU",
    language: "English",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
    language: "Spanish",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
    language: "French",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
    language: "Portuguese",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
    language: "English",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
    language: "French",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
    language: "Spanish",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
    language: "Hungarian",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
    language: "Icelandic",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    language: "Hindi",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
    language: "Indonesian",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
    language: "Arabic",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
    language: "Irish",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
    language: "Hebrew",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    language: "Italian",
  },
  {
    name: "Jamaica",
    dial_code: "+1 876",
    code: "JM",
    language: "English",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
    language: "Japanese",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
    language: "Arabic",
  },
  {
    name: "Kazakhstan",
    dial_code: "+7 7",
    code: "KZ",
    language: "Kazakh",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
    language: "English",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
    language: "English",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
    language: "Arabic",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
    language: "Kyrgyz",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
    language: "Latvian",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
    language: "Arabic",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
    language: "Sotho",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
    language: "English",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
    language: "German",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
    language: "Lithuanian",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
    language: "French",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
    language: "French",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
    language: "English",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
    language: "Malay",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
    language: "Dhivehi",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
    language: "French",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
    language: "Maltese",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
    language: "English",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
    language: "French",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
    language: "Arabic",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
    language: "English",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
    language: "French",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
    language: "Spanish",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
    language: "French",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
    language: "Mongolian",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
    language: "Montenegrin",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
    language: "English",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
    language: "Arabic",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
    language: "Burmese",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
    language: "English",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
    language: "English",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
    language: "Nepali",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
    language: "Dutch",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
    language: "Dutch",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
    language: "French",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
    language: "Maori",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
    language: "Spanish",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
    language: "French",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
    language: "English",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
    language: "English",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
    language: "English",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1 670",
    code: "MP",
    language: "English",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
    language: "Norwegian",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
    language: "Arabic",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
    language: "Urdu",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
    language: "English",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
    language: "Spanish",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
    language: "English",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
    language: "Spanish",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
    language: "Spanish",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
    language: "Filipino",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
    language: "Polish",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
    language: "Portuguese",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1 939",
    code: "PR",
    language: "Spanish",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
    language: "Arabic",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
    language: "Romanian",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
    language: "English",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
    language: "English",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
    language: "Italian",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
    language: "Arabic",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
    language: "French	",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
    language: "Serbian",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
    language: "English",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
    language: "English",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
    language: "English",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
    language: "Slovak",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
    language: "Slovene",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
    language: "English",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
    language: "Afrikaans",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
    language: "English",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
    language: "Spanish",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    language: "Sinhala",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
    language: "Arabic",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
    language: "Dutch",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
    language: "English",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
    language: "Swedish",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
    language: "German",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
    language: "Tajik",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
    language: "Thai",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
    language: "French",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
    language: "English",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
    language: "English",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1 868",
    code: "TT",
    language: "English",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
    language: "Arabic",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
    language: "Turkish",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
    language: "Turkmen",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1 649",
    code: "TC",
    language: "English",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
    language: "English",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
    language: "English",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
    language: "Ukrainian",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    language: "Arabic",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    language: "English",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
    language: "English",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
    language: "Spanish",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
    language: "Uzbek",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
    language: "English",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
    language: "French",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
    language: "Arabic",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
    language: "English",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
    language: "English",
  },
  {
    name: "Aland Islands",
    dial_code: "",
    code: "AX",
    language: "Swedish",
  },
  {
    name: "Antarctica",
    dial_code: null,
    code: "AQ",
    language: "English",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
    language: "Spanish",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
    language: "Malay",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
    language: "English",
  },
  {
    name: "Congo, The Democratic Republic of the",
    dial_code: "+243",
    code: "CD",
    language: "French",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
    language: "French",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
    language: "English",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
    language: "English",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
    language: "Italian",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
    language: "English",
  },
  {
    name: "Iran, Islamic Republic of",
    dial_code: "+98",
    code: "IR",
    language: "Arabic",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
    language: "English",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
    language: "English",
  },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: "+850",
    code: "KP",
    language: "Korean",
  },
  {
    name: "Korea, Republic of",
    dial_code: "+82",
    code: "KR",
    language: "Korean",
  },
  {
    name: "Lao People's Democratic Republic",
    dial_code: "+856",
    code: "LA",
    language: "Lao",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
    language: "Arabic",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
    language: "Portuguese",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    dial_code: "+389",
    code: "MK",
    language: "Macedonian",
  },
  {
    name: "Micronesia, Federated States of",
    dial_code: "+691",
    code: "FM",
    language: "English",
  },
  {
    name: "Moldova, Republic of",
    dial_code: "+373",
    code: "MD",
    language: "Romanian",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
    language: "Portuguese",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
    language: "Arabic",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
    language: "English",
  },
  {
    name: "Réunion",
    dial_code: "+262",
    code: "RE",
    language: "French",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
    language: "Russian",
  },
  {
    name: "Saint Barthélemy",
    dial_code: "+590",
    code: "BL",
    language: "French",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
    language: "English",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1 869",
    code: "KN",
    language: "English",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1 758",
    code: "LC",
    language: "English",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
    language: "Dutch",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
    language: "French",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1 784",
    code: "VC",
    language: "English",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
    language: "Portuguese",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
    language: "Arabic",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
    language: "Norwegian",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
    language: "Arabic",
  },
  {
    name: "Taiwan, Province of China",
    dial_code: "+886",
    code: "TW",
    language: "Mandarin",
  },
  {
    name: "Tanzania, United Republic of",
    dial_code: "+255",
    code: "TZ",
    language: "English",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
    language: "Portuguese",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    dial_code: "+58",
    code: "VE",
    language: "Spanish",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
    language: "Vietnamese",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1 284",
    code: "VG",
    language: "English",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1 340",
    code: "VI",
    language: "English",
  },
];

export const EDUCATION_TYPES_WITH_KEYS = [
  { label: "Associate's Degree", value: 0 },
  { label: "Bachelor's Degree", value: 1 },
  { label: "Master’s Degree", value: 2 },
  { label: "Doctorate Degree", value: 3 },
];

export const EDUCATION_TYPE_TITLES = [
  "Associate's Degree",
  "Bachelor's Degree",
  "Master’s Degree",
  "Doctorate Degree",
];

export const LANGUAGE_LEVEL_TYPES_WITH_KEYS = [
  { label: "A1 - Beginner", value: 1, percentage: 14.32 },
  { label: "A2 - Elementary", value: 2, percentage: 28.6 },
  { label: "B1 - Intermediate", value: 3, percentage: 42.88 },
  { label: "B2 - Upper", value: 4, percentage: 55.16 },
  { label: "C1 - Advanced", value: 5, percentage: 71.44 },
  { label: "C2 - Proficiency", value: 6, percentage: 85.72 },
  { label: "Native", value: 7, percentage: 100 },
];

export const LANGUAGE_LEVEL_TITLES = [
  "",
  "A1 - Beginner",
  "A2 - Elementary",
  "B1 - Intermediate",
  "B2 - Upper",
  "C1 - Advanced",
  "C2 - Proficiency",
  "Native",
];

export const WORKING_STYLE_TYPES_WITH_KEYS = [
  { label: "I do NOT want to work remotely", value: "none" },
  { label: "Remote or Onsite", value: "half" },
  { label: "I want to be 100% remote", value: "full" },
];

export const ISTANBUL_REGION_TYPES_WITH_KEYS = [
  { label: "All of Istanbul", value: "all" },
  { label: "Asia", value: "anatolia" },
  { label: "Europe", value: "europe" },
];

export const ENGLISH_LANGUAGE_ID = "606f5ad491f7f5655a8c0666";

export const ISTANBUL_PLACE_ID = "60783e804136605b0a48bfe8";

export const REPORT_LANGUAGES = [
  {
    flag: "🇺🇸",
    title: "English",
    code: "en-US",
  },
  {
    flag: "🇹🇷",
    title: "Turkish",
    code: "tr-TR",
  },
];

export const EDUCATION_TYPES = [
  {
    label: "Associate's Degree",
    value: 0,
  },
  {
    label: "Bachelor's Degree",
    value: 1,
  },
  {
    label: "Master’s Degree",
    value: 2,
  },
  {
    label: "Doctorate Degree",
    value: 3,
  },
];

export const LANGUAGE_LEVEL_TYPES = [
  {
    label: "A1 - Beginner",
    value: 1,
  },
  {
    label: "A2 - Elementary",
    value: 2,
  },
  {
    label: "B1 - Intermediate",
    value: 3,
  },
  {
    label: "B2 - Upper Intermediate",
    value: 4,
  },
  {
    label: "C1 - Advanced",
    value: 5,
  },
  {
    label: "C2 - Proficiency",
    value: 6,
  },
  {
    label: "Native",
    value: 7,
  },
];

export const MOST_SPOKEN_LANGUAGES = [
  "English",
  "Spanish",
  "German",
  "French",
  "Romanian",
  "Mandarin",
  "Arabic",
  "Russian",
  "Ukrainian",
  "Hindi",
  "Italian",
  "Turkish",
  "Portuguese",
  "Korean",
];

export const HIGHLIGHT_TYPE_ENUM = Object.freeze({
  LOCATION: 0,
  SALARY: 1,
  EXTRA: 2,
});

export const HIGHLIGHT_VALUE_ENUM = Object.freeze({
  RELOCATION_SUPPORT: 0,
  FULL_REMOTE: 1,
  POUND_BASED_SALARY: 2,
  EURO_BASED_SALARY: 3,
  DOLLAR_BASED_SALARY: 4,
  STOCK_OPTIONS: 5,
  MODERN_STACK: 6,
});

export const POSITION_HIGHLIGHT_CONTENT = Object.freeze({
  [HIGHLIGHT_VALUE_ENUM.RELOCATION_SUPPORT]: {
    text: "Relocation Support",
    icon: <RelocationIcon width={30} height={30} />,
  },
  [HIGHLIGHT_VALUE_ENUM.FULL_REMOTE]: {
    text: "Full Remote",
    icon: <FullRemoteIcon width={30} height={30} />,
  },
  [HIGHLIGHT_VALUE_ENUM.POUND_BASED_SALARY]: {
    text: "£ Based Salary",
    icon: <PoundIcon width={30} height={30} />,
  },
  [HIGHLIGHT_VALUE_ENUM.EURO_BASED_SALARY]: {
    text: "€ Based Salary",
    icon: <EuroIcon width={30} height={30} />,
  },
  [HIGHLIGHT_VALUE_ENUM.DOLLAR_BASED_SALARY]: {
    text: "$ Based Salary",
    icon: <DollarIcon width={30} height={30} />,
  },
  [HIGHLIGHT_VALUE_ENUM.STOCK_OPTIONS]: {
    text: "Stock Options",
    icon: <StockOptionIcon width={30} height={30} />,
  },
  [HIGHLIGHT_VALUE_ENUM.MODERN_STACK]: {
    text: "Modern Stack",
    icon: <StackIcon width={30} height={30} />,
  },
});

export const POSITION_HIGHLIGHT_COLOR = Object.freeze({
  [HIGHLIGHT_TYPE_ENUM.LOCATION]: {
    primaryColor: "#0B0849",
    secondaryColor: "#89CFF7",
  },
  [HIGHLIGHT_TYPE_ENUM.SALARY]: {
    primaryColor: "#0B0849",
    secondaryColor: "#89CFF7",
  },
  [HIGHLIGHT_TYPE_ENUM.EXTRA]: {
    primaryColor: "#0B0849",
    secondaryColor: "#89CFF7",
  },
});

export const talentProfiles = [
  {
    name: "Hakan Bilgin",
    role: "Senior Software Engineer,",
    company: "Zeplin",
    avatarURL: "/assets/talentProfilePhotos/hakan-bilgin.png",
    description:
      "“Being matched with Zeplin when I wasn't actively looking for a job, I was able to spot a very exciting opportunity I would miss otherwise. The communication was very open, friendly and professional throughout the whole process.”",
  },
  {
    name: "Beril İldeniz",
    role: "Product Manager,",
    company: "Lifemote",
    avatarURL: "/assets/talentProfilePhotos/beril-ildeniz.jpg",
    description:
      "“I had a very fast and successful experience as I was matched with Lifemote. TalentGrid matched me with the company that met my expectations, and in a very short time. TalentGrid understood the criteria of the position and company very well, which made the process very effortless.”",
  },
  {
    name: "Mert Pekdüzgün",
    role: "IOS Developer,",
    company: "BuyBuddy",
    avatarURL: "/assets/talentProfilePhotos/mert-pekduzgun.png",
    description:
      "When I got matched with BuyBuddy, I was very pleased that TalentGrid got back to me in a very short time and gave detailed information about the process. In addition, the TalentGrid team was very attentive and helpful throughout the entire process.",
  },
  {
    name: "Cem G.",
    role: "Android Developer,",
    company: "Kunduz",
    avatarURL: "/assets/talentProfilePhotos/cem-g.png",
    description:
      "The process of getting matched and meeting with the Kunduz team was very easy and effortless.Our communication with Talent Partner Cansu on the TalentGrid team made the process even easier for me. TalentGrid is a platform that I would definitely recommend to all developers.",
  },
  {
    name: "Rukiye Önal ",
    role: "QA Engineer,",
    company: "CitizenMe",
    avatarURL: "/assets/talentProfilePhotos/rukiye-onal.png",
    description:
      "“Although I was not actively looking for a job, after being matched with the QA Engineer position, the sincere energy and transparency of Talent Partner Cansu, whom I talked to about the position and the company, made me curious about the process. TalentGrid was very transparent in information sharing. They accompany the developer throughout the whole process as much as they accompany the company they're working with.”",
  },
  {
    name: "Esra Altındaş",
    role: "Senior Software Engineer,",
    company: "Wamo",
    avatarURL: "/assets/talentProfilePhotos/esra-altindas.jpg",
    description:
      "I was not actively looking for a job but was matched with the remote Front End Developer position at Wamo. It got my attention as it was an exact match with my criteria.Our communication with the TalentGrid team was very transparent at every stage, it was very reassuring.",
  },
];

export const SORTERS = {
  descend: "DESC",
  ascend: "ASC",
};

export const hiringSteps = [
  {
    key: MATCH_STATES.SENT,
    title: "You get matched",
    description:
      "New opportunity alert! Your skills and expectations possibly match with this position.",
    completedState: [MATCH_STATES.SENT],
    visible: true,
    failed: false,
  },
  {
    key: MATCH_STATES.INTERESTED,
    title: "You are “Interested”",
    actionTitle: "Next action: “Interested” or “Pass”?",
    description:
      "You have the first say in this match! You can state whether you are <b>interested</b> in this position or if you want to <b>pass</b>. </br></br> Your profile remains private unless you opt in first.",
    completedState: [MATCH_STATES.INTERESTED, MATCH_STATES.TG_ASSESSMENT],
    visible: true,
    failed: false,
  },
  {
    key: MATCH_STATES.TG_ASSESSMENT,
    title: "TG team validates profile",
    actionTitle: "Next action: TalentGrid team will validate your profile",
    description:
      "The ball is on our side. Now that you are “Interested” in this role, [TPNAME], a talent partner from the TalentGrid team will validate your profile and make sure your skills & expectations align with the company’s. </br></br> Once our team validates your profile, [COMPANYNAME] will be notified about this match.",
    completedState: [MATCH_STATES.COMPANY_ASSESSMENT],
    visible: true,
    failed: false,
  },
  {
    key: MATCH_STATES.COMPANY_ASSESSMENT,
    title: "Company is “Interested”",
    actionTitle:
      "Next action: [COMPANYNAME]’s turn to say “Interested” or “Pass”",
    description:
      "Good news, your profile has been validated and [COMPANYNAME] is notified about this match. If they opt in as well, your talent partner, [TPNAME], will guide you for a meeting to start the interview process.",
    completedState: [MATCH_STATES.INTERVIEW],
    visible: true,
    failed: false,
  },
  {
    key: MATCH_STATES.INTERVIEW,
    title: "Interview with company",
    actionTitle: "Next action: Interview process with the company",
    description:
      "Nice progress! [COMPANYNAME] thought your profile was a good match for this role and you will be proceeding with the interviews. Fingers crossed 🤞",
    completedState: [MATCH_STATES.SENT_OFFER],
    visible: true,
    failed: false,
  },
  {
    key: MATCH_STATES.SENT_OFFER,
    title: "You accept the offer",
    actionTitle: "Next action: Are you happy with your offer?",
    description:
      "Great news! [COMPANYNAME] are happy to see you join their team and shared an offer with you. If you have any questions related to the offer, you can always write to your talent partner for this role, [TPNAME], for guidance and help.",
    completedState: [MATCH_STATES.HIRED],
    visible: true,
    failed: false,
  },
  {
    key: MATCH_STATES.HIRED,
    title: "You start your new job",
    actionTitle: "Next action: Enjoy your new role!",
    description:
      "Congrats on your next role! <br/><br/> We’re super happy we’ve been a part of this joyful journey.",
    completedState: [],
    visible: true,
    failed: false,
  },
  {
    key: MATCH_STATES.PASSED,
    title: "Match Passed",
    description:
      "You “Passed” on this match. Thank you for the feedback! We will use this information to come up with more relevant matches in the future.",
    completedState: [],
    visible: false,
    failed: false,
  },
  {
    key: MATCH_STATES.FAILED,
    title: "Match Unsuccessful",
    description:
      "Unfortunately, we regret to inform you that we cannot proceed with you to the next step for this role at  [COMPANYNAME]. We recognize this may be a disappointment to you. Although we didn't match perfectly this time, there are many paths in TalentGrid and we encourage you to remain updated to consider applying for positions that may open up in the future!",
    completedState: [],
    visible: false,
    failed: false,
  },
  {
    key: POSITION_STATES.CLOSED,
    title: "Position Closed",
    description:
      "This position has been successfully filled by the company and is now closed.",
    completedState: [],
    visible: false,
    failed: false,
  },
];

export const DEFAULT_MATCH_FILTERS = [
  MATCH_STATES.SENT,
  MATCH_STATES.COMPANY_ASSESSMENT,
  MATCH_STATES.INTERESTED,
  MATCH_STATES.FAILED,
  MATCH_STATES.PASSED,
  MATCH_STATES.INTERVIEW,
  MATCH_STATES.SENT_OFFER,
  MATCH_STATES.TG_ASSESSMENT,
  MATCH_STATES.HIRED,
];

export const DEFAULT_MATCH_FILTER_CHECKBOX_STATE = {
  waiting: true,
  ongoing: true,
  passedUnsuccessful: true,
  closed: true,
};

export const DEFAULT_SORT_FILTER = "createdAt";

export const DEFAULT_TYPE_FILTER = "all";

export const FILTER_VALUE = {
  waiting: [MATCH_STATES.SENT],
  ongoing: [
    MATCH_STATES.INTERESTED,
    MATCH_STATES.TG_ASSESSMENT,
    MATCH_STATES.COMPANY_ASSESSMENT,
    MATCH_STATES.INTERVIEW,
    MATCH_STATES.SENT_OFFER,
    MATCH_STATES.HIRED,
  ],
  passedUnsuccessful: [MATCH_STATES.PASSED, MATCH_STATES.FAILED],
  closed: [], // it must be related closed positions
};

export const MATCH_FILTERS = {
  checkbox: [
    {
      name: "waiting",
      label: "Waiting my action",
    },
    {
      name: "ongoing",
      label: "Ongoing",
    },
    {
      name: "passedUnsuccessful",
      label: "Passed / Unsuccessful",
    },
  ],
  radio: [
    {
      name: "date",
      label: "Date created",
      value: "createdAt",
    },
    {
      name: "date",
      label: "Last updated",
      value: "updatedAt",
    },
  ],
  type: [
    {
      name: "tgCertified",
      label: "TG Certified",
      value: "tgCertified",
    },
    {
      name: "all",
      label: "All Position Type",
      value: "all",
    },
  ],
};

export const MATCH_HISTORY_ACTION_TYPES = {
  SEND_MATCH_REMINDER_EMAIL: 1,
  MATCH_SEEN_BY_DEVELOPER: 2,
  MATCH_TAKEN_BACK_BY_DEVELOPER: 3,
};

export const COMPANY_SIZES = [
  {
    id: 1,
    min: 1,
    max: 15,
    label: "1-15",
  },
  {
    id: 2,
    min: 15,
    max: 50,
    label: "15-50",
  },
  {
    id: 3,
    min: 51,
    max: 200,
    label: "51-200",
  },
  {
    id: 4,
    min: 201,
    max: 500,
    label: "201-500",
  },
  {
    id: 5,
    min: 501,
    max: 1000,
    label: "501-1000",
  },
  {
    id: 6,
    min: 1000,
    max: 9999,
    label: "100+",
  },
];

export const ACTION_BUTTON_TITLES = {
  IMPORT_LINKEDIN_BUTTON: "import linked in button",
  IMPORT_RESUME_BUTTON: "import cv button",
};

export const QUICK_EDIT_DATA_SESSION_IDS = {
  LINKEDIN_LINK: "d52c3555-9571-48a5-aa3c-97d71cebadaf",
  MISSING_LANGUAGE_LEVEL: "8cb5ab83-27cc-4945-bc9b-cbc0ef3535ab",
  LINKEDIN_LINK_FOR_CRAWLING: "714d8cf5-485f-4293-b225-bfb03e2785e3",
  GITHUB_LINK: "ca55a318-ee25-48a1-818d-5f36954692e8",
  EDUCATION: "d3bd1819-ba58-4a0d-b17e-3ab8b50c3cbe",
  LANGUAGES: "133d97d1-61ec-4ae3-85d9-d47dac1a9971",
  ABOUT: "537ee277-d1c9-4088-908d-1ffcc1f3881b",
  TECHNICAL_ARCHITECTURES: "1623014a-988d-4a27-a2bd-20b8802c6521",
  DATABASES: "1623014a-988d-4a27-a2bd-20b8802c6521",
  CLOUD_TECHNOLOGIES: "1623014a-988d-4a27-a2bd-20b8802c6521",
  VERSIONING_TOOLS: "1623014a-988d-4a27-a2bd-20b8802c6521",
  CI_CD_TOOLS: "1623014a-988d-4a27-a2bd-20b8802c6521",
  METHODOLOGIES: "1623014a-988d-4a27-a2bd-20b8802c6521",
  BUSINESS_TOOLS: "1623014a-988d-4a27-a2bd-20b8802c6521",
  ANALYTICS_AND_REPORTING_TOOLS: "1623014a-988d-4a27-a2bd-20b8802c6521",
  WORK_HISTORY: "a7a7fd5c-63a6-4a0e-9eca-6e37af30cf3f",
  TWITTER_LINK: "166364d1-3f25-4d30-a77d-5eb15f8643cc",
  BLOG_LINK: "44faefce-a3db-4312-b7bc-3a73b1c5b2d6",
  YOUTUBE_LINK: "56182154-b8dc-419c-816a-e74ee67ed67c",
  PORTFOLIO_LINK: "126a5f07-991f-48fb-953d-49220f9e922d",
  STACKOVERFLOW_LINK: "ed4ff245-6dd6-435f-9818-38c58eb41825",
  OPEN_TO_WORK: "ad3ft245-6dd7-419f-9818-38c58eb41825",
};
export const FREQUENTLY_ASKED_QUESTIONS = [
  {
    question: "How does TalentGrid&#39;s referral program work?",
    answer: (
      <ul>
        <li>
          You and your friend will get $250 if your friend starts a new job via
          TalentGrid and works there for at least 30 days.
        </li>
        <li>
          This reward is repeatable. You can invite as many friends as you want
          and increase your chances of winning. For example, when you have 6
          friends who meet the above criteria, you and your friends will get
          $1,500.
        </li>
      </ul>
    ),
  },
  {
    question: "How can I invite my friends?",
    answer: (
      <ul>
        <li>Go to the invite section</li>
        <li>Enter the email address of your friend and send an invite.</li>
        <li>Or you can invite them by sending the link via social accounts.</li>
      </ul>
    ),
  },
  {
    question: "What should my friends do?",
    answer: (
      <ul>
        <li>Click on the invite link in the referral email and sign up.</li>
        <li>Fill the profile</li>
        <li>
          Get matched, start a new job via TalentGrid, and work there for at
          least 30 days.
        </li>
      </ul>
    ),
  },
  {
    question: "How can I track progress and my earnings?",
    answer: (
      <ul>
        <li>Go to the Invite Page</li>
        <li>
          Scroll down to <b>Your Progress</b> section.
        </li>
        <li>
          You&#39;ll see the status of each referred friend and your earnings.
        </li>
      </ul>
    ),
  },
  {
    question: "How will I collect the rewards I have won?",
    answer: (
      <ul>
        <li>
          We will contact you via email when you are eligible for the reward.
        </li>
      </ul>
    ),
  },
];
export const PUBLIC_POSITION_STATE_TEXTS = {
  IN_PROGRESS: {
    userIsAuth: {
      buttonText: "Interested 👍",
      buttonDescription: null,
      description:
        "Interested? Create your profile to match with this position and many more!",
    },
    userIsNotAuth: {
      buttonText: "Interested 👍",
      buttonDescription: null,
      description:
        "Interested? Create your profile to match with this position and many more!",
    },
  },

  OTHER_STATES: {
    userIsAuth: {
      buttonText: "Go to My Profile",
      buttonDescription: null,
      description: "Interested? Go to your profile to manage your matches.",
    },
    userIsNotAuth: {
      buttonText: "Create Your Profile",
      buttonDescription: `This position has been closed.<br />Join our community and get matched with other positions.`,
      description:
        "Interested? Create your profile to match with this position and many more!",
    },
  },
};
