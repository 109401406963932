import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import DiscoverPage from "../../components/Discover";
import {
  Header,
  MobileNavigator,
  MobileDetailHeader,
} from "../../components/Header";
import { Container, BodyWrapper } from "../../components/BlockUi";
import { isMobile, handleHeadTag } from "../../helper";
import Spinner from "../../components/Modules/Loading";
import ErrorPage from "../ErrorPage";

const ALL_DOCUMENT = gql`
  {
    allDocuments {
      documents {
        id
        title
        description
        files {
          fileURL
          fileLanguage
        }
        visible
        publishedAt
        coverImageURL
      }
    }
  }
`;

const GET_USER = gql`
  {
    me {
      id
    }
  }
`;

const DISCOVER_TITLES = {
  companies: "Companies Hiring with TalentGrid",
  reports: "Reports",
  socials: "Social & Links",
  find_us_elsewhere: "Find us elsewhere",
};

export default function Discover() {
  const {
    data: reportData,
    loading,
    error: documentError,
    refetch: reportRefetch,
  } = useQuery(ALL_DOCUMENT, {
    fetchPolicy: "cache-and-network",
  });
  const {
    data: user,
    error: userError,
    loading: userLoading,
  } = useQuery(GET_USER, {
    fetchPolicy: "cache-and-network",
  });
  const [mobileTitle, setMobileTitle] = useState("");
  const history = useHistory();
  const { sectionName } = useParams();

  useEffect(() => {
    if (isMobile()) {
      setMobileTitle(DISCOVER_TITLES[sectionName]);
    }
  }, [sectionName]);

  const handleClick = () => {
    history.push("/discover");
  };

  handleHeadTag("discover");

  if (loading || userLoading) return <Spinner fullScreen width="50px" />;
  if (documentError || userError)
    return <ErrorPage error={documentError || userError} />;

  return (
    <>
      {isMobile() && sectionName ? (
        <BodyWrapper background="#0c084c">
          <MobileDetailHeader handleClick={handleClick} title={mobileTitle} />
          <Container>
            <DiscoverPage
              reportData={reportData}
              reportRefetch={reportRefetch}
            />
          </Container>
        </BodyWrapper>
      ) : (
        <>
          <Header />
          <Container px={{ tablet: "0px" }} mt={4}>
            <DiscoverPage
              reportData={reportData}
              reportRefetch={reportRefetch}
              user={user.me}
            />
          </Container>
        </>
      )}
      <MobileNavigator />
    </>
  );
}
