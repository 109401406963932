import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";

const Container = Styled.div`
    width: 100%;
    display: flex;
  progress {
    margin-right: 0px;
  }

  progress[value] {
    width: ${(props) => props.width};
    height: 7px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  
    border: none;
  }

  progress[value]::-webkit-progress-bar {
    height: 7px;
    border-radius: 20px;
    background-color: #ededed;
    transition: width 1s;
  }  

  progress[value]::-webkit-progress-value {
    height: 7px;
    border-radius: 20px;
    background-color: ${(props) => props.color};
    transition: width 1s;
  }

  progress[value]::-moz-progress-bar {
    height: 7px;
    border-radius: 20px;
    background-color: ${(props) => props.color};
    transition: width 1s;
  }
`;

const Value = Styled.span`
    font-family: ${(props) => props.theme.family.bold};
    font-size: 14px;
    font-weight: bold;
    color: #444444;
    margin-top:-3px;
    margin-left: 10px;
`;

const ProgressBar = ({ value, max, color, width, hideValue }) => {
  return (
    <Container color={color} width={width}>
      <progress value={value} max={max} />
      {!hideValue && (
        <Value>
          {value} / {max}
        </Value>
      )}
    </Container>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string,
};

ProgressBar.defaultProps = {
  max: 100,
  color: "#b9e8fd",
  width: "60%",
};

export default ProgressBar;
