import React from "react";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import AddIcon from "../../../images/noun-add.svg";
import CriteriaViewCard from "./Card";
import { ViewCardSubTitle } from "../CommonStyles";

export default function OtherViewCard({ other }) {
  return (
    <CriteriaViewCard title="Other" image={AddIcon}>
      <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
        <ViewCardSubTitle>Other preferences and criteria:</ViewCardSubTitle>
        <RowBlock>
          <ul>
            <li>{other.expected}</li>
          </ul>
        </RowBlock>
      </ColumnBlock>
    </CriteriaViewCard>
  );
}
