import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "./SubmitButton";
import SkipLink from "./SkipLink";
import TextField from "../../../Form/TextField";
import { basicInfoValidationSchema } from "../../../ProfileEdit/BasicInfos/validationSchema";
import { ImportLinkedinButton } from "../../../ProfileEdit/ProfileImport/CommonStyles";
import { ACTION_BUTTON_TITLES } from "../../../../utils/constants";

export default function QuickFillSocial({
  content,
  handleSubmit,
  next,
  showSkipButton,
  linkedinUrl,
}) {
  const [value, setValue] = useState("");
  const [isValid, setFormValid] = useState(false);
  const history = useHistory();

  const handleChange = async (event) => {
    const { value } = event.target;
    setValue(value);

    try {
      const validateResponse = await basicInfoValidationSchema.fields[
        content.data.name
      ].validate(value);
      if (validateResponse) {
        setFormValid(true);
      }
    } catch (err) {
      setFormValid(false);
    }
  };

  const onNextClick = () => {
    setValue("");
    next();
  };

  return (
    <>
      {content.data.name !== "linkedin" || !linkedinUrl ? (
        <>
          <TextField
            value={value}
            onChange={handleChange}
            width="100%"
            mt="3px"
            textAlign="left"
            placeholder="https://"
          />
          <SubmitButton
            handleSubmit={(event) =>
              handleSubmit(event, {
                social: {
                  [content.data.name]: value,
                },
              })
            }
            isValid={isValid}
          />
        </>
      ) : (
        <>
          <p
            style={{
              fontSize: "14px",
              wordWrap: "break-word",
              padding: "10px 0 20px 0",
            }}
          >
            {linkedinUrl}
          </p>
          <ImportLinkedinButton
            block
            handleClick={() =>
              history.push("/profile/edit/import", {
                from: ACTION_BUTTON_TITLES.IMPORT_LINKEDIN_BUTTON,
              })
            }
          >
            Import LinkedIn Profile
          </ImportLinkedinButton>
        </>
      )}

      {showSkipButton && <SkipLink next={onNextClick}>Skip</SkipLink>}
    </>
  );
}
