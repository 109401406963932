const getLocalStorage = ({ key, defaultValue, isJSON }) => {
  let val = localStorage.getItem(key);
  if (!val) {
    return defaultValue;
  }
  if (isJSON) {
    val = JSON.parse(val);
  }
  return val;
};

const setLocalStorage = ({ key, isJSON, value }) => {
  if (isJSON) {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};

export { getLocalStorage, setLocalStorage };
