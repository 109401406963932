import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $bio: String
    $social: SocialInputType
    $otherSkills: [ID]
    $education: [UserEducationInputType]
    $workHistory: [UserWorkHistoryInputType]
    $languages: [UserLanguageInputType]
    $preferences: UserPreferenceInputType
  ) {
    updateUser(
      bio: $bio
      social: $social
      otherSkills: $otherSkills
      education: $education
      workHistory: $workHistory
      languages: $languages
      preferences: $preferences
    ) {
      bio
      social {
        linkedin
        github
        stackoverflow
        portfolio
        blog
        twitter
        youtube
      }
      otherSkills {
        id
        categoryId
        category
        title
      }
      education {
        type
        school {
          name
          id
        }
        branch {
          id
          label
        }
        startDate
        endDate
      }
      workHistory {
        company
        position
        startDate
        endDate
        description
        present
      }
      preferences {
        openToWork
        currency
      }
      languages {
        language {
          label
          id
        }
        level
      }
    }
  }
`;

// TODO: categoryId'ye göre de sorgulama yapabiliriz burada.
export const SEARCH_TECHNOLOGY = gql`
  query allTechnology($search: String) {
    allTechnology(search: $search) {
      technologies {
        id
        title
        categoryId
        category
      }
    }
  }
`;

export const UPLOAD_RESUME = gql`
  mutation uploadResume($id: ID!, $resume: Upload!) {
    uploadResume(id: $id, resume: $resume) {
      fileName
      resumeURL
    }
  }
`;

export const DELETE_RESUME = gql`
  mutation DeleteResume($id: ID!) {
    deleteResume(id: $id)
  }
`;

export const SAVED_LINKEDINLOG_FOR_USER = gql`
  query savedLinkedinLogForUser($limit: Int, $page: Int) {
    savedLinkedinLogForUser(limit: $limit, page: $page) {
      isSaved
      savedData {
        avatarURL
        bio
        languages {
          label
          level
        }
        education {
          school {
            name
          }
          branch {
            label
          }
          startDate
          endDate
        }
        workHistory {
          company
          position
          description
          startDate
          endDate
          present
        }
        otherSkills
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_LANGAUGES = gql`
  {
    me {
      id
      languages {
        language {
          id
          label
          code
        }
        level
      }
    }
  }
`;

export const SAVED_RESUMELOG_FOR_USER = gql`
  query savedResumeLogForUser($limit: Int, $page: Int) {
    savedResumeLogForUser(limit: $limit, page: $page) {
      isSaved
      filename
      savedData {
        bio
        languages {
          label
          level
        }
        education {
          school {
            name
          }
          branch {
            label
          }
          startDate
          endDate
        }
        workHistory {
          company
          position
          description
          startDate
          endDate
          present
        }
        otherSkills
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_IMPORT_COUNT = gql`
  query findImportCountByUserId {
    findImportCountByUserId
  }
`;

export const RESUME_SAVE_COUNT = gql`
  query findResumeSaveCountByURL {
    findResumeSaveCountByURL
  }
`;
