import styled from "styled-components";
import { space, border } from "styled-system";

export const AddNewItem = styled.span`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 14px;
  color: #aaaaaa;
  cursor: pointer;
`;

export const DeleteItem = styled.div`
  font-family: ${(props) => props.theme.family.regular};
  font-size: 14px;
  color: #444444;
  cursor: pointer;
  background: #eeeeee;
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  max-height: 29px;
  max-width: 29px;
  ${space}
`;

export const DeleteIconWrapper = styled.div`
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  max-height: 29px;
  max-width: 29px;
  transform: rotate(45deg);
  ${space};
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  ${space}
  ${border}
`;

export const EducationHistoryPeriod = styled.span`
  width: 100%;
  font-family: ${(props) => props.theme.family.italic};
  font-style: italic;
  font-size: 14px;
  color: #444444;
  margin-bottom: 8px;
`;
