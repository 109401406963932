import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { NotFoundError } from "tg-design";
import { SingleHeader } from "../../components/Header";

const ErrorHead = styled.p`
  font-family: ${(props) => props.theme.family.bold};
`;

const ErrorMessage = styled.p`
  font-family: ${(props) => props.theme.family.medium};
`;

const ButtonText = styled.p`
  font-family: ${(props) => props.theme.family.bold};
`;

export default function NotFoundErrorPage() {
  const history = useHistory();

  const handleOnClickReturnHomePageButton = () => {
    return history.push("/");
  };

  return (
    <>
      <SingleHeader to="/" />
      <NotFoundError
        title={<ErrorHead>Page Not Found</ErrorHead>}
        message={
          <ErrorMessage>
            The page you were looking for cannot be found. It might have been
            removed, renamed, or did not exist in the first place.
          </ErrorMessage>
        }
        buttonText={<ButtonText>Return Home</ButtonText>}
        onClickButton={handleOnClickReturnHomePageButton}
      />
    </>
  );
}
