import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Container } from "../../components/BlockUi";
import Loading from "../../components/Modules/Loading";
import { Header, MobileNavigator } from "../../components/Header";
import InvitePage from "../../components/Invite";
import { handleHeadTag } from "../../helper";
import ErrorPage from "../ErrorPage";

const GET_USER = gql`
  {
    me {
      id
      name
      email
      inviteToken
      pendingInvites {
        email
        createdAt
      }
      invites {
        id
        status
        name
        hireStatus {
          isHired
          hiredDate
        }
        createdAt
        criteria {
          createdAt
        }
        __typename @skip(if: true)
      }
    }
  }
`;

export default function Invite() {
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch,
  } = useQuery(GET_USER);

  handleHeadTag("invite");
  if (userLoading) return <Loading fullScreen width="70px" />;
  if (userError) return <ErrorPage error={userError} />;

  return (
    <>
      <Header />
      <Container px={{ mobileS: 3 }} pb={{ mobileS: 6, tablet: 3 }} mt={4}>
        <InvitePage user={userData.me} refetch={refetch} />
      </Container>
      <MobileNavigator />
    </>
  );
}
