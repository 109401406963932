import React from "react";
import styled from "styled-components";
import { Row } from "./CommonModule";
import { ColumnBlock } from "../BlockUi";
import DataGroup from "../Modules/DataGroup";
import DefaultAvatar from "../../images/default-avatar.png";
import { Label } from "../FormUi";

const PartnerBox = styled.div`
  display: flex;
  margin-top: 20px;
`;

const PartnerName = styled.p`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;
const PartnerTitle = styled.p`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
`;

const PartnerAvatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 11px;
`;
const toEmbedLoomVideoURL = (value) => {
  const [loomAddress, loomVideoID] = value.split("/share/");

  if (loomAddress !== "https://www.loom.com") {
    return null;
  }

  return `https://www.loom.com/embed/${loomVideoID}`;
};
export default function IntroductionVideoSection({ assignee, content }) {
  return (
    <>
      {content && content.loomVideoURL && (
        <DataGroup title="Quick intro from the Talent Partner">
          <Row gridColumnGap="30px">
            <Label>
              <PartnerBox>
                <PartnerAvatar src={assignee.avatarURL || DefaultAvatar} />
                <div>
                  <PartnerName>{assignee.name}</PartnerName>
                  <PartnerTitle>Talent Partner</PartnerTitle>
                </div>
              </PartnerBox>
            </Label>
            <ColumnBlock
              style={{ flexGrow: "2" }}
              width={{ mobileS: "100%", tablet: "40%" }}
              alignItems={{ mobileL: "center" }}
              mb={{ mobileS: 2 }}
              mt={{ mobileS: 3 }}
            >
              <iframe
                title="PositionIntroductionVideo"
                src={toEmbedLoomVideoURL(content.loomVideoURL)}
                frameBorder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                style={{ width: "100%", height: "320px" }}
              />
            </ColumnBlock>
          </Row>
        </DataGroup>
      )}
    </>
  );
}
