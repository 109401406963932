import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "../styles/components/react-toastify.css";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { CloseIcon, WarningIcon, CheckIcon } from "./Icon";

const CloseContainer = styled.div`
  width: 60px;
  display: flex;
  border-left: 1px solid #eee;
  margin-top: -10px;
  margin-bottom: -10px;
  padding-left: 7px;

  svg {
    margin: 20px;
  }
`;

const IconContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 9px;
  border: solid 2px;
  border-color: ${(props) => (props.color ? props.color : "ff7675")};
  margin-right: 10px;
`;

const CustomMessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast--info {
  }
  .Toastify__toast--success {
    font-family: ${(props) => props.theme.family.medium};
    border-radius: 5px;
    background-color: #ffffff;
    color: #444444;
    border-left: 8px solid #8fdeac;
    box-shadow: 0 7px 10px 0 #aaaaaa;
    font-size: 14px;
    font-weight: 500;
  }
  .Toastify__toast--warning {
    font-family: ${(props) => props.theme.family.medium};
    border-radius: 5px;
    background-color: #ffffff;
    color: #444444;
    border-left: 8px solid #fbdd80;
    box-shadow: 0 7px 10px 0 #aaaaaa;
    font-size: 14px;
    font-weight: 500;
  }
  .Toastify__toast--error {
    font-family: ${(props) => props.theme.family.medium};
    border-radius: 5px;
    background-color: #ffffff;
    color: #444444;
    border-left: 8px solid #ff7675;
    box-shadow: 0 7px 10px 0 #aaaaaa;
    font-size: 14px;
    font-weight: 500;
  }
`;

const StyledToastCloseButton = ({ closeToast }) => (
  <CloseContainer onClick={closeToast}>
    <CloseIcon
      style={{ margin: "auto", display: "block" }}
      height="13"
      width="13"
    />
  </CloseContainer>
);

const CustomMessage = ({ message, style }) => {
  const IconComponent = style.icon;
  return (
    <CustomMessageContainer
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <IconContainer color={style.color}>
        <IconComponent
          fill={style.color}
          stroke={style.color}
          height="13"
          width="13"
        />
      </IconContainer>
      {message}
    </CustomMessageContainer>
  );
};

const messageStyles = {
  error: {
    icon: CloseIcon,
    color: "#ff7675",
  },
  success: {
    icon: CheckIcon,
    color: "#8fdeac",
  },
  warning: {
    icon: WarningIcon,
    color: "#fbdd80",
  },
};

export const ErrorMessage = (message) =>
  CustomMessage({ message, style: messageStyles.error });

export const SuccessMessage = (message) =>
  CustomMessage({ message, style: messageStyles.success });

export const WarningMessage = (message) =>
  CustomMessage({ message, style: messageStyles.warning });

export const TGToast = () => {
  return (
    <StyledToastContainer
      position="top-center"
      style={{ zIndex: 9999999 }}
      closeButton={StyledToastCloseButton}
    />
  );
};
