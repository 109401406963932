import React, { useState, useCallback } from "react";
import { useQuery } from "@apollo/client";

import styled from "styled-components";
import { LanguageLevelSelectField } from "tg-design";
import SubmitButton from "./SubmitButton";
import SkipLink from "./SkipLink";
import { ALL_LANGUAGES } from "../../../ProfileEdit/Education/queries";
import { AsyncSelectField } from "../../../Form/SelectField";
import { RowBlock } from "../../../BlockUi";

import { languageSchema } from "../../../ProfileEdit/Education/EducationValidationSchema";
import {
  debounce,
  turkishFilterOption,
  getLanguageLevelType,
} from "../../../../helper";
import message from "../../../../utils/message";

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 16px;
  line-height: normal;
  color: #0f0757;
`;

export default function QuickFillLanguages({
  handleSubmit,
  next,
  showSkipButton,
}) {
  const [state, setState] = useState({
    language: null,
    level: null,
  });
  const [isValid, setFormValid] = useState(false);

  const { refetch: languageRefetch } = useQuery(ALL_LANGUAGES, {
    skip: true,
  });

  const handleLanguageSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await languageRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        const _languages = data.allLanguages.languages.map((language) => {
          return {
            value: language?.id,
            label: language?.label,
          };
        });
        callback(_languages);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleOptionChange = async ({ value, label, type }) => {
    let _value = value;
    if (type === "language") {
      _value = {
        label,
        id: value,
      };
    }
    const newState = {
      ...state,
      [type]: _value,
    };
    setState(newState);
    try {
      const validateResponse = await languageSchema.validate({
        languages: [newState],
      });
      if (validateResponse) {
        setFormValid(true);
      }
    } catch (err) {
      setFormValid(false);
    }
  };

  return (
    <>
      <Title style={{ marginBottom: "5px" }}>Add Language</Title>
      <RowBlock my={3}>
        <AsyncSelectField
          label="Language"
          placeholder=""
          filterOption={turkishFilterOption}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
          loadOptions={handleLanguageSearch}
          onChange={(selectedOption) =>
            handleOptionChange({
              ...selectedOption,
              type: "language",
            })
          }
          width="100%"
        />
      </RowBlock>
      <RowBlock my={3}>
        <LanguageLevelSelectField
          placeholder=""
          label={<b>Level</b>}
          containerStyle={{ width: "100%" }}
          value={getLanguageLevelType(state) || null}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
          isSearchable={false}
          valueAlign="left"
          handleOnChange={({ selectedOption }) =>
            handleOptionChange({
              ...selectedOption,
              type: "level",
            })
          }
          bgColor="#f5f6f8"
          controlStyle={{
            border: "2px  #abe6c1 solid",
          }}
          customStatusStyle={{ focused: "2px  #abe6c1  solid" }}
        />
      </RowBlock>
      <SubmitButton
        isValid={isValid}
        handleSubmit={(event) => handleSubmit(event, { languages: state })}
      />
      {showSkipButton && <SkipLink next={next}>Skip</SkipLink>}
    </>
  );
}
