import React from "react";
import styled from "styled-components";
import DataGroup from "../Modules/DataGroup";

const Description = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  padding-bottom: 40px;
  color: #444444;
  > ul {
    margin-left: 20px;
    list-style: none;
  }

  ul > li {
    &::before {
      content: ${`"●"`};
      color: #72d1fb;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;

export default function PositionDescription({ position }) {
  const formattedContent = position.description
    .replaceAll("<strong>", "<b>")
    .replaceAll("</strong>", "</b>");
  return (
    <DataGroup title="Description">
      <Description
        dangerouslySetInnerHTML={{
          __html: formattedContent,
        }}
      />
    </DataGroup>
  );
}
