import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { BoxTitle } from "../../../components/BlockUi";
import RadioButton from "../../../components/Form/RadioButton";
import Loading from "../../../components/Modules/Loading";
import PartialError from "../../ErrorPage/PartialError";

const GET_PROFESSIONS = gql`
  {
    allProfession {
      id
      title
    }
  }
`;

export default function ProfessionSelectionStep({
  selectedProfession,
  onSelect,
}) {
  const { loading, error, data } = useQuery(GET_PROFESSIONS);

  if (loading) return <Loading width="50px" />;

  if (error) return <PartialError />;

  return (
    <>
      <>
        <BoxTitle color="font.0">
          In which area are you looking for jobs?
        </BoxTitle>
        {data?.allProfession?.map(({ id, title }) => (
          <RadioButton
            key={id}
            name={title}
            onChange={onSelect}
            onClick={onSelect}
            value={selectedProfession}
            id={id}
            label={title}
            centered
            checked={title === selectedProfession}
            icon={false}
          />
        ))}
      </>
    </>
  );
}
