import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { BoxText, RowBlock, Span } from "../../../components/BlockUi";
import { BasicLink } from "../../../components/FormUi";
import CodeInput from "../../../components/Form/CodeField";
import Timer from "../../../components/Timer";
import { captureErrorWithData } from "../../../helper";

const VERIFY_PIN = gql`
  query Pin($email: String!, $digit: Int!) {
    pin(email: $email, digit: $digit) {
      digit
    }
  }
`;

const CREATE_PIN = gql`
  mutation CreatePin($email: String!, $operation: String!) {
    createPin(email: $email, operation: $operation) {
      email
    }
  }
`;

export default function PinStep({ handleSubmit, email }) {
  const [error, setError] = useState(null);
  const { refetch: verifyPin, loading } = useQuery(VERIFY_PIN, {
    skip: true,
  });
  const [createPin] = useMutation(CREATE_PIN);
  const [showResendBtn, setShowResendBtn] = useState(true);

  const handleTimesUp = () => {
    sessionStorage.removeItem("resendPin");
    setShowResendBtn(true);
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      await createPin({ variables: { email, operation: "SIGNUP" } });
      sessionStorage.setItem("resendPin", 1);
      setShowResendBtn(false);
    } catch (err) {
      captureErrorWithData(err);
    }
  };

  const handlePinChange = async (val) => {
    if (val.length === 6) {
      try {
        setError(null);
        const { data } = await verifyPin({
          email,
          digit: parseInt(val, 10),
        });
        sessionStorage.removeItem("resendPin");
        handleSubmit(data);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    const resendPin = sessionStorage.getItem("resendPin");
    if (resendPin) {
      setShowResendBtn(false);
    }
  }, []);

  return (
    <>
      <BoxText color="font.0" mb="20px">
        We sent a verification code to <b> {email} </b> <br /> Enter the code to
        continue.
      </BoxText>
      <CodeInput fields={6} type="number" onChange={handlePinChange} />
      {error && (
        <Span color="red.0" fontWeight="bold">
          {error}
        </Span>
      )}
      {loading && (
        <Span color="blue.0" fontWeight="bold">
          We are checking...
        </Span>
      )}
      <RowBlock mt={{ mobileS: 3, laptop: 4, tablet: 4 }}>
        <Span fontSize="12px" fontWeight="bold">
          <Timer
            initialMinute={2}
            initialSeconds={59}
            handleTimesUp={handleTimesUp}
            clear={!showResendBtn}
          />
        </Span>
      </RowBlock>
      {showResendBtn && (
        <RowBlock mt={{ mobileS: 3, laptop: 4, tablet: 4 }}>
          <BasicLink
            color="font.1"
            mb={{ mobileS: 2, laptop: 4, tablet: 4 }}
            onClick={handleResend}
          >
            Didn&apos;t receive the code? <u>Resend</u>
          </BasicLink>
        </RowBlock>
      )}
    </>
  );
}
