import React from "react";
import { useHistory } from "react-router-dom";

import { ColumnBlock, RowBlock } from "../../BlockUi";
import { LanguageIcon } from "../../Icon";
import HoveredEmptyView from "../../Modules/HoveredEmptyView";
import {
  SectionTitle,
  EducationDescriptionText,
  EducationBoldTitle,
  StyledLi,
  IconWrap,
} from "../CommonStyles";
import { getLanguageLevelType } from "../../../helper";

export default function ProfileLanguagesSection({ languages }) {
  if (!languages) {
    languages = [];
  }

  const history = useHistory();

  const handleEdit = () => {
    history.push("profile/edit/education");
  };

  return (
    <RowBlock
      mt={2}
      mb={3}
      py={4}
      pl={3}
      pr={4}
      width="100%"
      border="solid 1px #eeeeee"
      borderRadius="5px"
    >
      <ColumnBlock width={{ mobileS: "100%", tablet: "30%" }}>
        <RowBlock>
          <IconWrap>
            <LanguageIcon />
          </IconWrap>
        </RowBlock>
      </ColumnBlock>
      <ColumnBlock width={{ mobileS: "100%", tablet: "70%" }}>
        <RowBlock justify="flex-start">
          <SectionTitle>Languages</SectionTitle>
        </RowBlock>
        {languages.length === 0 && (
          <RowBlock justify="flex-start" onClick={handleEdit}>
            <HoveredEmptyView text="Add" />
          </RowBlock>
        )}
        {languages.length > 0 && (
          <RowBlock justify="flex-start" mx={{ tablet: 2 }}>
            <ul>
              {languages.map((item, index) => (
                <StyledLi my={3} ml={{ mobileS: 3, tablet: 2 }} key={index}>
                  <EducationBoldTitle>
                    {item?.language?.label}
                  </EducationBoldTitle>
                  <br />
                  {item.level && (
                    <EducationDescriptionText>
                      {getLanguageLevelType(item).label}
                    </EducationDescriptionText>
                  )}
                  {!item.level && (
                    <EducationDescriptionText
                      style={{ marginTop: "6px", color: "red" }}
                    >
                      Missing Info: Level
                    </EducationDescriptionText>
                  )}
                </StyledLi>
              ))}
            </ul>
          </RowBlock>
        )}
      </ColumnBlock>
    </RowBlock>
  );
}
