import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation UpdateUser($workHistory: [UserWorkHistoryInputType]) {
    updateUser(workHistory: $workHistory) {
      id
      workHistory {
        company
        position
        startDate
        endDate
        description
        present
      }
    }
  }
`;
