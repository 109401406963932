import { isMobile } from "../helper";

/* eslint-disable prefer-rest-params */
/* eslint-disable vars-on-top */
/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable no-unused-expressions */

// Set your APP_ID
export const APP_ID = "xr99uyso";

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
export const load = () => {
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/xr99uyso";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
};

export const getSkillTitle = (index, skills) => {
  if (skills && skills.length > 0) {
    return skills[index] ? skills[index].title : null;
  }
};

export const getSkillExperience = (index, skills) => {
  if (skills && skills.length > 0) {
    return skills[index] ? skills[index].experience : null;
  }
};

// Initializes Intercom
export const boot = (options = {}) => {
  window &&
    window.Intercom &&
    process.env.REACT_APP_URL === "app.talentgrid.io" &&
    window.Intercom("boot", {
      app_id: APP_ID,
      user_id: options.id,
      email: options.email,
      name: options.name,
      role: options.role && options.role.title,
      "Member Status": options.status,
      Source: options.source,
      birthyear: options.birthyear,
      experience: options.experience,
      livingCity: options.livingCity && options.livingCity.city,
      livingCountry: options.livingCity && options.livingCity.country,
      language: options.preferences && options.preferences.languageCode,
      currency: options.preferences && options.preferences.currency,
      "Linkedin Profile": options.social && options.social.linkedin,
      "GitHub Profile": options.social && options.social.github,
      "Stackoverflow Profile": options.social && options.social.stackoverflow,
      Portfolio: options.social && options.social.portfolio,
      skill1Name: getSkillTitle(0, options.skills),
      skill2Name: getSkillTitle(1, options.skills),
      skill3Name: getSkillTitle(2, options.skills),
      skill4Name: getSkillTitle(3, options.skills),
      skill5Name: getSkillTitle(4, options.skills),
      skill1Exp: getSkillExperience(0, options.skills),
      skill2Exp: getSkillExperience(1, options.skills),
      skill3Exp: getSkillExperience(2, options.skills),
      skill4Exp: getSkillExperience(3, options.skills),
      skill5Exp: getSkillExperience(4, options.skills),
      hide_default_launcher: isMobile(),
    });
};

export const update = (options = {}) => {
  window &&
    window.Intercom &&
    window.Intercom("update", {
      ...options,
    });
};

export const show = () => {
  window && window.Intercom && window.Intercom("show");
};
