import React from "react";
import styled from "styled-components";

const ErrorPlaceHolder = styled.div`
  border: 1px solid #ff9898;
  border-radius: 5px;
  width: 100%;
  padding: 26px 36px 26px 26px;
  span {
    font-size: 30px;
    display: block;
    margin-bottom: 5px;
  }
`;
const LoadingPlaceHolder = styled.div`
  border: 1px solid #d9d4d4;
  width: 100%;
  padding: 26px 36px 26px 26px;
`;

export function LinkedinErrorPlaceHolder() {
  return (
    <ErrorPlaceHolder>
      <span>Oops...</span>
      We could not import this information from Linkedin. Either the information
      was not there in the first place or a problem occurred when matching it in
      our system.
    </ErrorPlaceHolder>
  );
}

export function LinkedinLoadingPlaceHolder() {
  return (
    <LoadingPlaceHolder>
      This process usually takes a few <br />
      minutes.. Hang tight 💪
    </LoadingPlaceHolder>
  );
}
