import React from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { Button } from "../FormUi";
import DefaultAvatar from "../../images/default-avatar.png";

const ImageContainer = styled.img`
  border-radius: 50%;
  height: 110px;
  width: 110px;
  margin-right: 34px;
`;

const Description = styled.p`
  font-family: ${(props) => props.theme.family.regular};
  font-size: 16px;
  line-height: 22px;
  color: #444444;

  span {
    display: block;
    margin-top: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  flex-direction: row;
`;

export default function ReferrerModal({
  open,
  handleClose,
  inviter,
  buttonText,
  descriptionTitle,
  descriptionText,
}) {
  return (
    <>
      {open && (
        <Modal handleClose={handleClose}>
          <ModalHeader>
            <HeaderContainer>
              Hey!{" "}
              <span role="img" aria-label="Hey">
                👋
              </span>
            </HeaderContainer>
          </ModalHeader>
          <ModalBody>
            <Container>
              <ImageContainer src={inviter?.avatarURL || DefaultAvatar} />
              <Description>
                <b>{inviter?.name}</b> {descriptionTitle}
                <span>{descriptionText}</span>
              </Description>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose}>{buttonText}</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
