import React from "react";
import styled from "styled-components";
import { space, width } from "styled-system";

const EmptyBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
  border: dashed 1px #dddddd;
  background-color: #f5f6f8;
  ${space}
  ${width}
`;

const HoveredBox = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #8684a6;
  background-color: #f5f6f8;
  cursor: pointer;
  ${space}
  ${width}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  ${space}
  ${width}

  &:hover ${HoveredBox} {
    display: flex;
  }
  &:hover ${EmptyBox} {
    display: none;
  }
`;

export default function HoverBox({ View, HoveredView, handleOnClick, width }) {
  return (
    <Container width={width}>
      {HoveredView && (
        <HoveredBox onClick={handleOnClick}>
          <HoveredView />
        </HoveredBox>
      )}
      {View && (
        <EmptyBox onClick={handleOnClick}>
          <View />
        </EmptyBox>
      )}
    </Container>
  );
}
