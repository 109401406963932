import { useLocation, useHistory } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { open } from "../store/slices/question";

/**
 * useLazyCaller
 *
 * Bu custom hook şu işe yarar; belirtilen timeout süresinin sonunda, gönderilen
 * "callback" fonksiyonunu çağırır. Ancak, timeout bitmeden bir kez daha ilgili
 * fonksiyon çağrılmaya çalışılırsa, timeout sıfırlanır ve tekrar çağrım için
 * timeout süresi kadar beklenir.
 *
 * Örneğin timeout 1000 olsun. 100ms sonra tekrar fonksiyon çağrılırsa, ilk çağrımdan
 * 1100 ms sonra callback çağrılacaktır. ANCAK, timeout bittikten sonra sadece 1
 * kez çağrılır.
 *
 * @returns Function
 */
export const useLazyCaller = () => {
  const [task, setTask] = useState(null);
  const lazyCaller = (callback, timeout) => {
    if (task) {
      clearTimeout(task);
    }

    setTask(setTimeout(callback, timeout));
  };
  return lazyCaller;
};

export const useQuestion = () => {
  const dispatch = useDispatch();

  const question = (payload = {}, callback) => {
    dispatch(
      open({
        ...payload,
        callback,
      })
    );
  };

  return question;
};

export const useSearchParam = ({ key = "" }) => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);

  const setValue = (val = "") => {
    if (!val) {
      urlParams.delete(key);
    } else if (urlParams.has(key)) {
      urlParams.set(key, val);
    } else {
      urlParams.append(key, val);
    }

    history.replace({
      ...location,
      search: decodeURIComponent(key.toString()),
    });
    return urlParams;
  };

  return {
    getValue: () => urlParams.get(key) || "",
    setValue,
  };
};
export const useURLQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuestion;
