import React from "react";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import GiftBoxIcon from "../../../images/noun-gift-box.svg";
import CriteriaViewCard from "./Card";
import { ViewCardSubTitle } from "../CommonStyles";

export default function BenefitsViewCard({ benefits }) {
  return (
    <CriteriaViewCard title="Side benefits" image={GiftBoxIcon}>
      <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
        <ViewCardSubTitle>Expected side benefits:</ViewCardSubTitle>
        <RowBlock overflowY="auto" height="110px" width="260px">
          <ul>
            {benefits &&
              benefits.expected
                .filter((e) => e !== null)
                .map(({ label }) => <li key={label}>{label}</li>)}
          </ul>
        </RowBlock>
      </ColumnBlock>
    </CriteriaViewCard>
  );
}
