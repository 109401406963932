/* eslint react/forbid-prop-types: 0 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { space } from "styled-system";

// sorry for this shit.
const SkillButton = styled.button`
  color: #444444;
  height: 30px;
  cursor: pointer;
  background: #fff;
  border: solid 2px;
  border-radius: 5px;
  margin-right: 15px;
  border-color: ${(props) => props.theme.colors.green[1]};
  ${space}
  font-size: 14px;
  font-family: ${(props) => props.theme.family.demi};
  padding: 4px 12px;
  display: ${(props) => (props.isHide ? "none" : "initial")};
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
`;

export default function SkillSelect({
  handleSelectedSkill,
  skills,
  selectedSkills,
}) {
  const [skillList, setSkillList] = useState(skills);
  const setSelectedSkill = (skill) => {
    handleSelectedSkill({ id: skill.id, title: skill.title, experience: "" });
    setSkillList(skillList.filter((s) => s.id !== skill.id));
  };

  return (
    <List>
      {skillList.slice(0, 6).map((skill) => (
        <SkillButton
          id={skill.id}
          type="button"
          mt={3}
          key={skill.id}
          onClick={() => setSelectedSkill(skill)}
          isHide={selectedSkills.some((val) => val.id === skill.id)}
        >
          {skill.title}
        </SkillButton>
      ))}
    </List>
  );
}

SkillSelect.propTypes = {
  handleSelectedSkill: PropTypes.func.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
};
