import React from "react";
import { Label, Input, IsRequired } from "../../FormUi";

const fieldStyle = {
  minHeight: "84px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const TextField = ({ ...props }) => {
  const { error, label, value, otherStyle } = props;

  return (
    <>
      <div style={{ ...fieldStyle, ...otherStyle }}>
        {label && (
          <Label
            direction={props.isRequired && "row"}
            justify={props.isRequired && "center"}
            mb={1}
          >
            {label}
            {props.isRequired && <IsRequired />}
          </Label>
        )}
        <Input value={value} {...props} />
        {error ? (
          <Label my={2} hasError={error}>
            {error}
          </Label>
        ) : null}
      </div>
    </>
  );
};

export default TextField;
