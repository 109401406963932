import React, { useState } from "react";

import { ColumnBlock, RowBlock, StickyBottom } from "../BlockUi";
import { Button } from "../FormUi";
import { Wrapper, Info, IconWrapper, SkipButton } from "./CommonStyles";
import { EducationIcon } from "../Icon";

import Education from "./Education";

export default function EducationStep({ step, handleClick, handleSkip }) {
  const [valid, setValid] = useState(false);

  const options = {
    single: true,
    showStatus: false,
    showTitle: false,
    border: false,
    showDescription: false,
  };
  const handleValid = (val) => {
    setValid(!!val.user);
  };
  return (
    <>
      <RowBlock mt={4} mb={5}>
        <RowBlock width="100%">
          <Wrapper mx={{ mobileS: 1, laptop: 7, tablet: 5 }} py={2} mt={4}>
            <RowBlock
              width="100%"
              py={3}
              justifyContent="center"
              borderBottom="2px solid #eee"
            >
              <ColumnBlock>
                <IconWrapper mr={3}>
                  <EducationIcon />
                </IconWrapper>
              </ColumnBlock>
              <ColumnBlock>
                <Info>{step.info}</Info>
              </ColumnBlock>
            </RowBlock>
            <RowBlock>
              <ColumnBlock width={{ mobileS: "100%", tablet: "70%" }}>
                <Education options={options} onUpdate={handleValid} />
              </ColumnBlock>
            </RowBlock>
            <RowBlock mb={4}>
              <SkipButton onClick={handleSkip}>Skip</SkipButton>
            </RowBlock>
          </Wrapper>
        </RowBlock>
      </RowBlock>
      <StickyBottom mt={3}>
        <Button disabled={!valid} onClick={handleClick} my={3}>
          {step.buttonText}
        </Button>
      </StickyBottom>
    </>
  );
}
