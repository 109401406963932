import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 10px;
  justify-content: center;
`;

const Step = styled.div`
  background-color: #dddddd;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-left: 5px;
  margin-right: 5px;
`;

export default function StepNavigation({ steps, currentIndex }) {
  return (
    <Container>
      {steps.map((step, index) => (
        <Step
          key={index.toString()}
          style={{
            backgroundColor: index === currentIndex ? "#abe6c1" : "#dddddd",
          }}
        />
      ))}
    </Container>
  );
}
