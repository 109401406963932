import React, { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ButtonLink } from "tg-design";
import TGCertifiedIcon from "../../images/tg_certified.svg";
import { RowBlock, Span, ColumnBlock } from "../BlockUi";
import { SmallBox, PositionTitle, CompanyName } from "./CommonModule";
import { LocationIcon, TimeIcon, ThumbsUpIcon } from "../Icon";
import MatchDetailAction from "./MatchDetailAction";
import MissingPhoneBox from "./MissingPhoneBox";
import { TgHeaderTab, TgHeaderTabs } from "../TgHeaderTabs";
import Avatar from "../Modules/Avatar";
import { POSITION_STATES, MATCH_STATES } from "../../utils/constants";
import ReferralModal from "./ReferralModal";

const MatchDate = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  color: #aaaaaa;
  > svg {
    margin-top: 1px;
  }
`;

const InterectCount = styled(MatchDate)`
  display: block;
  text-align: right;
  margin-top: 8px;
`;

const getStartDate = (history, createdAt) => {
  if (history && history.length) {
    const inProgressHistory = history.find(
      (i) => i.current === POSITION_STATES.IN_PROGRESS
    );
    if (inProgressHistory) {
      return inProgressHistory.createdAt;
    }
  }

  return createdAt;
};

export default function MatchDetailHeader({
  match,
  refetch,
  isEmpty,
  missingPhoneBox,
  setMissingPhoneBox,
  currentTab,
  setCurrentTab,
  tabs,
}) {
  const {
    createdAt,
    position: {
      title,
      company,
      criteria: { positionLocation },
      interestCount,
      history,
      isExternal,
    },
  } = match;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  dayjs.extend(relativeTime);
  dayjs.locale("en");

  const onMissingPhoneAdded = () => {
    setMissingPhoneBox(false);
    refetch();
  };

  const onInterested = () => {
    // If the user doesn't have a phone number yet, we should ask. Otherwise, we
    // don't need to do anything about it.
    if (
      match &&
      match.user &&
      (match.user.phone === null || match.user.phone.length === 0)
    ) {
      setMissingPhoneBox(true);
    }
  };

  const getPositionLocation = (value) => {
    if (value && value.remote) {
      const remoteOptions = value.remote.split(",");

      if (remoteOptions.length === 1 && remoteOptions.includes("full")) {
        return "Remote";
      }
      if (
        remoteOptions.some(
          (option) => option === "half" || option === "full"
        ) &&
        remoteOptions.includes("full") &&
        value.expected &&
        value.expected.title
      ) {
        return `Remote or ${value.expected.title}`;
      }
      if (value.expected && value.expected.title) {
        return value.expected.title;
      }
    }
    return "On site";
  };

  return (
    <>
      <ReferralModal
        open={isModalOpen}
        handleClose={closeModal}
        inviteLink={`${process.env.REACT_APP_URL}/landing/${match?.position?.publicID}?invite=${match?.user?.inviteToken}`}
      />
      <RowBlock>
        <Avatar
          url={company.logo}
          name={company.name}
          size="72px"
          fontSize="42px"
        />
        <ColumnBlock align="start" flex="1" ml="3" justify="space-between">
          <RowBlock>
            <PositionTitle mr={2}>{title}</PositionTitle>

            {!isExternal && (
              <img width="18px" src={TGCertifiedIcon} alt="TG Certified" />
            )}
          </RowBlock>

          <RowBlock align="center" justify="space-between" width="100%">
            <CompanyName mr={3}>{company.name}</CompanyName>

            <ColumnBlock>
              <MatchDate>
                <TimeIcon />
                <Span ml={1}>
                  {dayjs(getStartDate(history, createdAt)).fromNow()}
                </Span>
              </MatchDate>
              <InterectCount>
                <ThumbsUpIcon />
                <Span ml={1}>{interestCount} interested</Span>
              </InterectCount>
            </ColumnBlock>
          </RowBlock>
          <RowBlock justify="start">
            <SmallBox>
              <LocationIcon />
              <Span ml={1}>{getPositionLocation(positionLocation)}</Span>
            </SmallBox>
          </RowBlock>
        </ColumnBlock>
      </RowBlock>
      {!missingPhoneBox && (
        <MatchDetailAction
          match={match}
          state={match.state}
          matchId={match.id}
          refetch={refetch}
          isEmpty={isEmpty}
          position={match.position}
          onInterested={onInterested}
        />
      )}
      {missingPhoneBox && (
        <MissingPhoneBox
          assignee={match.position.assignee}
          onSuccess={onMissingPhoneAdded}
        />
      )}
      {(match.state === MATCH_STATES.SENT ||
        match.state === MATCH_STATES.PASSED) &&
        match.position.state !== POSITION_STATES.CLOSED &&
        match.position.state !== POSITION_STATES.CANCELED &&
        match.position.state !== POSITION_STATES.ON_HOLD &&
        match.position.state !== POSITION_STATES.FILLED &&
        !isEmpty && (
          <ButtonLink
            underline
            onClick={openModal}
            style={{ textAlign: "left", marginBottom: "30px" }}
          >
            Refer another person and earn cash reward!
          </ButtonLink>
        )}
      <TgHeaderTabs
        currentTab={currentTab}
        currentIndex={tabs.map((i) => i.name).indexOf(currentTab)}
        handleChange={setCurrentTab}
      >
        {tabs.map((tab) => (
          <TgHeaderTab key={tab.name} name={tab.name} icon={tab.icon}>
            {tab.title}
          </TgHeaderTab>
        ))}
      </TgHeaderTabs>
    </>
  );
}
