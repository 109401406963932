/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */

import { ApolloLink, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import {
  ApolloClient,
  defaultDataIdFromObject,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { withClientState } from "apollo-link-state";
import fetch from "node-fetch";
import toastMessage from "../utils/message";

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case "User":
        return `User`;
      case "Technology":
        return `Technology:${responseObject.id}`;
      case "RoleModel":
        return `Role:${responseObject.id}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        user(_, { args, toReference }) {
          return toReference({
            __typename: "User",
            id: args.id,
          });
        },
        role(_, { args, toReference }) {
          return toReference({
            __typename: "Role",
            id: args.id,
          });
        },
      },
    },
  },
  cacheRedirects: {
    User: {
      user: (_, { id }, { getCacheKey }) =>
        getCacheKey({ __typename: "User", id }),
    },
  },
});
// for this issue:  https://github.com/apollographql/apollo-feature-requests/issues/1#issuecomment-431842138
cache.originalReadQuery = cache.readQuery;
cache.readQuery = (...args) => {
  try {
    return cache.originalReadQuery(...args);
  } catch (err) {
    return undefined;
  }
};

const headerMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-LANGUAGE-CODE": "en-US",
    },
  });

  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const context = operation.getContext();
    const header = context.response.headers;

    if (
      header.get("X-Country-Code") !== `${undefined}` &&
      header.get("X-Country-Code") !== `${null}`
    ) {
      window.country = header.get("X-Country-Code");
    } else {
      window.country = "XX";
    }
    return response;
  })
);

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_URL,
  options: {
    reconnect: true,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      if (error.statusCode === 401) {
        localStorage.removeItem("isAuth");
        window.history.go();
        return error;
      }
      if (error.statusCode === 403) {
        toastMessage.error(error.message);
        localStorage.removeItem("isAuth");
        window.history.go();
      }
      return error;
    });
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const stateLink = withClientState({
  cache,
  resolvers: {
    Mutation: {
      updateNetworkStatus: (_, { isConnected }, { cache }) => {
        const data = {
          networkStatus: {
            __typename: "NetworkStatus",
            isConnected,
          },
        };
        cache.writeData({ data });
        return null;
      },
    },
  },
});

const uploadLink = new createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: "include",
  fetch,
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  ApolloLink.from([
    stateLink,
    errorLink,
    afterwareLink,
    headerMiddleware.concat(uploadLink),
  ])
);

export const githubClient = new ApolloClient({
  uri: "https://api.github.com/graphql",
  cache: new InMemoryCache(),
  headers: {
    Authorization: `bearer ${process.env.REACT_APP_GITHUB_AUTHORIZATION_KEY}`,
  },
});

export const useAppApolloClient = () => {
  return new ApolloClient({
    link,
    cache,
  });
};
