import React from "react";
import styled from "styled-components";
import DataGroup from "../Modules/DataGroup";
import { TechContainer, Row } from "./CommonModule";

const StyledLabel = styled.label`
  min-width: 10%;
`;

const Content = styled.p`
  font-family: ${(props) => props.theme.family.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #444444;
  flex: 1;

  > ul {
    margin-left: 20px;
    list-style: none;
  }

  ul > li {
    &::before {
      content: ${`"●"`};
      color: #72d1fb;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;

export default function CompanyInfo({ company }) {
  const isDefinedAnything =
    company?.contents?.place || company?.contents?.culture;

  const formattedContent = company.description
    .replaceAll("<strong>", "<b>")
    .replaceAll("</strong>", "</b>");

  return (
    <>
      <DataGroup title="About the Company">
        {company?.description && (
          <Content dangerouslySetInnerHTML={{ __html: formattedContent }} />
        )}
      </DataGroup>
      {isDefinedAnything && (
        <DataGroup title="Team & Culture">
          <TechContainer>
            {company?.contents?.place && (
              <Row labelWidth="20%">
                <StyledLabel>Key highlights:</StyledLabel>
                <Content>{company.contents.place}</Content>
              </Row>
            )}
            {company?.contents?.culture && (
              <Row labelWidth="20%">
                <StyledLabel>Company culture:</StyledLabel>
                <Content>{company.contents.culture}</Content>
              </Row>
            )}
          </TechContainer>
        </DataGroup>
      )}
    </>
  );
}
