import React from "react";
import { RowBlock, ColumnBlock } from "../../../../BlockUi";
import { SelectField } from "../../../../Form/SelectField";
import { Input, Label } from "../../../../FormUi";
import { CheckboxField } from "../../../../Form/CheckboxField";
import { createYearsList } from "../../../../ProfileEdit/WorkHistory/WorkHistoryForm";
import { getMonthOptions } from "../../../../../helper";

const years = createYearsList();

export const StepCompany = ({ onChange }) => {
  return (
    <>
      <RowBlock my={3}>
        <ColumnBlock width="100%">
          <Label mb={1}>Company Name You Work For?</Label>
          <Input
            textAlign="left"
            width="100%"
            onChange={(event) =>
              onChange({ type: "company", value: event.target.value })
            }
          />
        </ColumnBlock>
      </RowBlock>
      <RowBlock my={3}>
        <ColumnBlock width="100%">
          <Label mb={1}>Your Position</Label>
          <Input
            textAlign="left"
            width="100%"
            onChange={(event) =>
              onChange({ type: "position", value: event.target.value })
            }
          />
        </ColumnBlock>
      </RowBlock>
    </>
  );
};

export const StepStartDate = ({ onChange }) => {
  const monthOptions = getMonthOptions();

  return (
    <>
      <RowBlock my={3}>
        <ColumnBlock
          width={{ mobileS: "100%", tablet: "50%" }}
          pr={{ tablet: 2 }}
        >
          <SelectField
            label="Month started"
            placeholder=""
            isSearchable
            options={monthOptions}
            onChange={(selectedOption) =>
              onChange({
                ...selectedOption,
                type: "startMonth",
              })
            }
            width="100%"
          />
        </ColumnBlock>
        <ColumnBlock
          width={{ mobileS: "100%", tablet: "50%" }}
          pl={{ tablet: 2 }}
          mt={{ mobileS: 3, tablet: 0 }}
        >
          <SelectField
            label="Year started"
            placeholder=""
            isSearchable
            options={years}
            width="100%"
            onChange={(selectedOption) =>
              onChange({
                ...selectedOption,
                type: "startYear",
              })
            }
          />
        </ColumnBlock>
      </RowBlock>
    </>
  );
};

export const StepEndDate = ({ onChange, state, setState }) => {
  const monthOptions = getMonthOptions();

  const getPossibleEndMonths = (startMonth, startYear, endYear, months) => {
    if (endYear && startYear === endYear) {
      return months.filter((month) => {
        if (month.value >= startMonth) {
          return month;
        }
        return null;
      });
    }
    return months;
  };

  const getPossibleEndYears = (startMonth, endMonth, startYear, yearList) => {
    if (endMonth && endMonth < startMonth) {
      return yearList.filter((year) => {
        if (year.value > startYear) {
          return year;
        }
        return null;
      });
    }
    return yearList.filter((year) => {
      if (year.value >= startYear) {
        return year;
      }
      return null;
    });
  };

  const handlePresentChange = (event) => {
    setState({
      ...state,
      present: event.target.checked,
    });
    onChange({
      type: "present",
      value: event.target.checked,
    });
  };
  return (
    <>
      <RowBlock my={3}>
        <ColumnBlock width="100%">
          <CheckboxField
            checked={state.present}
            name="present"
            label="I am still working in this position."
            onChange={handlePresentChange}
            value={state.present}
            containerStyles={{
              fontSize: "14px",
              color: "#444444",
            }}
            checkboxStyles={{
              height: "20px",
              width: "20px",
              marginTop: "-1px",
              marginRight: "10px",
              flexShrink: "0",
            }}
          >
            I am still working in this position.
          </CheckboxField>
        </ColumnBlock>
      </RowBlock>
      {!state.present && (
        <RowBlock my={3}>
          <ColumnBlock
            width={{ mobileS: "100%", tablet: "50%" }}
            pr={{ tablet: 2 }}
          >
            <SelectField
              label="Month finished"
              placeholder=""
              isSearchable
              options={getPossibleEndMonths(
                state.startMonth,
                state.startYear,
                state.endYear,
                monthOptions
              )}
              onChange={(selectedOption) =>
                onChange({
                  ...selectedOption,
                  type: "endMonth",
                })
              }
              width="100%"
            />
          </ColumnBlock>
          <ColumnBlock
            width={{ mobileS: "100%", tablet: "50%" }}
            pl={{ tablet: 2 }}
            mt={{ mobileS: 3, tablet: 0 }}
          >
            <SelectField
              label="Year finished"
              placeholder=""
              isSearchable
              options={getPossibleEndYears(
                state.startMonth,
                state.endMonth,
                state.startYear,
                years
              )}
              width="100%"
              onChange={(selectedOption) =>
                onChange({
                  ...selectedOption,
                  type: "endYear",
                })
              }
            />
          </ColumnBlock>
        </RowBlock>
      )}
    </>
  );
};
