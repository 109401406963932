import React from "react";
import { useHistory } from "react-router-dom";
import { RowBlock } from "../../BlockUi";
import Collapse from "../../Modules/Collapse";
import WorkHistorySection from "./WorkHistory";

export default function ProfileWorkHistoryView({ workHistory = [] }) {
  const history = useHistory();

  const handleEdit = () => {
    history.push("profile/edit/work-history");
  };

  return (
    <RowBlock>
      <Collapse title="Work History" open edit handleOnEdit={handleEdit}>
        <RowBlock
          width="100%"
          mt={4}
          justifyContent={{ mobileL: "space-between" }}
          minHeight="80px"
        >
          <WorkHistorySection workHistory={workHistory} />
        </RowBlock>
      </Collapse>
    </RowBlock>
  );
}
