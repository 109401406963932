import React, { useState, useEffect } from "react";
import { useHistory, useParams, Switch, Route } from "react-router-dom";
import NavList from "./NavList";
import LayoutWithTabs from "../Layout/LayoutWithTabs";
import { isMobile } from "../../helper";
import AccountSettings from "./Settings";
import Notifications from "./Notifications";
import MatchSettings from "./MatchSettings";
// import Promotions from "./Promotions";

const routes = [
  {
    path: "/account/settings",
    component: AccountSettings,
    exact: true,
  },
  {
    path: "/account/notifications",
    component: Notifications,
  },
  {
    path: "/account/match-settings",
    component: MatchSettings,
  },

  //! Promosyon sayfası yenileri eklenene kadar gizlendi.
  // {
  //   path: "/account/promotions",
  //   component: Promotions,
  // },
];

export default function Account({ data, refetch }) {
  const [active, setActive] = useState("");
  const history = useHistory();
  const { sectionName } = useParams();

  useEffect(() => {
    setActive(sectionName);
  }, [history, sectionName]);

  const handleClick = (section) => {
    history.push(`/account/${section}`);
  };

  return (
    <LayoutWithTabs
      sectionName={active}
      NavList={() => (
        <NavList
          handleClick={handleClick}
          active={active}
          mobileDetail={isMobile() && sectionName}
        />
      )}
    >
      <Switch>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            render={() => (
              <route.component
                user={data.me}
                routes={route.routes}
                refetch={refetch}
              />
            )}
          />
        ))}
      </Switch>
    </LayoutWithTabs>
  );
}
