import React from "react";
import styled from "styled-components";

const Signal = styled.div`
  @keyframes lds-ripple {
    0% {
      top: 26px;
      left: 26px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 52px;
      height: 52px;
      opacity: 0;
    }
  }
  display: inline-block;
  position: relative;
  width: 54px;
  height: 54px;
  ${(props) => props.signalStyle && props.signalStyle};
  > div {
    position: absolute;
    border: 4px solid #fad156;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
`;

export default function PulsingSignal({ signalStyle }) {
  return (
    <Signal signalStyle={signalStyle}>
      <div />
      <div />
      <div />
    </Signal>
  );
}
