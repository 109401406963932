import React, { useEffect, useState } from "react";
import "dayjs/locale/tr";
import { DateItem } from "tg-design";
import styled from "styled-components";
import {
  Box,
  BoxTitle,
  InviteAction,
  InvitedUser,
  Circle,
} from "./CommonStyles";

import { RowBlock, ColumnBlock } from "../BlockUi";
import device from "../../styles/device";
import Pagination from "../Pagination/Pagination";
import { CheckIcon } from "../Icon";

const ItemContainer = styled.div`
  display: flex;
  width: 135px;
  justify-content: center;

  @media ${device.mobileMax} {
    display: inline-block;
    width: fit-content;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${device.mobileMax} {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;
    place-items: center;
    width: auto;
  }
`;

const DataDescription = styled.p`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 19px;
`;

const DataBox = styled.div`
  width: 135px;
  height: 63px;
  background: #f5f6f8;
  border-radius: 5px;
  padding: 10px 21px 10px 21px;

  span {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 25px;
    color: #000000;
  }
`;

export default function InviteList({ invites, noInvitation, pendingInvites }) {
  const [allInvitees, setAllInvitees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [listItemPerPage] = useState(5);
  useEffect(() => {
    if (pendingInvites) {
      const formattedPendingInvites = pendingInvites.map((item) => {
        return {
          email: item.email,
          name: null,
          hireStatus: { isHired: false, hiredDate: null },
          status: null,
          criteria: null,
          createdAt: item.createdAt,
        };
      });
      setAllInvitees(
        invites
          .concat(formattedPendingInvites)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    }
    // eslint-disable-next-line
  }, [pendingInvites]);

  const indexOfLastItem = currentPage * listItemPerPage;
  const indexOfFirstItem = indexOfLastItem - listItemPerPage;
  const currentListItems = allInvitees.slice(indexOfFirstItem, indexOfLastItem);

  const handlePaginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {allInvitees && (
        <Box px={{ mobileS: 3, tablet: 3 }}>
          <ColumnBlock my={4} width="100%">
            <RowBlock>
              <BoxTitle>Your progress</BoxTitle>
            </RowBlock>
            {noInvitation && (
              <RowBlock mt={3}>
                <i>
                  You will be able to see the people who registered via your
                  link as follows:
                </i>
              </RowBlock>
            )}
            <RowBlock
              width="100%"
              mt={4}
              px={{ laptop: "217px", mobile: "0px" }}
            >
              <Wrapper>
                <DataBox>
                  <span>{allInvitees.length}</span>
                  <DataDescription>invited</DataDescription>
                </DataBox>
                <DataBox>
                  <span>{allInvitees.filter((item) => item.name).length}</span>
                  <DataDescription>signed up</DataDescription>
                </DataBox>
                <DataBox>
                  <span>
                    {
                      allInvitees.filter((item) => item?.hireStatus?.isHired)
                        .length
                    }
                  </span>
                  <DataDescription>got hired</DataDescription>
                </DataBox>
                <DataBox>
                  <span>
                    $
                    {allInvitees.filter((item) => item?.hireStatus?.isHired)
                      .length * 250}
                  </span>
                  <DataDescription>earned</DataDescription>
                </DataBox>
              </Wrapper>
            </RowBlock>

            <RowBlock
              mt={4}
              width="100%"
              px={{ laptop: "217px", mobile: "0px" }}
            >
              {currentListItems.map(
                ({ email, name, createdAt, hireStatus }, index) => (
                  <RowBlock width="100%" key={index} my={3}>
                    <RowBlock
                      justifyContent="flex-start"
                      mr={{ laptop: 0, mobile: "5px" }}
                      width="100%"
                      display={{
                        mobileS: "flex",
                        tablet: "none",
                        laptop: "none",
                      }}
                    >
                      <InvitedUser>{name || email}</InvitedUser>
                    </RowBlock>
                    <RowBlock
                      borderBottom="1px solid #dddddd"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <ColumnBlock
                        display={{
                          mobileS: "none",
                          tablet: "flex",
                          laptop: "flex",
                        }}
                        width="135px"
                      >
                        <InvitedUser>{name || email}</InvitedUser>
                      </ColumnBlock>
                      <ItemContainer>
                        <ColumnBlock
                          justifyContent={{ mobileS: "flex-start" }}
                          width="100%"
                          alignItems="center"
                        >
                          <RowBlock justifyContent={{ mobileS: "flex-start" }}>
                            <InviteAction isTicked={name} mr={2}>
                              <DateItem
                                date={createdAt}
                                isTooltipDisabled={!name}
                              >
                                Signed up
                              </DateItem>
                            </InviteAction>
                            {name && (
                              <Circle color="#8fdeac">
                                <CheckIcon
                                  fill="#FFFFFF"
                                  viewBox="0 0 8 8"
                                  width="8px"
                                  heigth="8px"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </CheckIcon>
                              </Circle>
                            )}
                            {!name && <Circle />}
                          </RowBlock>
                        </ColumnBlock>
                      </ItemContainer>
                      <ItemContainer>
                        <ColumnBlock
                          justifyContent={{ mobileS: "flex-start" }}
                          width="100%"
                          alignItems="center"
                        >
                          <RowBlock justifyContent={{ mobileS: "flex-start" }}>
                            <DateItem
                              isTooltipDisabled={!hireStatus?.isHired}
                              date={hireStatus?.hiredDate}
                            >
                              <InviteAction
                                isTicked={hireStatus?.isHired}
                                mr={2}
                              >
                                Got hired
                              </InviteAction>
                            </DateItem>
                            {hireStatus?.isHired && (
                              <Circle color="#8fdeac">
                                <CheckIcon
                                  fill="#FFFFFF"
                                  viewBox="0 0 8 8"
                                  width="8px"
                                  heigth="8px"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </CheckIcon>
                              </Circle>
                            )}
                            {!hireStatus?.isHired && <Circle />}
                          </RowBlock>
                        </ColumnBlock>
                      </ItemContainer>
                      <ItemContainer>
                        <ColumnBlock
                          justifyContent={{ mobileS: "flex-start" }}
                          width="100%"
                          alignItems="center"
                        >
                          <RowBlock justifyContent={{ mobileS: "flex-start" }}>
                            <InviteAction isTicked={hireStatus?.isHired} mr={2}>
                              {hireStatus?.isHired
                                ? "$250 earned"
                                : "$0 earned"}
                            </InviteAction>
                          </RowBlock>
                        </ColumnBlock>
                      </ItemContainer>
                    </RowBlock>

                    <RowBlock
                      width={{ mobileS: "100%", laptop: "70%" }}
                      mt={2}
                      justifyContent="flex-start"
                    >
                      <ColumnBlock
                        width="60%"
                        display={{ mobileS: "none", tablet: "flex" }}
                      />
                    </RowBlock>
                  </RowBlock>
                )
              )}
            </RowBlock>
            <RowBlock>
              <Pagination
                itemPerPage={listItemPerPage}
                totalItems={allInvitees.length}
                handlePaginate={handlePaginate}
              />
            </RowBlock>
          </ColumnBlock>
        </Box>
      )}
    </>
  );
}
