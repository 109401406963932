import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useState, useEffect } from "react";
import {
  captureErrorWithData,
  removeTypename,
  uuidGenerate,
} from "../../../helper";
import {
  LANGUAGE_LEVEL_TYPES_WITH_KEYS,
  LINKEDIN_PROFILE_FIELDS,
} from "../../../utils/constants";
import message from "../../../utils/message";
import { WebSocketContext } from "../../../WebSocket";
import { ColumnBlock } from "../../BlockUi";
import { Button } from "../../Button";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";
import {
  GroupTitle,
  PrimaryTitle,
  SecondaryTitle,
  LinkedinSectionContainer,
  HeaderText,
} from "../CommonStyles";
import {
  LinkedinErrorPlaceHolder,
  LinkedinLoadingPlaceHolder,
} from "../ProfileImport/Linkedin/LinkedinPlaceholders";
import { UPDATE_USER_FROM_LINKEDIN } from "../ProfileImport/Linkedin/queries";
import { FETCH_FIELD_FROM_LINKEDIN } from "../queries";

export default function LinkedinLanguageForm({
  onUpdate,
  user,
  handleOnClose,
}) {
  const [fetchFieldFromLinkedin] = useLazyQuery(FETCH_FIELD_FROM_LINKEDIN);
  // eslint-disable-next-line
  const [uuid, setUuid] = useState(uuidGenerate());
  const [currentState, setCurrentState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateUserFromLinkedin, { loading: loadingForSave }] = useMutation(
    UPDATE_USER_FROM_LINKEDIN
  );

  const ws = useContext(WebSocketContext);

  const onSubscribe = ({ uuid }) => ws.socket.emit("subscribe", { uuid });
  const onDisconnect = ({ uuid }) => ws.socket.emit("unsubscribe", { uuid });
  const offListen = () => ws.socket.off("linkedInCrawled");

  const onListen = () => {
    setLoading(true);
    ws.socket.on("linkedInCrawled", (data) => {
      if (data.error) {
        setLoading(false);
        message.error(`An error has occurred: ${data.error}`);
      } else {
        const linkedinData = { ...data.linkedin };
        const formattedLanguageState = {
          uuid,
          languages: linkedinData?.languages
            ?.map((item) => {
              if (item.suggestions.length !== 0) {
                return {
                  language: {
                    id: item.suggestions[0]._id,
                    label: item.suggestions[0].label,
                  },
                  level: item.suggestions[0].level,
                  isChecked: true,
                };
              }
              return null;
            })
            .filter((i) => i),
        };

        if (
          !formattedLanguageState?.languages ||
          formattedLanguageState?.languages.length === 0
        ) {
          offListen();
          onDisconnect({ uuid });
        }
        setCurrentState(formattedLanguageState);
        setLoading(false);
      }
      offListen();
      onDisconnect({ uuid });
    });
  };

  useEffect(() => {
    const linkedinURL = user.social?.linkedin || null;
    const username = linkedinURL?.split("/").filter(Boolean).pop();
    if (!username) {
      message.error("Username is not valid");
    }
    try {
      fetchFieldFromLinkedin({
        variables: {
          uuid,
          username,
          field: LINKEDIN_PROFILE_FIELDS.LANGUAGE,
        },
      });
      onSubscribe({ uuid });
      onListen({ uuid });
    } catch (error) {
      error.message("Something went wrong!");
      captureErrorWithData(error);
      offListen();
      onDisconnect({ uuid });
    }
    // eslint-disable-next-line
  }, []);

  const handleOnSave = async () => {
    try {
      const variables = removeTypename({
        id: user?.id,
        uuid: currentState.uuid,
        languages: currentState.languages
          .map((item) => {
            if (item.isChecked) {
              return {
                language: item.language.id,
                level: item.level,
              };
            }
            return null;
          })
          .filter((i) => i),
      });

      const updatedLinkedinData = await updateUserFromLinkedin({ variables });
      onUpdate({ user: new Date() });
      setCurrentState(null);
      handleOnClose(updatedLinkedinData.data.updateUserFromLinkedin.languages);
    } catch (error) {
      captureErrorWithData(error);
      message.error("Something went wrong!");
      captureErrorWithData(error);
      setCurrentState(null);
      handleOnClose();
    }

    return null;
  };

  const handleOnChange = (changedIndex) => {
    setCurrentState({
      ...currentState,
      languages: currentState.languages.map((item, index) => {
        if (index === changedIndex) {
          item.isChecked = !item.isChecked;
          return item;
        }
        return item;
      }),
    });
  };

  return (
    <>
      <HeaderText>Import language info from Linkedin</HeaderText>
      {loading && <LinkedinLoadingPlaceHolder />}
      {currentState?.languages?.length === 0 && <LinkedinErrorPlaceHolder />}
      {currentState?.languages?.length > 0 && (
        <>
          <div style={{ marginTop: "10px" }}>
            <GroupTitle>Languages</GroupTitle>
            {currentState?.languages?.map((item, index) => {
              return (
                <ColumnBlock key={index} style={{ marginTop: "5px" }}>
                  <LinkedinSectionContainer
                    isChecked={item.isChecked}
                    onClick={() => handleOnChange(index)}
                  >
                    <div style={{ flex: "1" }}>
                      <PrimaryTitle>{item.language.label}</PrimaryTitle>
                      <SecondaryTitle>
                        {
                          LANGUAGE_LEVEL_TYPES_WITH_KEYS.find(
                            (i) => i.value === item.level
                          )?.label
                        }
                      </SecondaryTitle>
                    </div>
                    <div>
                      {item.isChecked ? (
                        <CheckedBoxIcon />
                      ) : (
                        <UncheckedBoxIcon />
                      )}
                    </div>
                  </LinkedinSectionContainer>
                </ColumnBlock>
              );
            })}
          </div>
          <Button
            onClick={handleOnSave}
            variant="primary"
            size="large"
            style={{ width: "100%", marginTop: "47px" }}
            disabled={loadingForSave}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
}
