import React from "react";
import styled from "styled-components";
import { space } from "styled-system";

const Wrapper = styled.div`
  ${space}
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.demi};
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-family: ${(props) => props.theme.family.demi};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #aaaaaa;
  margin-bottom: 15px;
`;

export default function Notifications({ user: { name } }) {
  return (
    <Wrapper>
      <Title aria-labelledby="hello">Hi {name} 🖐</Title>
      <Paragraph>
        You know, we always show strict sensitivity to protect your personal
        data. Likewise, we don&apos;t want to bother you with communications you
        may not be interested in. <br />
        <br /> We will soon enable you to adjust the settings of the mail
        notifications you want to receive here.
      </Paragraph>
    </Wrapper>
  );
}
