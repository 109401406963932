import React from "react";
import styled from "styled-components";
import { space } from "styled-system";
import device from "../../styles/device";
import { ColumnBlock, RowBlock } from "../BlockUi";

export const DetailWrap = styled.div`
  ${space}
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  background-color: #ffffff;
  align-items: left;
  @media ${device.tablet} {
    border-radius: 10px 10px 0 0;
  }
`;

const LayoutWithTabsWithoutWrap = ({ NavList, sectionName, children }) => {
  return (
    <>
      <RowBlock width="100%" justify="space-between" align="start">
        <NavList />
        {sectionName && (
          <ColumnBlock
            pl={{ mobileS: "0px", tablet: "24px", laptop: 0 }}
            width={{
              mobileS: "100%",
              tablet: "70%",
              laptop: "65%",
              laptopL: "61%",
            }}
          >
            {children}
          </ColumnBlock>
        )}
      </RowBlock>
    </>
  );
};

export default function LayoutWithTabs({ NavList, sectionName, children }) {
  if (sectionName === "companies") {
    return (
      <LayoutWithTabsWithoutWrap NavList={NavList} sectionName={sectionName}>
        {children}
      </LayoutWithTabsWithoutWrap>
    );
  }
  return (
    <>
      <RowBlock width="100%" justify="space-between" align="start">
        <NavList />
        {sectionName && (
          <ColumnBlock
            pl={{ mobileS: "0px", tablet: "24px", laptop: 0 }}
            width={{
              mobileS: "100%",
              tablet: "70%",
              laptop: "65%",
              laptopL: "61%",
            }}
          >
            <DetailWrap
              px={{ mobileS: 3, tablet: "50px" }}
              py={{ mobileS: "44px", tablet: "30px" }}
              pb={{ mobileS: "80px" }}
            >
              {children}
            </DetailWrap>
          </ColumnBlock>
        )}
      </RowBlock>
    </>
  );
}
