import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import FormScaffold from "../FormScaffold";
import { captureErrorWithData } from "../../../helper";
import {
  DeleteIcon,
  EditIconWithUnderline,
  LinkedinIcon,
  PlusIconSimple,
} from "../../Icon";
import ReadMore from "../../ReadMore";
import Drawer from "../../Drawer";
import {
  WorkHistoryCompany,
  WorkHistoryPosition,
  WorkHistoryDescription,
  WorkHistoryPeriod,
} from "../../Profile/CommonStyles";
import { UPDATE_USER } from "./queries";
import { Button } from "../../Button";
import WorkHistoryForm, { EMPTY_WORK_HISTORY_FIELDS } from "./WorkHistoryForm";
import LinkedinWorkHistoryForm from "./LinkedinWorkHistoryForm";
import { ButtonWrapper } from "../CommonStyles";

export default function WorkHistory({ user, onUpdate, refetch }) {
  const [drawer, setDrawer] = useState(false);
  const [linkedinDrawer, setLinkedinDrawer] = useState(false);
  const [selectedWork, setSelectedWork] = useState({ work: null, index: null });
  const [state, setState] = useState(user.workHistory || []);
  const [status, setStatus] = useState("pending");
  const [updateUser] = useMutation(UPDATE_USER);

  const history = useHistory();

  const openLinkedinDrawer = () => {
    if (user?.social?.linkedin) {
      return setLinkedinDrawer(true);
    }
    return history.push("/profile/edit/import");
  };

  const closeLinkedinDrawer = (workList) => {
    setLinkedinDrawer(false);

    if (workList && workList.length > 0) {
      setState(workList);
    }
  };

  useEffect(() => {
    if (state.length >= 1) {
      setStatus("success");
    }
    if (state.length === 0) {
      setStatus("pending");
    }
  }, [drawer, state]);

  const openDrawer = (index) => {
    if (typeof index === "number") {
      setSelectedWork({ work: state[index], index });
    } else {
      setSelectedWork({ work: EMPTY_WORK_HISTORY_FIELDS, index: null });
    }
    setDrawer(true);
  };

  const closeDrawerAfterSave = (workList) => {
    setState(workList);
    setDrawer(false);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  const deleteItem = async (index) => {
    // TODO: improve
    const filteredItems = state
      .slice(0, index)
      .concat(state.slice(index + 1, state.length));

    if (filteredItems.length === 0) {
      await updateUser({ variables: { workHistory: [] } });
      onUpdate({ user: new Date() });
    }

    setState(filteredItems);

    const variables = filteredItems.map((item) => {
      return {
        company: item.company,
        position: item.position,
        startDate: item.startDate
          ? dayjs(item.startDate).format("YYYY-MM-DD")
          : null,
        endDate: item.endDate ? dayjs(item.endDate).format("YYYY-MM-DD") : null,
        description: item.description,
        present: item.present,
      };
    });
    try {
      await updateUser({ variables: { workHistory: variables } });
      onUpdate({ user: new Date() });
      refetch();
    } catch (error) {
      captureErrorWithData(error);
    }
  };

  const WorkItem = ({ item, index }) => {
    return (
      <RowBlock
        mt={2}
        mb={3}
        py={2}
        pl={1}
        width="100%"
        border="solid 1px #eeeeee"
        borderRadius="5px"
      >
        <RowBlock
          width={{ mobileS: "100%", tablet: "75%" }}
          px={{ mobileS: "10px" }}
          alignItems="center"
          flex="1 auto"
          justify="space-between"
          wrap="nowrap"
        >
          <ColumnBlock mr={4}>
            <WorkHistoryCompany>{item.company}</WorkHistoryCompany>
            <WorkHistoryPosition>{item.position}</WorkHistoryPosition>
            <WorkHistoryPeriod>
              {dayjs(item.startDate).locale("en").format("MMMM YYYY")} - {""}
              {item.endDate
                ? dayjs(item.endDate).locale("en").format("MMMM YYYY")
                : "Present"}
            </WorkHistoryPeriod>
            {item.description && (
              <WorkHistoryDescription style={{ whiteSpace: "pre-wrap" }}>
                <ReadMore
                  text={
                    item.description
                      .replace(/[\r\n]{2,}/g, "\n") // Only 1 linebreak is allowed
                      .replace(/\n$/, "") // Remove last line break
                  }
                  limit={500}
                />
              </WorkHistoryDescription>
            )}
          </ColumnBlock>
          <RowBlock gridGap={2} wrap="nowrap">
            <DeleteIcon onClick={() => deleteItem(index)} />
            <EditIconWithUnderline onClick={() => openDrawer(index)} />
          </RowBlock>
        </RowBlock>
      </RowBlock>
    );
  };

  return (
    <div>
      {drawer && (
        <Drawer onClose={closeDrawer} showAnimation>
          <WorkHistoryForm
            workHistory={state}
            selectedWork={selectedWork}
            onUpdate={onUpdate}
            handleClose={closeDrawerAfterSave}
          />
        </Drawer>
      )}

      {linkedinDrawer && (
        <Drawer onClose={closeLinkedinDrawer}>
          <LinkedinWorkHistoryForm
            user={user}
            refetch={refetch}
            handleOnClose={closeLinkedinDrawer}
            onUpdate={onUpdate}
          />
        </Drawer>
      )}
      <FormScaffold
        label="Your past work experience"
        explanation="You can add multiple work details."
        status={status}
      >
        <div>
          {state.length > 0 &&
            state.map((item, index) => (
              <WorkItem item={item} index={index} key={index} />
            ))}
        </div>
        <ButtonWrapper>
          <Button
            variant="linkedin"
            size="mediumLarge"
            onClick={() => openLinkedinDrawer()}
          >
            <RowBlock>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LinkedinIcon color="#0072B1" />
              </div>
              <span>Import from Linkedin</span>
            </RowBlock>
          </Button>
          or
          <Button variant="secondary" size="small" onClick={() => openDrawer()}>
            <RowBlock justifyContent="space-evenly" alignItems="center">
              <PlusIconSimple width="20" height="20" color="black" />
              <span>Add New</span>
            </RowBlock>
          </Button>
        </ButtonWrapper>
      </FormScaffold>
    </div>
  );
}
