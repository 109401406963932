import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { space, maxWidth } from "styled-system";
import device from "../styles/device";
import { ColumnBlock } from "./BlockUi";
import { Button } from "./Button";
import greetingImageEN from "../images/greeting-en.png";
import PulsingSignal from "./Modules/PulsingSignal";

const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 13px 0 #cccccc;
  background-color: #ffffff;
  ${space}
`;

const Content = styled.div`
  ${maxWidth}
  ${space}
  line-height: 25px;
`;

const GreetingPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 291px;
  object-position: center;
`;

const GreetingContent = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: center;
  ${space}

  button.web {
    display: none;
  }

  @media ${device.laptop} {
    button.mobile {
      display: none;
    }
    button.web {
      display: block;
    }
  }
  .description {
    @media ${device.tablet} {
      flex: 0 0 50%;
    }
    font-size: 14px;
    color: ${(props) => props.theme.colors.font[0]};

    .title {
      font-size: 16px;
      font-family: ${(props) => props.theme.family.bold};
      color: ${(props) => props.theme.colors.main[0]};
    }

    @media ${device.tablet} {
      button {
        ${space}
      }
    }
  }

  @media ${device.tablet} {
    .greeting_image {
      flex: 0 0 50%;
    }
  }
`;

const signalStyle = {
  position: "absolute",
  right: "-48px",
  top: "-5px",
};
const signalStyleMobile = {
  ...signalStyle,
  right: "-24px",
};

export default function ProfileGreeting({ user }) {
  const [signal, setSignal] = useState(true);
  const history = useHistory();

  const handleClick = () => {
    const signalCode = localStorage.getItem("signal_profile");
    if (!signalCode) {
      localStorage.setItem("signal_profile", 1);
    }
    history.push("/onboarding");
  };

  useEffect(() => {
    const signalCode = localStorage.getItem("signal_profile");
    if (signalCode) {
      setSignal(false);
    }
  }, []);

  return (
    <Wrapper
      py={{ mobileS: 4, laptop: 4, tablet: 4 }}
      px={{ mobileS: 3, laptop: 5, tablet: 4 }}
    >
      <GreetingContent>
        <ColumnBlock className="description">
          <div className="title">Welcome {user.name}</div>
          <Content
            className="content"
            my={{ mobileS: 2, tablet: 3 }}
            maxWidth={{ mobileS: "100%", tablet: "70%" }}
          >
            When matching you with positions on TalentGrid, we value{" "}
            <b> your expectations </b> as much as <b> your skills </b>. Can you
            input your expectations from a new role,{" "}
            <b> so that we don&apos;t bother you with unrelated offers?</b>
          </Content>
          <Button
            type="button"
            variant="primary"
            size="large"
            className="web"
            my={{ mobileS: 2, tablet: 4 }}
            onClick={handleClick}
          >
            Set Expectations
            {signal && <PulsingSignal signalStyle={signalStyle} />}
          </Button>
        </ColumnBlock>
        <ColumnBlock className="greeting_image">
          <GreetingPhoto src={greetingImageEN} />
        </ColumnBlock>

        <Button
          type="button"
          size="large"
          variant="primary"
          className="mobile"
          my={{ mobileS: 2, tablet: 4 }}
          onClick={handleClick}
        >
          Set Expectations
          {signal && <PulsingSignal signalStyle={signalStyleMobile} />}
        </Button>
      </GreetingContent>
    </Wrapper>
  );
}
