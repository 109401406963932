import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import * as Yup from "yup";
import { BoxTitle, BoxText } from "../../../components/BlockUi";
import TextField from "../../../components/Form/TextField";
import { Form } from "../../../components/FormUi";
import { Button } from "../../../components/Button";
import { RegisterMessage, RegisterLink } from "../CommonStyles";

const CREATE_PIN = gql`
  mutation CreatePin($email: String!, $operation: String!) {
    createPin(email: $email, operation: $operation) {
      email
    }
  }
`;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(""),
});

export default function EmailStep({ handleSubmit, currentEmail }) {
  const [email, setEmail] = useState(currentEmail);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);
  const [createPin] = useMutation(CREATE_PIN);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await createPin({
        variables: { email, operation: "LOGIN" },
      });
      if (data.createPin) {
        handleSubmit(data.createPin);
      }
    } catch (err) {
      err.graphQLErrors.map(({ message }) => setError(message));
    }
  };

  const handleOnChange = async (event) => {
    event.persist();
    setEmail(event.target.value);

    validationSchema.fields.email
      .validate(event.target.value)
      .then(() => setValid(true))
      .catch(() => setValid(false));
  };

  return (
    <>
      <BoxTitle color="font.0">
        Welcome back
        <span role="img" aria-label="hello">
          👋
        </span>
      </BoxTitle>
      <BoxText color="font.1">
        You can log in by entering the email address you used when registering.{" "}
        <br /> We will send the temporary login code to your email address.
      </BoxText>
      <Form
        pt={{ mobileS: 4, laptop: 4, tablet: 4 }}
        pb={{ mobileS: 4, laptop: 6, tablet: 4 }}
        mt={{ mobileS: 4, laptop: 4, tablet: 4 }}
        px={{ mobileS: 0, laptop: 6, tablet: 3, mobileL: 3 }}
      >
        <TextField
          id="email"
          type="email"
          name="email"
          label="Your email"
          error={error}
          value={email}
          onChange={handleOnChange}
          autoFocus
          mt={2}
        />
        <Button
          variant="primary"
          size="large"
          type="submit"
          disabled={!valid}
          onClick={handleOnSubmit}
          mt={{ mobileS: 4, laptop: 5, tablet: 5 }}
        >
          Log in
        </Button>
      </Form>
      <RegisterMessage mb={{ mobileS: 2, laptop: 4, tablet: 4 }}>
        Not a member yet?{" "}
        <RegisterLink as="a" href="/register">
          Register
        </RegisterLink>
      </RegisterMessage>
    </>
  );
}
