import React from "react";
import dayjs from "dayjs";
import {
  GroupTitle,
  PrimaryTitle,
  SecondaryTitle,
  DescriptionText,
  DateText,
  SectionContainer,
} from "./CommonStyles";
import { ColumnBlock } from "../../BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";
import ReadMore from "../../ReadMore";

export const WorkHistoryFromImport = ({ state, handleStateChange }) => {
  if (state?.length === 0) return null;

  const stateChange = (event, index) => {
    if (!(event.target instanceof HTMLAnchorElement)) {
      handleStateChange("workHistory", index);
    }
  };

  const getEndDate = (item) => {
    if (item.present) {
      return "Present";
    }
    if (item.endDate) {
      return dayjs(item.endDate).locale("en").format("MMMM YYYY");
    }
    return "?";
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Work Experience</GroupTitle>
      {state.map((item, index) => {
        return (
          <ColumnBlock key={index} style={{ marginTop: "5px" }}>
            <SectionContainer
              isChecked={item.isChecked}
              onClick={(e) => stateChange(e, index)}
            >
              <div style={{ flex: "1" }}>
                <PrimaryTitle>{item.company}</PrimaryTitle>
                <SecondaryTitle>{item.position}</SecondaryTitle>
                <DateText>
                  {item.startDate
                    ? dayjs(item.startDate).locale("en").format("MMMM YYYY")
                    : "?"}{" "}
                  - {getEndDate(item)}
                </DateText>
                <DescriptionText>
                  {item.description && (
                    <ReadMore text={item.description || ""} limit={30} />
                  )}
                </DescriptionText>
              </div>
              <div>
                {item.isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
              </div>
            </SectionContainer>
          </ColumnBlock>
        );
      })}
    </div>
  );
};
