import gql from "graphql-tag";

export const CREATE_PUBLIC_FORCE_MATCH_IN_COMMUNITY = gql`
  mutation CrateForceMatchInCommunity(
    $publicPositionToken: String!
    $matchCreationType: MatchCreationEnumType
    $inviter: String
    $user: ID!
    $matchState: String
  ) {
    addForceMatch(
      matchState: $matchState
      publicPositionToken: $publicPositionToken
      user: $user
      inviter: $inviter
      matchCreationType: $matchCreationType
    ) {
      name
      matchState
      visible
      matchId
    }
  }
`;

export const GET_MATCH_CARDS = gql`
  query MyMatches(
    $filters: matchFilterInputType
    $sort: matchSortInputType
    $typeFilter: String
  ) {
    myMatches(filters: $filters, sort: $sort, typeFilter: $typeFilter) {
      matches {
        id
        rate
        state
        history {
          current
          previous
        }
        position {
          id
          title
          state
          isExternal
          criteria {
            positionLocation {
              expected {
                id
                title
                place {
                  city
                }
              }
              remote
            }
          }
          company {
            logo
            name
            locations {
              id
              title
            }
          }
        }
        user {
          id
          phone
        }
      }
      totalCount
    }
  }
`;

export const GET_MATCH = gql`
  query GET_MATCH(
    $id: ID!
    $historyLimit: Int
    $historySort: matchHistorySortInputType
  ) {
    match(id: $id) {
      id
      status
      createdAt
      state
      rate
      rejectReason {
        id
        label
      }
      user {
        id
        phone
        inviteToken
      }
      history(limit: $historyLimit, sort: $historySort) {
        current
        previous
        actions {
          type
        }
      }
      fields {
        role {
          matches {
            title
          }
          score
        }
        experience {
          score
        }
        skills {
          score
          matches {
            id
            title
          }
        }
        salary {
          score
        }
        benefits {
          score
          matches {
            label
          }
        }
        location {
          score
        }
        techStack {
          score
          matches {
            id
            title
          }
        }
        teamSize {
          score
          matches {
            label
          }
        }
        educationLevel {
          score
        }
        school {
          score
          matches {
            name
          }
        }
        language {
          score
          matches {
            label
          }
        }
        department {
          score
          matches {
            label
          }
        }
      }
      position {
        id
        state
        title
        description
        interestCount
        publicID
        processDurationInDays
        isExternal
        externalPositionApplyLink
        assignee {
          name
          avatarURL
        }
        history {
          current
          previous
          createdAt
        }
        content {
          video
          loomVideoURL
        }
        company {
          name
          logo
          description
          slogan
          website
          linkedin
          contents {
            culture
            place
          }
          sector {
            label
          }
          investStatus
          foundedYear
          teamSize
          techTeamSize
          tgNotes
          officePhotos {
            url
            description
          }
          locations {
            id
            title
            place {
              city
            }
          }
          technologies {
            id
            title
            category
            categoryId
          }
        }
        criteria {
          languages {
            language {
              label
              code
            }
          }

          stockOption {
            isProvided
            isVisibleToUser
            min
            max
            isRange
            certainValue
          }
          budget {
            currency
            income
            min
            max
            isVisibleToUser
            period
          }
          positionLocation {
            expected {
              id
              title
              place {
                city
              }
            }
            remote
            relocation
            visaSupport
          }
          benefits {
            expected {
              label
            }
          }
          technologies {
            expected {
              _id
              title
              category
              experience
            }
            plus {
              id
              title
              category
            }
            otherExpected {
              id
              title
              category
            }
          }
        }
        highlights {
          value
          type
        }
      }
    }
  }
`;

export const FILTER_MATCH_HISTORY_AND_ADD_ACTION = gql`
  mutation filterMatchHistoryAndAddAction($matchId: ID!) {
    filterMatchHistoryAndAddAction(matchId: $matchId) {
      id
    }
  }
`;
