/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { space } from "styled-system";
import device from "../../styles/device";
import {
  HIGHLIGHT_TYPE_ENUM,
  HIGHLIGHT_VALUE_ENUM,
} from "../../utils/constants";

export const HeaderContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 10px 13px 0 #cccccc;
  background-color: #ffffff;
  min-height: 114px;
  display: flex;
  flex-direction: column;
  ${space}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  button.web {
    display: none;
  }
  @media ${device.mobileL} {
    align-items: start;
    button.mobile {
      display: none;
    }
    button.web {
      display: block;
    }
  }
  ${space}
`;

export const Text = styled.p`
  line-height: 25px;
`;

export const signalStyle = {
  position: "absolute",
  right: "-48px",
  top: "-5px",
};

export const signalStyleMobile = {
  ...signalStyle,
  right: "-24px",
};

export const GET_DUMMY_DATA = () => {
  const date1 = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
  const date2 = new Date(Date.now() - 4 * 24 * 60 * 60 * 1000);
  const date3 = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);

  return {
    myMatches: [
      {
        id: "60115967829f855f9e0d39bb",
        rate: 68,
        createdAt: date1,
        status: true,
        state: "SENT",
        position: {
          description:
            "In the future, you’ll see the details of the position under this section.  If you indicate that you are <b> “Interested” </b>, we will provide you with further details and assist you start the interview process with the company. If you <b> “Pass”</b>, we will save your answer and use it to come up with more accurate matches. <b> We will never share your profile unless you show interest in a position.</b> ",
          id: "600801ef6a81476b2b5f0266",
          title: "A Great Position",
          criteria: {
            benefits: {
              expected: [],
            },
            budget: {
              min: 20000,
              max: 30000,
            },
            technologies: {
              expected: [
                [
                  {
                    title: "C",
                  },
                ],
              ],
              plus: [],
            },
            positionLocation: {
              expected: {
                title: "Berlin",
              },
              remote: "none",
            },
          },
          company: {
            name: "Acme Co",
            logo: null,
            technologies: [
              {
                title: "Python",
                categoryId: 1,
              },
              {
                title: "Java",
                categoryId: 1,
              },
              {
                title: "JavaScript",
                categoryId: 1,
              },
              {
                title: "C",
                categoryId: 1,
              },
              {
                title: "AWS Elastic Beanstalk",
                categoryId: 6,
              },
            ],
            locations: [
              {
                id: 1,
                title: "Maslak",
              },
            ],
            linkedin:
              "https://www.linkedin.com/company/talentgrid-io-tech-recruitment/mycompany/",
            website: "https://talentgrid.io/en/home/",
            sector: { label: "Software Development" },
            teamSize: 30,
            techTeamSize: 15,
            foundedYear: 2022,
            investStatus: null,
            slogan: "We are the Acme Co",
            contents: {
              place: "A lovely workplace",
              culture: "Supportive and integrated",
            },
            officePhotos: null,
          },
          interestCount: 16,
          highlights: [
            {
              value: HIGHLIGHT_VALUE_ENUM.RELOCATION_SUPPORT,
              type: HIGHLIGHT_TYPE_ENUM.LOCATION,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.FULL_REMOTE,
              type: HIGHLIGHT_TYPE_ENUM.LOCATION,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.POUND_BASED_SALARY,
              type: HIGHLIGHT_TYPE_ENUM.SALARY,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.EURO_BASED_SALARY,
              type: HIGHLIGHT_TYPE_ENUM.SALARY,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.DOLLAR_BASED_SALARY,
              type: HIGHLIGHT_TYPE_ENUM.SALARY,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.STOCK_OPTIONS,
              type: HIGHLIGHT_TYPE_ENUM.EXTRA,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.MODERN_STACK,
              type: HIGHLIGHT_TYPE_ENUM.EXTRA,
            },
          ],
        },
        fields: {
          role: {
            score: 0,
          },
          salary: {
            score: 100,
          },
          experience: {
            score: 100,
          },
          skills: {
            score: 100,
            matches: [],
          },
          benefits: {
            score: 0,
            matches: [],
          },
          location: {
            score: 100,
          },
          techStack: {
            score: 0,
          },
        },
      },
      {
        id: "60115967829f855f9e0d39bc",
        rate: 98,
        createdAt: date2,
        status: true,
        state: "INTERESTED",
        position: {
          description:
            "In the future, you’ll see the details of the position under this section.  If you indicate that you are <b> “Interested” </b>, we will provide you with further details and assist you start the interview process with the company. If you <b> “Pass”</b>, we will save your answer and use it to come up with more accurate matches. <b> We will never share your profile unless you show interest in a position.</b> ",
          id: "600801ef6a81476b2b5f0266",
          title: "Some Nice Oppurtunity",
          criteria: {
            benefits: {
              expected: [],
            },
            budget: {
              min: 20000,
              max: 30000,
            },
            technologies: {
              expected: [
                [
                  {
                    title: "C",
                  },
                ],
              ],
              plus: [],
            },
            positionLocation: {
              expected: {
                title: "Munich",
              },
              remote: "full",
            },
          },
          company: {
            name: "XYZ Enterprise",
            logo: null,
            technologies: [
              {
                title: "MongoDB",
                categoryId: 7,
              },
              {
                title: "React",
                categoryId: 1,
              },
              {
                title: "JavaScript",
                categoryId: 1,
              },
              {
                title: "C#",
                categoryId: 1,
              },
              {
                title: "AWS",
                categoryId: 3,
              },
            ],
            locations: [
              {
                title: "Amazon Istanbul",
              },
              {
                title: "Amazon Berlin",
              },
            ],
            linkedin:
              "https://www.linkedin.com/company/talentgrid-io-tech-recruitment/mycompany/",
            website: "https://talentgrid.io/en/home/",
            sector: { label: "Software Development" },
            teamSize: 30,
            techTeamSize: 15,
            foundedYear: 2022,
            investStatus: null,
            slogan: "We are the XYZ Enterprise",
            contents: {
              place: "We support your growth",
              culture:
                "XYZ Enterprise makes sure every employee treated equally",
            },
            officePhotos: null,
          },
          interestCount: 7,
          highlights: [
            {
              value: HIGHLIGHT_VALUE_ENUM.STOCK_OPTIONS,
              type: HIGHLIGHT_TYPE_ENUM.EXTRA,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.DOLLAR_BASED_SALARY,
              type: HIGHLIGHT_TYPE_ENUM.SALARY,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.FULL_REMOTE,
              type: HIGHLIGHT_TYPE_ENUM.LOCATION,
            },
          ],
        },
        fields: {
          role: {
            score: 0,
          },
          salary: {
            score: 100,
          },
          experience: {
            score: 100,
          },
          skills: {
            score: 100,
            matches: [],
          },
          benefits: {
            score: 0,
            matches: [],
          },
          location: {
            score: 100,
          },
          techStack: {
            score: 0,
          },
        },
      },
      {
        id: "60115967829f855f9e0d39bd",
        rate: 74,
        createdAt: date3,
        status: false,
        state: "PASSED",
        position: {
          description:
            "In the future, you’ll see the details of the position under this section.  If you indicate that you are <b> “Interested” </b>, we will provide you with further details and assist you start the interview process with the company. If you <b> “Pass”</b>, we will save your answer and use it to come up with more accurate matches. <b> We will never share your profile unless you show interest in a position.</b> ",
          id: "600801ef6a81476b2b5f0267",
          title: "An Unfamiliar Role",
          criteria: {
            benefits: {
              expected: [],
            },
            budget: {
              min: 20000,
              max: 30000,
            },
            technologies: {
              expected: [
                [
                  {
                    title: "C",
                  },
                ],
              ],
              plus: [],
            },
            positionLocation: {
              expected: {
                title: "Istanbul",
              },
              remote: "half",
            },
          },
          company: {
            name: "Huge Corp",
            logo: null,
            technologies: [
              {
                title: "MongoDB",
                categoryId: 7,
              },
              {
                title: "React",
                categoryId: 1,
              },
              {
                title: "JavaScript",
                categoryId: 1,
              },
              {
                title: "Kubernetes",
                categoryId: 6,
              },
              {
                title: "Github",
                categoryId: 4,
              },
            ],
            locations: [
              {
                id: 1,
                title: "Etiler",
              },
              {
                title: "Amazon Berlin",
              },
            ],
            linkedin:
              "https://www.linkedin.com/company/talentgrid-io-tech-recruitment/mycompany/",
            website: "https://talentgrid.io/en/home/",
            sector: { label: "Software Development" },
            teamSize: 30,
            techTeamSize: 15,
            foundedYear: 2022,
            investStatus: null,
            slogan: "We are the Huge Corp",
            contents: {
              place: "It's just okay",
              culture: "We are like a family",
            },
            officePhotos: null,
          },
          interestCount: 12,
          highlights: [
            {
              value: HIGHLIGHT_VALUE_ENUM.STOCK_OPTIONS,
              type: HIGHLIGHT_TYPE_ENUM.EXTRA,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.EURO_BASED_SALARY,
              type: HIGHLIGHT_TYPE_ENUM.SALARY,
            },
            {
              value: HIGHLIGHT_VALUE_ENUM.FULL_REMOTE,
              type: HIGHLIGHT_TYPE_ENUM.LOCATION,
            },
          ],
        },
        fields: {
          role: {
            score: 0,
          },
          salary: {
            score: 100,
          },
          experience: {
            score: 100,
          },
          skills: {
            score: 100,
            matches: [],
          },
          benefits: {
            score: 0,
            matches: [],
          },
          location: {
            score: 100,
          },
          techStack: {
            score: 0,
          },
        },
      },
    ],
  };
};
