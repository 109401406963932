import { createGlobalStyle } from "styled-components";

const Global = createGlobalStyle`
  * {
    margin: 0;
	padding: 0;
    box-sizing: border-box;
		outline: none;
  }
  #root {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: top;
		font-family: "Avenir Next LT Pro Regular", "Avenir Next LT Pro Bold", 'Helvetica','sans-serif';
		background: #f4f6fc;
	}
	ul {
		list-style: none;
	  }
	  
	  ul li::before :not(.image-carousel){
		content: "●";
		color: #72d1fb;
		font-weight: bold;
		display: inline-block; 
		width: 1em;
		text-align: center;
		position: absolute;
		left: -20px;
		top: 0;
	  }
	  li {
		font-family: "Avenir Next LT Pro Regular";
		font-size: 14px;
		font-weight: 500;
		color: #444444;
		position: relative;
	  }
	*:focus {
		outline: 0 !important;
	  }

	body {
		height: 100%;
		background: #f4f6fc;
		-webkit-text-stroke:1px transparent;
		-moz-osx-font-smoothing: grayscale;   /* Firefox */
		-webkit-font-smoothing: antialiased; /* WebKit  */
	}
	a {
		text-decoration: none;
	}
	ul {
		list-style: none;
	}
	button {
		cursor: pointer;
	}
	b {
		font-weight: 500;
		font-family: "Avenir Next LT Pro Bold";
		color: #444444;
	}
`;

export default Global;
