import React from "react";
import makeAnimated from "react-select/animated";
import {
  CriteriaFieldDescription,
  CriteriaFieldTitle,
  ColumnBlock,
} from "../BlockUi";
import { CitySelectField } from "../Form/SelectField";
import RadioButton from "../Form/RadioButton";
import {
  ISTANBUL_PLACE_ID,
  WORKING_STYLE_TYPES_WITH_KEYS,
  ISTANBUL_REGION_TYPES_WITH_KEYS,
} from "../../utils/constants";

const animatedComponents = makeAnimated();

const LocationCriteria = ({
  selectedValues,
  handleOnChange,
  user,
  hasError,
}) => {
  const handleOnRemoteChange = (e) => {
    const newState = JSON.parse(
      JSON.stringify({
        ...selectedValues,
        open: true,
        remote: e.target.value,
      })
    );

    if (!newState.expected) {
      newState.expected = [];
    }

    if (
      ["half", "none"].includes(newState.remote) &&
      newState.expected.length === 0 &&
      user.livingCity
    ) {
      newState.expected.push(user.livingCity);

      if (
        user.livingCity.id === ISTANBUL_PLACE_ID &&
        (!newState.istanbulRegion || newState.istanbulRegion === "none")
      ) {
        newState.istanbulRegion = "all";
      }
    }

    handleOnChange(newState);
  };

  const handleOnIstanbulRegionChange = (value) => {
    const newState = {
      ...selectedValues,
      istanbulRegion: value,
    };
    handleOnChange({ ...newState, istanbulRegion: value });
  };

  const onSelectCity = (vals) => {
    const newState = {
      ...selectedValues,
      expected: vals || [],
    };

    // Eğer kullanıcı doğrudan bir şehir seçerse, ancak seçtiği şehir bilgisine rağmen
    // remote için daha önce bir seçim yapmamışzsa, remote bilgisini NULL olarak kaydedemediğimiz
    // için, biz varsayılan olarak bir remote değeri veriyoruz.
    if (!newState.remote) {
      newState.remote = "half";
    }

    handleOnChange({ ...newState, expected: vals });
  };

  const isCitySelectionVisible =
    selectedValues.remote === "none" || selectedValues.remote === "half";

  const isIstanbulRegionVisible =
    isCitySelectionVisible &&
    selectedValues.expected &&
    selectedValues.expected.map((item) => item.id).includes(ISTANBUL_PLACE_ID);
  return (
    <>
      <ColumnBlock>
        <CriteriaFieldTitle>
          What is your preference for on site/remote working?
        </CriteriaFieldTitle>
        <CriteriaFieldDescription>
          Pick only one. <br />
          Important note: You should consider this choice independently of the
          forced remote working order due to COVID-19.
        </CriteriaFieldDescription>
        {WORKING_STYLE_TYPES_WITH_KEYS.map(({ label, value }, index) => (
          <RadioButton
            label={label}
            key={index}
            id={value}
            name="remote"
            value={selectedValues.remote}
            onChange={handleOnRemoteChange}
            width="100%"
            checked={value === selectedValues.remote}
          />
        ))}
      </ColumnBlock>
      {isCitySelectionVisible && (
        <ColumnBlock mt={3}>
          <CriteriaFieldTitle>
            Can you add the cities you want to work in?
          </CriteriaFieldTitle>
          <CriteriaFieldDescription>
            You can select all cities in that country by typing country
            directly.
          </CriteriaFieldDescription>
          <CitySelectField
            width="100%"
            onChange={onSelectCity}
            components={animatedComponents}
            countryPrefix="All cities, "
            showCountries
            isMulti
            placeholder="Search city"
            value={selectedValues.expected}
            hasError={hasError}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No results found" : "Type something to search"
            }
          />
        </ColumnBlock>
      )}
      {isIstanbulRegionVisible && (
        <ColumnBlock mt={3}>
          <CriteriaFieldTitle>
            In which part of Istanbul would you like to work?
          </CriteriaFieldTitle>
          {ISTANBUL_REGION_TYPES_WITH_KEYS.map(({ value, label }, index) => (
            <RadioButton
              label={label}
              key={index}
              id={value}
              name="remote"
              value={selectedValues.istanbulRegion || "all"}
              onChange={(e) => handleOnIstanbulRegionChange(e.target.value)}
              width="100%"
              checked={value === selectedValues.istanbulRegion}
            />
          ))}
        </ColumnBlock>
      )}
    </>
  );
};

export default LocationCriteria;
