import styled from "styled-components";
import { opacity, space, width } from "styled-system";
import device from "../../styles/device";

export const SectionTitle = styled.span`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  color: #0c084c;
`;

export const SkillItemRow = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f5f6f8;
  padding: 4px 0px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #444444;
  ${width}
  ${opacity}
`;

export const SkillItemNumber = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.family.bold};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: solid 1px #eeeeee;
  font-weight: bold;
`;

export const ViewCardSubTitle = styled.span`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: normal;
  color: #444444;
  margin-bottom: 10px;
`;

export const Tag = styled.div`
  font-family: ${(props) => props.theme.family.regular};
  border-radius: 5px;
  border: solid 2px #72d1fb;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #444444;
  padding: 6px 12px;
  margin-bottom: 5px;
  margin-right: 10px;
`;

export const ProfileName = styled.span`
  font-size: 16px;
  font-family: ${(props) => props.theme.family.bold};
  font-weight: bold;
  text-align: center;
  color: #444444;
  ${space}
`;

export const ProfileHeadlineTitle = styled.span`
  font-size: 12px;
  font-family: ${(props) => props.theme.family.bold};
  font-weight: bold;
  text-align: center;
  color: #444444;
  ${space}
`;

export const ProfileHeadlineItem = styled.span`
  font-size: 14px;
  // font-family: ${(props) => props.theme.family.regular};
  font-family: ${(props) => props.theme.family.demiBold};
  text-align: center;
  color: #444444;
  ${space}
`;

export const ProfileRole = styled.div`
  display: flex;
  width: fit-content;
  font-family: ${(props) => props.theme.family.demi};
  color: #0c084c;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  border-radius: 3px;
  border: solid 2px #0c084c;
  font-size: 11px;
  text-align: center;
  height: 24px;
  ${space}
`;

export const ProfileActivelyLooking = styled.div`
  display: flex;
  width: fit-content;
  font-family: ${(props) => props.theme.family.demi};
  color: #0c084c;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  text-align: center;
  ${space}
`;

export const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  ${space}
`;

export const Container = styled.div`
  padding-top: 15px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  @media ${device.tablet} {
    flex-direction: row;
  }
  > label {
    width: 100%;
    font-family: ${(props) => props.theme.family.bold};
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: #444444;

    @media ${device.tablet} {
      width: 40%;
    }
    @media ${device.laptop} {
      width: 30%;
    }
  }
  > span {
    width: 100%;
    font-family: ${(props) => props.theme.family.medium};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.79;
    color: #444444;

    @media ${device.tablet} {
      width: 60%;
    }
    @media ${device.laptop} {
      width: 70%;
    }
  }
`;

export const DashedBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: dashed 3px #dddddd;
  background-color: #f5f6f8;
  height: 100%;
  cursor: pointer;
  font-family: ${(props) => props.theme.family.bold};
  color: #aaaaaa;
  ${space}
  ${width}
  :hover {
    border: solid 3px;
    color: black;
  }
`;

export const PointerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  ${space}
  ${width}
`;

export const ProfileAboutTitle = styled.div`
  font-size: 12px;
  font-family: ${(props) => props.theme.family.bold};
  font-weight: bold;
  color: #444444;
  height: 25px;
  display: block;
  width: 100%;
  ${space}
`;

export const ProfileAboutContent = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.family.regular};
  color: #444444;
  line-height: 25px;
  text-align: left;
  display: block;
  ${space}
`;

export const EducationDescriptionText = styled.span`
  color: #444444;
  font-size: 14px;
  font-family: ${(props) => props.theme.family.regular};
`;

export const EducationBoldTitle = styled.span`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  color: #444444;
`;

export const WorkHistoryCompany = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  color: #0c084c;
  margin-bottom: 6px;
`;

export const WorkHistoryPosition = styled.div`
  width: 100%;
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  color: #444444;
  margin-bottom: 6px;
`;

export const WorkHistoryPeriod = styled.div`
  width: 100%;
  font-family: ${(props) => props.theme.family.italic};
  font-style: italic;
  font-size: 14px;
  color: #444444;
  margin-bottom: 8px;
`;

export const WorkHistoryDescription = styled.div`
  width: 100%;
  font-family: ${(props) => props.theme.family.regular};
  font-style: italic;
  font-size: 14px;
  color: #444444;
  word-break: break-word;
`;

export const StyledLi = styled.li`
  ${space}
`;

export const IconWrap = styled.div`
  height: 90px;
  width: 90px;
  border-radius: 90px;
  background-color: #f4f6fc;
  display: flex;
  justify-content: center;
  align-items: center;
  ${space}
`;

export const NumberSpan = styled.span`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  color: #444444;
`;

export const Status = styled.div`
  min-width: 18px;
  max-width: 37px;
  padding-top: 4px;
`;

export const Circle = styled.span`
  width: ${(props) => (props.size ? props.size : "15px")};
  height: ${(props) => (props.size ? props.size : "15px")};
  background-color: ${(props) => (props.color ? props.color : "#cccccc")};
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s;
`;
