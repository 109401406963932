import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import * as Yup from "yup";
import { LanguageLevelSelectField } from "tg-design";
import { ALL_LANGUAGES } from "../../../components/ProfileEdit/Education/queries";
import { GET_USER_COUNTRY } from "../../queries";
import {
  debounce,
  convertStringToNumber,
  captureErrorWithData,
  getLanguageLevelType,
} from "../../../helper";
import {
  BoxTitle,
  RowBlock,
  SkillitemCircle,
} from "../../../components/BlockUi";
import { BoxDescription } from "../CommonStyles";
import { Button } from "../../../components/Button";
import Loading from "../../../components/Modules/Loading";
import PartialError from "../../ErrorPage/PartialError";
import { COUNTRIES, MOST_SPOKEN_LANGUAGES } from "../../../utils/constants";
import { FlagBlock } from "../../../components/Form/PhoneField";
import { AsyncSelectField } from "../../../components/Form/SelectField";
import IntercomLauncherMessage from "../../../components/Modules/IntercomLauncherMessage";
import message from "../../../utils/message";
import size from "../../../styles/device";

const FlagContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  border: 2px solid #0c084c;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  :hover {
    background: #e9d8f2;
  }
`;

const validationSchema = Yup.array()
  .min(1)
  .of(
    Yup.object()
      .shape({
        level: Yup.number().required(),
        id: Yup.string().required(),
      })
      .required()
  );

export default function LanguageSelection({
  onSubmit,
  selectedValues,
  formState,
  setFormState,
}) {
  const [state, setState] = useState(selectedValues);
  const [formValid, setFormValid] = useState();

  const { loading, error, data } = useQuery(ALL_LANGUAGES, {
    variables: { limit: 300 },
  });

  const { refetch: languageSearch } = useQuery(ALL_LANGUAGES);

  const {
    data: userInfo,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER_COUNTRY);

  const handleLanguageSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await languageSearch({ search: val });
        const _languages = data.allLanguages.languages.map((language) => {
          return {
            value: language?.id,
            label: language?.label,
          };
        });
        const sortedlanguages = _languages.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        callback(sortedlanguages);
      } catch (err) {
        message.error("Something went wrong.");
        captureErrorWithData(err);
      }
    }, 500),
    []
  );

  useEffect(() => {
    const validateForm = async () => {
      const currentLanguages = (selectedValues || []).filter(
        (language) => language.id !== ""
      );
      try {
        const validateResponse = await validationSchema.isValid(
          currentLanguages
        );
        setFormValid(validateResponse);
      } catch (err) {
        if (err?.name === "ValidationError") return;
        message.error("Something went wrong.");
        captureErrorWithData(err);
      }
    };

    validateForm();
    // eslint-disable-next-line
  }, [formState]);

  if (loading || userLoading) return <Loading width="50px" />;

  if (error || userError) return <PartialError />;

  const { languages } = data.allLanguages;
  const { country } = userInfo.me.livingCity;

  const filterLanguages = () => {
    const mostSpokenLanguages = [];

    const nativeLanguage = COUNTRIES.filter(
      (c) => c.name.includes(country) && c.language
    );
    languages.forEach((language) => {
      if (language.label === nativeLanguage[0]?.language) {
        mostSpokenLanguages.push(language);
      }
    });

    for (let i = 0; i < MOST_SPOKEN_LANGUAGES.length; i++) {
      languages.forEach((language) => {
        if (
          MOST_SPOKEN_LANGUAGES[i] === language.label &&
          MOST_SPOKEN_LANGUAGES[i] !== nativeLanguage[0]?.language
        ) {
          mostSpokenLanguages.push(language);
        }
      });
    }

    const selectedLanguageLabels = (selectedValues || []).map(
      (language) => language.label
    );
    const filteredMostSpokenLanguages = mostSpokenLanguages.filter(
      (language) => !selectedLanguageLabels.includes(language.label)
    );

    return filteredMostSpokenLanguages;
  };

  const handleOptionChange = ({ value, label, type, idx }) => {
    const emptyRowIndex = selectedValues.findIndex(
      (language) => !language.label
    );
    let newState = state;

    const index = idx || idx === 0 ? idx : emptyRowIndex;

    if (selectedValues.length < index + 2 && label) {
      newState = [...newState, { id: "", label: null, level: null }];
    }

    if (type === "languages") {
      if (value) {
        newState[index].id = value;
        newState[index].label = label;
      }
      if (!value) {
        newState.splice(index, 1);
        if (newState.filter((language) => !language.id).length !== 1) {
          newState = [
            ...newState,
            { language: { id: null, label: null }, languageLevel: null },
          ];
        }
      }
    }
    if (type === "level") {
      newState[index].level = value;
    }

    setState(newState);
    setFormState({ ...formState, languages: newState });
    filterLanguages();
  };

  const tabletSize = convertStringToNumber(size.tablet);
  const mobileLSize = convertStringToNumber(size.mobileL);
  const filteredSlice = filterLanguages().slice(0, 10);

  return (
    <>
      <BoxTitle>Which languages can you communicate with?</BoxTitle>
      <BoxDescription color="font.1" mt={3}>
        You will be matched with the positions according to the languages you
        know Don’t forget to add your NATIVE language!
      </BoxDescription>
      <RowBlock py={4} gridGap="10px" justify="center">
        {filteredSlice &&
          filteredSlice.map((language, index) => (
            <FlagContainer
              onClick={() =>
                handleOptionChange({
                  value: language.id,
                  label: language.label,
                  type: "languages",
                })
              }
              key={index}
            >
              <FlagBlock
                style={{ marginBottom: "3px" }}
                display={{ mobileS: "none", tablet: "inline-table" }}
                className={
                  language?.code
                    ? `flag ${language?.code?.toLowerCase()}`
                    : `flag default`
                }
              />
              <span>{language.label}</span>
            </FlagContainer>
          ))}
      </RowBlock>
      {(state || []).map((language, idx) => (
        <RowBlock
          key={idx}
          width={{ mobileS: "100%", tablet: "530px" }}
          wrap="nowrap"
          gridGap="10px"
          my={2}
          justify="space-between"
          px={{ mobileS: "20px", tablet: 0 }}
        >
          <SkillitemCircle>{idx + 1}</SkillitemCircle>
          <AsyncSelectField
            selectedBgColorful
            width={{
              mobileS: "calc(100% - 140px)",
              tablet: "calc(100% - 250px)",
            }}
            placeholder={
              window.innerWidth < mobileLSize
                ? "Language"
                : "Search & Select Language"
            }
            value={languages.filter((value) => value.label === language.label)}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? (
                <IntercomLauncherMessage />
              ) : (
                "Type something to search"
              )
            }
            loadOptions={handleLanguageSearch}
            onChange={(selectedOption) =>
              handleOptionChange({
                ...selectedOption,
                type: "languages",
                idx,
              })
            }
            valueAlign="center"
            isClearable
          />
          <LanguageLevelSelectField
            menuListStyle={{ minHeight: "300px" }}
            placeholder={
              window.innerWidth < tabletSize ? "Level" : "Select Level"
            }
            containerStyle={{ width: "35%" }}
            value={getLanguageLevelType(language) || null}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No results found" : "Type something to search"
            }
            isSearchable={false}
            selectedBgColorful
            valueAlign="center"
            handleOnChange={({ selectedOption }) =>
              handleOptionChange({
                ...selectedOption,
                type: "level",
                idx,
              })
            }
            bgColor="#f5f6f8"
            controlStyle={{
              border: "2px  #abe6c1 solid",
            }}
            customStatusStyle={{ focused: "2px  #abe6c1  solid" }}
          />
        </RowBlock>
      ))}
      <RowBlock mt={5}>
        <Button
          disabled={!formValid}
          type="submit"
          variant="primary"
          size="large"
          onClick={onSubmit}
          alignItems="center"
          display="flex"
          justifyContent="center"
          className="register-updated"
        >
          Create My Profile
        </Button>
      </RowBlock>
    </>
  );
}
