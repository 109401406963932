import React from "react";
import styled from "styled-components";
import { FREQUENTLY_ASKED_QUESTIONS } from "../../utils/constants";
import { ColumnBlock } from "../BlockUi";
import { Box, BoxTitle } from "./CommonStyles";

const Wrapper = styled.div`
  width: 100%;
  & ul {
    margin-left: 30px;

    & li {
      margin-bottom: 5px;
    }
  }
`;

const QuestionContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

const Question = styled.p`
  font-family: "Avenir Next LT Pro Bold", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  color: #444444;
`;

const Answer = styled.p`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #444444;
`;

export default function FrequentlyAskedQuestions() {
  return (
    <Box px={{ mobileS: 3, tablet: 3, laptop: 217 }}>
      <ColumnBlock my={4} width="100%">
        <BoxTitle mb={39}>FAQ</BoxTitle>
        <Wrapper>
          {FREQUENTLY_ASKED_QUESTIONS.map((item, index) => {
            return (
              <QuestionContainer key={index}>
                <Question dangerouslySetInnerHTML={{ __html: item.question }} />
                <Answer>{item.answer}</Answer>
              </QuestionContainer>
            );
          })}
        </Wrapper>
        <span style={{ marginBottom: "20px" }}>
          <i>
            ***If the person you invite is already a TalentGrid member with a
            different username, email address, or phone number, you will not be
            able to get any bonus. This referral program is valid only when a
            new member joins TalentGrid.
          </i>
        </span>
        If you have any questions about the process or face any problems, you
        can always contact us via{" "}
        <a href="mailto:hello@talentgrid.io">hello@talentgrid.io</a>
      </ColumnBlock>
    </Box>
  );
}
