import React from "react";
import { PieChart } from "react-minimal-pie-chart";

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return `${text.substring(0, limit)}..`;
  }
  return text;
};

const transformData = (skillList) => {
  const colors = ["#0c084c", "#8fdeac", "#fad156", "#72D1FB", "#c2c1d2"];

  // Girilen skill sayısı 5'ten fazla ise ilk 5'i için TG'nin kendi renklerini
  // Geri kalan skiller için ise random ürettiği renkleri kullanır.
  skillList.forEach((current, index) => {
    if (index !== skillList.length - colors.length) {
      /* eslint-disable no-bitwise */
      return colors.push(
        `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`
      );
    }
  });

  const transformedData = skillList
    .map((skill, index) => {
      return {
        title: skill.title,
        value: skill.experience,
        color: colors[index],
        key: skill._id,
      };
    })
    .sort((a, b) => b.experience - a.experience);

  return transformedData;
};

export default function SkillDonut({ skills }) {
  return (
    <>
      <PieChart
        lineWidth={14}
        animate
        data={transformData(skills)}
        startAngle={90}
        label={({ dataEntry }) => truncateText(dataEntry.title, 10)}
        labelPosition={110}
        radius={40}
        viewBoxSize={[150, 150]}
        center={[75, 75]}
        labelStyle={{ fontSize: "6px", fontFamily: "Avenir Next LT Pro Demi" }}
        style={{ width: "100%" }}
      />
    </>
  );
}
