import React from "react";
import { ColumnBlock, ScrollBoxY } from "../../BlockUi";
import ToolsIcon from "../../../images/noun-tools.svg";
import CriteriaViewCard from "./Card";
import { ViewCardSubTitle, Tag } from "../CommonStyles";

export default function TechnologiesViewCard({ technologies }) {
  return (
    <CriteriaViewCard title="Tech stack" image={ToolsIcon}>
      <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
        <ViewCardSubTitle>
          Technology tools and approaches it wants to focus on:
        </ViewCardSubTitle>
        <ScrollBoxY>
          {technologies &&
            technologies.expected.map(({ title }) => (
              <Tag key={title}>{title}</Tag>
            ))}
        </ScrollBoxY>
      </ColumnBlock>
    </CriteriaViewCard>
  );
}
