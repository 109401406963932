import React, { useState, useRef } from "react";
import styled from "styled-components";
import { EditIcon } from "../Icon";

const CollapseContainer = styled.div`
  padding-top: 25px;
  width: 100%;
`;

const Heading = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #0c084c;
  border-bottom: solid 3px #0c084c;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #0c084c;
  flex: 2;
`;

const Triangle = styled.div`
  display: inline-block;
  position: relative;
  left: 4px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #0c084c;
  transform: ${(props) => (props.open ? "rotate(90deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
  margin-right: 15px;
  margin-bottom: 1px;
`;

const IconWrap = styled.div`
  padding: 0 5px 0 10px;
`;

const CollapseContent = styled.div`
  position: ${({ open }) => (open ? "inherit" : "absolute")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? 1 : 0)};
  height: 100%;
  word-break: break-word;
  transition: opacity 0.9s ease;
`;

/**
 *
 * @param {string} title - title of collapse
 * @param {boolean} open - status of collapse (open or close)
 * @param {boolean} edit - edit icon of collapse context (open or close)
 * @param {function} handleOnEdit - event of edit icon
 */

function Collapse({ title, open, children, edit, handleOnEdit, disabled }) {
  const [state, setState] = useState({ visible: open, contentHeight: "0px" });

  const contentRef = useRef();

  const handleToggle = () => {
    if (!disabled) {
      setState({
        ...state,
        visible: !state.visible,
        contentHeight: state.visible
          ? `${contentRef.current.scrollHeight}px`
          : "0px",
      });
    }
  };

  return (
    <CollapseContainer>
      <Heading>
        <Triangle open={state.visible} onClick={handleToggle} />
        <Title onClick={handleToggle}>{title}</Title>
        {edit && (
          <IconWrap onClick={handleOnEdit}>
            <EditIcon />
          </IconWrap>
        )}
      </Heading>
      <CollapseContent ref={contentRef} open={state.visible}>
        {children}
      </CollapseContent>
    </CollapseContainer>
  );
}

export default Collapse;
