import gql from "graphql-tag";

export const SEARCH_UNIVERSITY = gql`
  query AllUniversity($search: String) {
    allUniversity(search: $search) {
      universities {
        id
        name
      }
    }
  }
`;

export const SEARCH_BRANCH = gql`
  query AllUniversityBranch($search: String) {
    allUniversityBranch(search: $search) {
      universityBranches {
        id
        label
      }
    }
  }
`;

export const ALL_LANGUAGES = gql`
  query AllLanguages($search: String, $limit: Int) {
    allLanguages(search: $search, limit: $limit) {
      languages {
        id
        label
        code
        alternativeTexts
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $education: [UserEducationInputType]
    $languages: [UserLanguageInputType]
  ) {
    updateUser(education: $education, languages: $languages) {
      id
      languages {
        language {
          id
          label
        }
        level
      }
      education {
        type
        school {
          name
          id
        }
        branch {
          id
          label
        }
        startDate
        endDate
      }
    }
  }
`;
