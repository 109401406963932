import React from "react";
import {
  CriteriaFieldTitle,
  ColumnBlock,
  CriteriaDescription,
} from "../BlockUi";
import CurrencyField from "../Form/SalaryField";
import TextField from "../Form/TextField";

const FreelanceCriteria = ({ selectedValues, handleOnChange, currency }) => {
  return (
    <>
      <ColumnBlock>
        <CriteriaDescription>
          If you turn this toggle on, we will also match you with the available
          freelance positions besides full-time positions
        </CriteriaDescription>
        <CriteriaFieldTitle>
          How many hours can you dedicate per week?
        </CriteriaFieldTitle>
        <TextField
          type="text"
          name="availableWeeklyHour"
          autoFocus
          otherStyle={{ alignItems: "left" }}
          textAlign="left"
          value={selectedValues.availableWeeklyHour || ""}
          onChange={handleOnChange}
        />
      </ColumnBlock>
      <ColumnBlock>
        <CriteriaFieldTitle>
          What is your hourly expected rate?
        </CriteriaFieldTitle>
        <CurrencyField
          type="text"
          name="hourlyRate"
          currency={currency}
          value={selectedValues.hourlyRate || ""}
          onChange={handleOnChange}
        />
      </ColumnBlock>
    </>
  );
};

export default FreelanceCriteria;
