import React from "react";
import styled from "styled-components";
import { ColumnBlock } from "../BlockUi";

export const Tab = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: ${(props) => (props.selected ? "64px" : "60px")};
  width: ${(props) => (props.selected ? "100%" : "97%")};
  background-color: #fff;
  padding: 13px 14px;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? "bold" : "600")};
  color: #0c084c;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: ${(props) => props.theme.family.demi};
  cursor: pointer;
  box-shadow: ${(props) => (props.selected ? " 0 7px 10px 0 #cccccc" : "none")};
  transition: ${(props) => (props.selected ? "all 0.2s 0s ease" : "none")};
`;

export const IconField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #edf9fe;
  margin-right: 10px;
`;

export default function TabList({ tablist, handleClick, active }) {
  const Tabs = tablist.map((val) => {
    const Icon = val.icon;
    return (
      <Tab
        key={val.name}
        selected={active === val.name}
        onClick={() => handleClick(val.name)}
      >
        <IconField>
          <Icon fill={val.iconColor || "silver"} />
        </IconField>
        {val.text}
      </Tab>
    );
  });

  return <ColumnBlock align="center">{Tabs}</ColumnBlock>;
}
