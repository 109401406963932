import React from "react";
import CompanyBasics from "../CompanyBasics";
import TechnologiesUsed from "../TechnologiesUsed";
import CompanyInfo from "../CompanyInfo";
import TgCompanyNotes from "../TgCompanyNotes";

export default function CompanyTab({
  technologies,
  isEmpty,
  company,
  heapInfo,
}) {
  return (
    <>
      <CompanyBasics company={company} heapInfo={heapInfo} />
      <TechnologiesUsed technologies={technologies} isEmpty={isEmpty} />
      <CompanyInfo company={company} isEmpty={isEmpty} />
      {company.tgNotes && <TgCompanyNotes notes={company.tgNotes} />}
    </>
  );
}
