import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorage";
import {
  DEFAULT_MATCH_FILTER_CHECKBOX_STATE,
  DEFAULT_MATCH_FILTERS,
  DEFAULT_SORT_FILTER,
  DEFAULT_TYPE_FILTER,
  FILTER_VALUE,
} from "../../utils/constants";
import {
  MATCH_CHECKBOX_FILTERS_KEY,
  MATCH_SORT_FILTER_KEY,
  MATCH_STATE_FILTERS_KEY,
  MATCH_TYPE_FILTER_KEY,
} from "../../utils/localStorageConstants";

const initialState = {
  stateFilters: getLocalStorage({
    key: MATCH_STATE_FILTERS_KEY,
    isJSON: true,
    defaultValue: DEFAULT_MATCH_FILTERS,
  }),
  sortFilter: getLocalStorage({
    key: MATCH_SORT_FILTER_KEY,
    defaultValue: DEFAULT_SORT_FILTER,
  }),
  checkboxFilters: getLocalStorage({
    key: MATCH_CHECKBOX_FILTERS_KEY,
    defaultValue: DEFAULT_MATCH_FILTER_CHECKBOX_STATE,
    isJSON: true,
  }),
  typeFilter: getLocalStorage({
    key: MATCH_TYPE_FILTER_KEY,
    defaultValue: DEFAULT_TYPE_FILTER,
  }),
  isActive: true,
};

export const matchFilterSlice = createSlice({
  name: "matchFilter",
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.stateFilters = DEFAULT_MATCH_FILTERS;
      state.sortFilter = DEFAULT_SORT_FILTER;
      state.checkboxFilters = DEFAULT_MATCH_FILTER_CHECKBOX_STATE;
      state.typeFilter = DEFAULT_TYPE_FILTER;
      setLocalStorage({
        key: MATCH_STATE_FILTERS_KEY,
        isJSON: true,
        value: DEFAULT_MATCH_FILTERS,
      });
      setLocalStorage({
        key: MATCH_CHECKBOX_FILTERS_KEY,
        isJSON: true,
        value: DEFAULT_MATCH_FILTER_CHECKBOX_STATE,
      });
      setLocalStorage({
        key: MATCH_SORT_FILTER_KEY,
        value: DEFAULT_SORT_FILTER,
      });
      setLocalStorage({
        key: MATCH_TYPE_FILTER_KEY,
        value: DEFAULT_TYPE_FILTER,
      });
    },
    setMatchFilter: (state, { payload }) => {
      const { checked, name } = payload;
      state.checkboxFilters[name] = checked;
      setLocalStorage({
        key: MATCH_CHECKBOX_FILTERS_KEY,
        isJSON: true,
        value: state.checkboxFilters,
      });
      state.stateFilters = checked
        ? [...state.stateFilters, ...FILTER_VALUE[name]]
        : state.stateFilters.filter((f) => !FILTER_VALUE[name].includes(f));
      setLocalStorage({
        key: MATCH_STATE_FILTERS_KEY,
        isJSON: true,
        value: state.stateFilters,
      });
    },
    setMatchSort: (state, { payload }) => {
      const { value } = payload;
      if (!value) {
        return;
      }
      state.sortFilter = value;
      setLocalStorage({
        key: MATCH_SORT_FILTER_KEY,
        value: state.sortFilter,
      });
    },
    setMatchType: (state, { payload }) => {
      const { value } = payload;
      if (!value) {
        return;
      }
      state.typeFilter = value;
      setLocalStorage({
        key: MATCH_TYPE_FILTER_KEY,
        value: state.typeFilter,
      });
    },
    setMatchFilterActive: (state, { payload }) => {
      state.isActive = payload;
    },
  },
});

export const {
  resetFilters,
  setMatchFilter,
  setMatchSort,
  setMatchFilterActive,
  setMatchType,
} = matchFilterSlice.actions;

export default matchFilterSlice.reducer;
