import { gql } from "@apollo/client";

export const MATCH_STATE_CHANGE = gql`
  mutation PublicMatchStateChange(
    $match: ID!
    $action: String!
    $token: String!
  ) {
    publicMatchStateChange(match: $match, action: $action, token: $token)
  }
`;
