import styled from "styled-components";
import { space } from "styled-system";

export const InputWrap = styled.div`
  ${space}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  width: 37px;
  height: 56px;
  border-radius: 5px;
  background-color: #f5f6f8;
  border: 2px solid transparent;
  font-size: 30px;
  padding-left: 9px;
  margin: 5px;
  :focus {
    border: 2px solid #abe6c1;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
