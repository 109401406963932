import styled from "styled-components";
import {
  typography,
  space,
  color,
  fontWeight,
  width,
  fontSize,
  display,
  background,
  alignItems,
  justifyContent,
  height,
  overflow,
  overflowX,
  overflowY,
  gridRowGap,
  border,
  gridGap,
  flex,
} from "styled-system";
import device from "../styles/device";
import patternLeft from "../images/left-pattern.png";
import patternRight from "../images/right-pattern.png";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: auto;
  ${color}
  flex-direction: column;
  align-items: center;
  ${space}
  ${display}
  @media ${device.tablet} {
    width: calc(100% - 2.75rem);
  }
  @media ${device.laptop} {
    width: calc(100% - 12.75rem);
    max-width: 1280px;
  }

  @media ${device.desktop} {
    width: calc(100% - 24.75rem);
    max-width: 1280px;
  }

  @media ${device.desktopL} {
    width: calc(100% - 42.75rem);
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: auto;
  ${background}
  flex-direction: column;
  align-items: center;
  ${space}
`;

export const FixedBottomRow = styled.div`
  position: fixed;
  background: #fff;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  box-shadow: 0 0 15px 0 #dddddd;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  ${space}
`;

export const RowBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  flex-wrap: wrap;
  flex-wrap: ${(props) => props.wrap};
  pointer-events: ${(props) =>
    props.pointerEvents ? props.pointerEvents : "inherit"};
  ${width}
  ${space}
  ${display}
  ${alignItems}
  ${justifyContent}
  ${height}
  ${overflow}
  ${overflowX}
  ${overflowY}
  ${gridRowGap}
  ${border}
  ${flex}
  ${color}
  ${gridGap}
  ${background}
`;

export const ColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  opacity: ${(props) => props.opacity};
  flex: ${(props) => props.flex};
  ${width}
  ${space}
  ${display}
  ${alignItems}
  ${justifyContent}
  ${height}
  ${overflow}
  ${overflowX}
  ${overflowY}
  ${background}
`;

export const StickyBottom = styled.div`
  bottom: 0;
  background: #fff;
  width: 100%;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  ${space}
`;

export const Hint = styled.div`
  font-family: ${(props) => props.theme.family.regular};
  font-size: 14px;
  color: ${(props) => props.theme.colors.font[0]};
  ${space}
`;
export const Span = styled.span`
  ${typography}
  ${color}
  ${space}
  ${fontWeight}
  ${fontSize}
  ${width}
  text-overflow: ellipsis;
`;

export const SkillitemCircle = styled.div`
  border-radius: 50%;
  background-color: transparent;
  font-family: ${(props) => props.theme.family.bold};
  font-size: 16px;
  color: #444444;
  position: relative;
  min-width: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 40px;
  @media ${device.tablet} {
    background-color: #f5f6f8;
    min-width: 40px;
  }
`;

export const CriteriaFieldTitle = styled.div`
  color: ${(props) => props.theme.colors.font[0]};
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 25px;
  ${space}
`;

export const CriteriaDescription = styled.div`
  color: ${(props) => props.theme.colors.font[0]};
  margin-bottom: 25px;
`;

export const CriteriaFieldDescription = styled.div`
  color: ${(props) => props.theme.colors.font[1]};
  font-family: ${(props) => props.theme.family.demi};
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 7px;
  ${space}
`;

export const BoxTitle = styled.h2`
  font-size: 16px;
  line-height: 25px;
  font-family: ${(props) => props.theme.family.bold};
  color: #444444;
  ${color}
  ${typography}
`;

export const BoxText = styled.p`
  font-size: 14px;
  margin-top: 20px;
  color: #aaaaaa;
  ${space}
  ${color}
  ${typography}
  font-family: ${(props) => props.theme.family.medium};
`;

export const Box = styled.div`
  ${space}
  ${typography}
  ${background}
  max-width: ${(props) => props.maxWidth};
  min-height: ${(props) => props.minHeight};
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 13px 2px #dddddd;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top, right top;
  box-shadow: 0 1px 13px 2px #dddddd;
`;

export const OnboardingFormBox = styled(Box)`
  ${space}
  ${typography}
	background-color: #fff;
  max-width: ${(props) => props.maxWidth};
  min-height: ${(props) => props.minHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 1px 13px 2px #dddddd;
  align-items: center;
  box-shadow: 0 1px 13px 2px #dddddd;
  border-radius: 20px;
  align-self: center;
  width: 100%;

  @media ${device.tablet} {
    border-radius: 10px;
    width: 750px;
    min-width: 750px;
    justify-content: flex-start;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top, right top;
    background-image: url(${(props) =>
        props.withCover ? patternLeft : "none"}),
      url(${(props) => (props.withCover ? patternRight : "none")});
  }
`;

export const PublicPositionBox = styled.div`
  ${space}
  ${typography}
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 13px 2px #dddddd;
  border-radius: 10px;
`;

export const ScrollBoxX = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 54px;
  width: 240px;
  overflow-x: auto;
  overflow-y: hidden;

  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 20, 0.2), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 20, 0.2), rgba(255, 255, 255, 0));
  /* Shadows */
  /* Shadow covers */
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 12px 100%, 12px 100%, 6px 100%, 6px 100%;
  background-attachment: local, local, scroll, scroll;
`;

export const ScrollBoxY = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  height: 65px;
  overflow: auto;

  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        50% 0,
        farthest-side,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        50% 100%,
        farthest-side,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
`;

export const InlineInputs = styled.div`
  display: flex;
  ${space};
  @media only screen and (max-width: 768px) {
    :not(.keep-inline) {
      display: block;
    }
  }
`;
