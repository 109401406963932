import React from "react";
import styled from "styled-components";
import DataGroup from "../Modules/DataGroup";

const StyledText = styled.div`
  padding-top: 5px;
  font-family: ${(props) => props.theme.family.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #444444;

  & ul {
    margin-left: 20px;

    & li {
      margin-bottom: 5px;
    }
  }
`;

export default function TgCompanyNotes({ notes }) {
  return (
    <DataGroup title="Notes from TalentGrid Team">
      <StyledText dangerouslySetInnerHTML={{ __html: notes }} />
    </DataGroup>
  );
}
