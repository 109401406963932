import React from "react";
import styled from "styled-components";

const Wrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 55px;
  height: 16px;
  background: #fff;
`;

const DisabledIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  position: absolute;
  top: 0;
  left: -8px;
  width: 28px;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

const ActiveIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  position: absolute;
  top: 0;
  right: -2px;
  width: 19px;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  justify-content: center;
  align-items: center;
`;

const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  display: none;
  outline: 0;
  border: none;
  -moz-outline-style: none;
`;

const getSliderBackgroundStyle = ({ active, variant, theme }) => {
  if (variant === "success") {
    return active ? theme.colors.green[0] : theme.colors.red[2];
  }
  if (variant === "passive") {
    return active ? theme.colors.red[2] : theme.colors.font[1];
  }
};

const getActiveIcon = ({ variant }) => {
  if (variant === "success") {
    return <polyline points="20 6 9 17 4 12" strokeWidth="4" />;
  }
  if (variant === "passive") {
    return <line x1="0" y1="12" x2="50" y2="12" strokeWidth="5" />;
  }
};

const Slider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 0.4s;

  cursor: pointer;
  background-color: #ddd;
  border-radius: 34px;

  ${Checkbox}:focus + & {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }

  &:before {
    content: "";
    position: absolute;
    left: -4px;
    bottom: -8px;

    transition: 0.4s;

    height: 28px;
    width: 28px;
    background-color: ${(props) => getSliderBackgroundStyle({ ...props })};
    border: 2px solid #fff;
    border-radius: 50%;

    ${Checkbox}:checked + & {
      transform: translateX(35px);
    }
  }
`;

const Switch = ({
  active,
  id,
  name,
  variant = "success",
  onChange,
  ...props
}) => {
  return (
    <Wrapper>
      <Checkbox id={id} name={name} checked={active} onChange={onChange} />
      <Slider active={active} variant={variant}>
        <ActiveIcon show={active} viewBox="0 2 24 24">
          {getActiveIcon({ active, variant })}
        </ActiveIcon>
        {variant === "success" && (
          <DisabledIcon show={!active} viewBox="0 2 24 24">
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Criteria---off"
                transform="translate(-527.5, -41.3)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <g id="Criteria">
                  <g id="Group" transform="translate(112.000000, 33.000000)">
                    <g
                      id="Toggle-off"
                      transform="translate(415.000000, 0.000000)"
                    >
                      <g
                        id="noun_Cross_1220015"
                        transform="translate(12.000000, 12.000000)"
                      >
                        <path
                          d="M9.00206398,10.3529412 L5.64189886,6.99277606 L2.28173375,10.3529412 C1.19195046,11.3519092 -0.442724458,9.80804954 0.647058824,8.71826625 L4.00722394,5.35810114 L0.647058824,1.99793602 C-0.442724458,0.908152735 1.19195046,-0.726522188 2.28173375,0.363261094 L5.64189886,3.72342621 L9.00206398,0.363261094 C10.0918473,-0.726522188 11.6357069,0.908152735 10.6367389,1.99793602 L7.27657379,5.35810114 C8.36635707,6.44788442 9.54695562,7.5376677 10.6367389,8.71826625 C11.6357069,9.80804954 10.001032,11.3519092 9.00206398,10.3529412 Z"
                          id="Path"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </DisabledIcon>
        )}
        {props.children && props.children}
      </Slider>
    </Wrapper>
  );
};

export default Switch;
