import * as Yup from "yup";

export default Yup.object().shape({
  profile: Yup.object().shape({
    fullName: Yup.string().required("Gerekli").min(5),
    livingCity: Yup.object().shape({
      city: Yup.string().required("Gerekli").min(3),
    }),
    birthyear: Yup.number().required("Gerekli").min(4),
  }),
});
