import React from "react";
import { useHistory } from "react-router-dom";
import { RowBlock } from "../../BlockUi";
import Collapse from "../../Modules/Collapse";
import ProfileSchoolSection from "./Schools";
import ProfileLanguagesSection from "./Languages";

export default function ProfileEducationView({
  education = [],
  languages = [],
}) {
  const history = useHistory();

  const handleEdit = () => {
    history.push("profile/edit/education");
  };

  return (
    <RowBlock>
      <Collapse title="Education" open edit handleOnEdit={handleEdit}>
        <RowBlock
          width="100%"
          mt={4}
          justifyContent={{ mobileL: "space-between" }}
          minHeight="80px"
        >
          <ProfileSchoolSection education={education} />
          <ProfileLanguagesSection languages={languages} />
        </RowBlock>
      </Collapse>
    </RowBlock>
  );
}
