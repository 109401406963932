import React from "react";
import { useHistory } from "react-router-dom";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import {
  SectionTitle,
  EducationDescriptionText,
  EducationBoldTitle,
  StyledLi,
  IconWrap,
} from "../CommonStyles";
import { EducationIcon } from "../../Icon";
import HoveredEmptyView from "../../Modules/HoveredEmptyView";
import { historySortingStrategy } from "../../../helper";
import { EDUCATION_TYPE_TITLES } from "../../../utils/constants";

export default function ProfileSchoolSection({ education }) {
  if (!education) {
    education = [];
  }

  const history = useHistory();

  const handleEdit = () => {
    history.push("profile/edit/education");
  };

  education.slice().sort(historySortingStrategy);

  return (
    <RowBlock
      mt={2}
      mb={3}
      py={4}
      pl={3}
      pr={4}
      width="100%"
      border="solid 1px #eeeeee"
      borderRadius="5px"
    >
      <ColumnBlock width={{ mobileS: "100%", tablet: "30%" }}>
        <RowBlock>
          <IconWrap>
            <EducationIcon />
          </IconWrap>
        </RowBlock>
      </ColumnBlock>
      <ColumnBlock width={{ mobileS: "100%", tablet: "70%" }}>
        <RowBlock justify="flex-start">
          <SectionTitle>Education History</SectionTitle>
        </RowBlock>
        {education.length === 0 && (
          <RowBlock justify="flex-start" onClick={handleEdit}>
            <HoveredEmptyView text="Add" />
          </RowBlock>
        )}
        {education.length > 0 && (
          <RowBlock justify="flex-start" mx={{ tablet: 2 }}>
            <ul>
              {education.map((item, index) => (
                <StyledLi my={3} ml={{ mobileS: 3, tablet: 2 }} key={index}>
                  {/* TODO: branch label */}
                  <EducationBoldTitle>{item?.school?.name}</EducationBoldTitle>
                  &#8192;/&#8192;
                  {item?.branch?.label}
                  <br />
                  {(item.type || item.type === 0) && item.startDate && (
                    <EducationDescriptionText>
                      {EDUCATION_TYPE_TITLES[item.type]}, {item.startDate} -{" "}
                      {item.endDate}
                    </EducationDescriptionText>
                  )}
                  {((item.type !== 0 && !item.type) ||
                    !item.startDate ||
                    !item.endDate) && (
                    <EducationDescriptionText style={{ color: "red" }}>
                      Missing Info:
                      {item.type === undefined ||
                        (item.type === null && "Education Level")}
                      {!item.startDate && "Year started"}
                      {!item.endDate && "Year finished"}
                    </EducationDescriptionText>
                  )}
                </StyledLi>
              ))}
            </ul>
          </RowBlock>
        )}
      </ColumnBlock>
    </RowBlock>
  );
}
