import React from "react";
import styled from "styled-components";
import DataGroup from "../Modules/DataGroup";
import { Container, Row } from "./CommonModule";
import { ColumnBlock } from "../BlockUi";
import DefaultAvatar from "../../images/default-avatar.png";
import { Label } from "../FormUi";

const PartnerBox = styled.div`
  display: flex;
  height: 100px;
  align-items: center;
`;

const PartnerAvatar = styled.img`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 15px;
`;

const PartnerName = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
`;

const PartnerTitle = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9ca3af;
`;

const handleOnContentChange = (value) => {
  const [loomAddress, loomVideoID] = value.split("/share/");

  if (loomAddress !== "https://www.loom.com") {
    return null;
  }

  return `https://www.loom.com/embed/${loomVideoID}`;
};

export default function QuickPositionIntro({ ...props }) {
  const {
    match: { position },
    isEmpty,
  } = props;

  return (
    <>
      {position.content && position.content.loomVideoURL && (
        <DataGroup title="Quick intro from the Talent Partner">
          {!isEmpty && (
            <Container>
              <Row gridColumnGap="30px">
                <Label>
                  <PartnerBox>
                    <PartnerAvatar
                      src={position.assignee.avatarURL || DefaultAvatar}
                    />
                    <div>
                      <PartnerName>{position.assignee.name}</PartnerName>
                      <PartnerTitle>Talent Partner</PartnerTitle>
                    </div>
                  </PartnerBox>
                </Label>
                <ColumnBlock
                  style={{ flexGrow: "2" }}
                  width={{ mobileS: "100%", tablet: "40%" }}
                  alignItems={{ mobileL: "center" }}
                  mb={{ mobileS: 2 }}
                >
                  <iframe
                    title="PositionIntroductionVideo"
                    src={handleOnContentChange(position.content.loomVideoURL)}
                    frameBorder="0"
                    allowFullScreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    style={{ width: "100%", height: "250px" }}
                  />
                </ColumnBlock>
              </Row>
            </Container>
          )}
        </DataGroup>
      )}
    </>
  );
}
