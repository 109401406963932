import React from "react";
import PositionInfo from "../PositionInfo";
import Benefits from "../Benefits";
import QuickPositionIntro from "../QuickIntroFromTP";
import PositionHighlights from "../PositionHighlights";

export default function PositionTab({ match, isEmpty, swipeableHandlers }) {
  return (
    <>
      <PositionHighlights match={match} swipeableHandlers={swipeableHandlers} />
      <QuickPositionIntro match={match} />
      <PositionInfo match={match} isEmpty={isEmpty} />
      <Benefits match={match} />
    </>
  );
}
