import React from "react";
import styled from "styled-components";
import device from "../../styles/device";
import message from "../../utils/message";
import {
  FacebookIcon,
  InviteLinkIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "../Icon";

const Wrapper = styled.div`
  display: flex;

  padding-right: 4px;
  justify-content: space-between;
  width: 100%;
  @media ${device.mobileMax} {
    padding-right: 0px;
    margin-top: 30px;
    width: auto;
    display: grid;
    grid-template: 1fr 1fr 1fr/ 1fr 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;
    place-items: center;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  width: 70px;
  height: 48px;
  border: 2px solid;
  border-color: ${({ borderColor }) => borderColor || "transparent"};

  background-color: ${({ color }) => color || "#000"};

  &:hover {
    cursor: pointer;
  }

  @media ${device.mobileMax} {
    width: 48px;
  }
`;

const SOCIAL_MEDIAS = [
  {
    name: "LinkCopy",
    icon: <InviteLinkIcon />,
    url: (inviteLink) => {
      return `I recommend you for remote & relocation job opportunities at TalentGrid. Take a look! ${inviteLink}`;
    },
    boxColor: "#FFFFFF",
  },
  {
    name: "LinkedIn",
    icon: <LinkedinIcon color="#FFFFFF" />,
    url: (inviteLink, name) => {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${inviteLink}&title=&summary=${name} recommends you for remote and relocation job opportunities at TalentGrid. Take a look!&source=`;
    },
    boxColor: " #0e76a8 ",
  },
  {
    name: "Twitter",
    icon: <TwitterIcon color="#FFFFFF" />,
    url: (inviteLink, name) => {
      return `https://twitter.com/intent/tweet?url=${inviteLink}&text=${name} recommends you for remote and relocation job opportunities at TalentGrid. Take a look!`;
    },
    boxColor: "#1DA1F2",
  },
  {
    name: "WhatsApp",
    icon: <WhatsappIcon />,
    url: (inviteLink) => {
      return `https://api.whatsapp.com/send?text=${inviteLink}`;
    },
    boxColor: "#25d366",
  },
  {
    name: "Facebook",
    icon: <FacebookIcon />,
    url: (inviteLink) => {
      return `https://www.facebook.com/sharer/sharer.php?u=${inviteLink}`;
    },
    boxColor: "#4267B2",
  },
];

const openInNewTab = (url) => {
  return window.open(url, "_blank", "noopener,noreferrer");
};

const handleCopy = (value) => {
  const textarea = document.createElement("textarea");
  textarea.textContent = value;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
  message.success("Invitation link copied!");
};

export default function ShareOnSocialMediaField({ inviteLink, user }) {
  return (
    <>
      <Wrapper>
        {SOCIAL_MEDIAS.map((item, index) => {
          return (
            <>
              {item.name === "LinkCopy" && (
                <IconContainer
                  key={index}
                  borderColor="black"
                  color={item.boxColor}
                  onClick={() => handleCopy(item.url(inviteLink))}
                >
                  {item.icon}
                </IconContainer>
              )}
              {item.name !== "LinkCopy" && (
                <IconContainer
                  key={index}
                  color={item.boxColor}
                  onClick={() => openInNewTab(item.url(inviteLink, user.name))}
                >
                  {item.icon}
                </IconContainer>
              )}
            </>
          );
        })}
      </Wrapper>
    </>
  );
}
