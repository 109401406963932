import styled from "styled-components";
import { gridColumnGap, space } from "styled-system";
import device from "../../styles/device";

export const PositionTitle = styled.h2`
  font-family: ${(props) => props.theme.family.bold};
  letter-spacing: normal;
  color: #444444;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  ${space}

  @media ${device.mobileM} {
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 12px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
`;

export const SmallBox = styled.div`
  border-radius: 5px;
  border: solid 1px #dddddd;
  font-size: 11px;
  font-weight: 500;
  color: #444444;
  padding: 3px 8px 2px;
  margin: 4px 15px 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${space}
`;

export const CompanyName = styled.span`
  text-align: left;
  width: auto;
  font-size: 14px;
  color: #444444;
  max-width: 110px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 16px;
  ${space};
`;

export const Container = styled.div`
  padding-top: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
  ${gridColumnGap};
  @media ${device.tablet} {
    flex-direction: row;
  }
  > label {
    width: 100%;
    font-family: ${(props) => props.theme.family.bold};
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: #444444;

    @media ${device.tablet} {
      width: 40%;
    }
    @media ${device.laptop} {
      width: 30%;
    }
  }
  > span {
    width: 100%;
    font-family: ${(props) => props.theme.family.medium};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.79;
    color: #444444;

    @media ${device.tablet} {
      width: 60%;
    }
    @media ${device.laptop} {
      width: 70%;
    }
  }
`;

// todo: Alert'e donusturulecek. Alert.js > params: danger || warning || info
export const ErrorText = styled.div`
  color: #ffffff;
  font-size: 14px;
  background-color: #ff7675;
  padding: 15px;
  border-radius: 5px;
  font-family: ${(props) => props.theme.family.demi};
  margin: 20px 0;
`;

export const IconWrap = styled.span`
  position: relative;
  ${space}
  ${({ top }) => (top ? `top: ${top}` : "")};
  > svg {
    transform: ${({ rotate }) => (rotate ? "rotate(180deg)" : "none")};
  }
`;

export const Text = styled.span`
  white-space: break-spaces;
  font-family: ${(props) => props.theme.family.medium};
  > strong {
    font-family: ${(props) => props.theme.family.bold};
  }
`;

export const ItemText = styled.span`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #444444;
`;
