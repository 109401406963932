import React from "react";
import { GroupTitle, TagText } from "./CommonStyles";
import { RowBlock } from "../../BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";

export const OtherSkillsFromImport = ({ state, handleStateChange }) => {
  if (state?.length === 0) return null;

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Skills</GroupTitle>
      <RowBlock justify="flex-start" gridGap="10px">
        {state.map((item, index) => {
          return (
            <TagText
              isChecked={state[index]?.isChecked}
              onClick={() => {
                handleStateChange("otherSkills", index);
              }}
              key={index}
            >
              <span>{item.title}</span>
              <span style={{ paddingTop: "2px" }}>
                {item.isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
              </span>
            </TagText>
          );
        })}
      </RowBlock>
    </div>
  );
};
