import React from "react";
import styled from "styled-components";
import flatten from "lodash.flatten";
import DataGroup from "../Modules/DataGroup";
import { Container, Row, Text } from "../Match/CommonModule";
import { getPositionLocationFromCompanyLocations } from "../../helper";
import SkillDonut from "../Modules/SkillDonut";
import { FlagBlock } from "../Form/PhoneField";
import { Label } from "../FormUi";

const TechnologyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 35px;
  background: #f1fbf5;
  border: 2px solid #abe6c1;
  border-radius: 30px;
  padding: 10px;
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #444444;
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  margin-right: 5px;
  height: 35px;
  border: 2px solid #0c084c;
  border-radius: 30px;
  padding: 10px;
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #444444;
  margin-bottom: 5px;
`;

const positionData = [
  {
    name: "otherExpected",
    section: "criteria",
    subsequent: "technologies",
    key: "otherExpected",
    type: "list",
  },
  {
    name: "plus",
    section: "criteria",
    subsequent: "technologies",
    key: "plus",
    type: "list",
  },
];

const positionLabel = {
  otherExpected: "Other Required Skills",
  essential: "Required Skills",
  plus: "Nice to Have Skills",
};

const populateTechnologySkills = (technologySkillArrays) => {
  /*
  Bu fonksiyon company tarafından seçilmiş teknolojilerin AND ve OR farkından kaynaklanabilecek
  teknoloji duplication'larını önlemek amacıyla yazılmıştır. Eğer tekrar eden teknolojiler varsa
  arasından deneyim yılı en yüksek olanı seçer ve kullanıcıya gösterir.
 */

  const flattenArr = flatten(technologySkillArrays);

  return flattenArr
    .sort((a, b) => b.experience - a.experience)
    .filter(
      (item, idx) =>
        flattenArr.findIndex((fx) => item.title === fx.title) === idx
    );
};

export default function PositionRequirements({ publicPosition, isEmpty }) {
  const skills = populateTechnologySkills(
    publicPosition.criteria.technologies.expected
  );

  const regulatedData = positionData.reduce((acc, val) => {
    if (val.type === "list" && val.key === "plus") {
      acc[val.name] = publicPosition[val.section][val.subsequent][
        val.key
      ].reduce((accumulator, curVal) => {
        if (curVal) {
          return accumulator.concat(curVal.title || curVal.label);
        }
        return accumulator;
      }, []);
    } else if (val.type === "list" && val.key === "otherExpected") {
      acc[val.name] = populateTechnologySkills(
        publicPosition.criteria.technologies.otherExpected
      ).reduce((accumulator, curVal) => {
        if (curVal) {
          return accumulator.concat(curVal.title || curVal.label);
        }
        return accumulator;
      }, []);
    } else if (val.type === "remote") {
      acc[val.name] =
        publicPosition[val.section][val.subsequent][val.key] === "full"
          ? ["%100 Remote"]
          : [
              getPositionLocationFromCompanyLocations({
                positionLocation:
                  publicPosition[val.section][val.subsequent].expected,
                locations: publicPosition.company.locations,
              }),
            ];
    } else {
      acc[val.name] = [publicPosition[val.section]];
    }
    return acc;
  }, {});

  // TODO: filter empty keys
  const items = Object.keys(positionLabel).map((key) => (
    <div key={key}>
      {regulatedData[key] && regulatedData[key].length > 0 && (
        <Row gridColumnGap="30px">
          <label htmlFor={positionLabel[key]}>{positionLabel[key]}</label>

          {positionLabel[key] !== positionLabel.otherExpected &&
          positionLabel[key] !== positionLabel.plus ? (
            <Text
              dangerouslySetInnerHTML={{
                __html: regulatedData[key].join(", "),
              }}
            />
          ) : (
            <TechnologyContainer>
              {regulatedData[key].map((skill) => {
                return <Box key={skill}>{skill}</Box>;
              })}
            </TechnologyContainer>
          )}
        </Row>
      )}
    </div>
  ));

  return (
    <DataGroup title="Details and requirements">
      {!isEmpty && (
        <Container>
          <Row gridColumnGap="30px">
            <Label>Required Skills</Label>
            <div style={{ height: skills.length > 0 ? "300px" : "auto" }}>
              {skills.length > 0 ? <SkillDonut skills={skills} /> : "-"}
            </div>
          </Row>
          {items}

          {publicPosition.criteria.languages &&
            publicPosition.criteria.languages.length > 0 && (
              <Row gridColumnGap="30px">
                <Label>Required languages</Label>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {publicPosition.criteria.languages.map((positionLanguage) => {
                    return (
                      <div key={positionLanguage.language.code}>
                        {positionLanguage.language?.code &&
                          positionLanguage.language?.label && (
                            <Tag>
                              <FlagBlock
                                style={{ marginBottom: "3px" }}
                                display={{ tablet: "inline-table" }}
                                className={`flag ${positionLanguage.language.code.toLowerCase()}`}
                              />

                              {positionLanguage.language.label}
                            </Tag>
                          )}
                      </div>
                    );
                  })}
                </div>
              </Row>
            )}
        </Container>
      )}
    </DataGroup>
  );
}
