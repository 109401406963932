import React, { useState } from "react";
import styled from "styled-components";
import { space } from "styled-system";

const ReadMoreLink = styled.a`
  font-size: 14px;
  font-family: ${(props) => props.theme.family.regular};
  color: #aaaaaa;
  margin-left: 5px;
  line-height: 25px;
  cursor: pointer;
  ${space}
`;

export default function ReadMore({ text, limit }) {
  const hasOverflow = text && text.length > limit;
  let preText = text;
  if (hasOverflow) {
    const little = text.substr(0, limit);
    preText = `${little.substr(0, little.lastIndexOf(" "))}...`;
  }
  const [showAll, setShowAll] = useState(preText.length === text.length);

  return (
    <span>
      {!showAll && (
        <span>
          {preText}
          {hasOverflow && (
            <ReadMoreLink onClick={() => setShowAll(true)}>
              Read more
            </ReadMoreLink>
          )}
        </span>
      )}
      {showAll && (
        <span>
          {text}
          {hasOverflow && (
            <ReadMoreLink onClick={() => setShowAll(false)}>
              Read less
            </ReadMoreLink>
          )}
        </span>
      )}
    </span>
  );
}
