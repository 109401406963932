import React, { useCallback } from "react";
import ReactSelect from "react-select";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import { space } from "styled-system";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { selectStyles, creatableSelectStyles } from "./SelectStyles";
import { Label, IsRequired } from "../../FormUi";
import { ColumnBlock } from "../../BlockUi";
import { debounce } from "../../../helper";
import message from "../../../utils/message";

const StyledSelect = styled(ReactSelect)`
  ${space}
`;

const StyledCreatableSelect = styled(CreatableSelect)`
  ${space}
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  ${space}
`;

const StyledAsyncCreatableSelect = styled(AsyncCreatableSelect)`
  ${space}
`;

/**
 * Şehir arama componentinde kullanılıyor.
 */
const GET_CITIES = gql`
  query allPlaces($search: String, $showCountries: Boolean) {
    allPlaces(search: $search, showCountries: $showCountries) {
      places {
        id
        city
        country
      }
      totalPages
      currentPage
      totalCount
    }
  }
`;

export const SelectField = ({
  isRequired,
  textAlign,
  valueAlign,
  valuePadding,
  optionStyle,
  justifyLabel,
  menuListStyle,
  selectedBgColorful,
  ...props
}) => {
  const { label, width } = props;

  return (
    <ColumnBlock width={width}>
      {label && (
        <Label
          direction={isRequired && "row"}
          justify={isRequired && (justifyLabel || "center")}
          mb={1}
        >
          {label}
          {isRequired && <IsRequired />}
        </Label>
      )}
      <StyledSelect
        {...props}
        styles={selectStyles({
          textAlign,
          valueAlign,
          valuePadding,
          optionStyle,
          selectedBgColorful,
          menuListStyle,
        })}
      />
    </ColumnBlock>
  );
};

export const AsyncSelectField = ({
  isRequired,
  textAlign,
  valueAlign,
  optionStyle,
  selectedBgColorful,
  ...props
}) => {
  const { label, width } = props;

  return (
    <ColumnBlock width={width}>
      {label && (
        <Label direction={isRequired && "row"} mb={1}>
          {label}
          {isRequired && <IsRequired />}
        </Label>
      )}
      <StyledAsyncSelect
        {...props}
        styles={selectStyles({
          textAlign,
          valueAlign,
          optionStyle,
          selectedBgColorful,
        })}
      />
    </ColumnBlock>
  );
};

export const CreatableSelectField = ({
  selectedBgColorful,
  optionStyle,
  valueAlign,
  width,
  onChange,
  ...props
}) => {
  return (
    <ColumnBlock width={width}>
      <StyledCreatableSelect
        {...props}
        onChange={onChange}
        styles={creatableSelectStyles({
          selectedBgColorful,
          optionStyle,
          valueAlign,
        })}
        classNamePrefix="tg-select"
      />
    </ColumnBlock>
  );
};

export const AsyncCreatableSelectField = ({
  selectedBgColorful,
  optionStyle,
  valueAlign,
  onChange,
  width,
  ...props
}) => {
  return (
    <ColumnBlock width={width}>
      <StyledAsyncCreatableSelect
        {...props}
        onChange={onChange}
        styles={creatableSelectStyles({
          selectedBgColorful,
          optionStyle,
          valueAlign,
        })}
        classNamePrefix="tg-select"
      />
    </ColumnBlock>
  );
};

const getCityLabel = (option, countryPrefix) => {
  if (option.city === option.country) {
    return `${countryPrefix} ${option.city}`.trim();
  }
  return `${option.city}, ${option.country}`;
};

export const CitySelectField = ({
  textAlign,
  valueAlign,
  valuePadding,
  optionStyle,
  selectedBgColorful,
  onChange,
  defaultValue,
  isRequired,
  value,
  showCountries,
  countryPrefix = "",
  hasError,
  ...props
}) => {
  const { label, width } = props;
  const { refetch: searchCities } = useQuery(GET_CITIES, {
    skip: true,
  });

  const handleSelect = (values) => {
    onChange(values);
  };

  const loadOptions = useCallback(
    debounce(async (search, callback) => {
      try {
        const { data } = await searchCities({
          search,
          showCountries,
        });
        callback(data.allPlaces.places);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  return (
    <ColumnBlock width={width}>
      {label && (
        <Label
          direction={isRequired && "row"}
          justify={isRequired && "center"}
          mb={1}
        >
          {label}
          {isRequired && <IsRequired />}
        </Label>
      )}
      <StyledAsyncSelect
        {...props}
        styles={selectStyles({
          textAlign,
          valueAlign,
          valuePadding,
          optionStyle,
          selectedBgColorful,
          hasError,
        })}
        getOptionLabel={(option) => getCityLabel(option, countryPrefix)}
        getOptionValue={(option) => `${option.id}`}
        placeholder={props.placeholder || "Type something to search"}
        defaultValue={defaultValue}
        defaultOptions={[]}
        value={value}
        loadOptions={loadOptions}
        onChange={handleSelect}
      />
    </ColumnBlock>
  );
};
