import React, { useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { LanguageLevelSelectField } from "tg-design";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import { AsyncSelectField } from "../../Form/SelectField";
import { UPDATE_USER, ALL_LANGUAGES } from "./queries";
import { languageSchema } from "./EducationValidationSchema";

import {
  debounce,
  turkishFilterOption,
  captureErrorWithData,
  getLanguageLevelType,
} from "../../../helper";
import IntercomLauncherMessage from "../../Modules/IntercomLauncherMessage";
import { Button } from "../../FormUi";
import { FormTitle, FormErrorMessages } from "../CommonStyles";
import message from "../../../utils/message";

export const EMPTY_LANGUAGE_FIELDS = {
  language: {
    label: "",
    id: null,
  },
  level: 0,
};

export default function Language({
  languages = [],
  selectedLanguage,
  handleClose,
  onUpdate,
}) {
  if (!languages) {
    languages = [];
  }

  const newLanguage = JSON.parse(JSON.stringify(selectedLanguage.language));

  const [languageState, setLanguageState] = useState(newLanguage);

  const [state, setState] = useState(languages);

  const [savingStatus, setSavingStatus] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER);

  const insertNewLanguage = () => {
    const newState = state.slice();
    newState.push(selectedLanguage.language);
    selectedLanguage.index = state.length;
    setState(newState);
  };

  const { refetch: languageRefetch } = useQuery(ALL_LANGUAGES, {
    skip: true,
  });

  const handleLanguageSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await languageRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        const _languages = data.allLanguages.languages.map((language) => {
          return {
            value: language?.id,
            label: language?.label,
          };
        });
        const sortedlanguages = _languages.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        callback(sortedlanguages);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleOptionChange = ({ value, type, label }) => {
    let _value = value;

    if (type === "language") {
      _value = {
        label,
        id: value,
      };
    }

    const newList = {
      ...languageState,
      [type]: _value,
    };

    const newState = state.slice();
    newState[selectedLanguage.index] = newList;

    setLanguageState(newList);
    setState(newState);
  };

  const handleSubmit = async () => {
    const { index } = selectedLanguage;

    const nativeLanguageCount = languages.filter(
      (item) => item?.level === 7
    ).length;

    if (
      nativeLanguageCount === 1 &&
      selectedLanguage?.language?.level === 7 &&
      state[index]?.level !== 7
    ) {
      return message.error("You should speak at least one native language.");
    }

    const validationVariables = [
      {
        language: state[index]?.language?.id,
        level: state[index]?.level,
      },
    ];

    const variables = state.map((item) => {
      return {
        language: item.language?.id,
        level: item.level,
      };
    });

    setSavingStatus(true);

    try {
      await languageSchema.validate({ languages: validationVariables });
      await updateUser({ variables: { languages: variables } });
      onUpdate({ user: new Date() });
      setSavingStatus(false);
      handleClose(state);
    } catch (error) {
      if (error?.name === "ValidationError") return;
      captureErrorWithData(error);
    }
  };

  const LanguageRow = (initialFields, index) => {
    return (
      <>
        <FormTitle>
          {initialFields.__typename
            ? "Edit language info"
            : "Add new language info"}
        </FormTitle>
        <RowBlock mb={3} key={index}>
          <RowBlock
            pt={3}
            pb={5}
            justify="flex-start"
            width={{ mobileS: "95%", tablet: "95%", laptop: "100%" }}
          >
            <ColumnBlock width={{ mobileS: "100%", tablet: "70%" }} flex="1">
              <RowBlock my={3} justify="start">
                <AsyncSelectField
                  label="Language"
                  placeholder=""
                  filterOption={turkishFilterOption}
                  value={{
                    label: initialFields?.language?.label,
                  }}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? (
                      <IntercomLauncherMessage />
                    ) : (
                      "Type something to search"
                    )
                  }
                  loadOptions={handleLanguageSearch}
                  onChange={(selectedOption) =>
                    handleOptionChange({
                      ...selectedOption,
                      type: "language",
                      index,
                    })
                  }
                  width="100%"
                />
                {savingStatus && !languageState.language.label && (
                  <FormErrorMessages>Language is required.</FormErrorMessages>
                )}
              </RowBlock>
              <RowBlock my={3} justify="start">
                <LanguageLevelSelectField
                  label={<b>Level</b>}
                  labelStyle={{ fontSize: "14px" }}
                  placeholder=""
                  containerStyle={{ width: "100%" }}
                  value={getLanguageLevelType(initialFields) || null}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? "No results found" : "Type something to search"
                  }
                  isSearchable={false}
                  handleOnChange={({ selectedOption }) =>
                    handleOptionChange({
                      ...selectedOption,
                      type: "level",
                      index,
                    })
                  }
                  selectedBgColorful={false}
                  bgColor="#f5f6f8"
                  controlStyle={{
                    border: "2px  #abe6c1 solid",
                  }}
                  customStatusStyle={{ focused: "2px  #abe6c1  solid" }}
                />

                {savingStatus && !languageState.level && (
                  <FormErrorMessages>Level is required.</FormErrorMessages>
                )}
              </RowBlock>
              <RowBlock mt={4}>
                <Button onClick={() => handleSubmit()} width="100%">
                  Save
                </Button>
              </RowBlock>
            </ColumnBlock>
          </RowBlock>
        </RowBlock>
      </>
    );
  };

  if (selectedLanguage.index === null) {
    insertNewLanguage();
  }

  return <div>{LanguageRow(languageState)}</div>;
}
