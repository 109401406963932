import React, { useState } from "react";
import styled from "styled-components";
import { space, width } from "styled-system";
import { useMutation } from "@apollo/client";
import Switch from "../Form/ToggleSwitch";
import { SET_MATCH_SETTINGS } from "./queries";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  ${space}
`;

const ToggleWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: solid 1px #eeeeee;
  min-width: 100%;
  ${space}
`;

const Paragraph = styled.p`
  font-family: ${(props) => props.theme.family.demi};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #444444;
  margin-bottom: 15px;
  ${space}
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  display: flex;
  justify-content: space-between;
  ${space}
  ${width}
  max-width: 70%;
  font-size: 18px;
  cursor: pointer;

  color: ${(props) =>
    props.active ? props.theme.colors.main[0] : props.theme.colors.font[1]};
`;

const Toggle = ({ label, active, handleToggle, name }) => {
  const handleOnToggle = (e) => {
    e.preventDefault();
    handleToggle(active);
  };
  return (
    <ToggleWrapper onClick={handleOnToggle}>
      <Title active={active} pb={{ mobileS: 3, laptop: 4, tablet: 4 }}>
        {label}
      </Title>
      <Switch variant="passive" active={active} value={active} name={name} />
    </ToggleWrapper>
  );
};

export default function MatchSettings({ user: { matchSettings }, refetch }) {
  const [state, setState] = useState({ available: matchSettings.available });
  const [setMatchSettings] = useMutation(SET_MATCH_SETTINGS);

  const handleToggle = async (value) => {
    setState({ ...state, available: value });
    await setMatchSettings({
      variables: {
        available: value,
      },
    });
    refetch();
  };

  return (
    <Wrapper px={{ desktop: "60px" }}>
      <Toggle
        handleToggle={handleToggle}
        active={!state.available || false}
        label="I want to temporarily stop receiving new matches"
        name="available"
      />
      <Paragraph mt={4}>
        If you enable this, we will <u>not match</u> you with new roles. <br />{" "}
        <br /> You will therefore not receive any notification e-mails about new
        matches.
      </Paragraph>
      <Paragraph>This temporary halt will be valid for 90 days.</Paragraph>
    </Wrapper>
  );
}
