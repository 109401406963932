import React, { useState } from "react";
import * as Yup from "yup";
import {
  StepCompany,
  StepStartDate,
  StepEndDate,
} from "./Steps/WorkHistorySteps";
import StepContainer from "./StepContainer";
import {
  setFullDate,
  EMPTY_WORK_HISTORY_FIELDS,
} from "../../../ProfileEdit/WorkHistory/WorkHistoryForm";

const STEP_STRUCTURE = [
  {
    component: StepCompany,
    validation: Yup.object().shape({
      company: Yup.string().required(""),
      position: Yup.string().required(""),
    }),
  },
  {
    component: StepStartDate,
    validation: Yup.object().shape({
      startMonth: Yup.number().required(""),
      startYear: Yup.number().required(""),
    }),
  },
  {
    component: StepEndDate,
    validation: Yup.object().shape({
      present: Yup.boolean().required(""),
      endDate: Yup.mixed()
        .nullable("")
        .when("present", (present, schema) => {
          if (!present) {
            return schema.required("");
          }
          return schema.nullable("");
        }),
    }),
  },
];

export default function QuickFillWorkHistory({ handleSubmit, next }) {
  const [workHistory, setWorkHistory] = useState(EMPTY_WORK_HISTORY_FIELDS);

  const changeHook = (updateData, data) => {
    if (["startMonth", "startYear"].includes(data.type)) {
      setFullDate(updateData, "start");
    }
    if (["endMonth", "endYear"].includes(data.type)) {
      setFullDate(updateData, "end");
    }

    if (data.type === "present" && data.value === false) {
      updateData.endMonth = null;
      updateData.endYear = null;
      updateData.endDate = null;
    }
  };

  return (
    <>
      <StepContainer
        title="Add working experience"
        state={workHistory}
        setState={setWorkHistory}
        steps={STEP_STRUCTURE}
        nextQuickBox={next}
        handleSubmit={(event) => handleSubmit(event, { workHistory })}
        changeHook={changeHook}
        showSkipButton
      />
    </>
  );
}
