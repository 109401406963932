import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { display, space } from "styled-system";
import { Label, Input } from "../../FormUi";
import { SelectField } from "../SelectField";
import { COUNTRIES } from "../../../utils/constants";
import "../../../styles/components/phoneField.css";

export const RowBlock = styled.div`
  display: flex;
`;

export const FlagBlock = styled.div`
  display: inline-table;
  ${display};
`;

export const DialCodeBlock = styled.div`
  text-align: left;
  ${space}
`;

const FlagOption = (props, data) => {
  return (
    <div style={{ display: "flex" }}>
      <FlagBlock
        display={{ mobileS: "none", tablet: "inline-table" }}
        className={`flag ${props.code.toLowerCase()}`}
      />
      <DialCodeBlock pl={{ mobileS: 0, table: 5 }}>
        {data.context === "menu" ? props.name : ""}
        <span style={{ paddingLeft: 5 }}>{props.dial_code}</span>
      </DialCodeBlock>
    </div>
  );
};

const PhoneField = ({ ...props }) => {
  const { error, defaultValue, onChange, livingCity, label } = props;
  const defaultState = {
    isMenuOpen: false,
    dial_code: COUNTRIES.find((country) => country.code === "TR"),
    number: "",
  };

  if (defaultValue && defaultValue.length > 0 && defaultValue[0]) {
    const value = defaultValue[0];

    if (value.indexOf("+") === 0) {
      defaultState.dial_code = COUNTRIES.find(
        (country) => value.indexOf(country.dial_code.replace(/ /g, "")) === 0
      );
      defaultState.number = value.replace(
        defaultState.dial_code.dial_code.replace(/ /g, ""),
        ""
      );
    }
  } else if (livingCity) {
    defaultState.dial_code = COUNTRIES.find(
      (country) => country.name === livingCity.country
    );
  }

  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (state.number === null || state.number === "") {
      const dial_code = COUNTRIES.find(
        (country) => country.name === livingCity?.country
      );
      if (dial_code) {
        setState({
          ...state,
          dial_code,
        });
      }
    }
    // eslint-disable-next-line
  }, [livingCity?.country]);

  const handleChange = (event) => {
    let fullPhone = state.dial_code.dial_code + state.number;
    if (event.target) {
      fullPhone = state.dial_code.dial_code + event.target.value;
      setState({
        ...state,
        isMenuOpen: false,
        number: event.target.value,
      });
    } else {
      fullPhone =
        COUNTRIES.find((country) => country.code === event.code).dial_code +
        state.number;
      setState({
        ...state,
        isMenuOpen: false,
        dial_code: COUNTRIES.find((country) => country.code === event.code),
      });
    }

    onChange(fullPhone.replace(/ /g, ""));
  };

  const onMenuStatusChange = (status) => {
    setState({
      ...state,
      isMenuOpen: status,
    });
  };

  return (
    <>
      <div>
        {label && <Label mb={1}>{label}</Label>}
        <RowBlock>
          <SelectField
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.dial_code}
            formatOptionLabel={FlagOption}
            onMenuOpen={() => onMenuStatusChange(true)}
            onMenuClose={() => onMenuStatusChange(false)}
            placeholder="Ülke"
            value={state.dial_code}
            options={COUNTRIES}
            width={state.isMenuOpen ? "400px" : "200px"}
            space="10px"
            onChange={handleChange}
          />
          <Input
            value={state.number}
            style={{ marginLeft: 10, textAlign: "left", width: "100%" }}
            maxLength="15"
            onChange={handleChange}
          />
        </RowBlock>
        {error ? (
          <Label my={2} hasError={error}>
            {error}
          </Label>
        ) : null}
      </div>
    </>
  );
};

export default PhoneField;
