import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const isAuth = localStorage.getItem("isAuth");

  const handleRoute = (props) => {
    if (isAuth && restricted) {
      return <Redirect to="/profile" />;
    }

    if (!isAuth) {
      localStorage.removeItem("isAuth");
      return <Component name="login" />;
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={(props) => handleRoute(props)} />;
};

export default PublicRoute;
