import React from "react";
import styled from "styled-components";

const ErrorText = styled.div`
  color: #ff7675;
  font-size: 11px;
  position: ${(props) => props.position || "absolute"};
  margin-left: 5px;
  bottom: -20px;

  @media only screen and (max-width: 768px) {
    bottom: -25px;
  }
`;

export function ErrorField({ position, errorText }) {
  return <ErrorText position={position}>{errorText}</ErrorText>;
}
