import React from "react";
import styled from "styled-components";
import { space } from "styled-system";
import { ColumnBlock, RowBlock } from "../BlockUi";
import device from "../../styles/device";
import {
  GithubSocialIcon,
  HomeSocialIcon,
  LinkedinSocialIcon,
  TwitterSocialIcon,
  YoutubeSocialIcon,
} from "../Icon";

const Wrapper = styled.div`
  ${space}
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  ${space};
`;

const Logo = styled.div`
  ${space}
  @media ${device.tablet} {
    width: 220px;
    height: 126px;
  }
  @media ${device.mobileM} {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 18px;
  font-weight: 600;
  color: #0c084c;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    margin: 20px auto;
  }
`;

const SectionTitle = styled.div`
  font-family: ${(props) => props.theme.family.demi};
  font-size: 12px;
  font-weight: 500;
  color: #444444;
  background: "#fff";
  margin-top: 10px;
`;

export default function Social() {
  return (
    <Wrapper>
      <RowBlock justify="flex-start">
        <Title>Find us elsewhere</Title>
      </RowBlock>
      <Container>
        <RowBlock
          justify="flex-start"
          align="flex-start"
          gridGap={{ mobileL: "30px", tablet: "60px" }}
        >
          <ColumnBlock
            width={{ mobileS: "100%", laptop: "220px" }}
            height={{ mobileS: "200px", laptop: "152px" }}
            pb={{ mobileS: 3, laptop: 0 }}
            justify="center"
            align="center"
            onClick={() =>
              window.open(
                "https://www.talentgrid.io",
                "_blank",
                "noopener",
                "noreferrer"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <RowBlock
              background="#0C084C"
              width="100%"
              height="100%"
              align="center"
              borderRadius="5px"
            >
              <span>
                <Logo>
                  <HomeSocialIcon />
                </Logo>
              </span>
            </RowBlock>
            <SectionTitle>TalentGrid Website</SectionTitle>
          </ColumnBlock>
          <ColumnBlock
            width={{ mobileS: "100%", laptop: "220px" }}
            height={{ mobileS: "200px", laptop: "152px" }}
            pb={{ mobileS: 3, laptop: 0 }}
            align="center"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/talentgrid-io/",
                "_blank",
                "noopener",
                "noreferrer"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <RowBlock
              background="#0E76A8"
              width="100%"
              height="100%"
              borderRadius="5px"
              align="center"
              justify="center"
            >
              <span>
                <Logo>
                  <LinkedinSocialIcon />
                </Logo>
              </span>
            </RowBlock>
            <SectionTitle>LinkedIn</SectionTitle>
          </ColumnBlock>
          <ColumnBlock
            width={{ mobileS: "100%", laptop: "220px" }}
            height={{ mobileS: "200px", laptop: "152px" }}
            pb={{ mobileS: 3, laptop: 0 }}
            justify="center"
            align="center"
            onClick={() =>
              window.open(
                "https://twitter.com/TalentGrid_io",
                "_blank",
                "noopener",
                "noreferrer"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <RowBlock
              background="#1DA1F2"
              width="100%"
              height="100%"
              borderRadius="5px"
              align="center"
            >
              <span>
                <Logo>
                  <TwitterSocialIcon />
                </Logo>
              </span>
            </RowBlock>
            <SectionTitle>Twitter</SectionTitle>
          </ColumnBlock>
          <ColumnBlock
            width={{ mobileS: "100%", laptop: "220px" }}
            height={{ mobileS: "200px", laptop: "152px" }}
            pb={{ mobileS: 3, laptop: 0 }}
            justify="center"
            align="center"
            onClick={() =>
              window.open(
                "https://github.com/TalentGrid-io",
                "_blank",
                "noopener",
                "noreferrer"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <RowBlock
              background="#333333"
              width="100%"
              height="100%"
              borderRadius="5px"
              align="center"
            >
              <span>
                <Logo>
                  <GithubSocialIcon />
                </Logo>
              </span>
            </RowBlock>
            <SectionTitle>Github</SectionTitle>
          </ColumnBlock>
          <ColumnBlock
            width={{ mobileS: "100%", laptop: "220px" }}
            height={{ mobileS: "200px", laptop: "152px" }}
            pb={{ mobileS: 3, laptop: 0 }}
            justify="center"
            align="center"
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UC--pIg7HSqDQs2aTxsVlO7g",
                "_blank",
                "noopener",
                "noreferrer"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <RowBlock
              background="#FF0000"
              width="100%"
              height="100%"
              borderRadius="5px"
              align="center"
            >
              <span>
                <Logo>
                  <YoutubeSocialIcon />
                </Logo>
              </span>
            </RowBlock>
            <SectionTitle>YouTube</SectionTitle>
          </ColumnBlock>
        </RowBlock>
      </Container>
    </Wrapper>
  );
}
