import React, { useState } from "react";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { RowBlock, ColumnBlock } from "../BlockUi";
import { Button } from "../Button";
import { PlusIconSimple } from "../Icon";
import InviteList from "./InviteList";
import TextField from "../Form/TextField";
import {
  Container,
  Box,
  BoxTitle,
  BoxSubTitle,
  MoneyBox,
  Label,
} from "./CommonStyles";
import HowItWorks from "./HowItWorks";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import ShareOnSocialMediaField from "./ShareOnSocialMediaField";
import message from "../../utils/message";
import { SEND_INVITATION_LINK_TO_EMAIL_ADDRESS } from "./queries";
import { captureErrorWithData } from "../../helper";

const emailValidationSchema = yup.object().shape({
  email: yup.string().email("Not a proper e-mail"),
});

export default function InvitePage({ user, refetch }) {
  const [email, setEmail] = useState(null);
  const [sendInvitationMail] = useMutation(
    SEND_INVITATION_LINK_TO_EMAIL_ADDRESS
  );
  const dummyInviteData = [
    {
      name: "Name Surname",
      criteria: { createdAt: "2020-01-02" },
      createdAt: "2020-01-01",
    },
    {
      name: "Name Surname",
      criteria: null,
      createdAt: "2020-01-03",
    },
  ];

  const inviteLink = `${process.env.REACT_APP_URL}/register?invite=${user?.inviteToken}`;

  const handleOnchange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (!email) {
      return;
    }
    try {
      await emailValidationSchema.validate({ email });
      await sendInvitationMail({ variables: { inviteeEmail: email } });
      await refetch();
      message.success("Invitation mail sent to your friend!");
    } catch (err) {
      if (err.name === "ValidationError") {
        message.error(err.message);
      } else if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        err.graphQLErrors.map((errorMessage) =>
          message.error(errorMessage.message)
        );
      } else {
        captureErrorWithData(err);
      }
    }
  };

  return (
    <>
      <Box background py={4} px={{ mobileS: "20px" }}>
        <Container>
          <BoxTitle>Invite friends, earn cash rewards together.</BoxTitle>
          <BoxSubTitle mt={4}>
            When someone you invited starts their new job via TalentGrid, we
            pay:
          </BoxSubTitle>
          <RowBlock mt={3} mb={4}>
            <ColumnBlock
              width={{ mobileS: "107px", tablet: "140px" }}
              alignItems="center"
              justifyContent="flex-start"
            >
              <MoneyBox width={{ mobileS: "100px", tablet: "156px" }}>
                $250
              </MoneyBox>
              <BoxSubTitle mt={2}>you get</BoxSubTitle>
            </ColumnBlock>
            <ColumnBlock justifyContent="flex-start" mt={3}>
              <PlusIconSimple />
            </ColumnBlock>
            <ColumnBlock
              width={{ mobileS: "107px", tablet: "140px" }}
              alignItems="center"
              justifyContent="flex-start"
            >
              <MoneyBox width={{ mobileS: "100px", tablet: "156px" }}>
                $250
              </MoneyBox>
              <BoxSubTitle mt={2}>to your friend.</BoxSubTitle>
            </ColumnBlock>
          </RowBlock>
        </Container>
        <Container>
          <RowBlock
            mt={5}
            justifyContent={{ mobileS: "center", tablet: "flex-start" }}
          >
            <Label>Send invites via email</Label>
          </RowBlock>
          <RowBlock
            mt={2}
            justifyContent={{
              mobileS: "center",
              tablet: "flex-start",
            }}
            align="baseline"
          >
            <TextField
              textAlign="left"
              mt="-3px"
              placeholder="Enter email address"
              onChange={(e) => handleOnchange(e)}
            />
            <Button
              ml={{ mobileS: 1, tablet: 3 }}
              mr={{ mobileS: 1 }}
              mb={{ mobileS: 0, tablet: 3 }}
              my={{ mobileS: 1, tablet: 0 }}
              variant="primary"
              size={{ mobileS: "large", tablet: "small" }}
              onClick={handleSubmit}
            >
              Invite
            </Button>
          </RowBlock>
          <RowBlock>
            <ShareOnSocialMediaField inviteLink={inviteLink} user={user} />
          </RowBlock>
        </Container>
      </Box>
      <RowBlock mt={4} width="100%">
        <HowItWorks />
      </RowBlock>
      {user && (user.invites.length > 0 || user.pendingInvites.length > 0) ? (
        <RowBlock mt={4} width="100%">
          <InviteList
            invites={user.invites}
            pendingInvites={user.pendingInvites}
          />
        </RowBlock>
      ) : (
        <RowBlock mt={4} width="100%">
          <InviteList invites={dummyInviteData} noInvitation />
        </RowBlock>
      )}
      <RowBlock mt={4} width="100%">
        <FrequentlyAskedQuestions />
      </RowBlock>
    </>
  );
}
