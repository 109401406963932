import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { SingleHeader } from "../../components/Header";
import { RowBlock } from "../../components/BlockUi";
import { Button } from "../../components/Button";
import { useSearchParam } from "../../utils/hooks";
import { MATCH_STATE_CHANGE } from "./queries";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.p`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 48px;
  text-align: center;
`;

export default function MatchRedirectPage() {
  const [state, setState] = useState({ error: false, message: "Please wait" });
  const [changeMatchState] = useMutation(MATCH_STATE_CHANGE, {
    onError: (error) =>
      setState({ ...state, error: true, message: error.message }),
  });
  const history = useHistory();
  const matchParam = useSearchParam({ key: "match" });
  const actionParam = useSearchParam({ key: "action" });
  const tokenParam = useSearchParam({ key: "actionToken" });

  const variables = {
    match: matchParam.getValue(),
    action: actionParam.getValue(),
    token: tokenParam.getValue(),
  };
  useEffect(() => {
    changeMatchState({ variables }).then(({ data }) => {
      if (data && data.publicMatchStateChange) {
        history.push(`/matches/${variables.match}`);
      }
    });
    // eslint-disable-next-line
  }, [changeMatchState]);

  return (
    <>
      <SingleHeader to="/profile" />
      <Container>
        <Content>{state.message}</Content>
        <RowBlock gridGap="20px">
          {state.error && (
            <Button
              variant="transparent"
              size="large"
              onClick={() => history.push("/")}
            >
              Return Home
            </Button>
          )}
        </RowBlock>
      </Container>
    </>
  );
}
