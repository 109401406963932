import React from "react";
import styled from "styled-components";
import flatten from "lodash.flatten";
import { FlagBlock } from "tg-design";
import DataGroup from "../Modules/DataGroup";
import { Container, Row, Text } from "./CommonModule";
import { getPositionLocationFromCompanyLocations } from "../../helper";
import SkillDonut from "../Modules/SkillDonut";
import { ColumnBlock, RowBlock } from "../BlockUi";
import { SkillItemNumber, SkillItemRow } from "../Profile/CommonStyles";
import { Label } from "../FormUi";
import device from "../../styles/device";

const PositionDescription = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  padding-bottom: 40px;
  color: #444444;
  ul {
    margin-left: 3%;
  }
  ol {
    margin-left: 3%;
  }
  @media ${device.mobileMax} {
    ul {
      margin-left: 4%;
    }
    ol {
      margin-left: 4%;
    }
  }
`;
const boxStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "80px",
  marginRight: "5px",
  marginBottom: "5px",
  height: "35px",
  background: "#F1FBF5",
  border: "2px solid #ABE6C1",
  borderRadius: "30px",
  padding: "10px",
  fontSize: "14px",
  lineHeight: "19px",
  color: "#444444",
};

const ProgrammingLanguageLabel = styled.div`
  font-family: "Avenir Next LT Pro Bold", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 25px;
  margin-bottom: 20px;
  color: #444444;
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  margin-right: 5px;
  height: 35px;
  border: 2px solid #0c084c;
  border-radius: 30px;
  padding: 10px;
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #444444;
  margin-bottom: 5px;
`;

const positionData = [
  {
    name: "niceToHave",
    section: "criteria",
    subsequent: "technologies",
    key: "plus",
    type: "list",
  },
  {
    name: "otherExpected",
    section: "criteria",
    subsequent: "technologies",
    key: "otherExpected",
    type: "list",
  },
];

const positionLabel = {
  otherExpected: "Other required skills",
  essential: "Required skills",
  niceToHave: "Nice to have skills",
};

const populateTechnologySkills = (technologySkillArrays) => {
  /*
  Bu fonksiyon company tarafından seçilmiş teknolojilerin AND ve OR farkından kaynaklanabilecek
  teknoloji duplication'larını önlemek amacıyla yazılmıştır. Eğer tekrar eden teknolojiler varsa
  arasından deneyim yılı en yüksek olanı seçer ve kullanıcıya gösterir.
 */

  const flattenedArr = flatten(technologySkillArrays);

  return flattenedArr
    .sort((a, b) => b.experience - a.experience)
    .filter(
      (item, idx) =>
        flattenedArr.findIndex((fx) => item.title === fx.title) === idx
    );
};

export default function PositionInfo({ ...props }) {
  const {
    match: { position },
    isEmpty,
  } = props;

  const skills = populateTechnologySkills(
    position.criteria.technologies.expected
  );

  const regulatedData = positionData.reduce((acc, val) => {
    if (val.type === "list" && val.key === "plus") {
      acc[val.name] = position[val.section][val.subsequent][val.key]?.reduce(
        (accumulator, curVal) => {
          if (curVal) {
            return accumulator.concat(curVal.title || curVal.label);
          }
          return accumulator;
        },
        []
      );
    } else if (val.type === "list" && val.key === "otherExpected") {
      acc[val.name] = populateTechnologySkills(
        position.criteria.technologies.otherExpected
      ).reduce((accumulator, curVal) => {
        if (curVal) {
          return accumulator.concat(curVal.title || curVal.label);
        }
        return accumulator;
      }, []);
    } else if (val.type === "remote") {
      acc[val.name] =
        position[val.section][val.subsequent][val.key] === "full"
          ? ["100% remote"]
          : [
              getPositionLocationFromCompanyLocations({
                positionLocation:
                  position[val.section][val.subsequent].expected,
                locations: position.company.locations,
              }),
            ];
    } else {
      acc[val.name] = [position[val.section]];
    }
    return acc;
  }, {});
  // TODO: filter empty keys
  const items = Object.keys(positionLabel).map((key) => (
    <div key={key}>
      {regulatedData[key] && regulatedData[key].length > 0 && (
        <Row key={key} gridColumnGap="30px">
          <label htmlFor={positionLabel[key]}>{positionLabel[key]}</label>

          {positionLabel[key] === positionLabel.essential ? (
            <Text
              dangerouslySetInnerHTML={{
                __html: regulatedData[key].join(", "),
              }}
            />
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {regulatedData[key].map((skill, index) => {
                return (
                  <div key={index} style={boxStyle}>
                    {skill}
                  </div>
                );
              })}
            </div>
          )}
        </Row>
      )}
    </div>
  ));

  return (
    <>
      <DataGroup title="Details and requirements">
        {!isEmpty && (
          <Container>
            <PositionDescription
              dangerouslySetInnerHTML={{
                __html: position.description,
              }}
            />
            {skills.length > 0 && (
              <Row>
                <ColumnBlock
                  style={{ flex: "1 1 0" }}
                  width={{ mobileS: "100%", tablet: "60%" }}
                  justify="start"
                >
                  <ProgrammingLanguageLabel>
                    Required Skills
                  </ProgrammingLanguageLabel>
                  {skills.map((skill, index) => (
                    <SkillItemRow
                      width={{
                        mobileS: "100%",
                        tablet: "80%",
                        laptopL: "80%",
                      }}
                      key={index}
                      opacity={!skill.experience ? 0.3 : 1}
                    >
                      <ColumnBlock mr={4}>
                        <SkillItemNumber>{index + 1}</SkillItemNumber>
                      </ColumnBlock>
                      <ColumnBlock flex="1"> {skill.title}</ColumnBlock>
                      {skill.experience && (
                        <ColumnBlock>
                          {skill.experience < 8
                            ? `${skill.experience} year${
                                skill.experience === 1 ? "" : "s"
                              }`
                            : "8+ years"}
                        </ColumnBlock>
                      )}
                    </SkillItemRow>
                  ))}
                </ColumnBlock>
                <ColumnBlock
                  style={{ flex: "1.5 1 0" }}
                  width={{ mobileS: "100%", tablet: "40%" }}
                  alignItems={{ mobileL: "start" }}
                  mb={{ mobileS: 2 }}
                >
                  <SkillDonut skills={skills} />
                </ColumnBlock>
              </Row>
            )}
            {items}
            <Row gridColumnGap="30px">
              <Label>Required languages</Label>
              <RowBlock justify="start">
                {(position?.criteria?.languages || []).filter(
                  (l) => l?.language?.label && l?.language?.code
                ).length > 0
                  ? position.criteria.languages.map((language, index) => (
                      <Tag key={index}>
                        {language.language.code && (
                          <FlagBlock
                            style={{ marginBottom: "3px" }}
                            display={{
                              mobileS: "none",
                              tablet: "inline-table",
                            }}
                            className={`flag ${language.language.code.toLowerCase()}`}
                          />
                        )}
                        {language.language.label}
                      </Tag>
                    ))
                  : "-"}
              </RowBlock>
            </Row>
          </Container>
        )}
        {isEmpty && (
          <Container>
            <Row>
              <Text
                dangerouslySetInnerHTML={{
                  __html: position.description,
                }}
              />
            </Row>
          </Container>
        )}
      </DataGroup>
    </>
  );
}
