import gql from "graphql-tag";

export const SET_MATCH_SETTINGS = gql`
  mutation SetMatchSettings($available: Boolean!) {
    setMatchSettings(available: $available) {
      id
      matchSettings {
        available
      }
    }
  }
`;

export const GET_ACCOUNT_DELETE_REASON = gql`
  {
    allAccountDeleteReason {
      id
      label
    }
  }
`;

export const CREATE_DELETE_REASON = gql`
  mutation CreateAccountDeleteReason($label: String!) {
    createAccountDeleteReason(label: $label) {
      id
    }
  }
`;

export const DELETE_ME = gql`
  mutation deleteMe($deleteReason: ID!) {
    deleteMe(deleteReason: $deleteReason) {
      name
      status
      id
      deleteReason {
        reason {
          label
          id
        }
        active
      }
    }
  }
`;

export const LOGOUT = gql`
  query LOGOUT {
    logout
  }
`;
