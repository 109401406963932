import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import { space } from "styled-system";
import { PhoneField } from "tg-design";
import { Button } from "../Button";
import { basicInfoValidationSchema } from "../ProfileEdit/BasicInfos/validationSchema";
import message from "../../utils/message";
import DefaultAvatar from "../../images/default-avatar.png";

const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 33px;
  padding: 37px 45px 45px 45px;
  border-radius: 5px;
  box-shadow: 0 10px 13px 0 #cccccc;
  background-color: #ffffff;
  color: ${(props) => (props.success ? "#8fdeac" : "#aaa")};
  font-family: ${(props) => props.theme.family.bold};
  ${space}
`;

const Title = styled.h4`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #27282a;
  margin-bottom: 14px;
`;

const PartnerContainer = styled.div`
  display: flex;
  margin-bottom: 18px;
  align-items: center;
`;

const PartnerAvatar = styled.img`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-right: 15px;
`;

const PartnerDescription = styled.div`
  font-family: ${(props) => props.theme.family.regular};
  font-size: 14px;
  > strong {
    font-family: ${(props) => props.theme.family.bold};
    font-weight: bold;
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($phone: String) {
    updateUser(phone: $phone) {
      id
    }
  }
`;

export default function MatchDetailAction({ assignee, onSuccess }) {
  const [phone, setPhone] = useState("");
  const [updateUser] = useMutation(UPDATE_USER);

  const savePhoneNumber = () => {
    basicInfoValidationSchema.fields.phone
      .validate(phone)
      .then(async () => {
        await updateUser({
          variables: {
            phone,
          },
        });
        onSuccess();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onChangePhone = (value) => {
    setPhone(value);
  };

  return (
    <Container>
      <Title>
        Happy to see you opt-in! We may need to contact you about this role.
      </Title>
      {assignee && (
        <PartnerContainer>
          <PartnerAvatar
            src={assignee.avatarURL ? assignee.avatarURL : DefaultAvatar}
          />
          <PartnerDescription>
            After evaluating, your Talent Partner <b>{assignee.name}</b> might
            contact you.
          </PartnerDescription>
        </PartnerContainer>
      )}
      <PhoneField
        inputStyle={{
          background: "rgb(246,246,248)",
          "&:focus": {
            borderColor: "#abe6c1",
          },
        }}
        selectFieldStyle={{
          controlStyle: {
            background: "rgb(246,246,248)",
            border: "2px solid rgb(246,246,248) ",
          },
        }}
        customStatusStyle={{
          error: "2px solid red",
          focused: "2px solid #abe6c1",
        }}
        onChange={onChangePhone}
      />
      <Button
        variant="primary"
        size="medium"
        mt="22px"
        onClick={savePhoneNumber}
      >
        Save
      </Button>
    </Container>
  );
}
