import React from "react";
import {
  GroupTitle,
  PrimaryTitle,
  SecondaryTitle,
  DateText,
  SectionContainer,
} from "./CommonStyles";
import { ColumnBlock } from "../../BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";

export const EducationFromImport = ({ state, handleStateChange }) => {
  if (state?.length === 0) return null;

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Education</GroupTitle>
      {state.map((item, index) => {
        return (
          <ColumnBlock key={index} style={{ marginTop: "5px" }}>
            <SectionContainer
              isChecked={item.isChecked}
              onClick={() => handleStateChange("education", index)}
            >
              <div style={{ flex: "1" }}>
                <PrimaryTitle>{item?.school?.name}</PrimaryTitle>
                <SecondaryTitle>{item?.branch?.label}</SecondaryTitle>
                <DateText>
                  {item.startDate ? item.startDate : "?"} -{" "}
                  {item.endDate ? item.endDate : "?"}
                </DateText>
              </div>
              <div>
                {item.isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
              </div>
            </SectionContainer>
          </ColumnBlock>
        );
      })}
    </div>
  );
};
