/* eslint-disable no-return-assign */

import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import * as Yup from "yup";
import styled, { keyframes } from "styled-components";
import { BoxTitle, RowBlock, ColumnBlock } from "../../../components/BlockUi";
import { Button } from "../../../components/Button";
import TextField from "../../../components/Form/TextField";
import { Form, FormField } from "../../../components/FormUi";
import { CheckboxField } from "../../../components/Form/CheckboxField";
import PinStep from "./PinStep";
import { ArrowIcon } from "../../../components/Icon";
import {
  IconWrapper,
  Circle,
  CheckIcon,
  SuccessButton,
  BoxDescription,
} from "../CommonStyles";
import { captureErrorWithData } from "../../../helper";
import { PRIVACY_POLICY_TYPES } from "../../../utils/constants";

const CREATE_PIN = gql`
  mutation CreatePin($email: String!, $operation: String!) {
    createPin(email: $email, operation: $operation) {
      email
    }
  }
`;

const fadeInAnimation = keyframes`
  from{opacity: 0;
  height: 0px;}
  to{opacity: 1;
  height: 100px;}
`;
const AnimatedFormContainer = styled.div`
  animation: ${fadeInAnimation} 1.5s;
  animation-fill-mode: forwards;
`;

const validationSchema = Yup.object().shape({
  kvkkAccept: Yup.bool().oneOf([true], "Field must be checked"),
  country: Yup.string()
    .oneOf([PRIVACY_POLICY_TYPES.GLOBAL, PRIVACY_POLICY_TYPES.TR])
    .required(),
  localKvkkAccept: Yup.bool().when("country", (country, schema) => {
    if (country && country !== PRIVACY_POLICY_TYPES.GLOBAL) {
      return schema.required().oneOf([true]);
    }
    return schema;
  }),
  email: Yup.string().email().required("email gerekli"),
  pin: Yup.number().required("pin is gerekli"),
});

export default function EmailStep({ onSelect, nextStep, selectedValues }) {
  const [activeStep, setActiveStep] = useState(0);
  const [emailValid, setEmailValid] = useState(false);
  const [email, setEmail] = useState(selectedValues.email);
  const [formValid, setFormValid] = useState(false);
  const [error, setError] = useState(false);
  const [createPin] = useMutation(CREATE_PIN);

  useEffect(() => {
    const validateForm = async () => {
      try {
        const emailValidResponse = await validationSchema.fields.email.validate(
          email
        );

        setEmailValid(emailValidResponse);

        const validateResponse = await validationSchema.isValid(selectedValues);

        setFormValid(validateResponse);
      } catch (err) {
        if (err?.name === "ValidationError") return;
        captureErrorWithData(err);
      }
    };

    validateForm();
  }, [selectedValues, email]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteeEmail = urlParams.get("invitee");

    if (inviteeEmail && inviteeEmail !== "") {
      sessionStorage.setItem("inviteeEmail", inviteeEmail);
      onSelect({ inviteeEmail });

      setEmail(inviteeEmail);
    }

    /* eslint-disable-next-line */
  }, []);

  const handlePinSubmit = (pin) => {
    onSelect({
      pin: pin?.pin?.digit,
      country:
        window.country === PRIVACY_POLICY_TYPES.TR
          ? PRIVACY_POLICY_TYPES.TR
          : PRIVACY_POLICY_TYPES.GLOBAL,
    });
    setActiveStep(0);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await createPin({
        variables: { email: selectedValues.email, operation: "REGISTER" },
      });

      if (data && data.createPin) {
        setActiveStep(1);
      }
    } catch (pinError) {
      if (pinError.name === "Error") {
        return setError(pinError.message);
      }

      captureErrorWithData(pinError);
    }
  };

  const kvkkOnChange = (event) => {
    onSelect({ [event.target.name]: !selectedValues[event.target.name] });
  };

  const handleOnChange = async (event) => {
    setError(null);
    event.persist();
    setEmail(event.target.value);
    onSelect({ email: event.target.value });
    if (selectedValues.pin && selectedValues.pin !== "") {
      onSelect({ pin: null });
    }

    validationSchema.fields.email
      .validate(event.target.value)
      .then(() => setEmailValid(true))
      .catch(() => setEmailValid(false));
  };

  const renderStep = () => {
    if (activeStep === 0) {
      return (
        <RowBlock align="center">
          <TextField
            id="email"
            type="email"
            name="email"
            label="Your Email Address"
            error={error}
            value={(selectedValues.email = email)}
            background={selectedValues.pin ? "#c7eed5" : "#f5f6f8"}
            onChange={handleOnChange}
            autoFocus
            onKeyDown={(e) =>
              selectedValues.pin && e.key === "Enter" && e.preventDefault()
            }
          />
          {!selectedValues.pin && (
            <Button
              variant="primary"
              size="medium"
              disabled={!emailValid}
              ml={3}
              mt="1px"
              onClick={handleOnSubmit}
            >
              Verify
            </Button>
          )}
          {selectedValues.pin && (
            <SuccessButton type="button" size="medium" ml={3} mt="1px">
              Verified
              <IconWrapper ml={2}>
                <Circle>
                  <CheckIcon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                  </CheckIcon>
                </Circle>
              </IconWrapper>
            </SuccessButton>
          )}
        </RowBlock>
      );
    }
    return (
      <PinStep handleSubmit={handlePinSubmit} email={selectedValues.email} />
    );
  };

  return (
    <>
      <ColumnBlock
        px={{ mobileS: 4, tablet: 0 }}
        color="font.0"
        opacity={activeStep === 1 ? "0.2" : "1"}
      >
        <BoxTitle>
          Hey, glad to see you join{" "}
          <span role="img" aria-label="hello">
            👋
          </span>
          <br />
        </BoxTitle>
        <BoxDescription color="font.1" mt={3}>
          Can you enter your personal e-mail address so we can create your
          profile? You will use this e-mail address to log in, you don’t need to
          set a password.
        </BoxDescription>
      </ColumnBlock>
      <Form
        pt={{ mobileS: 4, laptop: 4, tablet: 4 }}
        pb={{ mobileS: 4, laptop: 5, tablet: 4 }}
        mt={{ mobileS: 4, laptop: 4, tablet: 4 }}
        px={{ mobileS: 4, laptop: 5, tablet: 3 }}
      >
        {renderStep()}
        {selectedValues.pin && (
          <AnimatedFormContainer>
            <ColumnBlock
              color="font.0"
              opacity={activeStep === 1 ? "0.2" : "1"}
            >
              <FormField mt={5} align="center" justify="flex-start">
                <RowBlock>
                  <CheckboxField
                    checked={selectedValues.kvkkAccept}
                    name="kvkkAccept"
                    onChange={kvkkOnChange}
                    value={selectedValues.kvkkAccept}
                  >
                    <span>
                      I accept the{" "}
                      <Button
                        variant="link"
                        as="a"
                        style={{ textDecoration: "underline" }}
                        href="https://talentgrid.io/wp-content/uploads/2022/06/TalentGrid-Candidate-Terms.pdf"
                        target="_blank"
                        fontSize="12px"
                      >
                        Candidate Terms
                      </Button>{" "}
                      and the{" "}
                      <Button
                        variant="link"
                        as="a"
                        style={{ textDecoration: "underline" }}
                        href="https://talentgrid.io/privacy/"
                        target="_blank"
                        fontSize="12px"
                      >
                        Privacy Policy
                      </Button>
                      .
                    </span>
                  </CheckboxField>
                  {selectedValues.country === PRIVACY_POLICY_TYPES.TR && (
                    <CheckboxField
                      checked={selectedValues.localKvkkAccept}
                      name="localKvkkAccept"
                      onChange={kvkkOnChange}
                      value={selectedValues.localKvkkAccept}
                    >
                      <span>
                        I’ve read and accept the{" "}
                        <Button
                          variant="link"
                          as="a"
                          style={{ textDecoration: "underline" }}
                          href=" https://talentgrid.io/tr/kvkk-bilgilendirme-metni/"
                          target="_blank"
                          fontSize="12px"
                        >
                          KVKK clarification text
                        </Button>{" "}
                        .
                      </span>
                    </CheckboxField>
                  )}
                </RowBlock>
              </FormField>
            </ColumnBlock>
          </AnimatedFormContainer>
        )}
      </Form>
      {selectedValues.pin && (
        <AnimatedFormContainer>
          <ColumnBlock color="font.0" opacity={activeStep === 1 ? "0.2" : "1"}>
            <RowBlock>
              <Button
                disabled={!formValid}
                type="submit"
                variant="primary"
                size="large"
                onClick={nextStep}
                alignItems="center"
                display="flex"
                justifyContent="center"
                className="register-created"
              >
                Continue
                <IconWrapper ml={3}>
                  <ArrowIcon direction="RIGHT" />
                </IconWrapper>
              </Button>
            </RowBlock>
          </ColumnBlock>
        </AnimatedFormContainer>
      )}
    </>
  );
}
