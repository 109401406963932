import React from "react";
import { useHistory } from "react-router-dom";
import { update as updateIntercom } from "../../../utils/Intercom";
import { RowBlock } from "../../BlockUi";
import SalaryViewCard from "./SalaryViewCard";
import LocationViewCard from "./LocationViewCard";
import TechnologiesViewCard from "./TechnologiesViewCard";
import BenefitsViewCard from "./BenefitsViewCard";
import CompanySizeViewCard from "./CompanySizeViewCard";
import FreelanceViewCard from "./FreelanceViewCard";
import OtherViewCard from "./OtherViewCard";

import Collapse from "../../Modules/Collapse";

export default function CriteriaViewCard({ criteria }) {
  const history = useHistory();

  const handleEdit = () => {
    history.push("profile/edit/criterias");
  };

  updateIntercom({
    "Min Salary Expectation": criteria.salary.expected,
    "Salary Strictness": criteria.salary.strictness + 1,
  });

  return (
    <Collapse title="Preferences" open edit handleOnEdit={handleEdit}>
      <RowBlock
        gridRowGap={{ mobileS: 3 }}
        justifyContent={{ mobileS: "space-between" }}
        px={{ tablet: 3 }}
      >
        {criteria.salary && (
          <SalaryViewCard
            key="salary"
            salary={criteria.salary}
            currency={criteria?.salary?.currency}
          />
        )}

        {criteria.location.open && criteria.location.remote !== "" && (
          <LocationViewCard key="location" locations={criteria.location} />
        )}

        {criteria.freelance.open &&
        criteria.freelance.availableWeeklyHour &&
        criteria.freelance.hourlyRate ? (
          <FreelanceViewCard key="freelance" freelance={criteria.freelance} />
        ) : null}

        {criteria.technologies.open &&
          criteria.technologies.expected.length > 0 && (
            <TechnologiesViewCard
              key="tech"
              technologies={criteria.technologies}
            />
          )}
        {criteria.benefits.open && criteria.benefits.expected.length > 0 && (
          <BenefitsViewCard key="benefit" benefits={criteria.benefits} />
        )}

        {criteria.companySize.open &&
          criteria.companySize.expected.length > 0 && (
            <CompanySizeViewCard
              key="companySize"
              companySize={criteria.companySize}
            />
          )}

        {criteria.other.open && criteria.other.expected !== "" && (
          <OtherViewCard other={criteria.other} />
        )}
      </RowBlock>
    </Collapse>
  );
}
