const toArrayCheck = (fields = []) => {
  return fields.map((key) => {
    return {
      key,
      isFilled: (user) => {
        return (user[key] || []).length > 0;
      },
    };
  });
};

const toInputCheck = (fields = []) => {
  return fields.map((key) => {
    return {
      key,
      isFilled: (user) => {
        return user[key];
      },
    };
  });
};

const FULLNESS_MAP = {
  basicInfo: [
    ...toInputCheck([
      "avatarURL",
      "bio",
      "name",
      "role",
      "birthyear",
      "livingCity",
      "phone",
    ]),
    {
      key: "social",
      isFilled: ({ social }) => {
        return Object.keys(social)
          .filter((i) => i !== "__typename")
          .some((key) => social[key]);
      },
    },
  ],
  skills: [...toArrayCheck(["skills", "otherSkills"])],
  criteria: [
    {
      key: "criterias",
      isFilled: (user) => {
        const criteria = user?.criteria || {};
        return Object.keys(criteria)
          .filter((key) => !["updatedAt", "__typename"].includes(key))
          .some((key) => criteria[key].open);
      },
    },
  ],
  education: [...toArrayCheck(["education", "languages"])],
  workHistory: [...toArrayCheck(["workHistory"])],
};

export default function (profile) {
  let totalCircleCount = 0;
  let filledCircleCount = 0;

  Object.keys(FULLNESS_MAP).forEach((key) => {
    const section = FULLNESS_MAP[key];
    totalCircleCount += section.length;
    section.forEach((circle) => {
      if (circle.isFilled(profile)) {
        filledCircleCount++;
      }
    });
  });

  return parseInt(((filledCircleCount / totalCircleCount) * 100).toFixed(0));
}
