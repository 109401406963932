import React from "react";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import { Container, BodyWrapper } from "../../components/BlockUi";
import MatchDetail from "../../components/Match/MatchDetail";
import { MobileDetailHeader } from "../../components/Header";

export default function MobileDetail({ match, refetch, isEmpty }) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleOnClick = () => {
    history.push("/matches");
  };
  return (
    <>
      <BodyWrapper background="#0c084c">
        <MobileDetailHeader
          handleClick={handleOnClick}
          title="Details and requirements"
        />
        <Container display="block">
          <Route path={`${path}/:positionId`}>
            <MatchDetail match={match} refetch={refetch} isEmpty={isEmpty} />
          </Route>
        </Container>
      </BodyWrapper>
    </>
  );
}
