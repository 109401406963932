import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
// eslint-disable-next-line import/prefer-default-export

export const GET_USER_STATUS = gql`
  {
    me {
      id
      name
      status
    }
  }
`;
export const GET_USER = gql`
  {
    me {
      status
      id
      name
      bio
      email
      avatarURL
      creatorToken
      resume {
        fileName
        resumeURL
      }
      preferences {
        languageCode
        currency
        openToWork
      }
      languages {
        language {
          id
          label
          code
        }
        level
      }
      education {
        type
        school {
          name
          id
        }
        branch {
          id
          label
        }
        startDate
        endDate
      }
      role {
        id
        title
      }
      roles {
        role {
          id
          title
        }
        experience
      }
      experience
      birthyear
      livingCity {
        id
        city
        iso2
        country
      }
      phone
      social {
        github
        linkedin
        stackoverflow
        portfolio
        twitter
        youtube
        blog
      }
      skills {
        _id
        title
        experience
        category
        categoryId
      }
      otherSkills {
        id
        categoryId
        category
        title
        creator {
          id
        }
      }
      updatedAt
      workHistory {
        company
        position
        startDate
        endDate
        description
        present
      }
      criteria {
        updatedAt
        salary {
          open
          expected
          income
          period
          currency
        }
        location {
          open
          remote
          expected {
            id
            city
            country
          }
          istanbulRegion
        }
        technologies {
          open
          expected {
            id
            title
          }
        }
        benefits {
          open
          expected {
            id
            label
          }
        }
        freelance {
          open
          availableWeeklyHour
          hourlyRate
          hourlyRateCurrency
        }
        companySize {
          open
          expected {
            id
            label
          }
        }
        other {
          open
          expected
        }
      }
    }
  }
`;

export const GET_USER_FOR_APP_PROVIDER = gql`
  {
    me {
      status
      id
      name
      bio
      email
      avatarURL
      languages {
        language {
          id
        }
        level
      }
      education {
        school {
          id
        }
        branch {
          id
        }
      }
      role {
        id
      }
      experience
      birthyear
      livingCity {
        id
      }
      phone
      social {
        github
        linkedin
        stackoverflow
        portfolio
        twitter
        youtube
        blog
      }
      skills {
        _id
      }
      otherSkills {
        id
      }
      workHistory {
        company
      }
      criteria {
        salary {
          open
          expected
          income
          period
          currency
        }
        location {
          open
          remote
          expected {
            id
          }
        }
        technologies {
          open
          expected {
            id
          }
        }
        benefits {
          open
          expected {
            id
          }
        }
        freelance {
          open
          availableWeeklyHour
          hourlyRate
          hourlyRateCurrency
        }
        companySize {
          open
          expected {
            id
          }
        }
        other {
          open
          expected
        }
      }
    }
  }
`;

const userIdQueryGQL = gql`
  query user {
    me {
      id
    }
  }
`;

export const useUserIdQuery = () => useQuery(userIdQueryGQL, {});

export const GET_TECHNOLOGIES = gql`
  query allTechnology(
    $search: String
    $limit: Int
    $sort: technologySortInputType
    $categoryIds: [Int]
    $verified: Boolean
    $exclude: [ID]
  ) {
    allTechnology(
      search: $search
      limit: $limit
      sort: $sort
      categoryIds: $categoryIds
      verified: $verified
      exclude: $exclude
    ) {
      technologies {
        id
        title
        categoryId
        category
      }
    }
  }
`;
