import * as Yup from "yup";

export const basicInfoValidationSchema = Yup.object().shape({
  bio: Yup.string()
    .trim()
    .min(1, "En az 1 karakter giriniz")
    .max(1000, "1000 karakterden daha fazla olamaz"),
  name: Yup.string().trim().required("Lütfen isim soyisim giriniz"),
  role: Yup.string().required(""),
  avatarURL: Yup.string().nullable(),
  experience: Yup.number().required(""),
  birthyear: Yup.number().required(""),
  livingCity: Yup.string().required(""),
  phone: Yup.string()
    .matches(/^\+(?:[0-9]●?){6,14}[0-9]$/, "This is not a valid phone number.")
    .required(`This is not a valid phone number.`),
  resume: Yup.object().shape({
    fileName: Yup.string().nullable(),
    resumeURL: Yup.string().nullable(),
  }),
  linkedin: Yup.string().matches(
    /^$|((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)([^/]+\/(.)))/,
    "Link geçerli gözükmüyor"
  ),
  github: Yup.string().matches(
    /^$|^(?:https?:\/\/)?(?:www\.)?github\.com\/(?<login>[A-z0-9_-]+)\/?/,
    "Link geçerli gözükmüyor"
  ),
  stackoverflow: Yup.string().matches(
    /^$|^(?:https?:\/\/)?(?:www\.)?stackoverflow\.com\/(?<login>[A-z0-9_-]+)\/?/,
    "Link geçerli gözükmüyor"
  ),
  portfolio: Yup.string().url(),
  twitter: Yup.string().matches(
    /^$|^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?<login>[A-z0-9_-]+)\/?/
  ),
  youtube: Yup.string().matches(
    /^$|^(https?:\/\/)?(www\.)?youtu((\.be)|(be\..{2,5}))\/((user)|(channel)|(c)|(@[A-z0-9_-]))\/?/
  ),
  blog: Yup.string().url(),
  openToWork: Yup.boolean(),
});

export const validationSchema = Yup.object().shape({});
