import gql from "graphql-tag";

export const ADD_EXTERNAL_REJECT_REASONS_TO_POSITION = gql`
  mutation addExternalRejectReasonToPosition(
    $publicID: ID!
    $rejectReasons: [ID]
  ) {
    addExternalRejectReasonToPosition(
      publicID: $publicID
      rejectReasons: $rejectReasons
    ) {
      id
    }
  }
`;
