import React from "react";
import { ColumnBlock, RowBlock, Span, ScrollBoxX } from "../../BlockUi";
import LocationIcon from "../../../images/noun-location.svg";
import CriteriaViewCard from "./Card";
import { ViewCardSubTitle, Tag } from "../CommonStyles";

const REMOTE_TYPE_TITLES = {
  none: "I do NOT want to work remotely",
  half: "Remote or Onsite",
  full: "I want to be 100% remote",
};

export default function LocationViewCard({ locations }) {
  return (
    <CriteriaViewCard title="Work Location" image={LocationIcon}>
      <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
        <RowBlock>
          <ColumnBlock justifyContent={{ mobileS: "flex-start" }}>
            {locations.expected.length > 0 && locations.remote !== "full" && (
              <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
                <ViewCardSubTitle>Preferred cities:</ViewCardSubTitle>
                <ScrollBoxX>
                  {locations.expected.length > 0 &&
                    locations.expected.map(({ city }, index) => (
                      <Tag style={{ whiteSpace: "nowrap" }} key={index}>
                        {city}
                      </Tag>
                    ))}
                </ScrollBoxX>
              </ColumnBlock>
            )}
            <ColumnBlock
              mt={{ laptop: 3 }}
              alignItems={{ mobileS: "flex-start" }}
            >
              <ViewCardSubTitle>Remote work preference:</ViewCardSubTitle>
              <Span fontSize={{ mobileS: "14px" }}>
                {REMOTE_TYPE_TITLES[locations.remote]}
              </Span>
            </ColumnBlock>
          </ColumnBlock>
        </RowBlock>
      </ColumnBlock>
    </CriteriaViewCard>
  );
}
