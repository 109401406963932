import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import gql from "graphql-tag";
import { Input } from "../../FormUi";
import { Button } from "../../Button";
import PinStep from "./PinStep";
import CloseAccount from "./CloseAccount";
import { RowBlock } from "../../BlockUi";
import { captureErrorWithData } from "../../../helper";

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.79;
  color: #444444;
  font-family: ${(props) => props.theme.family.bold};
`;

const Explanation = styled.div`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #aaaaaa;
  margin-bottom: 10px;
`;

const VerifyPinStep = styled.div`
  border-top: 1px solid #f4f6fc;
  border-bottom: 1px solid #f4f6fc;
  margin-top: 30px;
  padding: 25px 0 30px;
`;

const Status = styled.span`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 12px;
  color: #444444;
  display: inline-block;
`;

const Circle = styled.span`
  width: 15px;
  height: 15px;
  background-color: #8fdeac;
  border-radius: 50%;
  display: inline-block;
  vertical-align: sub;
  margin-right: 7px;
`;

const CheckIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  width: 15px;
  height: 15px;
  padding: 2px;
`;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(""),
});

const CREATE_PIN = gql`
  mutation CreatePin($email: String!, $operation: String!) {
    createPin(email: $email, operation: $operation) {
      email
    }
  }
`;

const staticContent = {
  oldOne: {
    title: "Current mail address",
    explanation:
      "To update your email address, we will first ask you to verify your current address.",
  },
  newOne: {
    title: "New mail address",
    explanation:
      "You can change your registered mail by verifying this new address.",
  },
};

const Statics = ({ type }) => {
  return (
    <>
      <Title>{staticContent[type].title}</Title>
      <Explanation>{staticContent[type].explanation}</Explanation>
    </>
  );
};

export default function AccountSettings({ user, refetch }) {
  const [createPin] = useMutation(CREATE_PIN);
  const [stepName, setStepName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [valid, setValid] = useState(false);
  const [oldDigit, setOldDigit] = useState();
  const { id, email, name, deleteReason } = user;

  const sendPinCurrentEmail = async (e) => {
    e.preventDefault();
    try {
      await createPin({ variables: { email, operation: "VALIDATE" } });
      setStepName("verifyPin");
    } catch (err) {
      captureErrorWithData(err);
    }
  };

  const sendPinNewEmail = async (e) => {
    e.preventDefault();
    try {
      await createPin({
        variables: { email: newEmail, operation: "EMAIL_CHANGE" },
      });
      setStepName("newEmailverifyPin");
    } catch (err) {
      captureErrorWithData(err);
    }
  };

  const handleEmailChange = async (event) => {
    event.persist();
    setNewEmail(event.target.value);

    validationSchema.fields.email
      .validate(event.target.value)
      .then(() => setValid(true))
      .catch(() => setValid(false));
  };

  let step;
  switch (stepName) {
    case "verifyPin":
      step = (
        <Wrapper>
          <Statics type="oldOne" />
          <div>
            <Input value={email} textAlign="left" disabled />
          </div>
          <VerifyPinStep>
            <PinStep
              oldEmail={email}
              oldDigit={oldDigit}
              setOldDigit={setOldDigit}
              handleSubmit={() => setStepName("createEmail")}
              title={`Is that you ${name}?`}
            />
          </VerifyPinStep>
        </Wrapper>
      );
      break;
    case "createEmail":
      step = (
        <Wrapper>
          <Statics type="newOne" />
          <div>
            <Input
              textAlign="left"
              onChange={handleEmailChange}
              value={newEmail}
            />
          </div>
          <Button
            variant="secondary"
            size="small"
            mt="30px"
            onClick={sendPinNewEmail}
            disabled={!valid}
          >
            Update
          </Button>
        </Wrapper>
      );
      break;
    case "newEmailverifyPin":
      step = (
        <Wrapper>
          <Statics type="newOne" />
          <div>
            <Input textAlign="left" disabled value={newEmail} />
          </div>
          <VerifyPinStep>
            <PinStep
              oldEmail={email}
              newEmail={newEmail}
              oldDigit={oldDigit}
              setOldDigit={setOldDigit}
              handleSubmit={() => setStepName("updatedEmail")}
              title="Can you confirm your new address as well?"
              newEmailStep
              refetch={refetch}
            />
          </VerifyPinStep>
        </Wrapper>
      );
      break;
    case "updatedEmail":
      step = (
        <Wrapper>
          <Statics type="oldOne" />
          <div>
            <Input
              value={email}
              disabled
              textAlign="left"
              mr={{ tablet: "40px" }}
            />
            <Status>
              <Circle>
                <CheckIcon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </CheckIcon>
              </Circle>
              Updated
            </Status>
          </div>
          <Button
            variant="secondary"
            size="small"
            mt="30px"
            onClick={sendPinCurrentEmail}
          >
            Update
          </Button>
        </Wrapper>
      );
      break;
    default:
      step = (
        <Wrapper>
          <Statics type="oldOne" />
          <div>
            <Input value={email} disabled textAlign="left" />
          </div>
          <Button
            variant="secondary"
            size="small"
            mt="30px"
            onClick={sendPinCurrentEmail}
          >
            Update
          </Button>
        </Wrapper>
      );
  }

  return (
    <div>
      {step}
      <RowBlock pt={{ mobile: 8, tablet: 8, laptop: 8 }} />
      <CloseAccount userId={id} deleteReason={deleteReason} />
    </div>
  );
}
