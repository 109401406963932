import React, { useState } from "react";
import styled from "styled-components";
import DataGroup from "../Modules/DataGroup";
import { Container, ItemText, Row } from "../Match/CommonModule";
import { ColumnBlock, RowBlock } from "../BlockUi";
import {
  getNumberWithCommas,
  getRemoteWorkTypes,
  removeTypename,
} from "../../helper";
import { Label } from "../FormUi";
import { ArrowIcon } from "../Icon";
import ExpandMessage from "../ExpandMessage";

const numberOfBenefitsShown = 5;

const CheckWrap = styled.div`
  display: flex;
  align-items: center;
`;
const Circle = styled.span`
  width: 10px;
  height: 10px;
  background-color: #8fdeac;
  border-radius: 50%;
  display: inline-block;
  vertical-align: sub;
  margin-right: 15px;
`;
const CheckIcon = styled.svg`
  display: flex;
  align-items: center;
  fill: none;
  stroke: white;
  stroke-width: 4px;
  width: 10px;
  height: 10px;
  padding: 2px;
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  margin-right: 5px;
  height: 35px;
  border: 2px solid #0c084c;
  border-radius: 30px;
  padding: 10px;
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #444444;
  margin-bottom: 5px;
`;

const Check = () => (
  <CheckWrap>
    <Circle>
      <CheckIcon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </CheckIcon>
    </Circle>
  </CheckWrap>
);

export default function Benefits({ criteria }) {
  const [state, setState] = useState({
    visible: false,
    size: numberOfBenefitsShown,
  });
  /* eslint-disable prefer-const */
  let { budget, stockOption, positionLocation, benefits } = criteria;
  benefits = removeTypename(benefits);

  return (
    <DataGroup title="Compensation and benefits">
      <Container>
        {budget && budget.isVisibleToUser && (
          <Row gridColumnGap="30px">
            <Label>Salary</Label>
            <ItemText>
              {`${budget.currency} ${getNumberWithCommas(
                budget.min
              )} - ${getNumberWithCommas(
                budget.max
              )} ${budget.income.toLowerCase()} ${budget.period.toLowerCase()} salary`}
            </ItemText>
          </Row>
        )}
        {stockOption && stockOption.isProvided && stockOption.isVisibleToUser && (
          <Row gridColumnGap="30px">
            <Label>Stock Option</Label>
            <ItemText>
              YES{" "}
              {stockOption.isRange === "RANGE" && (
                <ItemText>
                  {`(${stockOption.min} - ${stockOption.max}%)`}
                </ItemText>
              )}
              {stockOption.isRange === "CERTAIN" && (
                <ItemText>{`(${stockOption.certainValue}%)`}</ItemText>
              )}
            </ItemText>
          </Row>
        )}
        {(benefits?.expected?.length > 0 ||
          positionLocation.relocation ||
          positionLocation.visaSupport) && (
          <Row gridColumnGap="30px">
            <Label>Side Benefits</Label>
            <ColumnBlock>
              {positionLocation.relocation && (
                <RowBlock justify="start">
                  <Check />
                  <ItemText>Relocation support</ItemText>
                </RowBlock>
              )}
              {positionLocation.visaSupport && (
                <RowBlock justify="start">
                  <Check />
                  <ItemText>Visa support</ItemText>
                </RowBlock>
              )}

              {benefits?.expected?.slice(0, state.size).map((benefit) => {
                return (
                  <RowBlock
                    key={benefit.label}
                    justify="start"
                    display={state ? "flex" : "none"}
                  >
                    <Check />
                    <ItemText key={benefit.label}>{benefit.label}</ItemText>
                  </RowBlock>
                );
              })}
              {benefits?.expected?.length > numberOfBenefitsShown && (
                <ExpandMessage
                  handleClick={() =>
                    setState({
                      visible: !state.visible,
                      size:
                        state.size === numberOfBenefitsShown
                          ? benefits.expected.length
                          : numberOfBenefitsShown,
                    })
                  }
                >
                  {state.visible
                    ? "See less"
                    : `See all ${benefits.expected.length} benefits`}{" "}
                  <ArrowIcon direction={state.visible ? "UP" : "DOWN"} />
                </ExpandMessage>
              )}
            </ColumnBlock>
          </Row>
        )}
        {!(
          positionLocation?.remote?.split(",").length === 1 &&
          positionLocation?.remote?.split(",").includes("full")
        ) &&
          positionLocation?.expected?.place?.city && (
            <Row gridColumnGap="30px">
              <Label>Location</Label>
              <ColumnBlock>
                <Tag>{positionLocation.expected.place.city}</Tag>
              </ColumnBlock>
            </Row>
          )}
        {positionLocation.remote && (
          <Row gridColumnGap="30px">
            <Label>Working Types</Label>
            <ColumnBlock>
              <RowBlock align="center" justify="start" width="">
                {getRemoteWorkTypes(positionLocation)}
              </RowBlock>
            </ColumnBlock>
          </Row>
        )}
      </Container>
    </DataGroup>
  );
}
