import React from "react";
import { Button } from "../../../Button";

export default function SubmitButton({ handleSubmit, isValid }) {
  return (
    <Button
      variant="primary"
      width={{ tablet: "100%" }}
      onClick={handleSubmit}
      disabled={!isValid}
    >
      Save
    </Button>
  );
}
