import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";
import { space } from "styled-system";

import {
  ActionMenus,
  ActionMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "tg-design";
import useModal from "../../Modules/useModal";
import { Button } from "../../Button";
import { ColumnBlock } from "../../BlockUi";
import Spinner from "../../Modules/Loading";
import { captureErrorWithData } from "../../../helper";
import message from "../../../utils/message";
import HoveredEmptyView from "../../Modules/HoveredEmptyView";
import { BasicLink } from "../../FormUi";
import { DeleteIcon, CheckIcon, ThreeDotsMenu } from "../../Icon";
import { Dot, ImportCvButton } from "../ProfileImport/CommonStyles";
import {
  UPLOAD_RESUME,
  DELETE_RESUME,
  RESUME_SAVE_COUNT,
} from "../../Profile/Sidebar/queries";
import PartialError from "../../../pages/ErrorPage/PartialError";

const dropdownStyle = {
  right: "-1px",
  marginTop: "4px",
  width: "145px",
};

const Wrapper = styled.div`
  ${space}
  position: relative;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ImportedButtonContainer = styled.div`
  display: flex;
  border: 2px solid #72d1fb;
  font-family: ${(props) => props.theme.family.demi};
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  color: #72d1fb;
  border-radius: 50px;
  padding: 5px 15px;
`;

export default function UploadResume({
  id,
  file,
  fileName,
  parsing,
  onChange,
  autoFocus,
  handleImportButtonClick,
}) {
  const {
    data,
    loading: queryLoading,
    error: queryError,
    refetch,
  } = useQuery(RESUME_SAVE_COUNT, {
    fetchPolicy: "network-only",
  });

  const [uploadResume, { loading }] = useMutation(UPLOAD_RESUME);
  const [deleteResume] = useMutation(DELETE_RESUME);
  const [currentFileUrl, setCurrentFileUrl] = useState(file || null);

  const { showModal, setShowModal } = useModal();

  let fileInput = useRef(null);

  const [selectedFile, setSelectedFile] = useState();
  const [isDelete, setIsDelete] = useState(false);

  const onSelectFile = (e) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setSelectedFile(files);
      setShowModal();
    }
  };

  const onInputClick = (e) => {
    e.target.value = ""; // when choose same image, to trigger input onChange
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();

    if (selectedFile[0].size > 10485760) {
      setShowModal(false);
      return message.error("This file is larger than our 10 MB size limit.");
    }
    if (selectedFile[0].type !== "application/pdf") {
      setShowModal(false);
      return message.error("File type should be pdf");
    }
    try {
      const res = await uploadResume({
        variables: { id, resume: selectedFile[0] },
      });
      if (res) {
        setCurrentFileUrl(res.data.uploadResume.resumeURL);
        onChange({
          fileName: res.data.uploadResume.fileName,
          resumeURL: res.data.uploadResume.resumeURL,
        });
        setShowModal(false);
      }
      refetch();
    } catch (error) {
      message.error("Something went wrong!");
      setShowModal(false);
      refetch();
      captureErrorWithData(error);
    }
  };

  const handleClick = () => {
    fileInput.click();
  };

  const removeResume = async () => {
    setIsDelete(true);
    try {
      const res = await deleteResume({
        variables: { id },
      });
      if (res) {
        setCurrentFileUrl(null);
        onChange({ fileName: null, resumeURL: null });
      }
      setIsDelete(false);
    } catch (error) {
      message.error("Something went wrong!");
      captureErrorWithData(error);
      setIsDelete(false);
    }
  };

  const setVal = (val) => {
    fileInput = val;
  };

  const DeleteResume = () => {
    if (isDelete) {
      return <Spinner width="24px" mr="5px" />;
    }
    return <DeleteIcon onClick={removeResume} />;
  };

  if (queryLoading) return <Spinner width="24px" />;
  if (queryError) return <PartialError />;

  return (
    <div>
      {showModal && (
        <Modal handleClose={setShowModal}>
          <ModalHeader>
            <div>Are you sure you want to upload this file?</div>
            <small>PDF files with less than 10 MB size are supported.</small>
          </ModalHeader>
          <ModalBody>
            <ColumnBlock>File name: {selectedFile[0].name}</ColumnBlock>
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" size="large" onClick={handleFileSubmit}>
              {loading && <Spinner width="24px" mr="5px" />}
              Save CV
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Wrapper>
        <input
          type="file"
          accept="application/pdf"
          multiple={false}
          onChange={onSelectFile}
          onClick={onInputClick}
          style={{ display: "none" }}
          ref={(val) => setVal(val)}
        />
        {currentFileUrl ? (
          <>
            <Container>
              <BasicLink
                href={currentFileUrl}
                target="_blank"
                rel="noreferrer"
                pb="2"
              >
                <span>{fileName || "My CV"}</span>
              </BasicLink>
            </Container>
            <>
              {data.findResumeSaveCountByURL === 0 ? (
                <Container>
                  <ImportCvButton
                    handleClick={() => handleImportButtonClick()}
                    loading={parsing}
                  >
                    <>
                      {parsing ? (
                        <>
                          <span>Importing</span>
                          <Dot className="dot-one">.</Dot>
                          <Dot className="dot-two">.</Dot>
                          <Dot className="dot-three">.</Dot>
                        </>
                      ) : (
                        <span>Import from your CV</span>
                      )}
                    </>
                  </ImportCvButton>
                  <DeleteResume />
                </Container>
              ) : (
                <Container>
                  <ImportedButtonContainer>
                    <CheckIcon fill="#72d1fb" />
                    Imported
                  </ImportedButtonContainer>
                  <ActionMenus
                    menuStyles={dropdownStyle}
                    variant="right"
                    icon={<ThreeDotsMenu />}
                  >
                    <ActionMenu onClick={() => handleClick()}>
                      Upload new CV
                    </ActionMenu>
                    <ActionMenu onClick={() => handleImportButtonClick()}>
                      Re-import current CV
                    </ActionMenu>
                    <ActionMenu onClick={() => removeResume()}>
                      Remove CV
                    </ActionMenu>
                  </ActionMenus>
                </Container>
              )}
            </>
          </>
        ) : (
          <div onClick={handleClick}>
            <HoveredEmptyView text="Add" autoFocus={autoFocus} />
          </div>
        )}
      </Wrapper>
    </div>
  );
}
