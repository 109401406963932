/* eslint-disable no-unused-vars */

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import LogRocket from "logrocket";
import App from "./App";
import Global from "./styles/global";

if (process.env.REACT_APP_HOST_ENV !== "development") {
  Sentry.init({
    dsn: "https://3c4916b613a54b00920351d4ab93daed@o155687.ingest.sentry.io/5468929",
    environment: process.env.REACT_APP_HOST_ENV,
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [/Network\sError/i],
  });
}

if (process.env.REACT_APP_HOST_ENV === "production") {
  LogRocket.init("tg-developer/dev-app");
}

ReactDOM.render(
  <Suspense fallback="loading...">
    <BrowserRouter forceRefresh>
      <Global />
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
