import * as Yup from "yup";

export const schema = Yup.object().shape({
  educations: Yup.array().of(
    Yup.object().shape({
      school: Yup.string().trim().required(""),
      branch: Yup.string().trim().required(""),
      type: Yup.number().required(""),
      startDate: Yup.date().required(""),
      endDate: Yup.date().required(""),
    })
  ),
});

export const languageSchema = Yup.object().shape({
  languages: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().trim().required(""),
      level: Yup.number().min(1).max(7).required(""),
    })
  ),
});
