import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import {
  captureErrorWithData,
  removeTypename,
  uuidGenerate,
} from "../../../helper";
import { LINKEDIN_PROFILE_FIELDS } from "../../../utils/constants";
import message from "../../../utils/message";
import { WebSocketContext } from "../../../WebSocket";
import { ColumnBlock } from "../../BlockUi";
import { Button } from "../../Button";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";
import {
  GroupTitle,
  PrimaryTitle,
  SecondaryTitle,
  DateText,
  LinkedinSectionContainer,
  HeaderText,
} from "../CommonStyles";
import {
  LinkedinErrorPlaceHolder,
  LinkedinLoadingPlaceHolder,
} from "../ProfileImport/Linkedin/LinkedinPlaceholders";
import { UPDATE_USER_FROM_LINKEDIN } from "../ProfileImport/queries";
import { FETCH_FIELD_FROM_LINKEDIN } from "../queries";

export default function LinkedinEducationForm({
  onUpdate,
  user,
  handleOnClose,
}) {
  const [currentState, setCurrentState] = useState(null);
  // eslint-disable-next-line
  const [uuid, setUuid] = useState(uuidGenerate());
  const [loading, setLoading] = useState(false);
  const [fetchFieldFromLinkedin] = useLazyQuery(FETCH_FIELD_FROM_LINKEDIN);
  const [updateUserFromLinkedin, { loading: loadingForSave }] = useMutation(
    UPDATE_USER_FROM_LINKEDIN
  );

  const ws = useContext(WebSocketContext);

  const onSubscribe = ({ uuid }) => ws.socket.emit("subscribe", { uuid });
  const onDisconnect = ({ uuid }) => ws.socket.emit("unsubscribe", { uuid });
  const offListen = () => ws.socket.off("linkedInCrawled");

  const onListen = () => {
    setLoading(true);
    ws.socket.on("linkedInCrawled", (data) => {
      if (data.error) {
        setLoading(false);

        onDisconnect({ uuid });
        message.error(`An error has occurred: ${data.error}`);
      } else {
        const linkedinData = { ...data.linkedin };
        const formattedEducationState = {
          uuid,
          education: linkedinData?.education
            .map((item) => {
              if (item?.suggestions && item.suggestions.length !== 0) {
                return {
                  school: {
                    id: item.suggestions[0].school.id,
                    name: item.suggestions[0].school.name,
                  },
                  branch: {
                    id: item.suggestions[0].branch.id,
                    label: item.suggestions[0].branch.label,
                  },
                  startDate: Number(item.suggestions[0].startDate),
                  endDate: Number(item.suggestions[0].endDate),
                  type: item.suggestions[0].type || null,
                  isChecked: true,
                };
              }
              return null;
            })
            .filter((i) => i),
        };

        if (
          !formattedEducationState?.education ||
          formattedEducationState?.education.length === 0
        ) {
          offListen();
          onDisconnect({ uuid });
        }

        setCurrentState(formattedEducationState);
        setLoading(false);
      }

      offListen();
      onDisconnect({ uuid });
    });
  };

  useEffect(() => {
    const linkedinURL = user.social?.linkedin || null;
    const username = linkedinURL?.split("/").filter(Boolean).pop();
    if (!username) {
      message.error("Username is not valid");
    }
    try {
      fetchFieldFromLinkedin({
        variables: {
          uuid,
          username,
          field: LINKEDIN_PROFILE_FIELDS.EDUCATION,
        },
      });
      onSubscribe({ uuid });
      onListen({ uuid });
    } catch (error) {
      error.message("Something went wrong!");
      captureErrorWithData(error);
      offListen();
      onDisconnect({ uuid });
    }
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (changedIndex) => {
    setCurrentState({
      ...currentState,
      education: currentState.education.map((item, index) => {
        if (index === changedIndex) {
          item.isChecked = !item.isChecked;
          return item;
        }
        return item;
      }),
    });
  };

  const handleOnSave = async () => {
    try {
      const variables = removeTypename({
        id: user.id,
        uuid: currentState.uuid,
        education: currentState.education
          .map(
            (i) =>
              i.isChecked && {
                school: i.school.id,
                branch: i.branch.id,
                startDate: i.startDate,
                endDate: i.endDate,
                type: i.type,
              }
          )
          .filter((i) => i),
      });

      const updatedLinkedinData = await updateUserFromLinkedin({ variables });
      onUpdate({ user: new Date() });
      setCurrentState(null);
      onDisconnect({ uuid });
      handleOnClose(updatedLinkedinData.data.updateUserFromLinkedin.education);
    } catch (error) {
      onDisconnect({ uuid });
      captureErrorWithData(error);
      setCurrentState(null);
      handleOnClose();
    }
  };

  return (
    <>
      <HeaderText>Import education info from Linkedin</HeaderText>
      {loading && <LinkedinLoadingPlaceHolder />}
      {currentState?.education?.length === 0 && <LinkedinErrorPlaceHolder />}
      {currentState?.education?.length > 0 && (
        <>
          <div style={{ marginTop: "10px" }}>
            <GroupTitle>Education</GroupTitle>
            {currentState?.education?.map((item, index) => {
              return (
                <ColumnBlock key={index} style={{ marginTop: "5px" }}>
                  <LinkedinSectionContainer
                    isChecked={item.isChecked}
                    onClick={() => handleOnChange(index)}
                  >
                    <div style={{ flex: "1" }}>
                      <PrimaryTitle>{item.school.name}</PrimaryTitle>
                      <SecondaryTitle>{item.branch.label}</SecondaryTitle>
                      <DateText>
                        {item.startDate} - {item.endDate ? item.endDate : "?"}
                      </DateText>
                    </div>
                    <div>
                      {item.isChecked ? (
                        <CheckedBoxIcon />
                      ) : (
                        <UncheckedBoxIcon />
                      )}
                    </div>
                  </LinkedinSectionContainer>
                </ColumnBlock>
              );
            })}
          </div>
          <Button
            onClick={handleOnSave}
            variant="primary"
            size="large"
            style={{ width: "100%", marginTop: "47px" }}
            disabled={loadingForSave}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
}
