import React, { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { RowBlock, ColumnBlock } from "../BlockUi";
import { SelectField, AsyncSelectField } from "../Form/SelectField";
import FormScaffold from "../ProfileEdit/FormScaffold";
import { captureErrorWithData, debounce } from "../../helper";
import { EDUCATION_TYPES_WITH_KEYS } from "../../utils/constants";
import {
  SEARCH_UNIVERSITY,
  SEARCH_BRANCH,
  UPDATE_USER,
} from "../ProfileEdit/Education/queries";
import { PlusIconSimple } from "../Icon";
import { schema } from "../ProfileEdit/Education/EducationValidationSchema";
import {
  AddNewItem,
  DeleteItem,
  CardWrapper,
  DeleteIconWrapper,
} from "../ProfileEdit/Education/CommonStyles";
import IntercomLauncherMessage from "../Modules/IntercomLauncherMessage";
import message from "../../utils/message";
import {
  getPossibleStartYears,
  getPossibleEndYears,
} from "../ProfileEdit/Education/years";

const EMPTY_EDUCATION_FIELDS = {
  type: null,
  school: {
    name: "",
    id: null,
  },
  branch: {
    label: "",
    id: null,
  },
  startDate: null,
  endDate: null,
};

const getStatus = async (educations) => {
  try {
    await schema.validate({ educations });
    return "success";
  } catch (error) {
    return "pending";
  }
};

export default function Education({
  education = [],
  onUpdate,
  options = {
    single: false,
    showStatus: true,
    showTitle: true,
    border: true,
    showDescription: true,
  },
}) {
  if (!education) {
    education = [];
  }

  const {
    single = false,
    showStatus = true,
    showTitle = true,
    border = true,
    showDescription = true,
  } = options;

  const [state, setState] = useState([
    ...education.map((item) => {
      return {
        ...item,
      };
    }),
  ]);
  const [status, setStatus] = useState("pending");

  const [updateUser] = useMutation(UPDATE_USER);

  const { refetch: universityRefetch } = useQuery(SEARCH_UNIVERSITY, {
    skip: true,
  });

  const { refetch: branchRefetch } = useQuery(SEARCH_BRANCH, {
    skip: true,
  });

  const handleSchoolSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await universityRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        callback(
          data.allUniversity.universities.map((university) => {
            return { value: university.id, label: university.name };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleBranchSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await branchRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        const _branches = data.allUniversityBranch.universityBranches.map(
          (branch) => {
            return {
              value: branch?.id,
              label: branch?.label,
            };
          }
        );
        callback(_branches);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  useEffect(() => {
    const asyncCall = async () => {
      const variables = state.map((item) => {
        return {
          school: item.school?.id,
          branch: item.branch?.id,
          type: item.type,
          endDate: item.endDate,
          startDate: item.startDate,
        };
      });
      try {
        await schema.validate({ educations: variables });
        await updateUser({ variables: { education: variables } });
        onUpdate({ user: new Date() });
        const _status = await getStatus(education);
        setStatus(_status);
      } catch (error) {
        if (error.name !== "ValidationError") {
          captureErrorWithData(error);
        }
      }
    };
    asyncCall();
    // eslint-disable-next-line
  }, [state]);

  const handleOptionChange = ({ value, label, type, index }) => {
    let _value = value;

    if (type === "school") {
      _value = {
        name: label,
        id: value,
      };
    }
    if (type === "branch") {
      _value = {
        label,
        id: value,
      };
    }

    const newList = state.map((item, idx) => {
      if (index === idx) {
        const updatedItem = {
          ...item,
          [type]: _value,
        };

        // Kullanıcı başlangıç tarihinden daha küçük bir bitiş tarihi seçemez.
        if (
          Number.isInteger(updatedItem.startDate) &&
          Number.isInteger(updatedItem.endDate) &&
          updatedItem.endDate < updatedItem.startDate
        ) {
          updatedItem.endDate = null;
        }

        return updatedItem;
      }

      return item;
    });

    setState(newList);
  };

  const deleteItem = async (index) => {
    const filteredItems = state
      .slice(0, index)
      .concat(state.slice(index + 1, state.length));
    if (filteredItems.length === 0) {
      await updateUser({ variables: { education: [] } });
      onUpdate({ user: new Date() });
    }
    setState(filteredItems);
  };

  const getEducationType = (initialFields) => {
    if (initialFields.type !== null && initialFields.type !== undefined) {
      return EDUCATION_TYPES_WITH_KEYS[initialFields.type];
    }
    return "";
  };

  const EducationRow = (initialFields, index) => {
    return (
      <CardWrapper
        py={2}
        mb={3}
        border={border ? "1px solid #eeeeee" : "none"}
        borderRadius="5px"
        key={index}
      >
        <RowBlock
          px={4}
          py={3}
          justify="flex-start"
          width={{ mobileS: "90%", tablet: "95%" }}
        >
          <ColumnBlock
            width={{ mobileS: "100%", tablet: single ? "100%" : "70%" }}
          >
            <RowBlock my={3}>
              <SelectField
                label="Education Level"
                value={getEducationType(initialFields || null)}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                isSearchable={false}
                options={EDUCATION_TYPES_WITH_KEYS}
                width="100%"
                onChange={(selectedOption) =>
                  handleOptionChange({
                    ...selectedOption,
                    type: "type",
                    index,
                  })
                }
              />
            </RowBlock>
            <RowBlock my={3}>
              <AsyncSelectField
                label="School attended"
                value={{ label: initialFields?.school?.name || "" }}
                noOptionsMessage={({ inputValue }) =>
                  inputValue ? (
                    <IntercomLauncherMessage />
                  ) : (
                    "Type something to search"
                  )
                }
                loadOptions={handleSchoolSearch}
                onChange={(selectedOption) =>
                  handleOptionChange({
                    ...selectedOption,
                    type: "school",
                    index,
                  })
                }
                width="100%"
              />
            </RowBlock>
            <RowBlock my={3}>
              <AsyncSelectField
                label="Department"
                placeholder=""
                value={{
                  label: initialFields?.branch?.label,
                }}
                noOptionsMessage={({ inputValue }) =>
                  inputValue ? (
                    <IntercomLauncherMessage />
                  ) : (
                    "Type something to search"
                  )
                }
                loadOptions={handleBranchSearch}
                onChange={(selectedOption) =>
                  handleOptionChange({
                    ...selectedOption,
                    type: "branch",
                    index,
                  })
                }
                width="100%"
              />
            </RowBlock>
            <RowBlock my={3}>
              <ColumnBlock
                width={{ mobileS: "100%", tablet: "50%" }}
                pr={{ tablet: 2 }}
              >
                <SelectField
                  label="Year started"
                  placeholder=""
                  value={{ label: initialFields.startDate }}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? "No results found" : "Type something to search"
                  }
                  isSearchable
                  options={getPossibleStartYears(initialFields.endDate)}
                  onChange={(selectedOption) =>
                    handleOptionChange({
                      ...selectedOption,
                      type: "startDate",
                      index,
                    })
                  }
                  width="100%"
                />
              </ColumnBlock>
              <ColumnBlock
                width={{ mobileS: "100%", tablet: "50%" }}
                pl={{ tablet: 2 }}
                mt={{ mobileS: 3, tablet: 0 }}
              >
                <SelectField
                  label="Year finished"
                  placeholder=""
                  value={{
                    label: initialFields.endDate,
                  }}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? "No results found" : "Type something to search"
                  }
                  isSearchable
                  options={getPossibleEndYears(initialFields.startDate)}
                  width="100%"
                  onChange={(selectedOption) =>
                    handleOptionChange({
                      ...selectedOption,
                      type: "endDate",
                      index,
                    })
                  }
                />
              </ColumnBlock>
            </RowBlock>
          </ColumnBlock>
        </RowBlock>
        {!single && (
          <DeleteItem mt={1} mr={4} onClick={() => deleteItem(index)}>
            <DeleteIconWrapper>
              <PlusIconSimple color="white" />
            </DeleteIconWrapper>
          </DeleteItem>
        )}
      </CardWrapper>
    );
  };

  const insertEducation = () => {
    setState(state.concat(EMPTY_EDUCATION_FIELDS), state.length + 1);
  };

  const AddNewRow = () => {
    return (
      <RowBlock
        border="1px solid #eeeeee"
        borderRadius="5px"
        py={2}
        justify="center"
        width="100%"
        onClick={insertEducation}
      >
        <AddNewItem>Add New Education</AddNewItem>
      </RowBlock>
    );
  };

  return (
    <div>
      <FormScaffold
        label="Education history"
        explanation="You can add multiple education details."
        status={status}
        options={{ showStatus, showTitle, showDescription }}
      >
        {state.length > 0 &&
          state.map((education, index) => EducationRow(education, index))}
        {state.length === 0 && insertEducation()}
        {!single && (
          <RowBlock width="100%" mt={3}>
            {AddNewRow()}
          </RowBlock>
        )}
      </FormScaffold>
    </div>
  );
}
