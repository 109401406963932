import React, { useState } from "react";
import styled from "styled-components";
import device from "../../styles/device";

const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
  @media ${device.mobileMax} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color || "white"};

  &:hover {
    cursor: pointer;
  }
  @media ${device.mobileMax} {
    margin-top: 5px;
  }
`;

export default function Pagination({
  itemPerPage,
  totalItems,
  handlePaginate,
  navStyles = {},
}) {
  const pageNumbers = [];
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={navStyles}>
      <Wrapper>
        {pageNumbers.map((number) => {
          return (
            <NumberContainer
              key={number}
              backgroundColor={
                currentPageNumber === number ? "#8FDEAC" : "#A9A9A9"
              }
              onClick={() => {
                setCurrentPageNumber(number);
                handlePaginate(number);
              }}
            >
              {number}
            </NumberContainer>
          );
        })}
      </Wrapper>
    </nav>
  );
}
