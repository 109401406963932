import React from "react";
import styled from "styled-components";
import { Input } from "../../FormUi";
import { ErrorField } from "../../Modules/ErrorField";

const SupplementInput = styled(Input)`
  width: 100%;
  height: 34px;
  margin-left: 5px;
  text-align: left;
  border-color: ${(props) => (props.hasError ? "#ff7675" : "transparent")};
  :focus {
    border-color: ${(props) => props.theme.colors.green[0]};
  }
`;

const WrapTextBox = styled.div`
  position: relative;
  width: 100%;
`;

export default function OtherReason({ value, setOtherReason, validationErr }) {
  return (
    <WrapTextBox>
      <SupplementInput
        value={value}
        autoFocus
        onChange={(e) => setOtherReason(e.target.value)}
        hasError={validationErr && validationErr.message}
      />
      {validationErr && <ErrorField errorText={validationErr.message} />}
    </WrapTextBox>
  );
}
