import React, { useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import * as Yup from "yup";
import { GET_TECHNOLOGIES } from "../../../../graphql/queries";
import { debounce } from "../../../../helper";
import SubmitButton from "./SubmitButton";
import SkipLink from "./SkipLink";
import { AsyncSelectField } from "../../../Form/SelectField";
import { filterData } from "../../../ProfileEdit/Talent-Experience/OtherSkills";
import { techCategories } from "../../../../utils/constants";
import message from "../../../../utils/message";

const validationSchema = Yup.object().shape({
  otherSkills: Yup.array().required(),
});

export default function QuickFillOtherSkills({
  content,
  handleSubmit,
  next,
  showSkipButton,
}) {
  const [selections, setSelections] = useState([]);
  const [isValid, setFormValid] = useState(false);

  const { refetch: technologyRefetch } = useQuery(GET_TECHNOLOGIES, {
    skip: true,
  });

  const handleChange = async (selections) => {
    setSelections(selections);
    try {
      const validateResponse =
        await validationSchema.fields.otherSkills.validate(selections);
      if (validateResponse) {
        setFormValid(true);
      }
    } catch (err) {
      setFormValid(false);
    }
  };

  const handleDataSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        // refetch because of useQuery and useLazyQuery doesnt return a promise
        const { data } = await technologyRefetch({
          search: val.value,
          limit: 20,
          categoryIds: val.categoryIds,
          verified: true,
        });
        const filteredData = filterData(
          data.allTechnology.technologies,
          techCategories
        );
        callback(filteredData[content.data.dataKey]);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    [content.data.dataKey]
  );

  return (
    <>
      <AsyncSelectField
        name={content.name}
        mb="36px"
        placeholder={content.data.placeholder}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? "No results found" : "Type something to search"
        }
        closeMenuOnSelect={false}
        onChange={(selections) => handleChange(selections)}
        getOptionLabel={(option) =>
          option.title ? option.title : option.label
        }
        getOptionValue={(option) => option.id}
        isMulti
        loadOptions={(val, func) =>
          handleDataSearch(
            { value: val, categoryIds: [content.data.categoryId] },
            func
          )
        }
      />
      <SubmitButton
        handleSubmit={(event) =>
          handleSubmit(event, {
            otherSkills: selections,
          })
        }
        isValid={isValid}
      />
      {showSkipButton && <SkipLink next={next}>Skip</SkipLink>}
    </>
  );
}
