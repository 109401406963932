import React from "react";
import styled from "styled-components";
import { space, width, typography, background, flexbox } from "styled-system";
import device from "../styles/device";

export const FormField = styled.div`
  display: flex;
  ${space}
  ${flexbox}
  width: 100%;

  @media ${device.tablet} {
    width: 280px;
  }
`;

export const Form = styled.form`
  display: flex;
  ${space}
  width: 100%;
  flex-direction: column;
  align-items: center;
  ${flexbox}
`;
export const OnboardingFormContainer = styled.div`
  // width: calc(100% - 94px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${space}

  @media ${device.mobileL} {
    width: 500px;
  }
`;

export const Label = styled.label`
  display: flex;
  font-family: ${(props) => props.theme.family.bold};
  flex-direction: ${(props) => props.direction || "column"};
  justify-content: ${(props) => props.justify};
  color: ${(props) =>
    props.hasError ? "#ff7675" : props.theme.colors.font[0]};
  font-size: 14px;
  position: relative;
  font-weight: bold;
  ${space}
`;

export const BasicLink = styled.a`
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.font[1]};
  ${(props) => props.underline && "text-decoration: underline"};
  ${space}
  ${typography}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  width: 280px;
  height: 40px;
  background-color: #f5f6f8;
  border-radius: 5px;
  padding: 11px 9px;
  border-style: solid;
  font-weight: bold;
  text-align: center;
  font-family: ${(props) => props.theme.family.demi};
  color: #444444;
  font-size: 14px;
  ${space}
  ${width}
  ${typography}
  ${background}
  border-color: ${(props) => (props.hasError ? "#ff7675" : "transparent")};
  :focus {
    border-color: ${(props) => props.theme.colors.green[0]};
  }
`;

export const DigitInput = styled(Input)`
  width: 37px;
  height: 56px;
`;

const buttonSizes = {
  medium: "120px",
  large: "280px",
};
export const Button = styled.button`
  color: #fff;
  width: ${(props) =>
    props.size ? buttonSizes[props.size] : buttonSizes.large};
  height: 40px;
  cursor: pointer;
  background: ${(props) =>
    props.disabled ? "#dddddd" : props.theme.colors.green[0]};
  color: #fff;
  border-radius: 5px;
  ${space}
  font-size: 16px;
  border: none;
  color: #fff;
  font-weight: bold;
  font-family: ${(props) => props.theme.family.bold};
  line-height: 1.5;
  ${width}
`;

export const IsRequired = () => {
  return <span style={{ color: "#ff7675", paddingLeft: "5px" }}>*</span>;
};
