import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import device from "../../styles/device";
import { ErrorPageHeader } from "../../components/Header";
import { RowBlock } from "../../components/BlockUi";
import { Button } from "../../components/Button";
import ReadMore from "../../components/ReadMore";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ErrorHeader = styled.p`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 48px;
  text-align: center;
  line-height: 58px;
  @media ${device.mobileL} {
    font-size: 72px;
    line-height: 100px;
  }
`;

const ErrorMessageContainer = styled.div`
  background: #f2f2f2;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.25));
  margin: 30px 10px;
  border-radius: 5px;
  font-size: 14px;
  padding: 20px 10px;
`;

const DEFAULT_ERROR = {
  message: "An error occurred",
};

export default function ErrorPage({ error = DEFAULT_ERROR }) {
  const history = useHistory();

  const refreshPage = () => {
    window.location.reload();
  };

  const returnHome = () => {
    history.push("/");
    refreshPage();
  };

  return (
    <>
      <ErrorPageHeader returnHome={returnHome} />
      <Container>
        <ErrorHeader>Something Went Wrong</ErrorHeader>
        <ErrorMessageContainer>
          <ReadMore text={error.message} limit={250} />
        </ErrorMessageContainer>
        <RowBlock gridGap="20px">
          <Button variant="primary" size="large" onClick={refreshPage}>
            Try Again
          </Button>
          <Button variant="transparent" size="large" onClick={returnHome}>
            Home
          </Button>
        </RowBlock>
      </Container>
    </>
  );
}
