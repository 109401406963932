import React, { useState } from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter, Tag } from "tg-design";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button } from "../Button";
import Spinner from "../Modules/Loading";

import { GET_REJECT_REASON } from "../Match/queries";
import { ADD_EXTERNAL_REJECT_REASONS_TO_POSITION } from "./queries";
import PartialError from "../../pages/ErrorPage/PartialError";
import { RowBlock } from "../BlockUi";
import message from "../../utils/message";

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 3px 10px #dddddd;
  border-radius: 20px;
  padding: 5px;
  background: #ffffff;

  &.saved {
    background: #c7eed5;
  }
`;
const HeaderContainer = styled.div`
  flex-direction: row;
`;

const RejectReasonSelectionStep = ({ allSteps, setStep, publicPositionId }) => {
  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useQuery(GET_REJECT_REASON);

  const [addRejectReasonToPosition] = useMutation(
    ADD_EXTERNAL_REJECT_REASONS_TO_POSITION
  );

  const [selectedRejectReasons, setSelectedRejectReasons] = useState([]);

  const nextStep = () => {
    setStep(allSteps[1]);
  };
  const handleSubmit = async () => {
    if (selectedRejectReasons.length > 0) {
      try {
        await addRejectReasonToPosition({
          variables: {
            publicID: publicPositionId,
            rejectReasons: selectedRejectReasons,
          },
        });
      } catch (err) {
        err.graphQLErrors.map((errorMessage) =>
          message.error(errorMessage.message)
        );
      }
    }

    nextStep();
  };

  const handleOnReasonClick = (e, itemId) => {
    const names = e.currentTarget.className.split(" ");
    if (!names.includes("saved")) {
      names.push("saved");
      e.currentTarget.className = names.join(" ");
      setSelectedRejectReasons([...selectedRejectReasons, itemId]);
    } else {
      names.pop("saved");
      const filteredNames = names.filter((item) => item !== "saved");
      e.currentTarget.className = filteredNames.join(" ");
      const filteredReasons = selectedRejectReasons.filter(
        (rejectReasonId) => rejectReasonId !== itemId
      );
      setSelectedRejectReasons([...filteredReasons]);
    }
  };

  if (queryLoading) return <Spinner width="42px" center />;
  if (queryError) return <PartialError />;

  return (
    <>
      <ModalHeader>
        <HeaderContainer>Why did you pass this position?</HeaderContainer>
      </ModalHeader>
      <ModalBody>
        <RowBlock
          justify="start"
          gridGap="10px"
          pt={{ mobileS: 1, tablet: 2, laptop: 3 }}
          pb={{ mobileS: 1, laptop: 2 }}
        >
          {data.allRejectReason.map((item) => (
            <TagContainer
              key={item.id}
              onClick={(e) => handleOnReasonClick(e, item.id)}
            >
              <Tag
                fontSize="14px"
                backgroundColor="inherit"
                content={item.label}
              />
            </TagContainer>
          ))}
        </RowBlock>
      </ModalBody>
      <ModalFooter>
        <Button size="large" variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </>
  );
};

const RedirectingToRegisterStep = ({ handleClose }) => {
  const history = useHistory();
  const handleRedirect = () => {
    history.push("/register");
  };
  return (
    <>
      <ModalHeader>
        <HeaderContainer>
          We have obtained information about this position and your reasons for
          not expressing interest in it.
        </HeaderContainer>
      </ModalHeader>
      <ModalBody>
        Would you like to become a member and see other positions as well?
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleRedirect} size="large" variant="primary">
          Let&apos;s go!
        </Button>
        <Button size="small" variant="secondary" onClick={handleClose}>
          Maybe later
        </Button>
      </ModalFooter>
    </>
  );
};

export default function ExternalRejectReasonModal({
  open,
  handleClose,
  publicPositionId,
}) {
  const allSteps = ["rejectReasonSelection", "redirectingToRegister"];
  const [step, setStep] = useState(allSteps[0]);

  let stepComponent;
  switch (step) {
    case allSteps[0]:
      stepComponent = (
        <RejectReasonSelectionStep
          setStep={setStep}
          allSteps={allSteps}
          publicPositionId={publicPositionId}
        />
      );
      break;

    case allSteps[1]:
      stepComponent = <RedirectingToRegisterStep handleClose={handleClose} />;
      break;

    default:
      break;
  }
  return (
    <>{open && <Modal handleClose={handleClose}>{stepComponent}</Modal>}</>
  );
}
