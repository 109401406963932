import React, { useEffect, useState } from "react";
import { space } from "styled-system";
import styled from "styled-components";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSwipeable } from "react-swipeable";
import device from "../../styles/device";
import MatchDetailHeader from "./MatchDetailHeader";
import {
  GET_MATCH,
  FILTER_MATCH_HISTORY_AND_ADD_ACTION,
} from "../../pages/Match/queries";
import { GET_DUMMY_DATA } from "../../pages/Match/DummyData";
import Spinner from "../Modules/Loading";
import { TgTabContent } from "../TgHeaderTabs";
import { PositionTab, CompanyTab, HiringProcessTab } from "./tabs";
import { PositionTabIcon, CompanyTabIcon, HiringProcessTabIcon } from "../Icon";
import { getMatchHeapInfo } from "../../helper";
import { MATCH_STATES, SORTERS } from "../../utils/constants";
import { heapIO } from "../../utils/heap";
import PartialError from "../../pages/ErrorPage/PartialError";

const DetailWrap = styled.div`
  ${space}
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  background-color: #ffffff;
  align-items: left;
  @media ${device.tablet} {
    border-radius: 10px 10px 0 0;
  }
`;

const tabs = [
  {
    name: "position",
    title: "About the role",
    icon: PositionTabIcon,
  },
  {
    name: "company",
    title: "About the company",
    icon: CompanyTabIcon,
  },
  {
    name: "hiring",
    title: "Hiring process",
    icon: HiringProcessTabIcon,
  },
];

const MISSING_PHONE_BOX_MATCH_STATES = [
  MATCH_STATES.INTERESTED,
  MATCH_STATES.TG_ASSESSMENT,
  MATCH_STATES.COMPANY_ASSESSMENT,
  MATCH_STATES.INTERVIEW,
  MATCH_STATES.SENT_OFFER,
];

const MatchDetail = ({ match, isEmpty, refetch }) => {
  const [getMatch, { loading, data, error }] = useLazyQuery(GET_MATCH);
  const [addAction] = useMutation(FILTER_MATCH_HISTORY_AND_ADD_ACTION);
  const [selectedMatch, setSelectedMatch] = useState();
  const [missingPhoneBox, setMissingPhoneBox] = useState(false);
  const [currentTab, setCurrentTab] = useState("position");

  useEffect(() => {
    setCurrentTab(match.state === MATCH_STATES.SENT ? "position" : "hiring");
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectedMatch]);

  useEffect(() => {
    const getMatchHandler = async () => {
      await getMatch({
        variables: {
          id: match.id,
          historySort: { createdAt: SORTERS.descend },
        },
      });

      if (data) {
        setSelectedMatch(data.match);
      }
    };
    if (match && !isEmpty) {
      getMatchHandler();
    }
    if (isEmpty) {
      const dummyMatches = GET_DUMMY_DATA();
      const result = dummyMatches.myMatches.filter((m) => m.id === match.id)[0];
      setSelectedMatch(result);
    }
    if (
      data &&
      data.match &&
      MISSING_PHONE_BOX_MATCH_STATES.includes(data.match.state) &&
      data.match.user &&
      (!data.match.user.phone || data.match.user.phone.trim().length === 0)
    ) {
      setMissingPhoneBox(true);
    } else {
      setMissingPhoneBox(false);
    }
  }, [getMatch, match, isEmpty, data]);

  useEffect(() => {
    if (match.user) {
      addAction({
        variables: {
          matchId: match.id,
        },
      });
    }
  }, [addAction, match]);

  useEffect(() => {
    if (currentTab === "company") {
      heapIO.createCustomEvent(
        "Match About the Company Clicked",
        getMatchHeapInfo(match)
      );
    }
    // eslint-disable-next-line
  }, [currentTab]);

  const handleLeft = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    if (currentIndex === tabs.length - 1) {
      return;
    }
    setCurrentTab(tabs[currentIndex + 1].name);
  };

  const handleRight = () => {
    const currentIndex = tabs.findIndex((tab) => tab.name === currentTab);
    if (currentIndex < 1) {
      return;
    }

    setCurrentTab(tabs[currentIndex - 1].name);
  };

  const swipeableHandlers = useSwipeable({
    onSwiped: (event) => {
      if (event.dir === "Left") {
        handleLeft();
      } else if (event.dir === "Right") {
        handleRight();
      }
    },
  });

  if (loading) return <Spinner width="20px" />;
  if (error) return <PartialError />;

  const getTab = () => {
    let Component = null;
    switch (currentTab) {
      case "position":
        Component = PositionTab;
        break;
      case "company":
        Component = CompanyTab;
        break;
      case "hiring":
        Component = HiringProcessTab;
        break;
      default:
        throw new Error(`Undefined tab: ${currentTab}`);
    }

    return (
      <Component
        match={selectedMatch}
        isEmpty={isEmpty}
        technologies={selectedMatch.position.company.technologies}
        company={selectedMatch.position.company}
        fields={selectedMatch.fields}
        heapInfo={getMatchHeapInfo(match)}
      />
    );
  };

  if (selectedMatch) {
    return (
      <DetailWrap px={{ mobileS: 3, tablet: 5 }} py={{ mobileS: 3, tablet: 4 }}>
        <MatchDetailHeader
          match={selectedMatch}
          refetch={refetch}
          isEmpty={isEmpty}
          missingPhoneBox={missingPhoneBox}
          setMissingPhoneBox={setMissingPhoneBox}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabs={tabs}
        />

        <TgTabContent swipeableHandlers={swipeableHandlers}>
          {getTab()}
        </TgTabContent>
      </DetailWrap>
    );
  }
  if (!selectedMatch) return <Spinner width="20px" />;
};

export default MatchDetail;
