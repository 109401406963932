import styled from "styled-components";
import { gridColumnGap } from "styled-system";
import device from "../../styles/device";

export const CompanyLogo = styled.img`
  width: ${(props) => (props.width ? props.width : "112px")};
  height: ${(props) => (props.height ? props.height : "112px")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "none")};
  @media ${device.mobileMax} {
    border-radius: 50%;
  }
`;

export const PositionName = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  color: #444444;
`;

export const CompanyName = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #444444;
`;

export const TgDescription = styled.div`
  text-align: center;
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #0c084c;
  margin-bottom: 80px;
  @media ${device.mobileMax} {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 45px;
`;

export const TechContainer = styled.div`
  margin-top: 25px;
`;

export const TgSloganContainer = styled.div`
  display: flex;
  background: #f4f6fc;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  color: #0b0849;
  margin-bottom: 30px;

  @media ${device.mobileMax} {
    font-size: 14px;
    padding-left: 45px;
    padding-right: 45px;
    text-align: center;
  }
`;

export const SingleLogo = styled.img`
  margin-right: 32px;
  @media ${device.mobileMax} {
    margin-right: 10px;
    width: 34px;
    height: 38px;
  }
`;

export const Image = styled.img`
  max-width: ${(props) => (props.width ? props.width : "375 px")};
  max-height: ${(props) => (props.height ? props.height : "305 px")};
`;

export const Title = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  width: 100%;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: #0c084c;
  margin-bottom: 25px;
  @media ${device.mobileMax} {
    font-size: 18px;
    margin-top: 37px;
    margin-bottom: 14px;
  }
`;
export const ImageDescription = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: #000000;
  @media ${device.mobileMax} {
    font-size: 14px;
    margin-bottom: 67px;
  }
`;

export const PositionSlogan = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #444444;
  margin-top: 10px;
`;

export const TalentProfileContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 35px;

  @media ${device.mobileMax} {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
`;

export const TalentProfileBox = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 29px 23px 67px 23px;
`;

export const TalentAvatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 20px;
`;
export const TalentName = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  color: #0b0849;
`;
export const TalentRole = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;
export const TalentCompany = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;
export const TalentDescription = styled.div`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;

export const CompanyInfoBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background: #f6f6f6;
  border-radius: 5px;
`;

export const List = styled.ul`
  padding-left: 16px;
  display: flex;
  flex-flow: row wrap;
`;

export const Item = styled.li`
  margin-right: 22px;
  color: #979797;
  font-family: ${(props) => props.theme.family.demi};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  &::before {
    color: #0c084c;
    margin: 0px;
    left: -16px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
  ${gridColumnGap};
  @media ${device.tablet} {
    flex-direction: row;
  }
  > label {
    width: 100%;
    font-family: ${(props) => props.theme.family.bold};
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: #444444;

    @media ${device.tablet} {
      width: 40%;
    }
    @media ${device.laptop} {
      width: ${(props) => (props.labelWidth ? props.labelWidth : "30%")};
    }
  }
  > span {
    width: 100%;
    font-family: ${(props) => props.theme.family.medium};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.79;
    color: #444444;

    @media ${device.tablet} {
      width: 60%;
    }
    @media ${device.laptop} {
      width: 70%;
    }
  }
`;
