import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { space } from "styled-system";
import { ButtonLink } from "tg-design";
import { GET_COMPANY } from "./queries";
import { CompanyTab } from "../../Match/tabs";
import Loading from "../../Modules/Loading";
import DataGroup from "../../Modules/DataGroup";
import { ArrowLeft } from "../../Icon";
import { RowBlock } from "../../BlockUi";
import device from "../../../styles/device";
import Pagination from "../../Pagination/Pagination";
import PartialError from "../../../pages/ErrorPage/PartialError";
import { isMobile } from "../../../helper";

const Wrapper = styled.div`
  ${space}
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  background-color: ${(props) => props.background || "#ffffff"};
  align-items: left;
  @media ${device.tablet} {
    border-radius: 10px 10px 0 0;
  }
`;

const PositionTitle = styled.div`
  font-size: 14px;
  padding: 10px 0;
`;

const Back = styled.span`
  font-size: 16px;
  text-decoration: underline;
`;

export default function CompanyDetail({ companyId, setCompany }) {
  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { id: companyId },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [listItemPerPage] = useState(5);

  const handlePaginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <Loading width="70px" />;
  if (error) return <PartialError />;

  const indexOfLastItem = currentPage * listItemPerPage;
  const indexOfFirstItem = indexOfLastItem - listItemPerPage;
  const currentListItems = data?.company?.positions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <>
      {!isMobile() && (
        <Wrapper background="none" mb={3}>
          <ButtonLink type="button" onClick={() => setCompany(null)}>
            <RowBlock justify="start" align="center" gridGap="5px">
              <ArrowLeft color="#AAAAAA" /> <Back>Back</Back>
            </RowBlock>
          </ButtonLink>
        </Wrapper>
      )}
      <Wrapper
        px={{ mobileS: 3, tablet: "50px" }}
        py={{ mobileS: "44px", tablet: "30px" }}
      >
        {isMobile() && (
          <ButtonLink type="button" onClick={() => setCompany(null)}>
            <RowBlock justify="start" align="center" gridGap="5px">
              <ArrowLeft color="#AAAAAA" /> <Back>Back</Back>
            </RowBlock>
          </ButtonLink>
        )}

        {data?.company && (
          <CompanyTab
            technologies={data.company.technologies}
            company={data.company}
          />
        )}
      </Wrapper>
      <Wrapper
        px={{ mobileS: 3, tablet: "50px" }}
        pt={{ mobileS: "30px" }}
        pb={{ mobileS: "84px", tablet: "30px" }}
        my={{ mobileS: "10px", tablet: "35px" }}
      >
        <RowBlock justify="start">
          <DataGroup
            title="Positions opened at TalentGrid"
            containerStyles={{ width: "100%", height: "260px" }}
          >
            <hr style={{ marginTop: "10px" }} />
            {currentListItems.length
              ? currentListItems.map((i, index) => (
                  <>
                    <PositionTitle key={index}>{i.title}</PositionTitle>
                    <hr />
                  </>
                ))
              : "No positions found."}
          </DataGroup>
          <Pagination
            itemPerPage={listItemPerPage}
            totalItems={data?.company?.positions?.length}
            handlePaginate={handlePaginate}
            navStyles={{ margin: "auto" }}
          />
        </RowBlock>
      </Wrapper>
    </>
  );
}
