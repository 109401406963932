import React, { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import makeAnimated from "react-select/animated";
import FormScaffold from "../FormScaffold";
import { RowBlock } from "../../BlockUi";
import Toggle from "../Toggle";
import { AsyncSelectField } from "../../Form/SelectField";
import { captureErrorWithData, debounce } from "../../../helper";
import { TECH_CATEGORY_TITLES } from "../../../utils/constants";

const otherSkills = [
  { title: "Mimari teknolojiler", name: "architecture", id: 6 },
  { title: "Veritabanı sistemleri", name: "database", id: 7 },
  { title: "Bulut teknolojileri", name: "cloud", id: 3 },
  { title: "Versiyon kontrol araçları", name: "versioning", id: 4 },
  { title: "CI/CD Araçları", name: "cicd", id: 8 },
  { title: "Metodoloji ve uygulamalar", name: "methodologies", id: 9 },
  { title: "İş yönetim araçları", name: "tool", id: 2 },
  { title: "Analitik ve raporlama araçları", name: "analytics", id: 10 },
];

export function filterData(data, filterObj) {
  return data.reduce((acc, val) => {
    const category = filterObj[val.categoryId];
    if (!acc[category]) {
      acc[category] = [val];
    } else {
      acc[category].push(val);
    }
    return acc;
  }, {});
}

const animatedComponents = makeAnimated();

const UPDATE_USER = gql`
  mutation UpdateUser($otherSkills: [ID]) {
    updateUser(otherSkills: $otherSkills) {
      id
    }
  }
`;

export default function OtherSkills({ user, handleSearch, handleUpdate }) {
  const [state, setState] = useState(user.otherSkills);
  const [itemStatus, setItemStatus] = useState();
  const [updateUser] = useMutation(UPDATE_USER);

  const handleSubmit = async (newState) => {
    const otherSkillIds = newState.reduce((acc, val) => {
      acc.push(val.id);
      return acc;
    }, []);
    try {
      await updateUser({
        variables: {
          otherSkills: otherSkillIds,
        },
      });
      setItemStatus("success");
      handleUpdate();
    } catch (error) {
      setItemStatus("error");
      captureErrorWithData(error);
    }
  };

  const debouncedSave = useCallback(
    debounce((newState) => {
      setItemStatus("loading");
      handleSubmit(newState);
    }, 500),
    []
  );

  const handleChange = (value, id) => {
    setItemStatus("pending");
    const filteredState = state.filter((skill) => skill.categoryId !== id);
    if (value) {
      setState([...filteredState, ...value]);
      debouncedSave([...filteredState, ...value]);
    } else {
      setState([...filteredState]);
      debouncedSave([...filteredState]);
    }
  };

  let status = "pending";
  if (itemStatus) {
    status = state.length === 0 ? "pending" : itemStatus;
  } else {
    status = state.length > 0 ? "success" : "pending";
  }

  return (
    <div>
      <FormScaffold
        status={status}
        label="Do you have experience in the categories below?"
        explanation="Under each category, you can input the tools and technologies you have experienced."
      >
        {otherSkills.map((skill) => (
          <RowBlock key={skill.name} width="100%" mb={4}>
            <Toggle
              title={TECH_CATEGORY_TITLES[skill.name]}
              open={state.some((val) => val.categoryId === skill.id)}
              autoMinHeight
            >
              <AsyncSelectField
                defaultValue={state.filter(
                  (val) => val.categoryId === skill.id
                )}
                noOptionsMessage={({ inputValue }) =>
                  inputValue ? "No results found" : "Type something to search"
                }
                onChange={(val) => handleChange(val, skill.id)}
                getOptionLabel={(option) => `${option.title}`}
                getOptionValue={(option) => `${option.id}`}
                components={animatedComponents}
                isMulti
                closeMenuOnSelect={false}
                placeholder="Search technology..."
                loadOptions={(val, func) =>
                  handleSearch({ value: val, categoryIds: [skill.id] }, func)
                }
              />
            </Toggle>
          </RowBlock>
        ))}
      </FormScaffold>
    </div>
  );
}
