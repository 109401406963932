/* eslint react/forbid-prop-types: 0 */

import React, { useState } from "react";
import styled from "styled-components";
import { space } from "styled-system";

const RoleButton = styled.button`
  color: #444444;
  height: 30px;
  cursor: pointer;
  background: #fff;
  border: solid 2px;
  border-radius: 5px;
  margin-right: 15px;
  border-color: ${(props) => props.theme.colors.green[1]};
  ${space}
  font-size: 14px;
  font-family: ${(props) => props.theme.family.demi};
  padding: 4px 12px;
  display: ${(props) => (props.isHide ? "none" : "initial")};
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
`;

export default function RoleSelect({
  handleSelectedRole,
  roles,
  selectedRoles,
}) {
  const [roleList, setRoleList] = useState(roles);
  const setSelectedRole = (role) => {
    handleSelectedRole({ id: role.id, title: role.title, experience: "" });
    setRoleList(roleList.filter((s) => s.id !== role.id));
  };

  return (
    <List>
      {roleList.slice(0, 6).map((role) => (
        <RoleButton
          id={role.id}
          type="button"
          mt={3}
          key={role.id}
          onClick={() => setSelectedRole(role)}
          isHide={selectedRoles.some((val) => val.id === role.id)}
        >
          {role.title}
        </RoleButton>
      ))}
    </List>
  );
}
