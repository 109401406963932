import React, { useEffect, useState, useContext } from "react";
import { Route, useRouteMatch, useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Container, ColumnBlock, RowBlock } from "../../components/BlockUi";
import { Header, MobileNavigator } from "../../components/Header";
import MatchCardList from "../../components/Match/MatchCardList";
import MatchDetail from "../../components/Match/MatchDetail";
import {
  isMobile,
  handleHeadTag,
  isPositionOpen,
  isMatchActionActive,
} from "../../helper";
import MobileDetail from "./MobileDetail";
import Spinner from "../../components/Modules/Loading";
import {
  ButtonContainer,
  HeaderContainer,
  signalStyle,
  signalStyleMobile,
  Text,
  GET_DUMMY_DATA,
} from "./DummyData";
import { GET_MATCH_CARDS } from "./queries";
import { GET_USER_IMPORT_COUNT } from "../../components/Profile/Sidebar/queries";
import { GET_USER_STATUS } from "../../graphql/queries";
import { Button } from "../../components/Button";
import ProfileGreeting from "../../components/ProfileGreeting";
import ErrorPage from "../ErrorPage";
import { getLocalStorage } from "../../utils/localStorage";
import {
  MATCH_SORT_FILTER_KEY,
  MATCH_STATE_FILTERS_KEY,
} from "../../utils/localStorageConstants";
import {
  DEFAULT_MATCH_FILTERS,
  DEFAULT_SORT_FILTER,
} from "../../utils/constants";
import { setMatchFilterActive } from "../../store/slices/matchFilter";
import PulsingSignal from "../../components/Modules/PulsingSignal";
import AppContext from "../../AppContext";

const ScrollableBox = styled.div`
  height: 100vh;
  overflow: scroll;
`;

const sortMatches = (matches) => {
  return [...matches]
    .sort((a, b) =>
      isPositionOpen(a.position.state) === isPositionOpen(b.position.state)
        ? 0
        : isPositionOpen(a.position.state)
        ? -1
        : 1
    )
    .sort((a, b) =>
      isMatchActionActive(a.state) === isMatchActionActive(b.state)
        ? 0
        : isMatchActionActive(a.state)
        ? -1
        : 1
    );
};

const stateFilters = getLocalStorage({
  key: MATCH_STATE_FILTERS_KEY,
  isJSON: true,
  defaultValue: DEFAULT_MATCH_FILTERS,
});

const sortFilter = getLocalStorage({
  key: MATCH_SORT_FILTER_KEY,
  defaultValue: DEFAULT_SORT_FILTER,
});

export default function Match() {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);

  const [selectedMatch, setSelectedMatch] = useState();
  const [sortedMatches, setSortedMatches] = useState();

  const [mobile, setMobile] = useState(false);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER_STATUS, {
    fetchPolicy: `network-only`,
  });

  const { data, error, refetch } = useQuery(GET_MATCH_CARDS, {
    variables: {
      filters: { state: { in: stateFilters } },
      sort: { [sortFilter]: "DESC" },
    },
  });

  const {
    data: importCountData,
    loading: importCountLoading,
    error: importCountError,
  } = useQuery(GET_USER_IMPORT_COUNT, {
    fetchPolicy: "network-only",
  });

  const history = useHistory();
  const { path } = useRouteMatch();
  const { matchId } = useParams();

  const dummyMatches = GET_DUMMY_DATA();

  useEffect(() => {
    let sorted = [];
    const matches = data?.myMatches?.matches;
    const totalCount = data?.myMatches?.totalCount;

    if (data && totalCount > 0) {
      if (matches?.length > 0) {
        sorted = sortMatches(matches);
        setSortedMatches(sorted);
        const hasSelectedMatch = sorted.some((m) => m.id === matchId);
        if (hasSelectedMatch) {
          setSelectedMatch(sorted.filter((m) => m.id === matchId)[0]);
        }
        if (!hasSelectedMatch) {
          setSelectedMatch(sorted[0]);
          history.push(`/matches/${isMobile() ? "" : sorted[0].id}`);
        }
      }
      if (matches?.length === 0) {
        setSortedMatches([]);
        setSelectedMatch(null);
        history.push("/matches/");
      }
    }

    if (isMobile() && matchId) {
      setMobile(true);
    }

    if (matches?.length === 0 && totalCount === 0) {
      setSelectedMatch(
        matchId
          ? dummyMatches.myMatches.filter((m) => m.id === matchId)[0]
          : dummyMatches.myMatches[0]
      );
    }
    // eslint-disable-next-line
  }, [matchId, mobile, data]);

  useEffect(() => {
    if (data) {
      dispatch(setMatchFilterActive(data.myMatches?.totalCount > 0));
    }
    // eslint-disable-next-line
  }, [data]);

  const handleClickOnCard = (match) => {
    if (isMobile()) {
      setMobile(true);
    }
    history.push(`/matches/${match.id}`);
    if (match.id !== selectedMatch.id) {
      refetch();
    }
  };
  handleHeadTag("match");
  if (userLoading || importCountLoading)
    return <Spinner width="70px" fullScreen />;
  if (error || userError || importCountError)
    return <ErrorPage error={error || userError || importCountError} />;

  const matches = data?.myMatches?.matches;
  const totalCount = data?.myMatches?.totalCount;
  const importCount = importCountData.findImportCountByUserId;

  const handleClickOnComplete = () => {
    if (importCount === 0) {
      history.push("/profile/edit/import");
    } else {
      history.push("/profile/edit/info");
    }
  };

  return (
    <>
      {mobile && selectedMatch && matchId ? (
        <MobileDetail
          match={selectedMatch}
          refetch={refetch}
          isEmpty={matches?.length === 0 && totalCount === 0}
        />
      ) : (
        <>
          <Header />
          <Container
            display="block"
            px={{ mobileS: "20px", tablet: "0px" }}
            mt={4}
          >
            <RowBlock width="100%" justify="space-between" align="start">
              {data && matches?.length === 0 && totalCount === 0 && (
                <>
                  {userData && userData?.me?.status === "LEAD" && (
                    <RowBlock mb={4}>
                      <ProfileGreeting user={userData.me} />
                    </RowBlock>
                  )}
                  <RowBlock
                    width="100%"
                    justify="space-between"
                    align="start"
                    mb={4}
                  >
                    <HeaderContainer
                      py={{ mobileS: 4, laptop: 4, tablet: 4 }}
                      px={{ mobileS: 3, laptop: 5, tablet: 4 }}
                    >
                      <Text style={{ lineHeight: "25px" }}>
                        <b>
                          You don&apos;t have any matching positions at the
                          moment.
                        </b>{" "}
                        ⌛
                      </Text>
                      <Text style={{ lineHeight: "25px" }}>
                        When you match with new positions in the future, they
                        will look like this:{" "}
                        <span role="img" aria-label="sand-watch">
                          👇
                        </span>
                      </Text>
                      {appContext?.profilePercentage < 100 &&
                      userData?.me?.status !== "LEAD" ? (
                        <ButtonContainer mt={{ mobileS: 4 }}>
                          <Text>
                            Users who with more complete profiles tend to get
                            matched more often.
                          </Text>
                          <Button
                            type="button"
                            variant="primary"
                            size="large"
                            my={{ mobileS: 2, tablet: 3 }}
                            className="web"
                            onClick={() => handleClickOnComplete()}
                          >
                            Complete Profile
                            <PulsingSignal signalStyle={signalStyle} />
                          </Button>
                          <Button
                            type="button"
                            variant="primary"
                            size="large"
                            my={{ mobileS: 2, tablet: 3 }}
                            className="mobile"
                            onClick={() => history.push("/profile/edit/info")}
                          >
                            Complete Profile
                            <PulsingSignal signalStyle={signalStyleMobile} />
                          </Button>
                        </ButtonContainer>
                      ) : (
                        ""
                      )}
                    </HeaderContainer>
                  </RowBlock>
                </>
              )}
              <ColumnBlock
                width={{ mobileS: "100%", tablet: "40%", laptop: "35%" }}
              >
                <MatchCardList
                  activeMatchId={selectedMatch?.id}
                  matches={
                    totalCount === 0 ? dummyMatches.myMatches : sortedMatches
                  }
                  handleSelect={handleClickOnCard}
                  refetchMyMatches={refetch}
                  isEmpty={matches?.length === 0 && totalCount === 0}
                />
              </ColumnBlock>
              <ColumnBlock
                pl={{ mobileS: "0px", tablet: "24px" }}
                width={{ tablet: "60%", mobileL: "0%", laptop: "65%" }}
                display={{ mobileS: "none", tablet: "flex" }}
              >
                <ScrollableBox>
                  <Route path={`${path}/:matchId?`}>
                    {selectedMatch && (
                      <MatchDetail
                        match={selectedMatch}
                        refetch={refetch}
                        isEmpty={matches?.length === 0 && totalCount === 0}
                      />
                    )}
                  </Route>
                </ScrollableBox>
              </ColumnBlock>
            </RowBlock>
          </Container>
          <MobileNavigator />
        </>
      )}
    </>
  );
}
