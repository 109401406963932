import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { space } from "styled-system";
import { Container, PublicPositionBox } from "../../components/BlockUi";
import { SingleHeader } from "../../components/Header";
import PublicPosition from "../../components/PublicPosition";
import CreateProfileBarWeb from "../../components/PublicPosition/CreateProfileBar";
import { GET_PUBLIC_POSITION } from "../queries";

import device from "../../styles/device";
import patternLeft from "../../images/left-pattern.png";
import patternRight from "../../images/right-pattern.png";
import Loading from "../../components/Modules/Loading";
import ErrorPage from "../ErrorPage";
import { GET_USER } from "../../graphql/queries";
import {
  MATCH_CREATION_TYPES,
  PUBLIC_LINK_TYPES,
  HttpStatusCodes,
} from "../../utils/constants";
import { useURLQuery } from "../../utils/hooks";
import NotFoundErrorPage from "../NotFoundErrorPage";
import { SetUTMParamsToSession } from "../../utils/utms";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-size: 300px 900px;
  background-repeat: no-repeat;
  background-position: left top, right top;
  ${space}
  @media ${device.tablet} {
    background-image: url(${(props) =>
        props.background ? patternLeft : "none"}),
      url(${(props) => (props.background ? patternRight : "none")});
  }
`;

export default function PublicPositionPage() {
  const { id } = useParams();
  const queryString = useURLQuery();

  const [matchCreationType, setMatchCreationType] = useState(
    MATCH_CREATION_TYPES.LANDING_PAGE
  );
  const [referrerCode, setReferrerCode] = useState(null);
  const [forceMatchToken, setForceMatchToken] = useState(null);

  const [
    getUserData,
    { data: userData, loading: userLoading, error: userError },
  ] = useLazyQuery(GET_USER, {
    fetchPolicy: `network-only`,
  });

  const history = useHistory();

  const { loading, error, data } = useQuery(GET_PUBLIC_POSITION, {
    variables: { publicID: id },
    fetchPolicy: "no-cache",
  });

  const [isUserAuth, setIsUserAuth] = useState(false);

  useEffect(() => {
    const isAuth = localStorage.getItem("isAuth");

    if (isAuth) {
      getUserData();
      return setIsUserAuth(true);
    }
    return setIsUserAuth(false);
  }, [getUserData]);

  useEffect(() => {
    sessionStorage.removeItem("ppi");
    /*  
    Eğer kullanıcı yanlışlıkla Intersted butonuna basıp daha sonra landing sayfasına geri gelirse 
    public position info'yu (ppi) kaldırmamız lazım.
    */

    const typeOfPublicLink = history.location.pathname.split("/")[1];
    const invitationCode = queryString.get("invite");
    const fmtToken = queryString.get("fmt");

    if (fmtToken) {
      setForceMatchToken(fmtToken);
    }

    if (invitationCode) {
      setReferrerCode(invitationCode);
      setMatchCreationType(MATCH_CREATION_TYPES.REFERRAL);
    } else if (
      PUBLIC_LINK_TYPES[typeOfPublicLink] ===
      MATCH_CREATION_TYPES.MARKETING_PAGE
    ) {
      setMatchCreationType(MATCH_CREATION_TYPES.MARKETING_PAGE);
    } else {
      setMatchCreationType(MATCH_CREATION_TYPES.LANDING_PAGE);
    }

    SetUTMParamsToSession();
    // eslint-disable-next-line
  }, []);

  if (loading || userLoading) return <Loading fullScreen width="70px" />;
  if (userError) return <ErrorPage error={userError} />;
  if (error) {
    const isNotFoundError = error.graphQLErrors.find(
      (errorElement) => errorElement.statusCode === HttpStatusCodes.NOT_FOUND
    );
    if (isNotFoundError) return <NotFoundErrorPage />;
    return <ErrorPage error={error} />;
  }

  const { publicPosition } = data;

  return (
    <Box background>
      <SingleHeader to="/" />
      <Container>
        <PublicPositionBox
          pt={{ mobileS: 4, laptop: 5, tablet: 4 }}
          pb={{ mobileS: 4, laptop: 5, tablet: 4 }}
          px={{ mobileS: 27, laptop: 5, tablet: 4 }}
          mt={{ mobileS: 4, laptop: 80, tablet: 4 }}
        >
          <PublicPosition
            forceMatchToken={forceMatchToken}
            publicPosition={publicPosition}
            isUserAuth={isUserAuth}
            userData={userData}
            referrerCode={referrerCode}
            matchCreationType={matchCreationType}
          />
        </PublicPositionBox>
        <CreateProfileBarWeb
          forceMatchToken={forceMatchToken}
          publicPosition={publicPosition}
          token={publicPosition.publicPositionToken}
          isUserAuth={isUserAuth}
          userData={userData}
          referrerCode={referrerCode}
          matchCreationType={matchCreationType}
        />
      </Container>
    </Box>
  );
}
