import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import styled from "styled-components";
import { space } from "styled-system";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PhoneField,
} from "tg-design";
import { useLazyCaller } from "../../../utils/hooks";
import { Input } from "../../FormUi";
import { SelectField, CitySelectField } from "../../Form/SelectField";
import Textarea from "../../Form/Textarea";
import TextField from "../../Form/TextField";
import {
  rangeOfYears,
  getExperienceOptions,
  removeTypename,
  captureErrorWithData,
} from "../../../helper";
import FormScaffold from "../FormScaffold";
import Spinner from "../../Modules/Loading";
import ProfileImage from "./ProfileImage";
import { basicInfoValidationSchema } from "./validationSchema";
import IntercomLauncherMessage from "../../Modules/IntercomLauncherMessage";
import { Button } from "../../Button";
import { SalaryCritieria } from "../../Criterias";
import { UPDATE_CRITERIA, UPDATE_USER } from "../queries";
import PartialError from "../../../pages/ErrorPage/PartialError";
import { ImportLinkedinButton } from "../ProfileImport/CommonStyles";
import { SwitchHeading, SwitchTitle } from "../CommonStyles";
import {
  ACTION_BUTTON_TITLES,
  QUICK_EDIT_DATA_SESSION_IDS,
} from "../../../utils/constants";
import UploadResume from "./UploadResume";
import Roles from "./Roles";
import Switch from "../../Form/ToggleSwitch";

const Wrapper = styled.div`
  ${space}
`;

const THIS_YEAR = new Date().getFullYear();
const years = rangeOfYears(THIS_YEAR - 70, THIS_YEAR - 18)
  .map((year) => {
    return { title: year, id: year };
  })
  .sort((a, b) => b.id - a.id);

const GET_ROLES = gql`
  {
    allRole(limit: 0) {
      roles {
        id
        title
      }
    }
  }
`;

const SOCIAL_FIELDS = [
  "linkedin",
  "github",
  "stackoverflow",
  "portfolio",
  "twitter",
  "youtube",
  "blog",
];

const getStatusFieldCode = (changedField) => {
  if (SOCIAL_FIELDS.includes(changedField)) {
    return "social";
  }
  return changedField;
};

const SocialInput = ({ ...props }) => {
  return (
    <TextField
      otherStyle={{ alignItems: "flex-start" }}
      textAlign="left"
      valueAlign="left"
      width="100%"
      {...props}
    />
  );
};

export default function BasicInfos({ user, onUpdate }) {
  const experienceOptions = getExperienceOptions();

  const {
    loading: roleLoading,
    error: roleError,
    data: roleData,
  } = useQuery(GET_ROLES);
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateCriteria] = useMutation(UPDATE_CRITERIA);
  const [itemStatus, setItemStatus] = useState({});
  const [modalState, setModalState] = useState({ open: false });
  const lazyCaller = useLazyCaller();

  const history = useHistory();

  const cvRef = useRef(null);

  const [state, setState] = useState({
    ...user,
    role: user.role?.id || null,
    resume: {
      fileName: user?.resume?.fileName || null,
      resumeURL: user?.resume?.resumeURL || null,
    },
    linkedin: user.social.linkedin,
    github: user.social.github,
    stackoverflow: user.social.stackoverflow,
    portfolio: user.social.portfolio,
    twitter: user.social.twitter,
    youtube: user.social.youtube,
    blog: user.social.blog,
  });

  const handleSubmit = async (newState, changedField) => {
    const statusFieldCode = getStatusFieldCode(changedField);
    try {
      newState.livingCity = newState.livingCity && newState.livingCity.id;

      setItemStatus({ ...itemStatus, [statusFieldCode]: "loading" });
      const result = await updateUser({
        variables: {
          ...newState,
        },
      });
      setItemStatus({ ...itemStatus, [statusFieldCode]: "success" });
      const { preferences, criteria } = result.data.updateUser;
      if (
        changedField === "livingCity" &&
        user.preferences.currency !== preferences.currency
      ) {
        setModalState({
          open: true,
          salary: { ...criteria.salary, currency: preferences.currency },
        });
      }
      onUpdate({ user: new Date() });
    } catch (error) {
      setItemStatus({ ...itemStatus, [statusFieldCode]: "error" });
      captureErrorWithData(error);
    }
  };

  const handleSalarySubmit = async () => {
    const salary = removeTypename(modalState.salary);
    salary.open = true;
    const result = await updateCriteria({
      variables: { salary },
    });
    if (result.data) {
      setModalState({ open: false });
      onUpdate({ criteria: new Date() });
    }
  };

  // useCallback kullanamıyoruz. itemStatus'u kaybettiğimizde valide olmamış alanlar
  // başka bir validasyondan sonra valide olmuş gibi işaretleniyor.
  const validateAndSave = (newState, changedField, changes) => {
    const statusFieldCode = getStatusFieldCode(changedField);
    setItemStatus({ ...itemStatus, [statusFieldCode]: "pending" });
    basicInfoValidationSchema.fields[changedField]
      .validate(changes[changedField])
      .then(() => {
        setState({
          ...state,
          ...newState,
        });
        lazyCaller(() => handleSubmit(newState, changedField), 500);
      })
      .catch(() => {
        setItemStatus({ ...itemStatus, [statusFieldCode]: "error" });
      });
  };

  const applyChange = (changes) => {
    const item = Object.keys(changes)[0];
    let tempChanges = changes;
    if (item === "openToWork") {
      tempChanges = {
        preferences: {
          openToWork: !state.preferences.openToWork,
        },
      };
    }
    if (SOCIAL_FIELDS.includes(item)) {
      tempChanges = {
        social: {
          linkedin:
            changes.linkedin !== undefined
              ? changes.linkedin
              : state.social.linkedin,
          github:
            changes.github !== undefined ? changes.github : state.social.github,
          stackoverflow:
            changes.stackoverflow !== undefined
              ? changes.stackoverflow
              : state.social.stackoverflow,
          portfolio:
            changes.portfolio !== undefined
              ? changes.portfolio
              : state.social.portfolio,
          twitter:
            changes.twitter !== undefined
              ? changes.twitter
              : state.social.twitter,
          youtube:
            changes.youtube !== undefined
              ? changes.youtube
              : state.social.youtube,
          blog: changes.blog !== undefined ? changes.blog : state.social.blog,
        },
      };
    }

    validateAndSave(tempChanges, item, changes);
  };

  const handleChange = (event, select) => {
    if (event.target) {
      const { name, value } = event.target;
      applyChange({ [name]: value });
    } else {
      let val = event.id;
      if (select.name === ("experience" || "birthyear")) {
        val = Number(val);
      }
      applyChange({ [select.name]: val });
    }
  };

  const handleOpenToWorkChange = () => {
    applyChange({ openToWork: !state.preferences.openToWork });
  };

  const cityHandler = (val) => {
    applyChange({ livingCity: val });
  };

  const phoneHandler = (val) => {
    if (state.phone === val) {
      return;
    }
    applyChange({ phone: val });
  };

  const fileURLHandler = (field, val) => {
    applyChange({ [field]: val });
  };

  const getItemStatus = (name) => {
    let status = "pending";
    if (itemStatus[name]) {
      status = itemStatus[name];
    } else {
      status = state[name] ? "success" : "pending";
    }
    if (name === "avatarURL" && !state[name]) {
      status = "pending";
    }

    if (name === "resume" && !state.resume.resumeURL) {
      status = "pending";
    }

    if (name === "openToWork" && state.preferences.openToWork) {
      status = "success";
    }
    if (name === "openToWork" && !state.preferences.openToWork) {
      status = "pending";
    }

    if (name === "social") {
      if (itemStatus[name] && itemStatus[name] !== "success") {
        return itemStatus[name];
      }

      status = "pending";
      SOCIAL_FIELDS.forEach((key) => {
        if (state.social[key]) {
          status = "success";
        }
      });

      return status;
    }

    if (name === "experience" && user.experience === 0) {
      if (itemStatus[name]) {
        return itemStatus[name];
      }
      return "success";
    }

    return status;
  };

  if (roleLoading) return <Spinner center width="50px" mt={4} />;
  if (roleError) return <PartialError />;

  const focus = window.location.hash.substr(1);

  if (cvRef.current && focus === "cv") {
    cvRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  const handleModal = () => {
    setModalState({ open: false });
  };

  const handleSalaryCriteria = (salary) => {
    salary = {
      open: true,
      ...salary,
    };
    setModalState({ ...modalState, salary });
  };

  const handleLinkedinImport = () => {
    // Eğer Quickbox kısmında eksik dillerin gösterildiği kısım skip edilmişse ve sonra linkedin crawler çalıştırıldığında yine boş dil seviyesi gelmişse
    // quickbox'taki eksik dil bölümünü tekrar aktif etmek için sessionId'yi kaldırıyoruz.
    sessionStorage.removeItem(
      `qip-${QUICK_EDIT_DATA_SESSION_IDS.MISSING_LANGUAGE_LEVEL}`
    );
    history.push("/profile/edit/import", {
      from: ACTION_BUTTON_TITLES.IMPORT_LINKEDIN_BUTTON,
    });
  };

  const handleCvImport = () => {
    history.push("/profile/edit/import", {
      from: ACTION_BUTTON_TITLES.IMPORT_RESUME_BUTTON,
    });
  };

  return (
    <>
      <FormScaffold
        key="openToWork"
        status={getItemStatus("openToWork")}
        label="Actively looking for a job?"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <SwitchHeading>
            <SwitchTitle mr={3} size="sm" active={state.preferences.openToWork}>
              Actively Looking
            </SwitchTitle>
            <Switch
              onChange={handleOpenToWorkChange}
              name="openToWork"
              active={state.preferences.openToWork}
            />
          </SwitchHeading>
        </Wrapper>
      </FormScaffold>
      <FormScaffold
        key="avatarURL"
        status={getItemStatus("avatarURL")}
        label="Profile photo"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <ProfileImage
            name="avatarURL"
            defaultValue={state.avatarURL}
            onChange={(val) => fileURLHandler("avatarURL", val)}
            avatar={state.avatarURL}
          />
        </Wrapper>
      </FormScaffold>
      <FormScaffold
        key="bio"
        status={getItemStatus("bio")}
        label="About"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <Textarea
            name="bio"
            defaultValue={state.bio}
            onChange={handleChange}
            rows="5"
            autoFocus={focus === "bio"}
          />
        </Wrapper>
      </FormScaffold>
      <FormScaffold
        key="name"
        status={getItemStatus("name")}
        label="Full name"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <Input
            name="name"
            defaultValue={state.name}
            onChange={handleChange}
            textAlign="left"
            valueAlign="left"
            width="100%"
          />
        </Wrapper>
      </FormScaffold>
      <FormScaffold
        key="experience"
        status={getItemStatus("experience")}
        label="Total professional experience"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <SelectField
            name="experience"
            defaultValue={experienceOptions.filter(
              (obj) => parseInt(obj.id) === state.experience
            )}
            onChange={handleChange}
            placeholder=""
            options={experienceOptions}
            getOptionLabel={(option) => option.title || option.label}
            getOptionValue={(option) => option.id || option.value}
          />
        </Wrapper>
      </FormScaffold>
      <Roles user={user} onUpdate={onUpdate} roleData={roleData} />
      <FormScaffold
        key="birthyear"
        status={getItemStatus("birthyear")}
        label="Birth year"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <SelectField
            name="birthyear"
            defaultValue={years.filter((obj) => obj.id === state.birthyear)}
            onChange={handleChange}
            placeholder=""
            options={years}
            getOptionLabel={(option) => option.title || option.label}
            getOptionValue={(option) => option.id || option.value}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? (
                <IntercomLauncherMessage />
              ) : (
                "Type something to search"
              )
            }
          />
        </Wrapper>
      </FormScaffold>
      <FormScaffold
        key="livingCity"
        status={getItemStatus("livingCity")}
        label="City of residence"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        {modalState.open && (
          <Modal handleClose={handleModal} width="480px">
            <ModalHeader>
              You’ve changed your location. Would you like to update your salary
              preferences?
            </ModalHeader>
            <ModalBody>
              <SalaryCritieria
                rangeWidth
                handleOnChange={handleSalaryCriteria}
                selectedValues={modalState.salary}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary"
                size="large"
                onClick={handleSalarySubmit}
              >
                Update
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <CitySelectField
            name="livingCity"
            defaultValue={state.livingCity}
            onChange={cityHandler}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? (
                <IntercomLauncherMessage />
              ) : (
                "Type something to search"
              )
            }
          />
        </Wrapper>
      </FormScaffold>
      <FormScaffold
        key="phone"
        status={getItemStatus("phone")}
        label="Phone"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <PhoneField
            inputStyle={{
              background: "rgb(246,246,248)",
            }}
            selectFieldStyle={{
              controlStyle: {
                background: "rgb(246,246,248)",
                border: "2px solid rgb(246,246,248) ",
              },
            }}
            customStatusStyle={{
              error: "2px solid red",
              focused: "2px solid #abe6c1",
            }}
            name="phone"
            defaultValue={[state.phone]}
            onChange={phoneHandler}
            livingCity={state.livingCity}
            inputFocusedBorderColor="#abe6c1"
          />
        </Wrapper>
      </FormScaffold>
      <div ref={cvRef}>
        <FormScaffold
          key="cv"
          status={getItemStatus("resume")}
          label="CV"
          width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
        >
          <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
            <UploadResume
              file={state.resume.resumeURL}
              onChange={(val) => fileURLHandler("resume", val)}
              id={user.id}
              fileName={state.resume.fileName}
              autoFocus={focus === "cv"}
              handleImportButtonClick={handleCvImport}
            />
          </Wrapper>
        </FormScaffold>
      </div>
      <FormScaffold
        key="social"
        status={getItemStatus("social")}
        label="Social"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
          <SocialInput
            label="Linkedin profile"
            name="linkedin"
            defaultValue={state.linkedin}
            onChange={handleChange}
            placeholder="https://"
            autoFocus={focus === "linkedin"}
          />
        </Wrapper>
        <Wrapper
          px={{ mobileS: "27px", tablet: "0" }}
          style={{ marginTop: "-15px" }}
          mb="10px"
        >
          <ImportLinkedinButton
            handleClick={() => handleLinkedinImport()}
            disabled={!user.social.linkedin}
          >
            Import your LinkedIn profile
          </ImportLinkedinButton>
        </Wrapper>
        <SocialInput
          label="Github profile"
          name="github"
          defaultValue={state.github}
          onChange={handleChange}
          placeholder="https://"
          autoFocus={focus === "github"}
        />
        <SocialInput
          label="Stackoverflow profile"
          name="stackoverflow"
          defaultValue={state.stackoverflow}
          onChange={handleChange}
          placeholder="https://"
          autoFocus={focus === "stackoverflow"}
        />
        <SocialInput
          label="Twitter profile"
          name="twitter"
          defaultValue={state.twitter}
          onChange={handleChange}
          placeholder="https://"
          autoFocus={focus === "twitter"}
        />
        <SocialInput
          label="YouTube profile"
          name="youtube"
          defaultValue={state.youtube}
          onChange={handleChange}
          placeholder="https://"
          autoFocus={focus === "youtube"}
        />
        <SocialInput
          label="Personal website"
          name="portfolio"
          defaultValue={state.portfolio}
          onChange={handleChange}
          placeholder="https://"
          autoFocus={focus === "portfolio"}
        />
        <SocialInput
          label="Personal Blog"
          name="blog"
          defaultValue={state.blog}
          onChange={handleChange}
          placeholder="https://"
          autoFocus={focus === "blog"}
        />
      </FormScaffold>
    </>
  );
}
