import React from "react";
import Education from "./Education";
import Languages from "./Languages";
import { ColumnBlock } from "../../BlockUi";

export default function Content({ user, onUpdate, refetch }) {
  return (
    <>
      <ColumnBlock>
        <Education
          education={user.education}
          user={user}
          onUpdate={onUpdate}
          refetch={refetch}
        />
        <Languages
          languages={user.languages}
          user={user}
          onUpdate={onUpdate}
          refetch={refetch}
        />
      </ColumnBlock>
    </>
  );
}
