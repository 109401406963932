import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import FormScaffold from "../FormScaffold";
import { captureErrorWithData } from "../../../helper";
import {
  DeleteIcon,
  EditIconWithUnderline,
  LinkedinIcon,
  PlusIconSimple,
} from "../../Icon";
import Drawer from "../../Drawer";
import { UPDATE_USER } from "./queries";
import { Button } from "../../Button";
import LanguageForm, { EMPTY_LANGUAGE_FIELDS } from "./LanguageForm";
import {
  EducationBoldTitle,
  EducationDescriptionText,
} from "../../Profile/CommonStyles";
import {
  LANGUAGE_LEVEL_TITLES,
  QUICK_EDIT_DATA_SESSION_IDS,
} from "../../../utils/constants";
import { useQuestion } from "../../../utils/hooks";
import LinkedinLanguageForm from "./LinkedinLanguageForm";
import { ButtonWrapper } from "../CommonStyles";
import message from "../../../utils/message";

const WarningMessage = () => {
  return (
    <div>
      You are trying to <b>delete</b> a language from your profile. Are you
      sure?
    </div>
  );
};

export default function Language({ languages = [], onUpdate, refetch, user }) {
  const [drawer, setDrawer] = useState(false);
  const [linkedinDrawer, setLinkedinDrawer] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    language: null,
    index: null,
  });

  const question = useQuestion();
  const [state, setState] = useState(languages);

  const [status, setStatus] = useState("pending");
  const [updateUser] = useMutation(UPDATE_USER);
  const history = useHistory();

  const openLinkedinDrawer = () => {
    if (user?.social?.linkedin) {
      // Eğer Quickbox kısmında eksik dillerin gösterildiği kısım skip edilmişse ve sonra linkedin crawler çalıştırıldığında yine boş dil seviyesi gelmişse
      // quickbox'taki eksik dil bölümünü tekrar aktif etmek için sessionId'yi kaldırıyoruz.
      sessionStorage.removeItem(
        `qip-${QUICK_EDIT_DATA_SESSION_IDS.MISSING_LANGUAGE_LEVEL}`
      );
      return setLinkedinDrawer(true);
    }
    return history.push("/profile/edit/import");
  };

  const closeLinkedinDrawer = (languageList) => {
    setLinkedinDrawer(false);

    if (languageList && languageList.length > 0) {
      setState(languageList);
    }
  };
  useEffect(() => {
    if (state?.length >= 1) {
      setStatus("success");
    }
    if (state?.length === 0) {
      setStatus("pending");
    }
  }, [drawer, state]);

  const openDrawer = (index) => {
    if (typeof index === "number") {
      setSelectedLanguage({ language: languages[index], index });
    } else {
      setSelectedLanguage({ language: EMPTY_LANGUAGE_FIELDS, index: null });
    }
    setDrawer(true);
  };
  const closeDrawerAfterSave = (languageList) => {
    setState(languageList);
    setDrawer(false);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  const deleteItem = async (index) => {
    const nativeLanguageCount = state.filter(
      (item) => item?.level === 7
    ).length;

    if (nativeLanguageCount === 1 && state[index].level === 7) {
      return message.error("You should speak at least one native language.");
    }

    const filteredItems = state
      .slice(0, index)
      .concat(state.slice(index + 1, state.length));
    if (filteredItems.length === 0) {
      await updateUser({ variables: { languages: [] } });
      onUpdate({ user: new Date() });
    }

    setState(filteredItems);

    const variables = filteredItems.map((item) => {
      return {
        language: item.language?.id,
        level: item.level,
      };
    });
    try {
      await updateUser({ variables: { languages: variables } });
      onUpdate({ user: new Date() });
      refetch();
    } catch (error) {
      captureErrorWithData(error);
    }
  };

  const LanguageItem = ({ item, index }) => {
    return (
      <RowBlock
        mt={2}
        mb={3}
        py={2}
        pl={1}
        width="100%"
        border="solid 1px #eeeeee"
        borderRadius="5px"
      >
        <RowBlock
          width={{ mobileS: "100%", tablet: "75%" }}
          px={{ mobileS: "10px" }}
          alignItems="center"
          flex="1 auto"
          justify="space-between"
          wrap="nowrap"
        >
          <ColumnBlock mr={4}>
            <EducationBoldTitle>{item?.language?.label}</EducationBoldTitle>
            {!item.level && (
              <EducationDescriptionText
                style={{ marginTop: "6px", color: "red" }}
              >
                Missing Info: Level
              </EducationDescriptionText>
            )}
            {item.level && (
              <EducationDescriptionText style={{ marginTop: "6px" }}>
                {LANGUAGE_LEVEL_TITLES[item.level]}
              </EducationDescriptionText>
            )}
          </ColumnBlock>
          <RowBlock gridGap={2} wrap="nowrap">
            <DeleteIcon
              onClick={() =>
                question({ content: <WarningMessage /> }, () =>
                  deleteItem(index)
                )
              }
            />
            <EditIconWithUnderline onClick={() => openDrawer(index)} />
          </RowBlock>
        </RowBlock>
      </RowBlock>
    );
  };

  return (
    <div>
      {drawer && (
        <Drawer onClose={closeDrawer} showAnimation>
          <LanguageForm
            languages={languages}
            selectedLanguage={selectedLanguage}
            onUpdate={onUpdate}
            handleClose={closeDrawerAfterSave}
          />
        </Drawer>
      )}
      {linkedinDrawer && (
        <Drawer onClose={closeLinkedinDrawer}>
          <LinkedinLanguageForm
            onUpdate={onUpdate}
            user={user}
            handleOnClose={closeLinkedinDrawer}
          />
        </Drawer>
      )}
      <FormScaffold
        label="Languages"
        explanation="You can add the languages you speak, including your native language."
        status={status}
      >
        <div>
          {state?.length > 0 &&
            state.map((item, index) => (
              <LanguageItem item={item} index={index} key={index} />
            ))}
        </div>
        <ButtonWrapper>
          <Button
            variant="linkedin"
            size="mediumLarge"
            onClick={() => openLinkedinDrawer()}
          >
            <RowBlock>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LinkedinIcon color="#0072B1" />
              </div>
              <span>Import from Linkedin</span>
            </RowBlock>
          </Button>
          or
          <Button variant="secondary" size="small" onClick={() => openDrawer()}>
            <RowBlock justifyContent="space-evenly" alignItems="center">
              <PlusIconSimple width="20" height="20" color="black" />
              <span>Add New</span>
            </RowBlock>
          </Button>
        </ButtonWrapper>
      </FormScaffold>
    </div>
  );
}
