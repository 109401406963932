import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { BoxTitle, BoxText, RowBlock, Span } from "../../../components/BlockUi";
import { BasicLink, Form } from "../../../components/FormUi";
import CodeInput from "../../../components/Form/CodeField";
import Timer from "../../../components/Timer";
import { uuidGenerate } from "../../../helper";

const CREATE_PIN = gql`
  mutation CreatePin($email: String!, $operation: String!) {
    createPin(email: $email, operation: $operation) {
      email
    }
  }
`;

const LOGIN = gql`
  mutation Login(
    $email: String!
    $digit: Int!
    $publicPositionToken: String
    $matchCreationType: String
    $inviter: String
    $sessionID: ID
  ) {
    login(
      sessionID: $sessionID
      email: $email
      digit: $digit
      publicPositionToken: $publicPositionToken
      matchCreationType: $matchCreationType
      inviter: $inviter
    ) {
      token
      userId
    }
  }
`;

export default function PinStep({ handleSubmit, email }) {
  const [login] = useMutation(LOGIN);
  const [createPin] = useMutation(CREATE_PIN);
  const [sessionID] = useState(() => {
    const session = localStorage.getItem("session");
    if (!session) {
      const id = uuidGenerate();
      localStorage.setItem("session", id);
      return id;
    }
    return session;
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [showResendBtn, setShowResendBtn] = useState(true);

  const handleTimesUp = () => {
    sessionStorage.removeItem("resendPin");
    setShowResendBtn(true);
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      await createPin({ variables: { email, operation: "LOGIN" } });
      sessionStorage.setItem("resendPin", 1);
      setShowResendBtn(false);
      setError();
    } catch (err) {
      setError(err);
    }
  };

  const handlePinChange = async (val) => {
    setError();
    let occuredError = false;
    if (val.length === 6) {
      setLoading(true);
      try {
        const publicPositionInfo = JSON.parse(sessionStorage.getItem("ppi"));
        const { data } = await login({
          variables: {
            email,
            sessionID,
            digit: parseInt(val, 10),
            publicPositionToken: publicPositionInfo?.ppt,
            matchCreationType: publicPositionInfo?.type,
            inviter: publicPositionInfo?.inviteCode,
          },
        });
        sessionStorage.removeItem("resendPin");
        setLoading(false);
        handleSubmit({
          ...data,
          publicPositionToken: publicPositionInfo?.ppt || null,
        });
      } catch (err) {
        occuredError = true;
        setError(err);
        setLoading(false);
      }

      if (occuredError === false) {
        return sessionStorage.removeItem("ppi");
      }
    }
  };

  useEffect(() => {
    const resendPin = sessionStorage.getItem("resendPin");
    if (resendPin) {
      setShowResendBtn(false);
    }
  }, []);

  return (
    <>
      <BoxTitle color="font.0">
        Check your mailbox
        <span role="img" aria-label="hello">
          ✉️
        </span>
      </BoxTitle>
      <BoxText color="font.0">
        We sent a verification code to <b> {email} </b> <br /> Enter the code to
        continue.
      </BoxText>
      <Form
        pt={{ mobileS: 4, laptop: 4, tablet: 4 }}
        pb={{ mobileS: 4, laptop: 6, tablet: 4 }}
        mt={{ mobileS: 4, laptop: 4, tablet: 4 }}
        px={{ mobileS: 0, laptop: 6, tablet: 3, mobileL: 3 }}
      >
        <CodeInput fields={6} type="number" onChange={handlePinChange} />
        {error &&
          error.graphQLErrors.map(({ message }, i) => (
            <Span key={i} color="red.0" fontWeight="bold">
              {message}
            </Span>
          ))}
        {loading && (
          <Span color="blue.0" fontWeight="bold">
            We are checking...
          </Span>
        )}
        <RowBlock mt={{ mobileS: 3, laptop: 4, tablet: 4 }}>
          <Span fontSize="12px" fontWeight="bold">
            <Timer
              initialMinute={2}
              initialSeconds={59}
              handleTimesUp={handleTimesUp}
              clear={!showResendBtn}
            />
          </Span>
        </RowBlock>
      </Form>
      {showResendBtn && (
        <RowBlock mt={{ mobileS: 3, laptop: 4, tablet: 4 }}>
          <BasicLink
            color="font.1"
            mb={{ mobileS: 2, laptop: 4, tablet: 4 }}
            onClick={handleResend}
          >
            Didn&apos;t receive the code? <u> Resend</u>
          </BasicLink>
        </RowBlock>
      )}
    </>
  );
}
