import React from "react";
import { ColumnBlock } from "../BlockUi";
import TabList from "../Modules/Tabs";
import { CompanyTabIcon, FileIcon, LinkIcon } from "../Icon";

export default function NavList({ handleClick, active, mobileDetail }) {
  const display = mobileDetail ? "none" : "flex";

  const tablist = [
    {
      name: "companies",
      text: "Companies Hiring with TalentGrid",
      icon: CompanyTabIcon,
      iconColor: "#72D1FB",
    },
    {
      name: "reports",
      text: "Reports",
      icon: FileIcon,
      iconColor: "#72D1FB",
    },
    {
      name: "socials",
      text: "Social & Links",
      icon: LinkIcon,
      iconColor: "#72D1FB",
    },
  ];

  return (
    <ColumnBlock
      display={{ mobileS: display, tablet: "flex" }}
      wrap="nowrap"
      width={{ mobileS: "100%", tablet: "30%", laptop: "30%" }}
      px={{ mobileS: "20px", tablet: "0" }}
    >
      <TabList tablist={tablist} handleClick={handleClick} active={active} />
    </ColumnBlock>
  );
}
