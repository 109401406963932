import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import FormScaffold from "../FormScaffold";
import { captureErrorWithData } from "../../../helper";
import {
  DeleteIcon,
  EditIconWithUnderline,
  LinkedinIcon,
  PlusIconSimple,
} from "../../Icon";
import Drawer from "../../Drawer";
import { UPDATE_USER } from "./queries";
import { Button } from "../../Button";
import EducationForm, { EMPTY_EDUCATION_FIELDS } from "./EducationForm";
import {
  EducationBoldTitle,
  EducationDescriptionText,
} from "../../Profile/CommonStyles";
import { EDUCATION_TYPE_TITLES } from "../../../utils/constants";
import LinkedinEducationForm from "./LinkedinEducationForm";
import { ButtonWrapper } from "../CommonStyles";

export default function Education({ education = [], onUpdate, refetch, user }) {
  const [drawer, setDrawer] = useState(false);
  const [linkedinDrawer, setLinkedinDrawer] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState({
    education: null,
    index: null,
  });
  const [state, setState] = useState(education);

  const [status, setStatus] = useState("pending");
  const [updateUser] = useMutation(UPDATE_USER);

  const history = useHistory();

  const openLinkedinDrawer = () => {
    if (user?.social?.linkedin) {
      return setLinkedinDrawer(true);
    }
    return history.push("/profile/edit/import");
  };

  const closeLinkedinDrawer = (educationList) => {
    setLinkedinDrawer(false);
    if (educationList && educationList.length > 0) {
      setState(educationList);
    }
  };

  useEffect(() => {
    if (state.length >= 1) {
      setStatus("success");
    }
    if (state.length === 0) {
      setStatus("pending");
    }
  }, [drawer, state]);

  const openDrawer = (index) => {
    if (typeof index === "number") {
      setSelectedEducation({ education: education[index], index });
    } else {
      setSelectedEducation({ education: EMPTY_EDUCATION_FIELDS, index: null });
    }
    setDrawer(true);
  };

  const closeDrawerAfterSave = (educationList) => {
    setState(educationList);
    setDrawer(false);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };
  const deleteItem = async (index) => {
    const filteredItems = state
      .slice(0, index)
      .concat(state.slice(index + 1, state.length));
    if (filteredItems.length === 0) {
      await updateUser({ variables: { education: [] } });
      onUpdate({ user: new Date() });
    }

    setState(filteredItems);

    const variables = filteredItems.map((item) => {
      return {
        school: item.school?.id,
        branch: item.branch?.id,
        type: item.type,
        endDate: item.endDate,
        startDate: item.startDate,
      };
    });
    try {
      await updateUser({ variables: { education: variables } });
      onUpdate({ user: new Date() });
      refetch();
    } catch (error) {
      captureErrorWithData(error);
    }
  };

  const EducationItem = ({ item, index }) => {
    const [missingInformationState, setMissingInformationState] =
      useState(null);

    const checkMissingInformations = (items) => {
      const missingInformations = [];

      if (items.type === undefined || items.type === null) {
        missingInformations.push("Education Level");
      }
      if (items.startDate === undefined || items.startDate === null) {
        missingInformations.push("Year started");
      }
      if (items.endDate === undefined || items.endDate === null) {
        missingInformations.push("Year finished");
      }

      setMissingInformationState(missingInformations.join(", "));
    };

    useEffect(() => {
      checkMissingInformations(item);
    });

    return (
      <RowBlock
        mt={2}
        mb={3}
        py={2}
        pl={1}
        width="100%"
        border="solid 1px #eeeeee"
        borderRadius="5px"
      >
        <RowBlock
          width={{ mobileS: "100%", tablet: "75%" }}
          px={{ mobileS: "10px" }}
          alignItems="center"
          flex="1 auto"
          justify="space-between"
          wrap="nowrap"
        >
          <ColumnBlock mr={4}>
            <EducationBoldTitle>
              {item?.school?.name}{" "}
              {item?.branch && (
                <EducationDescriptionText>
                  / {item?.branch?.label}
                </EducationDescriptionText>
              )}
            </EducationBoldTitle>
            {!missingInformationState && (
              <EducationDescriptionText style={{ marginTop: "6px" }}>
                {EDUCATION_TYPE_TITLES[item.type]}, {item.startDate} -{" "}
                {item.endDate}
              </EducationDescriptionText>
            )}
            {missingInformationState && (
              <EducationDescriptionText style={{ color: "red" }}>
                Missing Info: {missingInformationState}
              </EducationDescriptionText>
            )}
          </ColumnBlock>
          <RowBlock gridGap={2} wrap="nowrap">
            <DeleteIcon onClick={() => deleteItem(index)} />
            <EditIconWithUnderline onClick={() => openDrawer(index)} />
          </RowBlock>
        </RowBlock>
      </RowBlock>
    );
  };

  return (
    <div>
      {drawer && (
        <Drawer onClose={closeDrawer} showAnimation>
          <EducationForm
            education={education}
            selectedEducation={selectedEducation}
            onUpdate={onUpdate}
            handleClose={closeDrawerAfterSave}
          />
        </Drawer>
      )}
      {linkedinDrawer && (
        <Drawer onClose={closeLinkedinDrawer}>
          <LinkedinEducationForm
            user={user}
            onUpdate={onUpdate}
            handleOnClose={closeLinkedinDrawer}
          />
        </Drawer>
      )}
      <FormScaffold
        label="Education history"
        explanation="You can add multiple education details."
        status={status}
      >
        <div>
          {state.length > 0 &&
            state.map((item, index) => (
              <EducationItem item={item} index={index} key={index} />
            ))}
        </div>
        <ButtonWrapper>
          <Button
            variant="linkedin"
            size="mediumLarge"
            onClick={() => openLinkedinDrawer()}
          >
            <RowBlock>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LinkedinIcon color="#0072B1" />
              </div>
              <span>Import from Linkedin</span>
            </RowBlock>
          </Button>
          or
          <Button variant="secondary" size="small" onClick={() => openDrawer()}>
            <RowBlock justifyContent="space-evenly" alignItems="center">
              <PlusIconSimple width="20" height="20" color="black" />
              <span>Add New</span>
            </RowBlock>
          </Button>
        </ButtonWrapper>
      </FormScaffold>
    </div>
  );
}
