import React from "react";
import styled from "styled-components";
import { space } from "styled-system";
import { ColumnBlock } from "./BlockUi";

const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  ${space}
`;

const Content = styled.div`
  ${space}
  line-height: 25px;
`;

const GreetingContent = styled.div`
  width: 100%;
  text-align: center;
  ${space}
  .description {
    font-size: 14px;
    color: ${(props) => props.theme.colors.font[0]};

    .title {
      font-size: 30px;
      font-family: ${(props) => props.theme.family.bold};
      color: ${(props) => props.theme.colors.main[0]};
    }

    button {
      ${space}
    }
    u {
      text-decoration-color: #fad156;
    }
  }
`;

export default function CriteriaGreeting() {
  return (
    <Wrapper
      py={{ mobileS: 3, laptop: 4, tablet: 4 }}
      px={{ mobileS: 3, laptop: 5, tablet: 4 }}
    >
      <GreetingContent>
        <ColumnBlock className="description">
          <div className="title">
            Tell Us About <u>Your Expectations</u>
          </div>
          <Content className="content" my={{ mobileS: 2, tablet: 2 }}>
            We will match you with the most suitable positions,
            <br /> according to{" "}
            <b>your expectations from a new role & company</b>
          </Content>
        </ColumnBlock>
      </GreetingContent>
    </Wrapper>
  );
}
