import React from "react";
import styled from "styled-components";

const PercentageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  width: 100%;
  border-radius: 5px;
  background-color: rgb(255, 255, 255, 0.8);
  position: relative;
`;

const Path = styled.path`
  @keyframes progress {
    0% {
      stroke-dasharray: 0 360;
    }
  }
  stroke-dashoffset: ${(props) => (props.percent ? props.percent : 0)};
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
`;

const PercentageContent = styled.div`
  position: absolute;
  bottom: 42px;
  font-family: ${(props) => props.theme.family.demi};
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #aaaaaa;
  > b {
    font-family: ${(props) => props.theme.family.bold};
    font-size: 18px;
    font-weight: bold;
    color: #444444;
    display: block;
  }
`;

const Line = styled.div`
  font-family: ${(props) => props.theme.family.demi};
  display: flex;
  justify-content: space-between;
  width: 55%;
  font-size: 10px;
  font-weight: 600;
  color: #aaaaaa;
  > span:first-child {
    padding-left: 3%;
  }
`;

export default function Percentage({ percent }) {
  const calcPercent = 142 - (percent * 142) / 100;
  const currentColor = percent < 60 ? "#FAD156" : "#8fdeac";

  return (
    <PercentageWrap>
      <svg viewBox="0 0 100 55" width="55%">
        <g fillOpacity="0" strokeWidth="5">
          <path
            d="M5 50a45 45 0 1 1 90 0"
            stroke="#ededed"
            strokeLinecap="round"
          />
          <Path
            d="M5 50a45 45 0 1 1 90 0"
            stroke={currentColor}
            strokeDasharray="142"
            strokeDashoffset="142"
            percent={calcPercent}
          />
        </g>
      </svg>
      <Line>
        <span>0</span> <span>100</span>
      </Line>
      <PercentageContent>
        Your profile is <br /> <b> {percent}% </b> complete.
      </PercentageContent>
    </PercentageWrap>
  );
}
