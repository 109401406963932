import React, { useState } from "react";
import * as Yup from "yup";
import { StepType, StepSchool, StepDates } from "./Steps/EducationSteps";
import StepContainer from "./StepContainer";

const STEP_STRUCTURE = [
  {
    component: StepType,
    validation: Yup.object().shape({
      type: Yup.number().required(""),
    }),
  },
  {
    component: StepSchool,
    validation: Yup.object().shape({
      school: Yup.string().trim().required(""),
      branch: Yup.string().trim().required(""),
    }),
  },
  {
    component: StepDates,
    validation: Yup.object().shape({
      startDate: Yup.date().required(""),
      endDate: Yup.date().required(""),
    }),
  },
];

export default function QuickFillEducation({ handleSubmit, next }) {
  const [education, setEducation] = useState({
    type: null,
    school: null,
    branch: null,
    startDate: null,
    endDate: null,
  });

  return (
    <>
      <StepContainer
        title="Add Education"
        state={education}
        setState={setEducation}
        steps={STEP_STRUCTURE}
        nextQuickBox={next}
        handleSubmit={(event) => handleSubmit(event, { education })}
        showSkipButton
      />
    </>
  );
}
