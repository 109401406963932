import React, { useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { RowBlock, ColumnBlock } from "../../../../BlockUi";
import { EDUCATION_TYPES_WITH_KEYS } from "../../../../../utils/constants";
import { SelectField, AsyncSelectField } from "../../../../Form/SelectField";
import RadioButton from "../../../../Form/RadioButton";
import { debounce } from "../../../../../helper";
import {
  SEARCH_UNIVERSITY,
  SEARCH_BRANCH,
} from "../../../../ProfileEdit/Education/queries";
import { Label } from "../../../../FormUi";
import message from "../../../../../utils/message";
import {
  getPossibleStartYears,
  getPossibleEndYears,
} from "../../../../ProfileEdit/Education/years";

export const StepType = ({ onChange }) => {
  const [type, setType] = useState("");
  const onLocalChange = (value) => {
    setType(value);
    onChange({
      type: "type",
      value,
    });
  };

  return (
    <>
      <RowBlock my={3}>
        <ColumnBlock width="100%">
          <Label mb={1}>What is your education level?</Label>
          {EDUCATION_TYPES_WITH_KEYS.map(({ value, label }, index) => (
            <RadioButton
              label={label}
              value={type ? type.toString() : ""}
              key={index}
              id={value}
              name="type"
              onChange={() => onLocalChange(value, label)}
              checked={value === type}
              width="unset"
              minWidth="unset"
            />
          ))}
        </ColumnBlock>
      </RowBlock>
    </>
  );
};

export const StepSchool = ({ onChange }) => {
  const { refetch: universityRefetch } = useQuery(SEARCH_UNIVERSITY, {
    skip: true,
  });

  const { refetch: branchRefetch } = useQuery(SEARCH_BRANCH, {
    skip: true,
  });

  const handleSchoolSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await universityRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        callback(
          data.allUniversity.universities.map((university) => {
            return { value: university?.id, label: university?.name };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleBranchSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await branchRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        const _branches = data.allUniversityBranch.universityBranches.map(
          (branch) => {
            return {
              value: branch?.id,
              label: branch?.label,
            };
          }
        );
        callback(_branches);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  return (
    <>
      <RowBlock my={3}>
        <AsyncSelectField
          label="School attended"
          placeholder=""
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
          loadOptions={handleSchoolSearch}
          onChange={(selectedOption) =>
            onChange({
              ...selectedOption,
              type: "school",
            })
          }
          width="100%"
        />
      </RowBlock>
      <RowBlock my={3}>
        <AsyncSelectField
          label="Department"
          placeholder=""
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
          loadOptions={handleBranchSearch}
          onChange={(selectedOption) =>
            onChange({
              ...selectedOption,
              type: "branch",
            })
          }
          width="100%"
        />
      </RowBlock>
    </>
  );
};

export const StepDates = ({ onChange, state }) => {
  return (
    <RowBlock my={3}>
      <ColumnBlock
        width={{ mobileS: "100%", tablet: "50%" }}
        pr={{ tablet: 2 }}
      >
        <SelectField
          label="Year started"
          placeholder=""
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
          isSearchable
          options={getPossibleStartYears(state.endDate)}
          onChange={(selectedOption) =>
            onChange({
              value: selectedOption.value,
              type: "startDate",
            })
          }
          width="100%"
        />
      </ColumnBlock>
      <ColumnBlock
        width={{ mobileS: "100%", tablet: "50%" }}
        pl={{ tablet: 2 }}
        mt={{ mobileS: 3, tablet: 0 }}
      >
        <SelectField
          label="Year finished"
          placeholder=""
          noOptionsMessage={({ inputValue }) =>
            inputValue ? "No results found" : "Type something to search"
          }
          isSearchable
          options={getPossibleEndYears(state.startDate)}
          width="100%"
          onChange={(selectedOption) =>
            onChange({
              value: selectedOption.value,
              type: "endDate",
            })
          }
        />
      </ColumnBlock>
    </RowBlock>
  );
};
