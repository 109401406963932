import gql from "graphql-tag";

export const GET_USER_WITH_LIVING_CITY = gql`
  {
    me {
      id
      livingCity {
        id
        city
        country
        iso2
      }
      preferences {
        currency
      }
    }
  }
`;

export const CALCULATE_MATCH = gql`
  mutation CALCULATE_MATCH {
    calculateMatch
  }
`;

export const ADD_CRITERIA = gql`
  mutation CREATE_CRITERIA(
    $salary: DeveloperSalaryInputType
    $benefits: DeveloperBenefitsInputType
    $technologies: DeveloperTechnologiesInputType
    $location: DeveloperLocationInputType
    $companySize: DeveloperCompanySizeInputType
    $other: DeveloperOtherCriteriaInputType
    $freelance: DeveloperFreelanceInputType
  ) {
    createDeveloperCriteria(
      salary: $salary
      benefits: $benefits
      technologies: $technologies
      location: $location
      companySize: $companySize
      other: $other
      freelance: $freelance
    ) {
      other {
        expected
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $role: ID
    $roles: [RolesInputType]
    $email: String!
    $experience: Int
    $skills: [SkillInputType]!
    $pin: Int!
    $kvkkAccept: Boolean!
    $kvkkRead: Boolean!
    $localKvkkAccept: Boolean!
    $localKvkkRead: Boolean!
    $inviter: String
    $creatorToken: ID
    $forceMatchToken: String
    $publicPositionToken: String
    $matchCreationType: MatchCreationEnumType
    $utms: UserUtmInputType
    $sessionID: ID
    $inviteeEmail: String
    $profession: Int
  ) {
    addUser(
      email: $email
      role: $role
      roles: $roles
      experience: $experience
      pin: $pin
      skills: $skills
      kvkkRead: $kvkkRead
      kvkkAccept: $kvkkAccept
      localKvkkAccept: $localKvkkAccept
      localKvkkRead: $localKvkkRead
      inviter: $inviter
      forceMatchToken: $forceMatchToken
      publicPositionToken: $publicPositionToken
      matchCreationType: $matchCreationType
      creatorToken: $creatorToken
      utms: $utms
      sessionID: $sessionID
      inviteeEmail: $inviteeEmail
      profession: $profession
    ) {
      id
      email
      status
      source
      experience
      role {
        title
      }
      roles {
        role {
          title
        }
        experience
      }
      skills {
        title
        experience
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $name: String
    $birthyear: Int
    $role: ID
    $experience: Int
    $livingCity: ID
    $social: SocialInputType
    $skills: [SkillInputType]
    $roles: [RolesInputType]
    $profession: Int
  ) {
    updateUser(
      name: $name
      role: $role
      experience: $experience
      birthyear: $birthyear
      livingCity: $livingCity
      social: $social
      skills: $skills
      roles: $roles
      profession: $profession
    ) {
      id
      email
      name
      status
      experience
      birthyear
      source
      experience
      livingCity {
        country
      }
      role {
        title
      }
      skills {
        title
        experience
      }
      social {
        github
      }
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation UpdateUser($languages: [UserLanguageInputType]) {
    updateUser(languages: $languages) {
      id
      languages {
        language {
          id
          label
        }
        level
      }
    }
  }
`;
export const GET_PUBLIC_POSITION = gql`
  query PublicPosition($publicID: ID) {
    publicPosition(publicID: $publicID) {
      publicPositionToken
      publicID
      title
      state
      highlights {
        value
        type
      }
      content {
        video
        loomVideoURL
      }
      role {
        title
      }
      title
      description
      createdAt
      interestCount
      history {
        current
        previous
        createdAt
      }
      assignee {
        name
        avatarURL
      }
      criteria {
        stockOption {
          isProvided
          isVisibleToUser
          min
          max
          isRange
          certainValue
        }
        budget {
          currency
          income
          min
          max
          isVisibleToUser
          period
        }
        positionLocation {
          expected {
            id
            title
            place {
              city
            }
          }
          remote
          relocation
          visaSupport
        }
        benefits {
          expected {
            label
          }
        }
        technologies {
          expected {
            _id
            title
            experience
          }
          otherExpected {
            id
            title
          }
          plus {
            title
          }
        }
        education {
          type
        }
        languages {
          language {
            label
            code
          }
        }
      }
      company {
        name
        logo
        slogan
        description
        website
        linkedin
        foundedYear
        sector {
          label
        }
        teamSize
        techTeamSize
        investStatus
        tgNotes
        officePhotos {
          url
          description
        }
        contents {
          place
          culture
        }
        technologies {
          id
          title
          category
          categoryId
        }
        locations {
          title
          location {
            coordinates
          }
          place {
            city
          }
          fullAddress
          headquarter
        }
      }
    }
  }
`;

export const GET_USER_BY_INVITER_CODE = gql`
  query PositionInviter($inviteCode: String) {
    positionInviter(inviteCode: $inviteCode) {
      avatarURL
      name
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $digit: Int!) {
    login(email: $email, digit: $digit) {
      token
    }
  }
`;

export const GET_USER_COUNTRY = gql`
  {
    me {
      livingCity {
        country
      }
    }
  }
`;
