/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { Button } from "../Button";
import { close } from "../../store/slices/question";

export default function Question() {
  const dispatch = useDispatch();
  const question = useSelector((state) => state.question);

  if (question.isOpen === false) {
    return "";
  }

  const handleOk = () => {
    question.callback();
    dispatch(close());
  };

  const handleCancel = () => {
    dispatch(close());
  };

  return (
    <Modal handleClose={handleCancel} width="400px">
      <ModalHeader>{question.title}</ModalHeader>
      <ModalBody>{question.content}</ModalBody>
      <ModalFooter>
        <Button variant="primary" size="small" onClick={handleOk}>
          Yes
        </Button>
        <Button variant="danger" size="small" onClick={handleCancel}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
}
