import React from "react";
import { useQuery } from "@apollo/client";
import { Container, RowBlock } from "../../components/BlockUi";
import Loading from "../../components/Modules/Loading";
import { Header, MobileNavigator } from "../../components/Header";
import ProfileGreeting from "../../components/ProfileGreeting";
import Profile from "../../components/Profile";
import { GET_USER } from "../../graphql/queries";
import { handleHeadTag } from "../../helper";
import { boot as bootIntercom } from "../../utils/Intercom";
import ErrorPage from "../ErrorPage";

export default function ProfilePage() {
  const { data, loading, error } = useQuery(GET_USER, {
    fetchPolicy: `network-only`,
  });

  handleHeadTag("profile");
  if (loading) return <Loading fullScreen width="70px" />;

  if (error) return <ErrorPage error={error} />;

  if (data && data.me) {
    bootIntercom(data.me);
  }

  return (
    <>
      <Header />
      <Container
        px={{ mobileS: "20px", tablet: "0px" }}
        mt={{ mobileS: 3, tablet: 4 }}
        maxWidth={{ laptop: "1024px" }}
      >
        {data && data.me?.status !== "MEMBER" && (
          <RowBlock>
            <ProfileGreeting user={data.me} />
          </RowBlock>
        )}
        <RowBlock
          opacity={data?.me?.status === "MEMBER" ? 1 : 0.4}
          width="100%"
          pb={5}
          mt={data?.me?.status === "LEAD" ? 5 : 0}
          pointerEvents={data?.me?.status === "LEAD" ? "none" : "auto"}
        >
          <Profile user={data?.me} />
        </RowBlock>
      </Container>
      <MobileNavigator />
    </>
  );
}
