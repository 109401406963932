import React from "react";
import styled from "styled-components";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import {
  Container,
  SingleLogo,
  TgDescription,
  TgSloganContainer,
  Image,
  Title,
  ImageDescription,
  TalentProfileContainer,
  TalentProfileBox,
  TalentAvatar,
  TalentName,
  TalentRole,
  TalentCompany,
} from "../CommonModule";
import tgSingleLogo from "../../../images/tg-single-logo.png";
import tgTechnologies from "../../../images/tg-technologies.png";
import onlineProtection from "../../../images/online-protection.png";
import { talentProfiles } from "../../../utils/constants";

import device from "../../../styles/device";

const ImageAndTextWrapper = styled.div`
  display: flex;
  flex-basis: 0;
  justify-content: space-between;
  @media ${device.mobileMax} {
    flex-direction: ${(props) =>
      props.imageOnRight ? "column-reverse" : "column"};
  }
`;

const PrivacyAndTechSectionContainer = styled.div`
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;
  @media ${device.mobileMax} {
    width: 100%;
    margin-bottom: 0px;
  }
`;

export default function TalentGridTab() {
  return (
    <Container>
      <TgSloganContainer>
        <SingleLogo src={tgSingleLogo} />
        We match tech talent with growing tech companies.
      </TgSloganContainer>
      <TgDescription>
        40+ companies grew their development team with TalentGrid in the last 12
        months.
      </TgDescription>

      <PrivacyAndTechSectionContainer>
        <ImageAndTextWrapper imageOnRight style={{ marginBottom: "25px" }}>
          <ColumnBlock
            style={{
              justifyContent: "center",
              flexBasis: "auto",
            }}
          >
            <Title textAlign="right">Work with the tech stack you prefer</Title>
            <ImageDescription textAlign="right">
              We know how critical it is for you to build experince with <br />
              the technologies you are excited about.
              <br /> <br /> We care about it too. We created a tech stack
              database to help <br /> everyone browse through companies based on
              the tools and technologies they use
            </ImageDescription>
          </ColumnBlock>

          <Image src={tgTechnologies} />
        </ImageAndTextWrapper>
        <ImageAndTextWrapper>
          <Image src={onlineProtection} />
          <ColumnBlock
            style={{
              justifyContent: "center",
              flexBasis: "auto",
            }}
          >
            <Title textAlign="left">Protect your privacy</Title>
            <ImageDescription textAlign="left">
              Companies cannot view or browse your profile <br /> <br /> Your
              talen profile is visible to a company if and only if you
              <br /> indicate that you are interested in a position there
            </ImageDescription>
          </ColumnBlock>
        </ImageAndTextWrapper>
      </PrivacyAndTechSectionContainer>

      <Title style={{ marginBottom: "25px", textAlign: "left" }}>
        Hear from others
      </Title>
      <RowBlock>
        <TalentProfileContainer>
          {talentProfiles.map((profile) => {
            return (
              <TalentProfileBox>
                <RowBlock
                  style={{ marginBottom: "35px", justifyContent: "left" }}
                >
                  <TalentAvatar src={profile.avatarURL} />
                  <ColumnBlock>
                    <TalentName> {profile.name}</TalentName>
                    <TalentRole>{profile.role}</TalentRole>
                    <TalentCompany>{profile.company}</TalentCompany>
                  </ColumnBlock>
                </RowBlock>
                <RowBlock>{profile.description}</RowBlock>
              </TalentProfileBox>
            );
          })}
        </TalentProfileContainer>
      </RowBlock>
    </Container>
  );
}
