import gql from "graphql-tag";

export const FETCH_USER_FROM_LINKEDIN = gql`
  query FetchUserFromLinkedin($uuid: ID, $username: String, $userId: ID) {
    fetchUserFromLinkedin(uuid: $uuid, userId: $userId, username: $username)
  }
`;

export const UPDATE_USER_FROM_LINKEDIN = gql`
  mutation UpdateUserfromLinkedin(
    $id: ID
    $bio: String
    $otherSkills: [ID]
    $languages: [UserLanguageInputType]
    $workHistory: [UserWorkHistoryInputType]
    $education: [UserEducationInputType]
    $isLinkedinAvatarUpload: Boolean
    $avatarURL: String
    $uuid: String
  ) {
    updateUserFromLinkedin(
      id: $id
      isLinkedinAvatarUpload: $isLinkedinAvatarUpload
      avatarURL: $avatarURL
      bio: $bio
      otherSkills: $otherSkills
      languages: $languages
      workHistory: $workHistory
      education: $education
      uuid: $uuid
    ) {
      id
    }
  }
`;

export const UPDATE_USER_FROM_RESUME = gql`
  mutation UpdateUserfromResume(
    $id: ID
    $bio: String
    $otherSkills: [ID]
    $languages: [UserLanguageInputType]
    $workHistory: [UserWorkHistoryInputType]
    $education: [UserEducationInputType]
    $uuid: String
  ) {
    updateUserFromResume(
      id: $id
      bio: $bio
      otherSkills: $otherSkills
      languages: $languages
      workHistory: $workHistory
      education: $education
      uuid: $uuid
    ) {
      id
    }
  }
`;

export const PARSE_RESUME = gql`
  mutation ParseResume($id: ID, $uuid: ID) {
    parseResume(id: $id, uuid: $uuid) {
      name
      bio
      social {
        linkedin
        github
        stackoverflow
        portfolio
        blog
        twitter
        youtube
      }
      languages {
        language {
          label
          id
        }
        level
      }
      education {
        type
        school {
          name
          id
        }
        branch {
          id
          label
        }
        startDate
        endDate
      }
      workHistory {
        company
        position
        startDate
        endDate
        description
        present
      }
      skills {
        _id
        categoryId
        title
      }
    }
  }
`;
