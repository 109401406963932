import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { NavLink, Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import device from "../../styles/device";
import logoImage from "../../images/talentgrid-logo-dark.png";
import { Container, Span, RowBlock } from "../BlockUi";
import {
  NavigationProfileIcon,
  NavigationSettingsIcon,
  NavigationNetworkIcon,
  NavigationMatchIcon,
  ArrowLeft,
  NavigationDiscoverIcon,
} from "../Icon";
import message from "../../utils/message";

const MobileHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: transparent;
  color: #ededed;
  padding: 24px 24px 17px;
`;

const Title = styled.h2`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 18px;
  font-weight: bold;
  flex: 2;
  text-align: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  padding: 14px 0;
  height: 55px;
  box-shadow: 0 4px 10px 0 #ededed;
`;

const HeaderContainer = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  @media ${device.mobileMax} {
    justify-content: space-around;
  }
`;

const Navigator = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    padding: 14px 0;
    height: 55px;
  }
`;

const MobileNavigatorWrapper = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  padding: 14px 0;
  height: 84px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media ${device.tablet} {
    display: none;
  }
`;

const NavigatorItem = styled(NavLink)`
  display: flex;
  align-items: flex-start;
  margin: 0 15px;
  color: ${(props) => props.theme.colors.font[1]};
  font-size: 14px;
  font-famiy: ${(props) => props.theme.family.demi};
  font-weight: bold;
  span {
    display: none;
  }
  &.${(props) => props.activeClassName} {
    color: ${(props) => props.theme.colors.main[0]};
    background-color: ${(props) => props.theme.colors.font[2]};
    padding: 12px 12px;
    border-radius: 22px;
    @media ${device.tablet} {
      background-color: transparent;
      padding: 0;
      border-radius: none;
      span {
        display: block;
      }
    }
    span {
      display: block;
    }
  }
  svg {
    fill: currentColor;
    margin-right: 8px;
  }
  @media ${device.tablet} {
    background-color: transparent;
    padding: 0;
    border-radius: none;
    span {
      display: block;
    }
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 28px;
  object-position: center;
  @media ${device.tablet} {
    object-position: left;
  }
`;

const SingleLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 28px;
  object-position: center;
`;

const LogoLink = styled(Link)`
  display: flex;
`;

const LogoContainerErrorPage = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const Budget = styled.span`
  position: relative;
  top: -5px;
  left: ${(props) => (props.left ? props.left : 0)};
  width: 15px;
  height: 15px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  font-family: ${(props) => props.theme.family.bold};
  background-color: #8fdeac;
`;

const GET_MATCH_COUNT = gql`
  {
    me {
      id
      matchCount
    }
  }
`;

const AccountLink = styled(NavigatorItem)`
  & svg {
    margin-top: 2px;
  }
`;

const DiscoverLink = styled(NavigatorItem)`
  & svg {
    margin-top: -2px;
  }
`;

export const LanguageBlock = styled.div`
  text-align: left;
`;

export function MobileDetailHeader({ handleClick, title }) {
  return (
    <MobileHeader>
      <Span fontSize="20px" fontWeight="bold" onClick={handleClick}>
        <ArrowLeft />
      </Span>
      <Title>{title}</Title>
    </MobileHeader>
  );
}

export function SingleHeader({ to = "profile" }) {
  return (
    <HeaderWrapper>
      <Container>
        <RowBlock width="100%">
          <LogoLink to={to}>
            <SingleLogo center src={logoImage} />
          </LogoLink>
        </RowBlock>
      </Container>
    </HeaderWrapper>
  );
}

export function ErrorPageHeader({ returnHome }) {
  return (
    <HeaderWrapper>
      <Container>
        <RowBlock width="100%">
          <LogoContainerErrorPage>
            <Logo src={logoImage} onClick={returnHome} />
          </LogoContainerErrorPage>
        </RowBlock>
      </Container>
    </HeaderWrapper>
  );
}

export function Header() {
  const { data, error } = useQuery(GET_MATCH_COUNT, {
    fetchPolicy: "network-only",
  });
  if (error) {
    message.error("Someting went wrong");
  }
  const { pathname } = useLocation();

  const matchCount = data && data?.me?.matchCount;

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <LogoLink to="/profile">
          <Logo center src={logoImage} />
        </LogoLink>
        <Navigator>
          <NavigatorItem activeClassName="active" to="/profile">
            <NavigationProfileIcon name="profile" active="active" />
            Profile
          </NavigatorItem>
          <NavigatorItem activeClassName="active" to="/matches">
            <NavigationMatchIcon />
            Matches
            {matchCount ? <Budget>{matchCount}</Budget> : null}
          </NavigatorItem>
          <NavigatorItem activeClassName="active" to="/invite">
            <NavigationNetworkIcon />
            Invite & Earn
          </NavigatorItem>
          <DiscoverLink
            activeClassName="active"
            to="/discover/companies"
            isActive={() => pathname.includes("/discover")}
          >
            <NavigationDiscoverIcon />
            Discover
          </DiscoverLink>
          <AccountLink activeClassName="active" to="/account/settings">
            <NavigationSettingsIcon />
            Account
          </AccountLink>
        </Navigator>
      </HeaderContainer>
    </HeaderWrapper>
  );
}

export function MobileNavigator() {
  const { data } = useQuery(GET_MATCH_COUNT, { fetchPolicy: "network-only" });
  const matchCount = data && data?.me?.matchCount;
  return (
    <MobileNavigatorWrapper>
      <HeaderContainer>
        <NavigatorItem activeClassName="active" to="/profile">
          <NavigationProfileIcon name="profile" active="active" />
          <Span>Profile</Span>
        </NavigatorItem>
        <NavigatorItem activeClassName="active" to="/matches">
          <NavigationMatchIcon />
          <Span>Matches</Span>
          {matchCount ? <Budget left="-2px">{matchCount}</Budget> : null}
        </NavigatorItem>
        <NavigatorItem activeClassName="active" to="/invite">
          <NavigationNetworkIcon />
          <Span>Invite & Earn</Span>
        </NavigatorItem>
        <NavigatorItem activeClassName="active" to="/discover">
          <NavigationDiscoverIcon />
          <Span>Discover</Span>
        </NavigatorItem>
        <NavigatorItem activeClassName="active" to="/account">
          <NavigationSettingsIcon />
          <Span>Account</Span>
        </NavigatorItem>
      </HeaderContainer>
    </MobileNavigatorWrapper>
  );
}
