import React from "react";
import { Button } from "tg-design";
import styled, { keyframes } from "styled-components";
import { space } from "styled-system";
import { DocumentIcon, LinkedinIcon } from "../../Icon";

export const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 1.79;
  color: #444444;
`;

export const UrlText = styled.p`
  font-size: 14px;
  padding-bottom: 20px;
`;

export const SaveButtonContainer = styled.div`
  ${space}
  text-align: center;
  box-shadow: 0px -2px 8px #d8d8d8;
  position: sticky;
  bottom: 0;
  background: #fff;
  margin-top: 10px;
`;

export const dotAnimation = keyframes`
   0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
`;

export const Dot = styled.span`
  opacity: 0;
  animation: ${dotAnimation} 1.5s ease-in-out infinite;

  &.dot-one {
    animation-delay: 0.2s;
  }
  &.dot-two {
    animation-delay: 0.6s;
  }
  &.dot-three {
    animation-delay: 1s;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.family.regular};
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  background: ${(props) => (props.isChecked ? " #f1f1f1" : "white")};
  cursor: pointer;
`;

export const ProfilePhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  background: ${(props) => (props.isChecked ? " #f1f1f1" : "white")};
  cursor: pointer;
`;

export const GroupTitle = styled.p`
  color: #0c084c;
  font-size: 16px;
  font-family: ${(props) => props.theme.family.bold};
  font-weight: 600;
  margin-top: 10px;
`;

export const PrimaryTitle = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.family.bold};
  font-weight: 600;
  line-height: 25px;
`;

export const SecondaryTitle = styled.p`
  font-size: 12px;
  font-family: ${(props) => props.theme.family.bold};
  font-weight: 500;
  line-height: 20px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
`;

export const DescriptionText = styled.p`
  font-size: 14px;
  font-weight: 500;
  flex: 1;
`;

export const TipText = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const TipListText = styled.li`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const DateText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  padding-top: 5px;
`;

export const Container = styled.div`
  border: 1px solid #d9d4d4;
  border-radius: 5px;
  padding: 20px;
`;

export const TagText = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.family.regular};
  gap: 10px;
  border: 1px solid rgba(199, 199, 199, 1);
  border-radius: 4px;
  padding: 10px;
  background: ${(props) => (props.isChecked ? " #f1f1f1" : "white")};
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100px;
  border-radius: 50%;
  height: 100px;
`;

export const ImportLinkedinButton = ({
  handleClick,
  disabled,
  loading,
  block,
  children,
}) => {
  return (
    <Button
      style={{ background: disabled || loading ? "#CCCCCC" : "#0072B1" }}
      size="xs"
      onClick={() => handleClick()}
      disabled={disabled || loading}
      loading={loading}
      block={block}
    >
      <LinkedinIcon color="#fff" />
      <span style={{ marginTop: "2px" }}>{children}</span>
    </Button>
  );
};

export const ImportCvButton = ({
  handleClick,
  disabled,
  loading,
  block,
  children,
}) => {
  return (
    <Button
      style={{ background: disabled || loading ? "#CCCCCC" : "#72D1FB" }}
      size="xs"
      onClick={() => handleClick()}
      disabled={disabled || loading}
      loading={loading}
      block={block}
    >
      <DocumentIcon color="#fff" />
      <span style={{ marginTop: "2px" }}>{children}</span>
    </Button>
  );
};
