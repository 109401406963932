import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import {
  ButtonLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "tg-design";
import { ImportLinkedinButton } from "../../ProfileEdit/ProfileImport/CommonStyles";
import Spinner from "../../Modules/Loading";
import QuickFillOtherSkills from "./QuickEditBoxes/QuickFillOtherSkills";
import QuickFillAbout from "./QuickEditBoxes/QuickFillAbout";
import QuickFillSocial from "./QuickEditBoxes/QuickFillSocial";
import QuickFillEducation from "./QuickEditBoxes/QuickFillEducation";
import QuickFillLanguages from "./QuickEditBoxes/QuickFillLanguages";
import QuickFillWorkHistory from "./QuickEditBoxes/QuickFillWorkHistory";
import {
  ACTION_BUTTON_TITLES,
  QUICK_EDIT_DATA_SESSION_IDS,
  TECH_CATEGORY_TOOLS,
} from "../../../utils/constants";
import { removeTypename } from "../../../helper";
import { UPDATE_USER } from "./queries";
import QuickFillMissingLanguageLevel from "./QuickEditBoxes/QuickFillMissingLanguageLevel";
import QuickFillOpenToWork from "./QuickEditBoxes/QuickFillOpenToWork";

const QuickEditWrap = styled.div`
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 10px 13px 0 #cccccc;
  padding: 40px;
  background-color: #ffffff;
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: visibility 0s, opacity 1s linear;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  line-height: 1.79;
  color: #444444;
`;

const Explanation = styled.div`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #aaaaaa;
`;

const FullProfileTitle = styled.div`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 14px;
  line-height: 1.79;
  color: #444444;
  margin-bottom: 10px;
`;

export const quickEditData = [
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.OPEN_TO_WORK,
    title: "Are you actively looking for a job?",
    component: QuickFillOpenToWork,
    data: { name: "openToWork" },
    isEmpty(user) {
      return !user?.preferences.openToWork;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.LINKEDIN_LINK,
    title: "What is your LinkedIn profile?",
    component: QuickFillSocial,
    data: { name: "linkedin" },
    isEmpty(user) {
      return !user?.social?.linkedin;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.MISSING_LANGUAGE_LEVEL,
    component: QuickFillMissingLanguageLevel,
    isEmpty(user) {
      const emptyLanguageLevelList = user.languages.filter(
        (item) => item?.level === null
      );

      if (emptyLanguageLevelList.length > 0) {
        return true;
      }
      return false;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.LINKEDIN_LINK_FOR_CRAWLING,
    title: "Fast-track to completing your profile",
    description:
      "We could easily get your profile details from your linkedIn profile:",
    component: QuickFillSocial,
    data: { name: "linkedin" },
    isEmpty(user) {
      return user?.social?.linkedin && !user.isLinkedinSaved;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.GITHUB_LINK,
    title: "What is your Github profile?",
    component: QuickFillSocial,
    data: { name: "github" },
    isEmpty(user) {
      return !user?.social?.github;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.EDUCATION,
    component: QuickFillEducation,
    isEmpty(user) {
      return !user.education || user.education.length === 0;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.LANGUAGES,
    component: QuickFillLanguages,
    isEmpty(user) {
      return !user.languages || user.languages.length === 0;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.ABOUT,
    title: "Can you provide a brief description about you?",
    component: QuickFillAbout,
    isEmpty(user) {
      return !user.bio;
    },
  },
  {
    group: "OtherSkills",
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.TECHNICAL_ARCHITECTURES,
    title: "Do you have experience with technical architectues?",
    component: QuickFillOtherSkills,
    data: {
      placeholder: "Search technology...",
      categoryId: 6,
      dataKey: "architecture",
    },
    isEmpty(user) {
      return (
        !user.otherSkills ||
        user.otherSkills.filter(
          (item) => item.categoryId === TECH_CATEGORY_TOOLS.ARCHITECTURE
        ).length === 0
      );
    },
  },
  {
    group: "OtherSkills",
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.DATABASES,
    title: "Do you have experience with DB systems?",
    component: QuickFillOtherSkills,
    data: {
      placeholder: "Search technology...",
      categoryId: 7,
      dataKey: "database",
    },
    isEmpty(user) {
      return (
        !user.otherSkills ||
        user.otherSkills.filter(
          (item) => item.categoryId === TECH_CATEGORY_TOOLS.DATABASE
        ).length === 0
      );
    },
  },
  {
    group: "OtherSkills",
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.CLOUD_TECHNOLOGIES,
    title: "Do you have experience with cloud technologies?",
    component: QuickFillOtherSkills,
    data: {
      placeholder: "Search technology...",
      categoryId: 3,
      dataKey: "cloud",
    },
    isEmpty(user) {
      return (
        !user.otherSkills ||
        user.otherSkills.filter(
          (item) => item.categoryId === TECH_CATEGORY_TOOLS.CLOUD
        ).length === 0
      );
    },
  },
  {
    group: "OtherSkills",
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.VERSIONING_TOOLS,
    title: "Do you have experience with versioning tools?",
    component: QuickFillOtherSkills,
    data: {
      placeholder: "Search technology...",
      categoryId: 4,
      dataKey: "versioning",
    },
    isEmpty(user) {
      return (
        !user.otherSkills ||
        user.otherSkills.filter(
          (item) => item.categoryId === TECH_CATEGORY_TOOLS.VERSIONING
        ).length === 0
      );
    },
  },
  {
    group: "OtherSkills",
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.CI_CD_TOOLS,
    title: "Do you have experience with CI / CD tools?",
    component: QuickFillOtherSkills,
    data: {
      placeholder: "Search technology...",
      categoryId: 8,
      dataKey: "cicd",
    },
    isEmpty(user) {
      return (
        !user.otherSkills ||
        user.otherSkills.filter(
          (item) => item.categoryId === TECH_CATEGORY_TOOLS.CICD
        ).length === 0
      );
    },
  },
  {
    group: "OtherSkills",
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.METHODOLOGIES,
    title: "What methodologies do you have experience with?",
    component: QuickFillOtherSkills,
    data: {
      placeholder: "Search technology...",
      categoryId: 9,
      dataKey: "methodologies",
    },
    isEmpty(user) {
      return (
        !user.otherSkills ||
        user.otherSkills.filter(
          (item) => item.categoryId === TECH_CATEGORY_TOOLS.METHODOLOGIES
        ).length === 0
      );
    },
  },
  {
    group: "OtherSkills",
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.BUSINESS_TOOLS,
    title: "What business tools do you have experience with?",
    component: QuickFillOtherSkills,
    data: {
      placeholder: "Search technology...",
      categoryId: 2,
      dataKey: "tool",
    },
    isEmpty(user) {
      return (
        !user.otherSkills ||
        user.otherSkills.filter(
          (item) => item.categoryId === TECH_CATEGORY_TOOLS.TOOL
        ).length === 0
      );
    },
  },
  {
    group: "OtherSkills",
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.ANALYTICS_AND_REPORTING_TOOLS,
    title: "Do you have experience with analytics & reporting tools?",
    component: QuickFillOtherSkills,
    data: {
      placeholder: "Search technology...",
      categoryId: 10,
      dataKey: "analytics",
    },
    isEmpty(user) {
      return (
        !user.otherSkills ||
        user.otherSkills.filter(
          (item) => item.categoryId === TECH_CATEGORY_TOOLS.ANALYTICS
        ).length === 0
      );
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.WORK_HISTORY,
    component: QuickFillWorkHistory,
    isEmpty(user) {
      return !user.workHistory || user.workHistory.length === 0;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.TWITTER_LINK,
    title: "What is your Twitter profile?",
    component: QuickFillSocial,
    data: { name: "twitter" },
    isEmpty(user) {
      return !user.social.twitter;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.BLOG_LINK,
    title: "What is your blog profile?",
    component: QuickFillSocial,
    data: { name: "blog" },
    isEmpty(user) {
      return !user.social.blog;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.YOUTUBE_LINK,
    title: "What is your YouTube channel?",
    component: QuickFillSocial,
    data: { name: "youtube" },
    isEmpty(user) {
      return !user.social.youtube;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.PORTFOLIO_LINK,
    title: "What is your personal website/portfolio link?",
    component: QuickFillSocial,
    data: { name: "portfolio" },
    isEmpty(user) {
      return !user.social.portfolio;
    },
  },
  {
    sessionId: QUICK_EDIT_DATA_SESSION_IDS.STACKOVERFLOW_LINK,
    title: "What is your StackOverflow profile?",
    component: QuickFillSocial,
    data: { name: "stackoverflow" },
    isEmpty(user) {
      return !user.social.stackoverflow;
    },
  },
];

const toSessionKey = (emptyDataInput) => {
  // quick-info-passed
  return `qip-${emptyDataInput.sessionId}`;
};

const isPassedBefore = (emptyDataInput) => {
  const value = sessionStorage.getItem(toSessionKey(emptyDataInput));
  if (value) {
    return true;
  }
  return false;
};

const shouldCookieBeSet = (currentEmptyData, emptyDataInputs) => {
  if (
    currentEmptyData?.data?.name &&
    currentEmptyData.data.name === "linkedin"
  ) {
    return false;
  }
  const groupItems = quickEditData.filter(
    (i) => i.sessionId === currentEmptyData.sessionId
  );

  if (groupItems.length === 1) {
    return true;
  }

  const filteredEmptyItems = emptyDataInputs.filter(
    (i) => i.sessionId === currentEmptyData.sessionId
  );

  return groupItems.length !== filteredEmptyItems.length;
};

const isGroupEmpty = (item, user) => {
  if (!item || !item.group) {
    return false;
  }
  const groupItems = quickEditData.filter(
    (i) => i.group && i.group === item.group
  );
  const emptyItems = groupItems.filter((i) => i.isEmpty(user));
  return emptyItems.length === groupItems.length;
};

const getPossibleDataInputs = (user) => {
  return quickEditData.filter(
    (item) =>
      (item.isEmpty(user) && !isPassedBefore(item)) ||
      (item.group && isPassedBefore(item) && isGroupEmpty(item, user))
  );
};

export default function QuickEdit({ user, percent }) {
  const [updateUser] = useMutation(UPDATE_USER);

  const [state, setState] = useState(user);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [emptyDataInputs, setEmptyDataInputs] = useState(
    getPossibleDataInputs(user)
  );
  const [content, setContent] = useState(
    emptyDataInputs.length > 0 ? emptyDataInputs[currentIndex] : null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(false);
  }, [user]);

  useEffect(() => {
    if (emptyDataInputs.length === 0) {
      setCurrentIndex(0);
      setContent(null);
    } else if (emptyDataInputs.length > currentIndex) {
      setContent(emptyDataInputs[currentIndex]);
    } else {
      setCurrentIndex(currentIndex - 1);
      setContent(emptyDataInputs[currentIndex - 1]);
    }
  }, [currentIndex, emptyDataInputs, user.isLinkedinSaved]);

  const handleSubmit = async (event, updates) => {
    event.preventDefault();
    try {
      setLoading(true);
      const variables = { ...state };
      if (updates.bio) {
        variables.bio = updates.bio;
      }
      if (updates.social) {
        variables.social = { ...variables.social, ...updates.social };
      }
      if (updates.preferences) {
        variables.preferences = {
          ...variables.preferences,
          ...updates.preferences,
        };
      }
      if (updates.otherSkills) {
        variables.otherSkills = [
          ...variables.otherSkills,
          ...updates.otherSkills,
        ];
      }
      if (updates.education) {
        variables.education = [...variables.education, updates.education];
      }
      if (updates.workHistory) {
        variables.workHistory = [...variables.workHistory, updates.workHistory];
      }
      if (updates.languages) {
        variables.languages = [updates.languages];
      }

      const { data } = await updateUser({
        variables: {
          bio: variables.bio,
          social: removeTypename(variables.social),
          preferences: removeTypename(variables.preferences),
          otherSkills: variables.otherSkills.map((item) => item.id),
          education: variables.education.map((item) => {
            return {
              type: item.type,
              school:
                typeof item.school === "string" ? item.school : item.school.id,
              branch:
                typeof item.branch === "string" ? item.branch : item.branch.id,
              startDate: item.startDate,
              endDate: item.endDate,
            };
          }),
          workHistory: variables.workHistory.map((item) => {
            return {
              company: item.company,
              position: item.position,
              startDate: item.startDate
                ? dayjs(item.startDate).format("YYYY-MM-DD")
                : null,
              endDate: item.endDate
                ? dayjs(item.endDate).format("YYYY-MM-DD")
                : null,
              description: "",
              present: item.present,
            };
          }),
          languages: variables.languages.map((item) => {
            return {
              language: item.language.id,
              level: item.level,
            };
          }),
        },
      });
      if (data.updateUser) {
        setState(data.updateUser);
        setEmptyDataInputs(getPossibleDataInputs(data.updateUser));
        setLoading(false);
        if (updates.social.linkedin) {
          setIsModalOpen(true);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const setNextContent = () => {
    const currentEmptyData = emptyDataInputs[currentIndex];

    if (shouldCookieBeSet(currentEmptyData, emptyDataInputs)) {
      sessionStorage.setItem(toSessionKey(currentEmptyData), true);
    }

    // Eğer kullanıcı grup halinde olan dataları atladıysa ve o gruptan
    // başka bir veri daha önce girildiyse, o zaman gösterecek hiçbir şey
    // olmayabilir. Bu nedenle tekrar göstermek istediğimiz inputları filtreliyoruz.
    const possibleDataInputs = currentEmptyData.group
      ? getPossibleDataInputs(user)
      : emptyDataInputs;

    if (possibleDataInputs.length > currentIndex + 1) {
      setCurrentIndex(currentIndex + 1);
      setContent(possibleDataInputs[currentIndex + 1]);
    } else if (
      possibleDataInputs.length > 0 &&
      possibleDataInputs[0].sessionId !== currentEmptyData.sessionId
    ) {
      setCurrentIndex(0);
      setContent(possibleDataInputs[0]);
    } else {
      setCurrentIndex(0);
      setContent(null);
    }

    setEmptyDataInputs(possibleDataInputs);
  };

  if (!content) return null;
  if (loading) return <Spinner width="42px" center mt="50px" />;
  const Component = content.component;

  return (
    <QuickEditWrap hide={loading}>
      {percent === 100 && (
        <FullProfileTitle>
          <span role="img" aria-label="sparkles">
            ✨
          </span>
          You might want to spice up your profile with a little more info.
        </FullProfileTitle>
      )}
      {content.title && (
        <>
          <Title>{content.title}</Title>
          <Explanation>
            {content.description ||
              "Adding these informations helps your profile look stronger."}
          </Explanation>
        </>
      )}
      <Component
        user={user}
        content={content}
        handleSubmit={handleSubmit}
        next={setNextContent}
        showSkipButton
        linkedinUrl={user.social.linkedin}
      />
      {isModalOpen && (
        <Modal handleClose={() => setIsModalOpen(false)}>
          <ModalHeader>
            <p>
              <b>LinkedIn profile successfully saved!</b> ✅
            </p>
          </ModalHeader>
          <ModalBody>
            <p style={{ color: "#444" }}>
              We ‘d love to <b>make life easier for you.</b>
            </p>
            <br />
            <p style={{ color: "#444" }}>
              Companies usually prioritise profiles with more details. Would you
              like us to import your profile details from your LinkedIn profile?
            </p>
          </ModalBody>
          <ModalFooter style={{ alignItems: "center" }}>
            <ImportLinkedinButton
              handleClick={() => {
                // Eğer Quickbox kısmında eksik dillerin gösterildiği kısım skip edilmişse ve sonra linkedin crawler çalıştırıldığında yine boş dil seviyesi gelmişse
                // quickbox'taki eksik dil bölümünü tekrar aktif etmek için sessionId'yi kaldırıyoruz.
                sessionStorage.removeItem(
                  `qip-${QUICK_EDIT_DATA_SESSION_IDS.MISSING_LANGUAGE_LEVEL}`
                );
                return history.push("/profile/edit/import", {
                  from: ACTION_BUTTON_TITLES.IMPORT_LINKEDIN_BUTTON,
                });
              }}
            >
              Import your LinkedIn profile
            </ImportLinkedinButton>

            <ButtonLink onClick={() => setIsModalOpen(false)}>
              I&apos;ll do it later
            </ButtonLink>
          </ModalFooter>
        </Modal>
      )}
    </QuickEditWrap>
  );
}
