import { useLazyQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import React, { useState, useEffect, useContext } from "react";
import {
  captureErrorWithData,
  removeTypename,
  uuidGenerate,
} from "../../../helper";
import { LINKEDIN_PROFILE_FIELDS } from "../../../utils/constants";
import message from "../../../utils/message";
import { WebSocketContext } from "../../../WebSocket";
import { ColumnBlock } from "../../BlockUi";
import { Button } from "../../Button";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";
import ReadMore from "../../ReadMore";
import {
  PrimaryTitle,
  SecondaryTitle,
  DescriptionText,
  DateText,
  LinkedinSectionContainer,
  HeaderText,
  GroupTitle,
} from "../CommonStyles";
import {
  LinkedinErrorPlaceHolder,
  LinkedinLoadingPlaceHolder,
} from "../ProfileImport/Linkedin/LinkedinPlaceholders";
import { UPDATE_USER_FROM_LINKEDIN } from "../ProfileImport/Linkedin/queries";
import { FETCH_FIELD_FROM_LINKEDIN } from "../queries";

export default function LinkedinWorkHistoryForm({
  user,
  handleOnClose,
  onUpdate,
}) {
  const [currentState, setCurrentState] = useState(null);
  // eslint-disable-next-line
  const [uuid, setUuid] = useState(uuidGenerate());
  const [updateUserFromLinkedin, { loading: loadingForSave }] = useMutation(
    UPDATE_USER_FROM_LINKEDIN
  );
  const [loading, setLoading] = useState(false);
  const [fetchFieldFromLinkedin] = useLazyQuery(FETCH_FIELD_FROM_LINKEDIN);

  const ws = useContext(WebSocketContext);

  const onSubscribe = ({ uuid }) => ws.socket.emit("subscribe", { uuid });
  const onDisconnect = ({ uuid }) => ws.socket.emit("unsubscribe", { uuid });
  const offListen = () => ws.socket.off("linkedInCrawled");

  const onListen = () => {
    setLoading(true);
    ws.socket.on("linkedInCrawled", (data) => {
      if (data.error) {
        setLoading(false);

        message.error(`An error has occurred: ${data.error}`);
      } else {
        const linkedinData = { ...data.linkedin };
        const formattedWorkHistoryData = {
          uuid,
          workHistory: linkedinData?.workHistory?.map((item) => {
            return {
              company: item.company || null,
              position: item.position || null,
              description: item.description || null,
              startDate: item.startDate
                ? dayjs(item.startDate).format("YYYY-MM-DD")
                : null,
              endDate: item.endDate
                ? dayjs(item.endDate).format("YYYY-MM-DD")
                : null,
              present: item.present,
              isChecked: true,
            };
          }),
        };
        if (
          !formattedWorkHistoryData?.workHistory ||
          formattedWorkHistoryData?.workHistory.length === 0
        ) {
          offListen();
          onDisconnect({ uuid });
        }

        setCurrentState(formattedWorkHistoryData);
        setLoading(false);
      }
      offListen();
      onDisconnect({ uuid });
    });
  };

  useEffect(() => {
    const linkedinURL = user.social?.linkedin || null;
    const username = linkedinURL?.split("/").filter(Boolean).pop();
    if (!username) {
      message.error("Username is not valid");
    }
    try {
      fetchFieldFromLinkedin({
        variables: {
          uuid,
          username,
          field: LINKEDIN_PROFILE_FIELDS.EXPERIENCE,
        },
      });
      onSubscribe({ uuid });
      onListen({ uuid });
    } catch (error) {
      error.message("Something went wrong!");
      captureErrorWithData(error);
      offListen();
      onDisconnect({ uuid });
    }
    // eslint-disable-next-line
  }, []);
  const handleOnChange = (changedIndex) => {
    setCurrentState({
      ...currentState,
      workHistory: currentState?.workHistory?.map((item, index) => {
        if (index === changedIndex) {
          item.isChecked = !item.isChecked;
          return item;
        }
        return item;
      }),
    });
  };

  const handleOnSave = async () => {
    try {
      const variables = removeTypename({
        id: user?.id,
        uuid: currentState?.uuid,
        workHistory: currentState?.workHistory
          .map(
            (item) =>
              item.isChecked && {
                company: item.company || null,
                position: item.position || null,
                description: item.description || null,
                startDate: item.startDate
                  ? dayjs(item.startDate).format("YYYY-MM-DD")
                  : null,
                endDate: item.endDate
                  ? dayjs(item.endDate).format("YYYY-MM-DD")
                  : null,
                present: item.present,
              }
          )
          .filter((i) => i),
      });
      const updatedLinkedinData = await updateUserFromLinkedin({ variables });
      onUpdate({ user: new Date() });

      setCurrentState(null);
      handleOnClose(
        updatedLinkedinData.data.updateUserFromLinkedin.workHistory
      );
    } catch (error) {
      error.message("Something went wrong!");
      captureErrorWithData(error);
      setCurrentState(null);
      handleOnClose();
    }
  };

  return (
    <>
      <HeaderText>Import experience info from Linkedin</HeaderText>
      {loading && <LinkedinLoadingPlaceHolder />}
      {currentState?.workHistory?.length === 0 && <LinkedinErrorPlaceHolder />}
      {currentState?.workHistory?.length > 0 && (
        <>
          <GroupTitle>Experience</GroupTitle>
          {currentState?.workHistory?.map((item, index) => {
            return (
              <ColumnBlock key={index} style={{ marginTop: "5px" }}>
                <LinkedinSectionContainer
                  isChecked={item.isChecked}
                  onClick={() => handleOnChange(index)}
                >
                  <div style={{ flex: "1" }}>
                    <PrimaryTitle>{item.company}</PrimaryTitle>
                    <SecondaryTitle>{item.position}</SecondaryTitle>
                    <DateText>
                      {dayjs(item.startDate).locale("en").format("MMMM YYYY")} -{" "}
                      {item.endDate
                        ? dayjs(item.endDate).locale("en").format("MMMM YYYY")
                        : "Present"}
                    </DateText>
                    <DescriptionText>
                      {item.description && (
                        <ReadMore text={item.description || ""} limit={30} />
                      )}
                    </DescriptionText>
                  </div>
                  <div>
                    {item.isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
                  </div>
                </LinkedinSectionContainer>
              </ColumnBlock>
            );
          })}
          <Button
            disabled={loadingForSave}
            onClick={handleOnSave}
            variant="primary"
            size="large"
            style={{ width: "100%", marginTop: "47px" }}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
}
