import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import AccountPage from "../../components/Account";
import {
  Header,
  MobileNavigator,
  MobileDetailHeader,
} from "../../components/Header";
import { Container, BodyWrapper } from "../../components/BlockUi";
import { isMobile, handleHeadTag } from "../../helper";
import Spinner from "../../components/Modules/Loading";
import ErrorPage from "../ErrorPage";

const GET_USER = gql`
  {
    me {
      id
      email
      name
      preferences {
        languageCode
        currency
      }
      matchSettings {
        available
        availableDate
        minMatchScore
      }
      deleteReason {
        reason {
          id
          label
        }
        active
        createdAt
      }
    }
  }
`;

const MOBILE_TITLES = {
  settings: "Account Settings",
  notifications: "Notifications",
  "match-settings": "Match Settings",
  promotions: "Promotions",
};

export default function Account() {
  const { data, loading, error, refetch } = useQuery(GET_USER);
  const [mobileTitle, setMobileTitle] = useState("");
  const history = useHistory();

  const { sectionName } = useParams();

  useEffect(() => {
    if (isMobile()) {
      setMobileTitle(MOBILE_TITLES[sectionName]);
    }
  }, [sectionName]);

  const handleClick = () => {
    history.push("/account");
  };

  handleHeadTag("account");

  if (loading) return <Spinner fullScreen width="50px" />;

  if (error || !data || !data?.me) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      {isMobile() && sectionName ? (
        <BodyWrapper background="#0c084c">
          <MobileDetailHeader handleClick={handleClick} title={mobileTitle} />
          <Container>
            <AccountPage data={data} refetch={refetch} />
          </Container>
        </BodyWrapper>
      ) : (
        <>
          <Header />
          <Container px={{ tablet: "0px" }} mt={4}>
            <AccountPage data={data} refetch={refetch} />
          </Container>
        </>
      )}
      <MobileNavigator />
    </>
  );
}
