import React from "react";
import styled from "styled-components";
import Spinner from "../../Modules/Loading";
import { SearchIcon } from "../../Icon";
import { debounce } from "../../../helper";
import { RowBlock } from "../../BlockUi";

const StyledInput = styled.input`
  width: 197px;
  font-family: ${(props) => props.theme.family.regular};
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px 0 0 5px;
  padding: 8px;

  &:focus {
    outline: none;
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  border-radius: 0 5px 5px 0;
  background: #444444;
  color: #eeeeee;
`;

export default function SearchInput({ onLazyChange, isSearching, ...props }) {
  const lazyCaller = debounce((value) => {
    onLazyChange(value);
  }, 500);

  const handleChange = (event) => {
    lazyCaller(event.target.value);
  };

  return (
    <RowBlock>
      <StyledInput {...props} onChange={handleChange} />
      <IconContainer>
        {!isSearching && <SearchIcon />}
        {isSearching && <Spinner width="20px" />}
      </IconContainer>
    </RowBlock>
  );
}
