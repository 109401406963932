import React from "react";
import { techCategories, TECH_CATEGORY_TITLES } from "../../../utils/constants";
import { Container, Row } from "../CommonStyles";

const categoryLabel = [
  "language",
  "versioning",
  "cloud",
  "tool",
  "architecture",
  "database",
  "analytics",
  "methodologies",
  "cicd",
];

export default function OtherSkills({ otherSkills }) {
  const regulatedData = otherSkills.reduce((acc, val) => {
    const category = techCategories[val.categoryId];
    if (!acc[category]) {
      acc[category] = [val.title];
    } else {
      acc[category].push(val.title);
    }

    return acc;
  }, {});

  const items = categoryLabel.map((key, index) => (
    <>
      {regulatedData[key] && (
        <Row key={index}>
          <label htmlFor={index}>{TECH_CATEGORY_TITLES[key]}:</label>
          <span>{regulatedData[key] && regulatedData[key].join(", ")}</span>
        </Row>
      )}
    </>
  ));

  return <Container>{items}</Container>;
}
