import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  cursor: pointer;
`;

const Body = styled.div`
  border-radius: 5px;
  box-shadow: 0 8px 12px 0 #cccccc;
  background-color: #ffffff;
  display: ${(props) => (props.open ? "block" : "none")};
  padding: 10px 10px 0;
  position: absolute;
  left: 110px;
  bottom: 1px;
  font-family: ${(props) => props.theme.family.demi};
  font-size: 14px;
  font-weight: 600;
  color: #444444;
  white-space: nowrap;
`;

export const DropDownItem = styled.div`
  cursor: pointer;
  padding-bottom: 10px;
`;

export function DropDown({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [setIsOpen]);

  return (
    <Wrapper ref={wrapperRef}>
      <Header onClick={() => setIsOpen(!isOpen)}>{title}</Header>
      <Body open={isOpen}>{children}</Body>
    </Wrapper>
  );
}
