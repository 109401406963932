import gql from "graphql-tag";

export const UPDATE_PASS_REASON = gql`
  mutation updatePassReason($id: ID!, $rejectReason: [ID]) {
    updatePassReason(id: $id, rejectReason: $rejectReason) {
      id
    }
  }
`;

export const MATCH_STATE_CHANGE = gql`
  mutation matchStateChange($id: ID!, $state: String!) {
    matchStateChange(id: $id, state: $state) {
      id
      state
    }
  }
`;

export const ADD_MATCH_APPLY_HISTORY = gql`
  mutation addMatchApplyHistory($id: ID!, $applyLink: String!) {
    addMatchApplyHistory(id: $id, applyLink: $applyLink) {
      id
    }
  }
`;

export const GET_REJECT_REASON = gql`
  {
    allRejectReason(type: 0) {
      id
      label
    }
  }
`;

export const CREATE_REJECT_REASON = gql`
  mutation createRejectReason(
    $label: String!
    $type: Int!
    $description: String
  ) {
    createRejectReason(label: $label, type: $type, description: $description) {
      id
    }
  }
`;
