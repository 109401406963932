import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import ProfileHeader from "./Header";
import ProfileTechnologiesCard from "./Technologies";
import ProfileCriteriasCard from "./Criterias";
import ProfileEducationView from "./Education";
import ProfileWorkHistoryView from "./WorkHistory";
import { ProfileBox } from "./CommonStyles";
import Sidebar from "./Sidebar";
import { ColumnBlock, RowBlock } from "../BlockUi";
import { SAVED_LINKEDINLOG_FOR_USER } from "./Sidebar/queries";
import PartialError from "../../pages/ErrorPage/PartialError";
import { ImportLinkedinButton } from "../ProfileEdit/ProfileImport/CommonStyles";
import { ACTION_BUTTON_TITLES } from "../../utils/constants";

export default function Profile({ user }) {
  const [isLinkedinSaved, setIsLinkedinSaved] = useState();
  const { data, error } = useQuery(SAVED_LINKEDINLOG_FOR_USER);
  const history = useHistory();

  useEffect(() => {
    if (data?.savedLinkedinLogForUser) {
      setIsLinkedinSaved(data.savedLinkedinLogForUser.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <PartialError />;

  return (
    <>
      <ColumnBlock width={{ tablet: "90%", mobileS: "100%", laptop: "70%" }}>
        <RowBlock display={{ laptop: "none" }}>
          {!isLinkedinSaved && (
            <ImportLinkedinButton
              block
              handleClick={() =>
                history.push("/profile/edit/import", {
                  from: ACTION_BUTTON_TITLES.IMPORT_LINKEDIN_BUTTON,
                })
              }
            >
              Import LinkedIn Profile
            </ImportLinkedinButton>
          )}
        </RowBlock>

        <ProfileBox
          px={{ mobileS: 3, tablet: 4, laptop: 5 }}
          py={{ mobileS: 2, tablet: 3, laptop: 4 }}
          my={2}
        >
          <ProfileHeader user={user} />
        </ProfileBox>

        <ProfileBox
          px={{ mobileS: 3, tablet: 4, laptop: 5 }}
          py={{ mobileS: 2, tablet: 3, laptop: 4 }}
          my={2}
        >
          <ProfileTechnologiesCard
            skills={user?.skills}
            otherSkills={user?.otherSkills}
          />
        </ProfileBox>

        <ProfileBox
          px={{ mobileS: 3, tablet: 4, laptop: 5 }}
          py={{ mobileS: 2, tablet: 3, laptop: 4 }}
          my={2}
        >
          {user?.criteria && (
            <ProfileCriteriasCard
              criteria={user?.criteria}
              preferences={user?.preferences}
            />
          )}
        </ProfileBox>
        <ProfileBox
          px={{ mobileS: 3, tablet: 4, laptop: 5 }}
          py={{ mobileS: 2, tablet: 3, laptop: 4 }}
          my={2}
        >
          <ProfileEducationView
            education={user?.education}
            languages={user?.languages}
          />
        </ProfileBox>
        <ProfileBox
          px={{ mobileS: 3, tablet: 4, laptop: 5 }}
          py={{ mobileS: 2, tablet: 3, laptop: 4 }}
          my={2}
        >
          <ProfileWorkHistoryView workHistory={user?.workHistory} />
        </ProfileBox>
      </ColumnBlock>
      <ColumnBlock
        ml={4}
        width={{ mobileS: "100%", tablet: "40%", laptop: "25%" }}
        display={{ mobileS: "none", laptop: "flex" }}
        justify="flex-start"
      >
        {isLinkedinSaved !== undefined && (
          <Sidebar user={user} isLinkedinSaved={isLinkedinSaved} />
        )}
      </ColumnBlock>
    </>
  );
}
