import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { space } from "styled-system";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import Spinner from "../../Modules/Loading";
import RadioButton from "../../Form/RadioButton";
import { Span, RowBlock, ColumnBlock } from "../../BlockUi";
import { Button } from "../../Button";
import OtherReason from "../../Form/OtherReason";
import {
  GET_ACCOUNT_DELETE_REASON,
  CREATE_DELETE_REASON,
  DELETE_ME,
  LOGOUT,
} from "../queries";
import { useLogout } from "../../../config/auth";

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  color: #aaaaaa;
  padding-top: 10px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const ReasonContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0 10px 13px 0 #cccccc;
  ${space}
`;

const ReasonTitle = styled.h3`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.79;
  color: #444444;
`;

const ReasonExplanation = styled.span`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 12px;
  font-weight: 500;
  line-height: 2.08;
  color: #aaaaaa;
  display: inline-block;
  margin-bottom: 10px;
`;

const validationSchema = Yup.object().shape({
  otherReason: Yup.string().required().min(5),
});

export default function CloseAccount({ deleteReason }) {
  const history = useHistory();
  const logoutHook = useLogout();
  const [loading, setLoading] = useState(false);
  const [
    allAccountDeleteReason,
    { loading: allReasonLoading, data, error: allReasonError },
  ] = useLazyQuery(GET_ACCOUNT_DELETE_REASON, {
    onCompleted: () => {
      setLoading(false);
    },
  });
  const [createAccountDeleteReason] = useMutation(CREATE_DELETE_REASON);
  const [deleteMe] = useMutation(DELETE_ME);
  const [deleteReasonId, setDeleteReasonId] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [validationErr, setValidationErr] = useState("");
  const [openReason, setOpenReason] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState();

  const [logout] = useLazyQuery(LOGOUT, {
    onCompleted: () => {
      if (window.heap) {
        window.heap.resetIdentity();
      }
      history.push("/login");
      localStorage.removeItem("isAuth");
    },
  });

  const handleClick = () => {
    if (!data) {
      allAccountDeleteReason();
    }
    setOpenReason(!openReason);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      if (deleteReasonId === "other") {
        await validationSchema.validate({ otherReason });
        const createdDeleteReason = await createAccountDeleteReason({
          variables: {
            label: otherReason,
          },
        });
        await deleteMe({
          variables: {
            deleteReason: createdDeleteReason.data.createAccountDeleteReason.id,
          },
        });
      } else {
        await deleteMe({
          variables: {
            deleteReason: deleteReasonId,
          },
        });
      }
      setLoading(false);
      setOpenReason(false);
      logout();
      logoutHook();
    } catch (err) {
      if (err.name === "ValidationError") {
        setValidationErr(err);
        setOpenModal(false);
        setLoading(false);
      } else {
        setError(err);
      }
    }
  };

  useEffect(() => {
    if (allReasonError) {
      setError(allReasonError);
    }
    if (allReasonLoading) {
      setLoading(allReasonLoading);
    }
  }, [allReasonLoading, allReasonError]);

  const deleteReasonList =
    data &&
    data.allAccountDeleteReason.map((item) => {
      return (
        <RadioButton
          key={item.id}
          label={item?.label}
          id={item.id}
          name={item.id}
          checked={item.id === deleteReasonId}
          value={deleteReasonId}
          onChange={() => setDeleteReasonId(item.id)}
          width="100%"
        />
      );
    });

  const toggleModal = () => {
    setOpenModal(false);
    setOpenReason(false);
  };

  const isFormHidden = () => {
    return deleteReason.find((i) => i.active);
  };
  return (
    <>
      <Title onClick={() => handleClick()}>Close account</Title>
      {loading && <Spinner width="42px" center />}
      {error &&
        error.graphQLErrors.map(({ message }, i) => (
          <Span key={i} color="red.0" fontWeight="bold">
            {message}
          </Span>
        ))}

      {data && openReason && !isFormHidden() && !loading && (
        <ReasonContainer
          px={{ mobileS: 4, tablet: "56px" }}
          py={{ mobileS: 4, tablet: "44px" }}
          pt={{ tablet: "20px" }}
        >
          <ReasonTitle>Why do you want to close your account?</ReasonTitle>
          <ReasonExplanation>
            Learning the reason will help us develop a better product.
          </ReasonExplanation>
          <ColumnBlock>
            {deleteReasonList}
            <RadioButton
              label="Other"
              name="other"
              id="other"
              checked={deleteReasonId === "other"}
              value={deleteReasonId}
              onChange={() => setDeleteReasonId("other")}
              width="100%"
            >
              {deleteReasonId === "other" && (
                <OtherReason
                  value={otherReason}
                  setOtherReason={setOtherReason}
                  validationErr={validationErr}
                />
              )}
            </RadioButton>
          </ColumnBlock>

          <RowBlock
            justifyContent={{ mobileS: "center", tablet: "flex-start" }}
            mt={4}
          >
            <Button
              mr={{ tablet: 4 }}
              variant="secondary"
              size="medium"
              onClick={() => setOpenModal(true)}
              disabled={!deleteReasonId}
            >
              Close account
              {loading && <Spinner width="32px" mr={1} />}
            </Button>
          </RowBlock>
        </ReasonContainer>
      )}
      {openModal && (
        <Modal handleClose={toggleModal} width="400px">
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalBody>
            <b>Are you sure you want to permanently delete your account?</b>
          </ModalBody>
          <ModalFooter>
            <Button variant="danger" size="small" onClick={handleDelete}>
              Yes
            </Button>
            <Button variant="primary" size="small" onClick={toggleModal}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
