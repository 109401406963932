import React from "react";
import styled from "styled-components";
import { space } from "styled-system";
import Switch from "./Form/ToggleSwitch";
import device from "../styles/device";
import PulsingSignal from "./Modules/PulsingSignal";

const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  ${space}

  @media ${device.mobileL} {
    min-width: 520px;
  }

  @media ${device.tablet} {
    min-width: 640px;
  }

  @media ${device.laptop} {
    min-width: 724px;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  display: flex;
  justify-content: space-between;
  ${space}
  font-size: 18px;
  cursor: pointer;
  color: ${(props) =>
    props.active ? props.theme.colors.main[0] : props.theme.colors.font[1]};
`;

const ChildBlock = styled.div`
  width: 100%;
  height: 100%;
  ${space}
  border-top: 2px solid #eeeeee;
`;

const signalStyle = {
  position: "absolute",
  right: "-40px",
  top: "-20px",
};

export default function CriteriaSelectionCard({
  label,
  children,
  active,
  handleToggle,
  id,
  signal,
}) {
  const handleOnToggle = (e) => {
    e.preventDefault();
    handleToggle(id);
  };
  return (
    <Wrapper mx={{ mobileS: 1, laptop: 7, tablet: 5 }}>
      <Title
        active={active}
        py={{ mobileS: 3, laptop: 4, tablet: 4 }}
        px={{ mobileS: 3, laptop: 6, tablet: 5 }}
        onClick={handleOnToggle}
        id={id}
      >
        {label}
        <Switch name={label} active={active}>
          {signal && <PulsingSignal signalStyle={signalStyle} />}
        </Switch>
      </Title>
      {active && (
        <ChildBlock
          py={{ mobileS: 4, laptop: 5, tablet: 4 }}
          px={{ mobileS: 3, laptop: 6, tablet: 5 }}
        >
          {children}
        </ChildBlock>
      )}
    </Wrapper>
  );
}
