import gql from "graphql-tag";

export const ALL_DOCUMENT = gql`
  {
    allDocuments {
      documents {
        id
        title
        description
        files {
          fileURL
          fileLanguage
        }
        visible
        publishedAt
        coverImageURL
      }
    }
  }
`;

export const GET_USER = gql`
  {
    me {
      id
    }
  }
`;

export const GET_ALL_COMPANY = gql`
  query allCompany(
    $limit: Int
    $search: String
    $page: Int
    $sort: companySortInputType
  ) {
    allCompany(limit: $limit, search: $search, page: $page, sort: $sort) {
      companies {
        id
        name
        logo
      }
      currentPage
      totalCount
      totalPages
    }
  }
`;

export const GET_COMPANY = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      name
      description
      summary
      slogan
      contents {
        place
        culture
      }
      website
      linkedin
      logo
      sector {
        id
        label
      }
      investStatus
      foundedYear
      teamSize
      techTeamSize
      tgNotes
      officePhotos {
        id
        url
        description
      }
      technologies {
        id
        title
        category
        categoryId
      }
      positions {
        id
        title
        createdAt
      }
    }
  }
`;
