import React from "react";
import {
  CriteriaFieldDescription,
  CriteriaFieldTitle,
  ColumnBlock,
} from "../BlockUi";
import Textarea from "../Form/Textarea";

const OtherCriteria = ({ selectedValues, handleOnChange }) => {
  return (
    <>
      <ColumnBlock>
        <CriteriaFieldTitle>
          What other criteria do you want to see here?
        </CriteriaFieldTitle>
        <CriteriaFieldDescription>
          We will evaluate the items you entered and add them to the options.
        </CriteriaFieldDescription>
        <Textarea
          value={selectedValues.expected}
          rows={4}
          cols={10}
          type="textarea"
          name="email"
          onChange={(e) => handleOnChange({ expected: e.target.value })}
          mt={2}
        />
      </ColumnBlock>
    </>
  );
};

export default OtherCriteria;
