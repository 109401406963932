import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  SelectField,
  CitySelectField,
} from "../../../components/Form/SelectField";
import { rangeOfYears } from "../../../helper";
import { Button } from "../../../components/Button";
import { FormField } from "../../../components/FormUi";
import { BoxTitle, BoxText, RowBlock } from "../../../components/BlockUi";
import TextField from "../../../components/Form/TextField";

const THIS_YEAR = new Date().getFullYear();

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 60px 0px;
`;

export default function ProfileSelection({
  handleProfileChange,
  onSubmit,
  selectedValues,
  formValid,
  formState,
  setFormState,
}) {
  const history = useHistory();
  const { state } = history.location;

  useEffect(() => {
    if (state) {
      setFormState({
        ...formState,
        profile: {
          fullName: state.name,
          birthyear: state.birthyear,
          livingCity: state.livingCity,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (e) => {
    handleProfileChange({ name: e.target.name, value: e.target.value });
  };
  const years = rangeOfYears(THIS_YEAR - 70, THIS_YEAR - 18)
    .map((year) => {
      return { label: year, value: year };
    })
    .sort((a, b) => b.value - a.value);
  return (
    <>
      <BoxTitle color="font.0">
        Lets get to know you more.{" "}
        <span role="img" aria-label="punch">
          👊
        </span>
      </BoxTitle>
      <BoxText color="font.1">We want to get to know you better.</BoxText>
      <FormContainer>
        <FormField justifyContent="center">
          <TextField
            label="Your Full Name"
            name="fullName"
            value={selectedValues.fullName}
            background={selectedValues.fullName ? "#c7eed5" : "#f5f6f8"}
            autoFocus
            onChange={handleOnChange}
            isRequired
            otherStyle={{ minHeight: "0px" }}
          />
        </FormField>
        <FormField justifyContent="center">
          <SelectField
            name="birthyear"
            label="Year of Birth"
            options={years}
            width="280px"
            placeholder="Select year"
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No results found" : "Type something to search"
            }
            value={years.filter((i) => i.value === selectedValues.birthyear)}
            onChange={(e) =>
              handleProfileChange({ name: "birthyear", value: e.value })
            }
            selectedBgColorful
            optionStyle={{ textAlign: "center" }}
            valuePadding="0 0 0 15px"
            valueAlign="center"
            textAlign="center"
            isRequired
          />
        </FormField>
        <FormField justifyContent="center">
          <CitySelectField
            name="livingCity"
            label="City of Residence"
            width="280px"
            placeholder="Search city"
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No results found" : "Type something to search"
            }
            value={selectedValues.livingCity}
            onChange={(value) =>
              handleProfileChange({ name: "livingCity", value })
            }
            selectedBgColorful
            optionStyle={{ textAlign: "center" }}
            valuePadding="0 0 0 15px"
            valueAlign="center"
            textAlign="center"
            isRequired
          />
        </FormField>
      </FormContainer>
      <RowBlock>
        <Button
          disabled={!formValid}
          type="submit"
          variant="primary"
          size="large"
          onClick={onSubmit}
          alignItems="center"
          display="flex"
          justifyContent="center"
          className="register-updated"
        >
          Continue
        </Button>
      </RowBlock>
    </>
  );
}
