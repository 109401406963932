/* eslint-disable array-callback-return */

import React, { useState, useCallback, useEffect } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { RowBlock, ColumnBlock, SkillitemCircle } from "../../BlockUi";
import { SelectField, AsyncCreatableSelectField } from "../../Form/SelectField";
import FormScaffold from "../FormScaffold";
import {
  captureErrorWithData,
  debounce,
  uuidGenerate,
  isMobile,
} from "../../../helper";
import message from "../../../utils/message";
import { experience } from "../../../utils/constants";

const UPDATE_USER = gql`
  mutation UpdateUser($skills: [SkillInputType], $creatorToken: ID) {
    updateUser(skills: $skills, creatorToken: $creatorToken) {
      id
    }
  }
`;

const CREATE_TECHNOLOGY = gql`
  mutation createTechnology($title: String!, $creatorToken: ID) {
    createTechnology(title: $title, creatorToken: $creatorToken) {
      id
      title
      creatorToken {
        email
      }
    }
  }
`;

export default function Skills({ user, handleSearch, handleUpdate }) {
  const [skillList, setSkillList] = useState(user.skills || []);
  const [itemStatus, setItemStatus] = useState();
  const [creatorToken, setCreatorToken] = useState(user.creatorToken);

  const [updateUser] = useMutation(UPDATE_USER);
  const [createTechnology, { loading: createTechnologyLoading }] =
    useMutation(CREATE_TECHNOLOGY);

  useEffect(() => {
    if (!creatorToken) {
      const creatorToken = uuidGenerate();
      setCreatorToken(creatorToken);
      updateUser({
        variables: {
          creatorToken,
        },
      });
    }
  }, [creatorToken, updateUser]);

  const handleSubmit = async (newState) => {
    const skillData = newState.reduce((acc, val) => {
      acc.push({ _id: val._id || val.id, experience: val.experience });
      return acc;
    }, []);
    try {
      await updateUser({
        variables: {
          skills: skillData,
        },
      });
      setItemStatus("success");
      handleUpdate();
    } catch (error) {
      setItemStatus("error");
      captureErrorWithData(error);
    }
  };

  const debouncedSave = useCallback(
    debounce((newState) => {
      setItemStatus("loading");
      handleSubmit(newState);
    }, 500),
    []
  );
  const applyChange = (changes) => {
    setSkillList(changes);
    debouncedSave(changes);
  };

  const handleSkill = (skill, index) => {
    if (skillList.length === 1 && skill._id === 0) {
      return message.error(
        "You need to have at least one skill in order to get matches."
      );
    }

    setItemStatus("pending");
    if (skill._id === 0) {
      const newList = [...skillList];
      newList.splice(index, 1);
      applyChange([...newList]);
    }

    if (skill._id !== 0) {
      if (index >= skillList.length) {
        applyChange([...skillList, { ...skill, experience: 1 } || 0]);
      } else {
        const updatedState = skillList.map((item, idx) => {
          return idx === index
            ? { ...skill, experience: item.experience }
            : { ...item, experience: item.experience };
        });
        applyChange(updatedState);
      }
    }
  };

  const handleExperience = (value, index) => {
    setItemStatus("pending");
    const updatedStateExperience = skillList.map((skill, idx) => {
      return idx === index ? { ...skill, ...value } : skill;
    });
    applyChange(updatedStateExperience);
  };

  const handleCreate = async (inputValue, index) => {
    try {
      if (skillList.some((i) => i.title === inputValue)) {
        throw new Error("This skill already exists.");
      }
      const newTechnology = await createTechnology({
        variables: {
          title: inputValue,
          creatorToken,
        },
      });
      const newOption = {
        label: newTechnology.data.createTechnology.title,
        value: newTechnology.data.createTechnology.id,
      };

      handleSkill(
        { experience: null, _id: newOption.value, title: newOption.label },
        index
      );
    } catch (err) {
      message.error(err.message);
    }
  };

  const getSkillByIndex = (idx) => {
    return skillList.map(
      ({ title, _id, experience: experienceValue }, index) => {
        const { name, value } = experience.filter(
          (i) => i.value === experienceValue
        )[0];
        if (index === idx) {
          const skills = {
            value: _id,
            title,
            name: isMobile() ? value : name,
          };
          if (experienceValue === 8 && isMobile()) {
            skills.name = "8+";
          }
          return skills;
        }
      }
    );
  };

  let status = "pending";
  if (itemStatus) {
    status = itemStatus;
  } else {
    status = skillList.length > 0 ? "success" : "pending";
  }

  const experienceWidth = isMobile() ? 80 : 115;

  return (
    <div>
      <FormScaffold
        status={status}
        label="Which programming language or frameworks have you worked with in the past?"
        explanation="You can enter up to 5 technologies"
      >
        <ColumnBlock>
          {Array(5)
            .fill("")
            .map((_, index) => (
              <RowBlock
                key={index}
                width={{ mobileS: "100%", tablet: "100%", laptopL: "80%" }}
                wrap="no-wrap"
                justify="space-between"
                my={2}
              >
                <SkillitemCircle>{index + 1}</SkillitemCircle>
                <AsyncCreatableSelectField
                  value={getSkillByIndex(index)}
                  width={{
                    mobileS: `calc(100% - ${experienceWidth + 50}px)`,
                    tablet: `calc(100% - ${experienceWidth + 80}px)`,
                  }}
                  getOptionLabel={(option) => option.label || option.title}
                  getOptionValue={(option) => option.id}
                  onChange={(opt) => {
                    handleSkill(
                      { _id: opt ? opt.id : 0, title: opt ? opt.title : "" },
                      index
                    );
                  }}
                  onCreateOption={(inputValue) =>
                    handleCreate(inputValue, index)
                  }
                  isLoading={createTechnologyLoading}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  formatCreateLabel={(userInput) => `Add New:  ${userInput}`}
                  isClearable
                  placeholder="Add Language / Framework"
                  loadOptions={(val, func) =>
                    handleSearch(
                      {
                        value: val,
                        exclude: skillList.map((i) => i._id || []),
                        verified: true,
                      },
                      func
                    )
                  }
                />
                <SelectField
                  placeholder="Experience"
                  value={getSkillByIndex(index)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  isSearchable={false}
                  onChange={(selectedOption) => {
                    handleExperience(
                      { experience: selectedOption.value },
                      index
                    );
                  }}
                  options={experience}
                  width={`${experienceWidth}px`}
                />
              </RowBlock>
            ))}
        </ColumnBlock>
      </FormScaffold>
    </div>
  );
}
