import React from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { useLogout } from "../../config/auth";

import { ColumnBlock } from "../BlockUi";
import TabList, { Tab, IconField } from "../Modules/Tabs";
import {
  AccountSettingsIcon,
  BellIcon,
  SignOutIcon,
  FeedbackIcon,
  GithubBoardIcon,
  MatchSettingsIcon,
  // DiscountIcon,
} from "../Icon";
import { show, boot } from "../../utils/Intercom";

const LOGOUT = gql`
  query LOGOUT {
    logout
  }
`;
const BottomTabList = styled.div`
  margin-top: 40px;
`;

const GrayIconField = styled(IconField)`
  background-color: #f5f6f8;
`;

export default function NavList({ handleClick, active, mobileDetail }) {
  const history = useHistory();
  const logoutHook = useLogout();

  const [logout] = useLazyQuery(LOGOUT, {
    onCompleted: () => {
      if (window.heap) {
        window.heap.resetIdentity();
      }
      history.push("/login");
      localStorage.removeItem("isAuth");
    },
  });

  const display = mobileDetail ? "none" : "flex";

  const handleLogout = async () => {
    logout();
    logoutHook();
  };

  const handleFeedback = async () => {
    boot();
    show();
  };

  const handleBugsOrRequest = async () => {
    return window.open("https://talentgrid.producter.co/portal/");
  };

  const tablist = [
    {
      name: "settings",
      text: "Account Settings",
      icon: AccountSettingsIcon,
      iconColor: "#72D1FB",
    },
    {
      name: "match-settings",
      text: "Match Settings",
      icon: MatchSettingsIcon,
      iconColor: "#72D1FB",
    },
    {
      name: "notifications",
      text: "Notifications",
      icon: BellIcon,
      iconColor: "#72D1FB",
    },

    //! Promosyon butonu yenileri eklenene kadar gizlendi
    // {
    //   name: "promotions",
    //   text: t("pages.account.promotions"),
    //   icon: DiscountIcon,
    //   iconColor: "#72D1FB",
    // },
  ];

  return (
    <ColumnBlock
      display={{ mobileS: display, tablet: "flex" }}
      width={{ mobileS: "100%", tablet: "30%", laptop: "30%" }}
      px={{ mobileS: "20px", tablet: "0" }}
    >
      <TabList tablist={tablist} handleClick={handleClick} active={active} />
      <BottomTabList>
        <Tab onClick={handleFeedback}>
          <GrayIconField>
            <FeedbackIcon />
          </GrayIconField>
          Chat with Us
        </Tab>
        <Tab onClick={handleBugsOrRequest}>
          <GrayIconField>
            <GithubBoardIcon />
          </GrayIconField>
          Report Bugs / Requests
        </Tab>
        <Tab onClick={handleLogout}>
          <GrayIconField>
            <SignOutIcon />
          </GrayIconField>
          Log out
        </Tab>
      </BottomTabList>
    </ColumnBlock>
  );
}
