import React from "react";
import { BoxTitle } from "../../../components/BlockUi";
import RadioButton from "../../../components/Form/RadioButton";
import { getExperienceOptions } from "../../../helper";

export default function ExperienceSelectionStep({
  selectedExperience = "",
  onSelect,
}) {
  const experienceOptions = getExperienceOptions();

  return (
    <>
      <BoxTitle color="font.0">
        How many years of professional experience do you have?
      </BoxTitle>
      {experienceOptions.map(({ id, title }) => (
        <RadioButton
          key={id}
          name="profession"
          onChange={onSelect}
          onClick={onSelect}
          value={selectedExperience}
          id={id}
          checked={id === selectedExperience}
          label={title}
          centered
          icon={false}
        />
      ))}
    </>
  );
}
