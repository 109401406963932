import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { space } from "styled-system";
import {
  ButtonLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "tg-design";
import { RowBlock } from "../BlockUi";
import {
  MATCH_STATE_CHANGE,
  UPDATE_PASS_REASON,
  ADD_MATCH_APPLY_HISTORY,
} from "./queries";
import { ExternalLinkIcon, ThumbIcon } from "../Icon";
import Spinner from "../Modules/Loading";
import { Button, btnSize } from "../Button";
import RejectReason from "./RejectReason";
import { ErrorText, IconWrap } from "./CommonModule";
import {
  captureErrorWithData,
  isPositionOpen,
  getMatchHeapInfo,
} from "../../helper";
import { heapIO } from "../../utils/heap";
import message from "../../utils/message";
import {
  MATCH_STATES,
  MATCH_HISTORY_ACTION_TYPES,
} from "../../utils/constants";

const DetailAction = styled.div`
  ${space}
  padding: 15px 0px;
`;

const StatusBox = styled.div`
  background-color: ${(props) => (props.success ? "#e3f7ea" : "#f5f6f8")};
  border-radius: 20px;
  border: solid 2px ${(props) => (props.success ? "#8fdeac" : "#ddd")};
  color: ${(props) => (props.success ? "#8fdeac" : "#aaa")};
  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  font-family: ${(props) => props.theme.family.bold};
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  ${space}
`;

export default function MatchDetailAction({
  match,
  state,
  matchId,
  refetch,
  isEmpty,
  position,
  onInterested,
}) {
  const [matchStateChange, { error: updateMutationError }] =
    useMutation(MATCH_STATE_CHANGE);
  const [addMatchApplyHistory] = useMutation(ADD_MATCH_APPLY_HISTORY);
  const [updatePassReason] = useMutation(UPDATE_PASS_REASON);

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentState, setCurrentState] = useState(state);

  useEffect(() => {
    setCurrentState(state);
  }, [state]);

  const isMatchTakenBack = match?.history?.some((i) =>
    i.actions.find(
      (j) => j.type === MATCH_HISTORY_ACTION_TYPES.MATCH_TAKEN_BACK_BY_DEVELOPER
    )
  );

  const handleInterested = async () => {
    if (isEmpty) {
      return;
    }

    if (position.isExternal && position.externalPositionApplyLink) {
      await addMatchApplyHistory({
        variables: {
          id: matchId,
          applyLink: position.externalPositionApplyLink,
        },
      });
      const url = new URL(position.externalPositionApplyLink);
      url.searchParams.append("utm_source", "talentgrid");
      window.open(url, "_blank");
      return;
    }

    try {
      setLoading(true);
      const { data } = await matchStateChange({
        variables: {
          id: matchId,
          state: "INTERESTED",
        },
      });

      if (data) {
        onInterested();
        await refetch();
      }

      heapIO.createCustomEvent(
        "Matches Match Interested Clicked",
        getMatchHeapInfo(match)
      );

      setLoading(false);
    } catch (err) {
      captureErrorWithData(err);
    }
  };

  const handleUndo = async () => {
    try {
      await matchStateChange({
        variables: {
          id: matchId,
          state: "SENT",
        },
      });
      if (match.state === MATCH_STATES.PASSED) {
        await updatePassReason({
          variables: {
            id: matchId,
            rejectReason: [],
          },
        });
      }
      await refetch();
    } catch (err) {
      message.error(err.message);
      captureErrorWithData(err);
      setIsModalOpen(false);
    }
  };

  const handlePass = async () => {
    if (isEmpty) {
      return;
    }

    try {
      await matchStateChange({
        variables: {
          id: matchId,
          state: MATCH_STATES.PASSED,
        },
      });
      await refetch();
    } catch (err) {
      captureErrorWithData(err);
    }

    heapIO.createCustomEvent(
      "Matches Match Pass Clicked",
      getMatchHeapInfo(match)
    );
  };

  if (!isPositionOpen(position.state)) {
    return (
      <DetailAction>
        <RowBlock justify="start">
          <StatusBox>Position Closed</StatusBox>
        </RowBlock>
      </DetailAction>
    );
  }

  let buttons = "";
  switch (currentState) {
    case "INTERESTED":
    case "TG_ASSESSMENT":
    case "COMPANY_ASSESSMENT":
    case "INTERVIEW":
    case "SENT_OFFER":
    case "HIRED":
      buttons = (
        <RowBlock justify="start" align="center" gridGap="10px">
          <StatusBox success>
            <IconWrap rotate={+true} top="1px" mr={2}>
              <ThumbIcon color="#8fdeac" width="18" height="16" />
            </IconWrap>
            Interested
          </StatusBox>
          {!isMatchTakenBack &&
            !isEmpty &&
            (match.state === MATCH_STATES.INTERESTED ||
              match.state === MATCH_STATES.PASSED) && (
              <ButtonLink underline onClick={() => setIsModalOpen(true)}>
                Undo
              </ButtonLink>
            )}
        </RowBlock>
      );
      break;
    case "PASSED":
      buttons = (
        <RowBlock justify="start" align="center" gridGap="10px">
          <StatusBox>
            <IconWrap top="4px" mr={2}>
              <ThumbIcon color="#aaaaaa" width="18" height="16" />
            </IconWrap>
            Passed
          </StatusBox>
          {!isMatchTakenBack &&
            !isEmpty &&
            (match.state === MATCH_STATES.INTERESTED ||
              match.state === MATCH_STATES.PASSED) && (
              <ButtonLink underline onClick={() => setIsModalOpen(true)}>
                Undo
              </ButtonLink>
            )}
        </RowBlock>
      );
      break;
    case "FAILED":
      buttons = (
        <RowBlock justify="start">
          <StatusBox>Failed</StatusBox>
        </RowBlock>
      );
      break;
    default:
      buttons = (
        <RowBlock
          px={{ mobileS: "10%", tablet: "0" }}
          justifyContent={{ mobileS: "center", tablet: "flex-start" }}
        >
          <Button
            mr={{ mobileS: 0, tablet: 4 }}
            mb={3}
            onClick={handleInterested}
            variant="primary"
            size="large"
          >
            {loading ? (
              <Spinner width="32px" mr={1} />
            ) : (
              <IconWrap rotate top="2px" mr={3}>
                {position.isExternal ? (
                  <ExternalLinkIcon fillColor="#fff" size="16" />
                ) : (
                  <ThumbIcon color="#fff" />
                )}
              </IconWrap>
            )}
            {position.isExternal ? "Apply" : "Interested"}
          </Button>

          <Button
            variant="secondary"
            width={{ mobileS: btnSize.large, tablet: btnSize.small }}
            onClick={() => handlePass()}
          >
            <IconWrap top="6px" mr={3}>
              <ThumbIcon />
            </IconWrap>
            Pass
          </Button>
        </RowBlock>
      );
  }

  return (
    <>
      <DetailAction>
        {match.state === MATCH_STATES.PASSED &&
        (!match.rejectReason || match.rejectReason.length === 0) ? (
          <RejectReason
            matchId={matchId}
            heapInfo={getMatchHeapInfo(match)}
            matchStateChange={matchStateChange}
            updateMutationError={updateMutationError}
            refetch={refetch}
          />
        ) : (
          <>
            {updateMutationError && (
              <ErrorText>Something went wrong...</ErrorText>
            )}
            {buttons}
          </>
        )}
        {match.state === MATCH_STATES.PASSED &&
          match?.rejectReason?.length > 0 && (
            <RowBlock
              justify="start"
              pt={3}
              style={{ fontSize: "14px" }}
              gridGap="10px"
            >
              <b>Pass reasons:</b>{" "}
              {match.rejectReason.map((i, index) => (
                <>
                  {i.label}
                  {match.rejectReason.length - 1 > index ? ", " : ""}
                </>
              ))}
            </RowBlock>
          )}
      </DetailAction>
      {isModalOpen && (
        <Modal handleClose={() => setIsModalOpen(false)}>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalBody>
            This action can be done only once for this role. Are you sure you
            want to undo your action?
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" size="small" onClick={handleUndo}>
              Yes
            </Button>
            <Button
              variant="danger"
              size="small"
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
