import React from "react";
import styled from "styled-components";

const SkipLinkContainer = styled.div`
  font-family: ${(props) => props.theme.family.regular};
  text-align: center;
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: underline;
  margin-top: 14px;
  cursor: pointer;
`;

export default function SkipLink({ next }) {
  return (
    <SkipLinkContainer>
      <span href="#" onClick={next}>
        Skip
      </span>
    </SkipLinkContainer>
  );
}
