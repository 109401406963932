import React, { useState, useCallback, useEffect } from "react";
import { useMutation } from "@apollo/client";
import FormScaffold from "./FormScaffold";
import Toggle from "./Toggle";
import { RowBlock } from "../BlockUi";
import { debounce, removeTypename } from "../../helper";
import message from "../../utils/message";
import { ISTANBUL_PLACE_ID } from "../../utils/constants";
import { UPDATE_CRITERIA } from "./queries";
import {
  SalaryCritieria,
  BenefitsCriteria,
  TechnologiesCriteria,
  LocationCriteria,
  CompanySizeCriteria,
  FreelanceCriteria,
  OtherCriteria,
} from "../Criterias";

const criteriaType = () => {
  return {
    salary: {
      open: true,
      expected: 0,
      strictness: 4,
      period: "MONTHLY",
      income: "NET",
    },
    location: {
      open: false,
      expected: [],
      remote: null,
    },
    companySize: {
      open: false,
      expected: [],
    },
    other: {
      open: false,
      expected: "",
    },
    technologies: {
      open: false,
      expected: [],
    },
    benefits: {
      open: false,
      expected: [],
    },
    freelance: {
      open: false,
      availableWeeklyHour: null,
      hourlyRate: null,
      hourlyRateCurrency: "USD",
    },
  };
};

export default function Criterias({ user, onUpdate }) {
  const [state, setState] = useState(
    user.criteria ? user.criteria : criteriaType()
  );
  const [itemStatus, setItemStatus] = useState();
  const [isValidCriteria, setIsValidCriteria] = useState(false);

  const [updateDeveloperCriteria] = useMutation(UPDATE_CRITERIA);

  useEffect(() => {
    const validCriterias = Object.values(state)
      .filter((s) => typeof s === "object")
      .filter((c) => c.open);
    setIsValidCriteria(validCriterias.length !== 0);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleSubmit = async (newState) => {
    try {
      await updateDeveloperCriteria({
        variables: {
          ...newState,
        },
      });
      onUpdate({ criteria: new Date() });
      setItemStatus("success");
    } catch (error) {
      message.error(error.message);
      setItemStatus("error");
    }
  };

  const debouncedSave = useCallback(
    debounce((newState) => handleSubmit(newState), 1000),
    []
  );
  const applyChange = (changes) => {
    setItemStatus("pending");
    const newState = {
      ...state,
      ...changes,
    };
    setState(newState);

    const key = Object.keys(changes);
    if (key[0] === "salary" || key[0] === "other" || key[0] === "freelance") {
      debouncedSave({
        [key]: removeTypename(changes[key]),
      });
    } else if (key[0] === "location") {
      const location = { ...changes[key] };
      if (
        location.expected &&
        location.expected.length > 0 &&
        typeof location.expected[0] !== "string"
      ) {
        location.expected = location.expected.map((item) => item.id);
      }
      debouncedSave({
        location: removeTypename(location),
      });
    } else {
      const arrangedData = {
        [key]: {
          ...removeTypename(changes[key]),
          expected: changes[key].expected.map(({ id }) => id),
        },
      };
      debouncedSave(arrangedData);
    }
  };

  const handleSalary = (salary) => {
    applyChange({ salary });
  };

  const handleLocation = (values) => {
    applyChange({
      location: {
        ...state.location,
        ...values,
      },
    });
  };

  const handleTechnologies = (value) => {
    applyChange({
      technologies: {
        ...state.technologies,
        expected: value || [],
      },
    });
  };

  const handleFreelance = (e) => {
    const key =
      e.target?.name ||
      (e.key === "expected" ? "hourlyRate" : "hourlyRateCurrency");
    const value = e.target?.value || e.value;
    const changes = {
      [key]: e.key === "currency" ? value : parseInt(value, 10),
    };
    applyChange({
      freelance: {
        ...state.freelance,
        ...changes,
      },
    });
  };

  const handleBenefits = (value) => {
    applyChange({
      benefits: {
        ...state.benefits,
        expected: value || [],
      },
    });
  };

  const handleCompanySize = (val) => {
    applyChange({
      companySize: { ...state.companySize, expected: val },
    });
  };

  const handleOther = (val) => {
    applyChange({
      other: { ...state.other, expected: val.expected },
    });
  };

  const tryToAddLivingCityToLocationCriteria = (newState) => {
    if (!newState.expected) {
      newState.expected = [];
    }

    if (
      ["half", "none"].includes(newState.remote) &&
      newState.expected.length === 0 &&
      user.livingCity
    ) {
      newState.expected.push(user.livingCity);

      if (
        user.livingCity.id === ISTANBUL_PLACE_ID &&
        (!newState.istanbulRegion || newState.istanbulRegion === "none")
      ) {
        newState.istanbulRegion = "all";
      }
    }
  };

  const handleOnOff = (section, checked) => {
    // Eski verilerde remote bilgisi NULL olabilen kayıtlar var.
    // Ancak API'dan mutlaka bir seçim istiyoruz. Eğer kullanıcı Çalışma Konumunu
    // kapalıdan açığa getiriyorsa ama remote bilgisi henüz yoksa, bunu doğrudan
    // kaydetmiyoruz.
    if (section === "location" && state[section].remote === null) {
      return;
    }

    const newState = state[section];
    if (section === "location" && checked) {
      tryToAddLivingCityToLocationCriteria(newState);
    }

    applyChange({
      [section]: { ...newState, open: checked },
    });
  };

  let status = "pending";
  if (itemStatus) {
    status = !isValidCriteria ? "pending" : itemStatus;
  } else {
    status = isValidCriteria ? "success" : "pending";
  }

  return (
    <FormScaffold
      status={status}
      label="What are your expectations from a new role and company?"
    >
      <RowBlock width="100%" mb={4}>
        <Toggle
          title="Salary"
          open
          pr={{ laptopL: "15%" }}
          handleOnOff={handleOnOff}
          sectionName="salary"
          showSwitch={false}
        >
          <SalaryCritieria
            selectedValues={state.salary}
            user={user}
            handleOnChange={handleSalary}
            rangeWidth
          />
        </Toggle>
      </RowBlock>

      <RowBlock width="100%" mb={4}>
        <Toggle
          title="Work Location"
          open={state.location.open}
          handleOnOff={handleOnOff}
          sectionName="location"
          autoMinHeight
          showSwitch
        >
          <LocationCriteria
            selectedValues={state.location}
            handleOnChange={handleLocation}
            user={user}
          />
        </Toggle>
      </RowBlock>
      <RowBlock width="100%" mb={4}>
        <Toggle
          title="Freelance"
          open={state.freelance.open}
          handleOnOff={handleOnOff}
          sectionName="freelance"
          autoMinHeight
        >
          <FreelanceCriteria
            selectedValues={state.freelance}
            handleOnChange={handleFreelance}
            currency={
              user?.criteria?.freelance?.hourlyRateCurrency ||
              user?.preferences?.currency
            }
          />
        </Toggle>
      </RowBlock>

      <RowBlock width="100%" mb={4}>
        <Toggle
          title="Tech Stack"
          open={state.technologies.open}
          handleOnOff={handleOnOff}
          sectionName="technologies"
        >
          <TechnologiesCriteria
            selectedValues={state.technologies}
            handleOnChange={handleTechnologies}
          />
        </Toggle>
      </RowBlock>

      <RowBlock width="100%" mb={4}>
        <Toggle
          title="Side Benefits"
          open={state.benefits.open}
          handleOnOff={handleOnOff}
          sectionName="benefits"
        >
          <BenefitsCriteria
            selectedValues={state.benefits}
            handleOnChange={handleBenefits}
          />
        </Toggle>
      </RowBlock>

      <RowBlock width="100%" mb={4}>
        <Toggle
          title="Company Size"
          open={state.companySize.open}
          handleOnOff={handleOnOff}
          sectionName="companySize"
        >
          <CompanySizeCriteria
            selectedValues={state.companySize}
            handleOnChange={handleCompanySize}
          />
        </Toggle>
      </RowBlock>

      <RowBlock width="100%" mb={4}>
        <Toggle
          title="Other"
          open={state.other.open}
          handleOnOff={handleOnOff}
          sectionName="other"
        >
          <OtherCriteria
            selectedValues={state.other}
            handleOnChange={handleOther}
          />
        </Toggle>
      </RowBlock>
    </FormScaffold>
  );
}
