import { toast } from "react-toastify";
import {
  ErrorMessage,
  SuccessMessage,
  WarningMessage,
} from "../components/ToastComponents";

export default {
  error: (message, options) => {
    toast.error(ErrorMessage(message), options);
  },
  success: (message) => {
    toast.success(SuccessMessage(message));
  },
  warning: (message) => {
    toast.warning(WarningMessage(message));
  },
  close: () => {
    toast.dismiss();
  },
};
