import React, { useCallback } from "react";
import { useQuery } from "@apollo/client";
import { GET_TECHNOLOGIES } from "../../../graphql/queries";
import Skills from "./Skills";
import OtherSkills from "./OtherSkills";
import { debounce, captureErrorWithData } from "../../../helper";
import message from "../../../utils/message";
import PartialError from "../../../pages/ErrorPage/PartialError";

export default function Content({ user, onUpdate }) {
  const { error, refetch: searchTechnologies } = useQuery(GET_TECHNOLOGIES, {
    skip: true,
  });

  const handleSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const response = await searchTechnologies({
          search: val.value,
          limit: 20,
          categoryIds: val.categoryIds,
          exclude: val.exclude,
          verified: val.verified,
        });
        callback(response.data?.allTechnology?.technologies || []);
      } catch (err) {
        message.error("Something went wrong.");
        captureErrorWithData(err);
      }
    }, 500),
    []
  );

  if (error) return <PartialError />;

  const handleUpdate = () => {
    onUpdate({ user: new Date() });
  };

  return (
    <>
      <Skills
        user={user}
        handleUpdate={handleUpdate}
        handleSearch={handleSearch}
      />
      <OtherSkills
        user={user}
        handleUpdate={handleUpdate}
        handleSearch={handleSearch}
      />
    </>
  );
}
