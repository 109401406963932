/* eslint-disable react/no-danger */

import React from "react";
import styled from "styled-components";
import device from "../../../styles/device";

const BlockRadioButton = styled.input`
  &[type="radio"] {
    visibility: hidden;
  }
  &[type="checkbox"] {
    visibility: hidden;
  }
`;

const Icon = styled.svg`
  fill: ${(props) => (props.checked ? "#8fdeac" : props.theme.colors.font[1])};
  stroke: white;
  stroke-width: 2px;
`;

const HiddenOption = styled.input.attrs({ type: "option" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledOption = styled.div`
  display: inline-block;
  min-width: 13px;
  width: 13px;
  height: 13px;
  background: ${(props) =>
    props.checked ? "#fff" : props.theme.colors.font[4]};
  border: 1px solid;
  border-color: ${(props) =>
    props.checked ? "#8fdeac" : props.theme.colors.font[3]};
  border-radius: 50%;
  transition: all 150ms;
  margin-right: 15px;

  ${HiddenOption}:focus + & {
    box-shadow: 0 0 0 2px #8fdeac;
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const RadioLabel = styled.label`
  display: flex;
  font-family: ${(props) => props.theme.family.demi};
  border-radius: 5px;
  padding: 10px 12px;
  flex-direction: row;
  justify-content: ${(props) => (props.centered ? "center" : "left")};
  align-items: center;
  color: #444444;
  font-size: 14px;
  position: relative;
  width: ${(props) => (props.width ? props.width : "280px")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "280px")};
  @media ${device.tablet} {
    height: 40px;
  }
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.green[2] : "#f5f6f8"};
`;

const RadioButton = ({
  name,
  label,
  id,
  onChange,
  onClick,
  width,
  minWidth,
  checked,
  icon = true,
  centered = false,
  children,
}) => {
  return (
    <>
      <BlockRadioButton
        name={name}
        id={id}
        type="radio"
        value={id}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      <RadioLabel
        width={width}
        minWidth={minWidth}
        name={name}
        isSelected={checked}
        htmlFor={id}
        centered={centered}
      >
        {icon && (
          <StyledOption checked={checked}>
            <Icon viewBox="0 -1 21 24" checked={checked}>
              <circle cx="11" cy="7" r="9" strokeWidth="3" />
            </Icon>
          </StyledOption>
        )}
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {children}
      </RadioLabel>
    </>
  );
};

export default RadioButton;
