import React from "react";
import styled from "styled-components";
import device from "../../styles/device";
import { ColumnBlock } from "../BlockUi";
import { Box, BoxTitle } from "./CommonStyles";

const STEPS = [
  {
    step: "1.",
    stepTitle: "Invitee signs up",
    stepDescription:
      "Your friends can easily sign up using your personal invite link. ",
    stepImage: "/assets/howItWorksPhotos/invitee_signs_up.png",
  },
  {
    step: "2.",
    stepTitle: "Gets matched & hired ",
    stepDescription:
      "TalentGrid suggests relevant matches. One match leads to a successful hire!",
    stepImage: "/assets/howItWorksPhotos/get_matched_and_hired.png",
  },
  {
    step: "3.",
    stepTitle: "You both earn cash reward",
    stepDescription:
      "Once hired, you and your friend both earn cash reward from TalentGrid. ",
    stepImage: "/assets/howItWorksPhotos/you_both_earn_cash_reward.png",
  },
];
const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media ${device.mobileMax} {
    flex-direction: column;
    align-items: center;
    & div + div {
      margin-top: 20%;
    }
  }
`;

const StepContainer = styled.div`
  width: 210px;
`;

const StepNumber = styled.p`
  font-family: "Avenir Next LT Pro Bold", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #444444;
  margin-top: 30px;
`;

const StepTitle = styled.p`
  font-family: "Avenir Next LT Pro Bold", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #444444;
  margin-top: 22px;
`;

const StepDescription = styled.p`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #444444;
  margin-top: 12px;
`;

const StepImage = styled.img``;
export default function HowItWorks() {
  return (
    <Box px={{ mobileS: 3, tablet: 3, laptop: 217 }}>
      <ColumnBlock my={4} width="100%">
        <BoxTitle mb={39}>How it works</BoxTitle>
        <StepsWrapper>
          {STEPS.map((item, index) => {
            return (
              <StepContainer key={index}>
                <StepImage src={item.stepImage} />
                <StepNumber>{item.step}</StepNumber>
                <StepTitle>{item.stepTitle}</StepTitle>
                <StepDescription>{item.stepDescription}</StepDescription>
              </StepContainer>
            );
          })}
        </StepsWrapper>
      </ColumnBlock>
    </Box>
  );
}
