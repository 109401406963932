import React, { useState, Fragment } from "react";
import dayjs from "dayjs";
import { ButtonLink, Modal, ModalBody } from "tg-design";
import styled from "styled-components";
import {
  PrimaryTitle,
  SecondaryTitle,
  GroupTitle,
  DateText,
  TagText,
  DescriptionText,
  SectionContainer,
  ProfilePhotoContainer,
  Title,
} from "./CommonStyles";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import ReadMore from "../../ReadMore";
import ProfileImage from "../BasicInfos/ProfileImage";
import { LANGUAGE_LEVEL_TYPES_WITH_KEYS } from "../../../utils/constants";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  line-height: 20px;
  font-size: 14px;
`;

const Line = styled.div`
  border-top: 2px solid;
  margin-bottom: 10px;
`;

export const PreviousImports = ({ previousData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalState, setModalState] = useState(false);

  const openModal = (state) => {
    setIsModalOpen(true);
    setModalState(state);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <Title>Previous imports</Title>
      <Line style={{ borderTop: "2px solid", marginBottom: "10px" }} />
      {previousData?.length === 0 && <p>Nothing to show.</p>}
      {previousData?.length > 0 &&
        previousData.map((item, index) => (
          <Fragment key={index}>
            <Container>
              <p>
                Import from {dayjs(item.createdAt).format("DD.MM.YYYY H:mm")}
              </p>
              <ButtonLink
                onClick={() => openModal(item)}
                style={{ marginRight: "20px" }}
              >
                Details
              </ButtonLink>
            </Container>
            <hr />
          </Fragment>
        ))}
      {isModalOpen && (
        <Modal maxHeight="80vh" handleClose={() => setIsModalOpen(false)}>
          <ModalBody style={{ color: "#000" }}>
            {modalState.__typename === "ResumeLog" && (
              <span>Imported from {modalState.filename || "unknown file"}</span>
            )}
            {modalState?.savedData?.avatarURL && (
              <>
                <GroupTitle style={{ marginTop: "0" }}>
                  Profile Photo
                </GroupTitle>
                <ColumnBlock>
                  <ProfilePhotoContainer isChecked>
                    <ProfileImage
                      name="avatarURL"
                      avatar={modalState.savedData.avatarURL}
                      showEdit={false}
                    />
                  </ProfilePhotoContainer>
                </ColumnBlock>
              </>
            )}

            {modalState?.savedData?.bio && (
              <>
                <GroupTitle>About</GroupTitle>
                <ColumnBlock>
                  <SectionContainer isChecked>
                    <DescriptionText>
                      <ReadMore
                        text={JSON.stringify(modalState.savedData.bio)}
                        limit={250}
                      />
                    </DescriptionText>
                  </SectionContainer>
                </ColumnBlock>
              </>
            )}
            {modalState?.savedData?.workHistory?.length > 0 && (
              <>
                <GroupTitle>Work Experience</GroupTitle>
                {modalState.savedData.workHistory.map((item, index) => {
                  return (
                    <ColumnBlock key={index} style={{ marginTop: "5px" }}>
                      <SectionContainer isChecked>
                        <div style={{ flex: "1" }}>
                          <PrimaryTitle>{item.company}</PrimaryTitle>
                          <SecondaryTitle>{item.position}</SecondaryTitle>
                          <DateText>
                            {dayjs(item.startDate)
                              .locale("en")
                              .format("MMMM YYYY")}{" "}
                            -{" "}
                            {item.endDate
                              ? dayjs(item.endDate)
                                  .locale("en")
                                  .format("MMMM YYYY")
                              : "Present"}
                          </DateText>
                          <DescriptionText>
                            {item.description && (
                              <ReadMore
                                text={item.description || ""}
                                limit={30}
                              />
                            )}
                          </DescriptionText>
                        </div>
                      </SectionContainer>
                    </ColumnBlock>
                  );
                })}
              </>
            )}
            {modalState?.savedData?.education?.length > 0 && (
              <>
                <GroupTitle>Education</GroupTitle>
                {modalState.savedData.education.map((item, index) => {
                  return (
                    <ColumnBlock key={index} style={{ marginTop: "5px" }}>
                      <SectionContainer isChecked>
                        <div style={{ flex: "1" }}>
                          <PrimaryTitle>{item?.school?.name}</PrimaryTitle>
                          <SecondaryTitle>{item?.branch?.label}</SecondaryTitle>
                          <DateText>
                            {item.startDate} -{" "}
                            {item.endDate ? item.endDate : "?"}
                          </DateText>
                        </div>
                      </SectionContainer>
                    </ColumnBlock>
                  );
                })}
              </>
            )}
            {modalState?.savedData?.languages?.length > 0 && (
              <>
                <GroupTitle>Languages</GroupTitle>
                {modalState.savedData.languages.map((item, index) => {
                  return (
                    <ColumnBlock key={index} style={{ marginTop: "5px" }}>
                      <SectionContainer isChecked>
                        <div style={{ flex: "1" }}>
                          <PrimaryTitle>{item.label}</PrimaryTitle>
                          <SecondaryTitle>
                            {
                              LANGUAGE_LEVEL_TYPES_WITH_KEYS.find(
                                (i) => i.value === item.level
                              )?.label
                            }
                          </SecondaryTitle>
                        </div>
                      </SectionContainer>
                    </ColumnBlock>
                  );
                })}
              </>
            )}
            {modalState?.savedData?.otherSkills?.length > 0 && (
              <>
                <GroupTitle>Skills</GroupTitle>
                <RowBlock justify="flex-start" gridGap="10px">
                  {modalState.savedData.otherSkills.map((item, index) => {
                    return (
                      <TagText isChecked key={index}>
                        <span>{item}</span>
                      </TagText>
                    );
                  })}
                </RowBlock>
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
