import React, { useState, useEffect } from "react";
import styled from "styled-components";
import findLast from "lodash.findlast";
import { Hint, Stepper, Modal, ModalBody } from "tg-design";
import {
  MATCH_STATES,
  MATCH_STATES_ARRAY,
  POSITION_STATES,
  hiringSteps,
} from "../../../utils/constants";
import { isMobile, isPositionOpen } from "../../../helper";

const StepperContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 30px;

  &div:first-child {
    width: 50%;
  }
`;

const Content = styled.p`
  font-family: ${(props) => props.theme.family.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #444444;
  flex: 1;
`;

const ACTIVE_STEP_COLOR = "#72D1FB";
const PASSIVE_STEP_COLOR = "#AAAAAA";
const FAILED_STEP_COLOR = "#FFBBBA";
const COMPLETED_STEP_COLOR = "#8fdeac";

const PASSIVE_HINT_COLOR = "#DDDDDD";

export const getPreviousHistoryState = ({ history, state }) => {
  if (Array.isArray(history) && history.length > 0) {
    let previousStates = history.map((i) => i.previous);
    if (state !== MATCH_STATES.FAILED) {
      previousStates = previousStates.filter((i) => i !== MATCH_STATES.FAILED);
    }
    if (state !== MATCH_STATES.PASSED) {
      previousStates = previousStates.filter((i) => i !== MATCH_STATES.PASSED);
    }
    previousStates = previousStates.filter((i) => i !== MATCH_STATES.CANCELED);

    const lastPreviousState = findLast(MATCH_STATES_ARRAY, (i) =>
      previousStates.includes(i)
    );
    return lastPreviousState;
  }
  return MATCH_STATES.SENT;
};

export const getCurrentHistoryState = ({ history, state }) => {
  if (Array.isArray(history) && history.length > 0) {
    let currentStates = history.map((i) => i.current);
    if (state !== MATCH_STATES.FAILED) {
      currentStates = currentStates.filter((i) => i !== MATCH_STATES.FAILED);
    }
    if (state !== MATCH_STATES.PASSED) {
      currentStates = currentStates.filter((i) => i !== MATCH_STATES.PASSED);
    }
    currentStates = currentStates.filter((i) => i !== MATCH_STATES.CANCELED);

    const lastCurrentState = findLast(MATCH_STATES_ARRAY, (i) =>
      currentStates.includes(i)
    );
    return lastCurrentState;
  }
  return state;
};

export default function HiringProcessTab({ match, isEmpty }) {
  const isPositionExternal = match.position.isExternal;
  const isPositionPassive = !isPositionOpen(match.position.state);
  const isPositionPassiveAndFilled =
    isPositionPassive && match.state === MATCH_STATES.HIRED;
  const isMatchFailed = match.state === MATCH_STATES.FAILED;
  const isMatchPassed = match.state === MATCH_STATES.PASSED;
  const isFailed =
    (isPositionPassive || isMatchFailed || isMatchPassed) &&
    !isPositionPassiveAndFilled;
  const currentMatchState = !isEmpty
    ? getCurrentHistoryState(match)
    : match.state;
  const previousMatchState = !isEmpty
    ? getPreviousHistoryState(match)
    : MATCH_STATES.SENT;

  const [activeHintStep, setActiveHintStep] = useState({});
  const [toggleMobileHint, setToggleMobileHint] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isMobile()) {
      setIsModalOpen(true);
    }
  }, [toggleMobileHint]);

  const getActiveStepperStep = () => {
    if (isMatchFailed) {
      const activeStepperStep = hiringSteps.find((i) =>
        i.completedState.includes(previousMatchState)
      );
      activeStepperStep.failed = isFailed;
      return activeStepperStep;
    }
    if (isMatchPassed) {
      const activeStepperStep = hiringSteps.find(
        (i) => i.key === MATCH_STATES.SENT
      );
      activeStepperStep.failed = isFailed;
      return activeStepperStep;
    }
    if (isPositionPassiveAndFilled) {
      const activeStepperStep = hiringSteps.find((i) =>
        i.key.includes(currentMatchState)
      );
      return activeStepperStep;
    }

    if (isPositionPassive) {
      const activeStepperStep = hiringSteps.find((i) =>
        i.completedState.includes(currentMatchState)
      );
      activeStepperStep.failed = isFailed;
      return activeStepperStep;
    }

    const activeStepperStep = hiringSteps.find((i) =>
      i.completedState.includes(currentMatchState)
    );
    activeStepperStep.failed = isFailed;
    return activeStepperStep;
  };

  const activeStepperStep = getActiveStepperStep();

  const getActiveStepColor = () => {
    if (isMatchFailed || isMatchPassed) {
      return FAILED_STEP_COLOR;
    }
    if (isPositionPassiveAndFilled) {
      return COMPLETED_STEP_COLOR;
    }
    if (isPositionPassive) {
      return PASSIVE_STEP_COLOR;
    }
    return ACTIVE_STEP_COLOR;
  };

  const getActiveHintStep = () => {
    if (isMatchFailed) {
      const index = hiringSteps.findIndex((i) => i.key === MATCH_STATES.FAILED);
      return hiringSteps[index];
    }
    if (isMatchPassed) {
      const index = hiringSteps.findIndex((i) => i.key === MATCH_STATES.PASSED);
      return hiringSteps[index];
    }

    if (isPositionPassiveAndFilled) {
      const index = hiringSteps.findIndex((i) => i.key === MATCH_STATES.HIRED);
      return hiringSteps[index];
    }

    if (isPositionPassive) {
      const index = hiringSteps.findIndex(
        (i) => i.key === POSITION_STATES.CLOSED
      );
      return hiringSteps[index];
    }

    if (!hiringSteps.find((i) => i.key === activeStepperStep.key).visible) {
      const index = hiringSteps.findIndex(
        (i) => i.key === activeStepperStep.key
      );
      return hiringSteps[index];
    }
    const index =
      hiringSteps.findIndex((i) => i.key === activeStepperStep.key) + 1;
    return hiringSteps[index];
  };

  const formatHintDescription = (hint) => {
    const stringToReplace = match?.position?.assignee?.name
      ? "[TPNAME]"
      : ", [TPNAME],";

    return hint?.description
      .replace(
        stringToReplace,
        match?.position?.assignee?.name
          ? `<b>${match?.position?.assignee?.name}</b>`
          : ""
      )
      .replace("[COMPANYNAME]", `<b>${match?.position?.company?.name}</b>`);
  };

  const formatHintTitle = (title) => {
    let newTitle = title;
    const symbolColor = getActiveStepColor();
    const actionSymbol = `<div style="width:25px;height:25px;border:3px solid ${symbolColor};border-radius: 50%;display:inline-block;vertical-align:bottom;"></div> `;

    if (title.includes("Next action")) {
      newTitle = title.replace(
        "Next action:",
        `Next action:<br/><br/>${actionSymbol}`
      );
    } else {
      newTitle = actionSymbol + title;
    }

    return newTitle.replace("[COMPANYNAME]", match?.position?.company?.name);
  };

  const getHintTitle = (hint) => {
    const nextStepIndex =
      hiringSteps.findIndex((i) => i.key === activeStepperStep.key) + 1;
    const nextStepKey = hiringSteps[nextStepIndex].key;

    if (nextStepKey === hint.key && hint.actionTitle) {
      return (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: formatHintTitle(hint.actionTitle),
          }}
        />
      );
    }
    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatHintTitle(hint.title),
        }}
      />
    );
  };

  useEffect(() => {
    const hintStep = getActiveHintStep();
    const description = formatHintDescription(hintStep);
    const title = getHintTitle(hintStep);

    setActiveHintStep({ ...hintStep, title, description });
    setToggleMobileHint(!toggleMobileHint);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [match]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleMouseEnter = ({ step }) => {
    const hintStep = hiringSteps.find((i) => i.key === step.key);
    const description = formatHintDescription(hintStep);
    setActiveHintStep({ ...hintStep, description });
    setToggleMobileHint(!toggleMobileHint);
  };
  const handleMouseLeave = () => {
    const hintStep = getActiveHintStep();
    const title = getHintTitle(hintStep);
    const description = formatHintDescription(hintStep);
    setActiveHintStep({ ...hintStep, title, description });
  };

  return (
    <>
      {!isPositionExternal && (
        <Content style={{ paddingTop: "12px" }}>
          Estimated time until your interview with the company:{" "}
          <b>{match?.position?.processDurationInDays || "15"} Days</b>
        </Content>
      )}

      {isPositionExternal && (
        <Content>
          <br />
          This position is not TG Certified, so we don&apos;t own the hiring
          process. After you apply to the post, we can&apos;t promise you will
          be assessed and notified about your application.
          <br /> You can always filter and apply to TG Certified positions to
          follow the hiring process transparently while receiving a more
          personalized experience.
        </Content>
      )}
      {!isPositionExternal && (
        <StepperContainer>
          <Stepper
            onMouseEnter={({ step }) => handleMouseEnter({ step })}
            onMouseLeave={handleMouseLeave}
            currentStepKey={activeStepperStep.key}
            activeStepColor={getActiveStepColor()}
            steps={hiringSteps}
          />
          {!isMobile() ? (
            <Hint
              descriptionStyle={{ fontSize: "14px" }}
              activeHint={activeHintStep}
              color={isFailed ? PASSIVE_HINT_COLOR : null}
            />
          ) : (
            <>
              {isModalOpen && (
                <Modal handleClose={handleModalClose}>
                  <ModalBody>
                    <Hint
                      descriptionStyle={{ fontSize: "14px" }}
                      activeHint={activeHintStep}
                      color={isFailed ? PASSIVE_HINT_COLOR : null}
                    />
                  </ModalBody>
                </Modal>
              )}
            </>
          )}
        </StepperContainer>
      )}
    </>
  );
}
