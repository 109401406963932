import React, { useState, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { RowBlock, ColumnBlock } from "../../BlockUi";
import { SelectField, AsyncSelectField } from "../../Form/SelectField";
import { captureErrorWithData, debounce } from "../../../helper";
import { EDUCATION_TYPES_WITH_KEYS } from "../../../utils/constants";
import { SEARCH_UNIVERSITY, SEARCH_BRANCH, UPDATE_USER } from "./queries";
import { Button } from "../../FormUi";
import { schema } from "./EducationValidationSchema";
import IntercomLauncherMessage from "../../Modules/IntercomLauncherMessage";
import { FormTitle, FormErrorMessages } from "../CommonStyles";
import message from "../../../utils/message";
import { getPossibleStartYears, getPossibleEndYears } from "./years";

export const EMPTY_EDUCATION_FIELDS = {
  type: null,
  school: {
    name: "",
    id: null,
  },
  branch: {
    label: "",
    id: null,
  },
  startDate: null,
  endDate: null,
};

export default function EducationForm({
  education = [],
  selectedEducation,
  handleClose,
  onUpdate,
}) {
  if (!education) {
    education = [];
  }

  const newEducation = JSON.parse(JSON.stringify(selectedEducation.education));

  const [educationState, setEducationState] = useState(newEducation);
  const [state, setState] = useState(education);

  const [savingStatus, setSavingStatus] = useState(false);
  const [updateUser] = useMutation(UPDATE_USER);

  const insertNewEducation = () => {
    const newState = state.slice();
    newState.push(selectedEducation.education);
    selectedEducation.index = state.length;
    setState(newState);
  };

  const { refetch: universityRefetch } = useQuery(SEARCH_UNIVERSITY, {
    skip: true,
  });

  const { refetch: branchRefetch } = useQuery(SEARCH_BRANCH, {
    skip: true,
  });

  const handleSchoolSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await universityRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        callback(
          data.allUniversity.universities.map((university) => {
            return { value: university.id, label: university.name };
          })
        );
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleBranchSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const { data } = await branchRefetch({ search: val }); // refetch because of useQuery and useLazyQuery doesnt return a promise
        const _branches = data.allUniversityBranch.universityBranches.map(
          (branch) => {
            return {
              value: branch?.id,
              label: branch?.label,
            };
          }
        );
        callback(_branches);
      } catch (err) {
        message.error("Something went wrong.");
      }
    }, 500),
    []
  );

  const handleOptionChange = ({ value, label, type }) => {
    let _value = value;

    if (type === "school") {
      _value = {
        name: label,
        id: value,
      };
    }
    if (type === "branch") {
      _value = {
        label,
        id: value,
      };
    }

    const newList = {
      ...educationState,
      [type]: _value,
    };

    // Kullanıcı başlangıç tarihinden daha küçük bir bitiş tarihi seçemez.
    if (
      Number.isInteger(newList.startDate) &&
      Number.isInteger(newList.endDate) &&
      newList.endDate < newList.startDate
    ) {
      newList.endDate = null;
    }
    const newState = state.slice();
    newState[selectedEducation.index] = newList;

    setEducationState(newList);
    setState(newState);
  };

  const getEducationType = (initialFields) => {
    if (initialFields.type !== null && initialFields.type !== undefined) {
      return EDUCATION_TYPES_WITH_KEYS[initialFields.type];
    }
    return "";
  };

  const handleSubmit = async () => {
    const { index } = selectedEducation;

    const validationVariables = [
      {
        school: state[index]?.school?.id,
        branch: state[index]?.branch?.id,
        type: state[index]?.type,
        endDate: state[index]?.endDate,
        startDate: state[index]?.startDate,
      },
    ];

    const variables = state.map((item) => {
      return {
        school: item.school?.id,
        branch: item.branch?.id,
        type: item.type,
        endDate: item.endDate,
        startDate: item.startDate,
      };
    });

    setSavingStatus(true);

    try {
      await schema.validate({ educations: validationVariables });
      await updateUser({ variables: { education: variables } });
      onUpdate({ user: new Date() });
      setSavingStatus(false);
      handleClose(state);
    } catch (error) {
      if (error?.name === "ValidationError") return;
      captureErrorWithData(error);
    }
  };

  const EducationRow = (initialFields, index) => {
    return (
      <>
        <FormTitle>
          {initialFields.__typename
            ? "Edit education info"
            : "Add new education info"}
        </FormTitle>
        <RowBlock mb={3} key={index}>
          <RowBlock
            pt={3}
            pb={5}
            justify="flex-start"
            width={{ mobileS: "95%", tablet: "95%", laptop: "100%" }}
          >
            <ColumnBlock width={{ mobileS: "100%", tablet: "70%" }} flex="1">
              <RowBlock my={3} justify="start">
                <SelectField
                  label="Education Level"
                  value={getEducationType(initialFields || null)}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isSearchable={false}
                  options={EDUCATION_TYPES_WITH_KEYS}
                  width="100%"
                  onChange={(selectedOption) =>
                    handleOptionChange({
                      ...selectedOption,
                      type: "type",
                      index,
                    })
                  }
                />
                {savingStatus && !Number.isInteger(educationState.type) && (
                  <FormErrorMessages>
                    Education level is required.
                  </FormErrorMessages>
                )}
              </RowBlock>
              <RowBlock my={3} justify="start">
                <AsyncSelectField
                  label="School attended"
                  value={{ label: initialFields?.school?.name || "" }}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? (
                      <IntercomLauncherMessage />
                    ) : (
                      "Type something to search"
                    )
                  }
                  loadOptions={handleSchoolSearch}
                  onChange={(selectedOption) =>
                    handleOptionChange({
                      ...selectedOption,
                      type: "school",
                      index,
                    })
                  }
                  width="100%"
                />
                {savingStatus && !educationState.school.name && (
                  <FormErrorMessages>School is required.</FormErrorMessages>
                )}
              </RowBlock>
              <RowBlock my={3} justify="start">
                <AsyncSelectField
                  label="Department"
                  placeholder=""
                  value={{
                    label: initialFields?.branch?.label,
                  }}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? (
                      <IntercomLauncherMessage />
                    ) : (
                      "Type something to search"
                    )
                  }
                  loadOptions={handleBranchSearch}
                  onChange={(selectedOption) =>
                    handleOptionChange({
                      ...selectedOption,
                      type: "branch",
                      index,
                    })
                  }
                  width="100%"
                />

                {savingStatus && !educationState.branch.label && (
                  <FormErrorMessages>Department is required.</FormErrorMessages>
                )}
              </RowBlock>
              <RowBlock my={3} align="baseline">
                <ColumnBlock
                  width={{ mobileS: "100%", tablet: "50%" }}
                  pr={{ tablet: 2 }}
                >
                  <SelectField
                    label="Year started"
                    placeholder=""
                    value={{ label: initialFields.startDate }}
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? "No results found"
                        : "Type something to search"
                    }
                    isSearchable
                    options={getPossibleStartYears(initialFields.endDate)}
                    onChange={(selectedOption) =>
                      handleOptionChange({
                        ...selectedOption,
                        type: "startDate",
                        index,
                      })
                    }
                    width="100%"
                  />
                  {savingStatus && !educationState.startDate && (
                    <FormErrorMessages>
                      Start date is required.
                    </FormErrorMessages>
                  )}
                </ColumnBlock>
                <ColumnBlock
                  width={{ mobileS: "100%", tablet: "50%" }}
                  pl={{ tablet: 2 }}
                  mt={{ mobileS: 3, tablet: 0 }}
                >
                  <SelectField
                    label="Year finished"
                    placeholder=""
                    value={{ label: initialFields.endDate }}
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? "No results found"
                        : "Type something to search"
                    }
                    isSearchable
                    options={getPossibleEndYears(initialFields.startDate)}
                    width="100%"
                    onChange={(selectedOption) =>
                      handleOptionChange({
                        ...selectedOption,
                        type: "endDate",
                        index,
                      })
                    }
                  />
                  {savingStatus && !educationState.endDate && (
                    <FormErrorMessages>End date is required.</FormErrorMessages>
                  )}
                </ColumnBlock>
              </RowBlock>
              <RowBlock mt={4}>
                <Button onClick={() => handleSubmit()} width="100%">
                  Save
                </Button>
              </RowBlock>
            </ColumnBlock>
          </RowBlock>
        </RowBlock>
      </>
    );
  };

  if (selectedEducation.index === null) {
    insertNewEducation();
  }

  return <div>{EducationRow(educationState)}</div>;
}
