import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ButtonLink } from "tg-design";
import { ThumbsUpIcon, BlackLinkedInIcon, OpenWebSiteIcon } from "../Icon";
import { Button } from "../Button";
import { CompanyLink } from "../Match/CompanyBasics";
import { Span, ColumnBlock } from "../BlockUi";
import device from "../../styles/device";
import { CREATE_PUBLIC_FORCE_MATCH_IN_COMMUNITY } from "../../pages/Match/queries";
import ErrorPage from "../../pages/ErrorPage";
import {
  MATCH_STATES,
  POSITION_STATES,
  PUBLIC_POSITION_STATE_TEXTS,
} from "../../utils/constants";
import ExternalRejectReasonModal from "./ExternalRejectReasonModal";
import Loading from "../Modules/Loading";

const ButtonHeader = styled.span`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #0b0849;
  margin-bottom: 13px;
  max-width: 500px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  @media ${device.mobileMax} {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  border-left: 2px solid #e3e3e3;
  padding-left: 33px;
  padding-bottom: 33px;
  margin-left: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ hasText }) => (hasText ? "0px" : "33px")};
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InterestCount = styled.div`
  display: block;
  text-align: right;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #aaaaaa;
  > svg {
    margin-top: 1px;
  }
`;

export default function ExternalLinksAndCreateProfile({
  publicPosition,
  isUserAuth,
  userData,
  referrerCode,
  matchCreationType,
  forceMatchToken,
}) {
  const history = useHistory();

  const urlTo = {
    register: `/register`,
    profile: `/profile`,
  };

  const [createPublicForceMatch, { loading, error }] = useMutation(
    CREATE_PUBLIC_FORCE_MATCH_IN_COMMUNITY
  );

  const [positionState] = useState(
    publicPosition.state === POSITION_STATES.IN_PROGRESS
      ? POSITION_STATES.IN_PROGRESS
      : "OTHER_STATES"
  );

  const [isRejectReasonModalOpen, setIsRejectReasonModalOpen] = useState(false);

  const createPublicForceMatchWithPassState = async () => {
    const { data } = await createPublicForceMatch({
      variables: {
        user: userData.me.id,
        publicPositionToken: publicPosition?.publicPositionToken,
        matchCreationType,
        inviter: referrerCode,
        matchState: MATCH_STATES.PASSED,
      },
    });

    if (data?.addForceMatch?.matchId) {
      return history.push(`/matches/${data.addForceMatch.matchId}`);
    }

    return history.push(`/matches`);
  };

  const handlePassButtonClick = async () => {
    if (!isUserAuth) {
      // Kullanıcı auth değilse reject reason aşamasına yönlendiriliyor.
      return setIsRejectReasonModalOpen(true);
    }

    if (
      positionState === POSITION_STATES.IN_PROGRESS &&
      isUserAuth &&
      userData
    ) {
      // eslint-disable-next-line
      return await createPublicForceMatchWithPassState();
    }

    return null;
  };

  const handleCloseRejectReasonModal = () => {
    setIsRejectReasonModalOpen(false);
  };

  const handleClick = () => {
    if (positionState === POSITION_STATES.IN_PROGRESS) {
      if (!isUserAuth && forceMatchToken) {
        return history.push(`${urlTo.register}?fmt=${forceMatchToken}`);
      }
      if (isUserAuth && userData) {
        createPublicForceMatch({
          variables: {
            user: userData.me.id,
            publicPositionToken: publicPosition?.publicPositionToken,
            matchCreationType,
            inviter: referrerCode,
            matchState: MATCH_STATES.INTERESTED,
          },
        });
        return history.push(urlTo.profile);
      }
      sessionStorage.setItem(
        "ppi",
        JSON.stringify({
          ppt: publicPosition?.publicPositionToken,
          type: matchCreationType,
          inviteCode: referrerCode,
        })
      );
      return history.push(urlTo.register);
    }
    if (isUserAuth && userData) {
      return history.push(urlTo.profile);
    }
    return history.push(urlTo.register);
  };

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading width="70px" />;

  return (
    <>
      <ExternalRejectReasonModal
        open={isRejectReasonModalOpen}
        handleClose={handleCloseRejectReasonModal}
        publicPositionId={publicPosition.publicID}
      />
      <Container style={{ marginLeft: "auto" }}>
        <ColumnBlock
          style={{ justifyContent: "space-between", alignItems: "start" }}
        >
          <ColumnBlock>
            <InterestCount>
              <ThumbsUpIcon />
              <Span ml={1}>{publicPosition.interestCount} Interested</Span>
            </InterestCount>
          </ColumnBlock>
          <LinkContainer>
            {publicPosition.company.linkedin && (
              <CompanyLink
                src={publicPosition.company.linkedin}
                icon={<BlackLinkedInIcon />}
              />
            )}
            {publicPosition.company.website && (
              <CompanyLink
                src={publicPosition.company.website}
                icon={<OpenWebSiteIcon />}
              />
            )}
          </LinkContainer>
        </ColumnBlock>
        <ButtonContainer hasText={!isUserAuth}>
          {!isUserAuth && (
            <ButtonHeader
              dangerouslySetInnerHTML={{
                __html:
                  PUBLIC_POSITION_STATE_TEXTS[positionState].userIsNotAuth
                    .buttonDescription,
              }}
            />
          )}
          <Button
            style={{ boxShadow: "none" }}
            type="button"
            variant="primary"
            size="large"
            className="web"
            onClick={handleClick}
          >
            {isUserAuth
              ? PUBLIC_POSITION_STATE_TEXTS[positionState].userIsAuth.buttonText
              : PUBLIC_POSITION_STATE_TEXTS[positionState].userIsNotAuth
                  .buttonText}
          </Button>
          <Button
            style={{ marginTop: "13px", marginBottom: "10px" }}
            type="button"
            size="large"
            className="web"
            variant="secondary"
            onClick={handlePassButtonClick}
          >
            Not Interested{" "}
            <span role="img" aria-label="thumbsdown">
              👎
            </span>
          </Button>
          {!isUserAuth && (
            <ButtonLink type="button" onClick={() => history.push(`/login`)}>
              Already have an account? Login
            </ButtonLink>
          )}
        </ButtonContainer>
      </Container>
    </>
  );
}
