import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { Header, MobileDetailHeader } from "../../components/Header";
import { Container, BodyWrapper } from "../../components/BlockUi";
import ProfileEdit from "../../components/ProfileEdit";
import Spinner from "../../components/Modules/Loading";
import { isMobile, handleHeadTag } from "../../helper";
import { GET_USER } from "../../graphql/queries";
import ErrorPage from "../ErrorPage";

const MOBILE_TITLES = {
  info: "Basic Info",
  skills: "Skills & Experience",
  criterias: "Preferences & Criteria",
  education: "Education",
  "work-history": "Work History",
  linkedin: "Import LinkedIn",
};

export default function ProfileEditPage() {
  const { data, loading, error, refetch } = useQuery(GET_USER);
  const [state, setState] = useState();
  const [mobileTitle, setMobileTitle] = useState("");
  const history = useHistory();
  const { sectionName } = useParams();

  handleHeadTag("profileEdit");

  useEffect(() => {
    if (isMobile()) {
      setMobileTitle(MOBILE_TITLES[sectionName]);
    }
    // eslint-disable-next-line
  }, [sectionName]);

  useEffect(() => {
    if (data) {
      setState(data.me);
    }
  }, [data]);

  if (loading) return <Spinner fullScreen width="50px" />;
  if (error) return <ErrorPage error={error} />;

  const handleOnClick = () => {
    history.push("/profile");
  };

  return (
    <>
      {isMobile() ? (
        <BodyWrapper background="#0c084c">
          <MobileDetailHeader handleClick={handleOnClick} title={mobileTitle} />
          <Container>
            {data && <ProfileEdit user={data.me} refetch={refetch} />}
          </Container>
        </BodyWrapper>
      ) : (
        <>
          <Header />
          <Container px={{ tablet: "0px" }} mt={4}>
            {state && <ProfileEdit user={state} refetch={refetch} />}
          </Container>
        </>
      )}
    </>
  );
}
