import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { GET_ALL_COMPANY } from "./queries";
import Loading from "../../Modules/Loading";
import DefaultAvatar from "../../../images/default-avatar.png";
import CompanyDetail from "./CompanyDetail";
import SearchInput from "./SearchInput";
import { DetailWrap } from "../../Layout/LayoutWithTabs";
import PartialError from "../../../pages/ErrorPage/PartialError";
import { SORTERS } from "../../../utils/constants";

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 10px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 18px;
  font-weight: 600;
  color: #0c084c;
  text-align: center;
`;

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 50px 40px;
  justify-items: stretch;
  @media only screen and (max-width: 320px) {
    margin: 0 50px;
  }
`;

const Box = styled.div`
  width: 125px;
  height: 125px;
`;

const CompanyAvatar = styled.img`
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: #e6e6e6;
`;

const CompanyName = styled.div`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 12px;
  width: 100%;
  text-align: center;
`;

const sort = {
  createdAt: SORTERS.descend,
};

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Companies() {
  // eslint-disable-next-line no-unused-vars
  const [getCompany, { loading, data, error, fetchMore }] =
    useLazyQuery(GET_ALL_COMPANY);
  const query = useUrlQuery();

  const [defaultLimit, setDefaultLimit] = useState(20);
  const [companies, setCompanies] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [isSearching, setSearching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    sort: query.get("sort") ? query.get("sort") : "-updatedAt",
  });

  const history = useHistory();

  const handleSearchChange = async (search) => {
    const newFilters = {
      ...filters,
      search,
    };
    setFilters(newFilters);
    query.set("search", search);
    history.push({ search: query.toString() });

    setSearching(true);
    const result = await fetchMore({
      variables: {
        ...newFilters,
        sort,
      },
    });
    setSearching(false);
    setCompanies(result?.data?.allCompany?.companies);
  };

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const result = await fetchMore({
        variables: {
          limit: defaultLimit,
          sort,
        },
      });
      setIsFetching(false);
      setCompanies(result?.data?.allCompany?.companies);
      history.replace({
        search: "",
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLimit]);

  window.onscroll = () => {
    if (
      window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 1 &&
      data?.allCompany?.totalCount > defaultLimit &&
      !currentCompany &&
      !filters?.search
    ) {
      const newLimit = defaultLimit + 20;
      setDefaultLimit(newLimit);
    }
  };

  if (currentCompany) {
    return (
      <CompanyDetail
        companyId={currentCompany}
        setCompany={setCurrentCompany}
      />
    );
  }

  if (loading) return <Loading width="70px" />;
  if (error) return <PartialError />;

  return (
    <DetailWrap
      px={{ mobileS: 3, tablet: "50px" }}
      py={{ mobileS: "44px", tablet: "30px" }}
      pb={{ mobileS: "80px" }}
    >
      <TitleContainer>
        <Title>Companies Hiring with TalentGrid</Title>
        <SearchInput
          type="text"
          placeholder="Search companies..."
          onLazyChange={handleSearchChange}
          isSearching={isSearching}
          defaultValue={filters.search}
        />
      </TitleContainer>
      <BoxContainer>
        {(companies || []).map((i) => (
          <Box key={i.id}>
            <CompanyAvatar
              src={i.logo || DefaultAvatar}
              onClick={() => setCurrentCompany(i.id)}
            />
            <CompanyName>{i.name}</CompanyName>
          </Box>
        ))}
        {!companies?.length && <>No company found.</>}
      </BoxContainer>
      {isFetching && <Loading width="70px" />}
    </DetailWrap>
  );
}
