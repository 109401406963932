import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button } from "../Button";
import device from "../../styles/device";
import { CREATE_PUBLIC_FORCE_MATCH_IN_COMMUNITY } from "../../pages/Match/queries";
import ErrorPage from "../../pages/ErrorPage";
import {
  MATCH_STATES,
  POSITION_STATES,
  PUBLIC_POSITION_STATE_TEXTS,
} from "../../utils/constants";
import ExternalRejectReasonModal from "./ExternalRejectReasonModal";
import Loading from "../Modules/Loading";

const MobileView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const BarContainer = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  background: #0b0849;
  border-radius: 10px;
  @media ${device.mobileMax} {
    width: 100vw;
    border-radius: 0px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;
const Description = styled.span`
  font-family: "Avenir Next LT Pro Regular", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
`;

const TextAndButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export default function CreateProfileBarMobile({
  publicPosition,
  token,
  isUserAuth,
  userData,
  referrerCode,
  matchCreationType,
  forceMatchToken,
}) {
  const history = useHistory();
  const urlTo = {
    register: `/register`,
    profile: `/profile`,
  };

  const [createPublicForceMatch, { loading, error }] = useMutation(
    CREATE_PUBLIC_FORCE_MATCH_IN_COMMUNITY
  );

  const [positionState] = useState(
    publicPosition.state === POSITION_STATES.IN_PROGRESS
      ? POSITION_STATES.IN_PROGRESS
      : "OTHER_STATES"
  );

  const [isRejectReasonModalOpen, setIsRejectReasonModalOpen] = useState(false);

  const createPublicForceMatchWithPassState = async () => {
    const { data } = await createPublicForceMatch({
      variables: {
        user: userData.me.id,
        publicPositionToken: publicPosition?.publicPositionToken,
        matchCreationType,
        inviter: referrerCode,
        matchState: MATCH_STATES.PASSED,
      },
    });

    if (data?.addForceMatch?.matchId) {
      return history.push(`/matches/${data.addForceMatch.matchId}`);
    }

    return history.push(`/matches`);
  };

  const handlePassButtonClick = async () => {
    if (!isUserAuth) {
      // Kullanıcı auth değilse reject reason aşamasına yönlendiriliyor.
      return setIsRejectReasonModalOpen(true);
    }

    if (
      positionState === POSITION_STATES.IN_PROGRESS &&
      isUserAuth &&
      userData
    ) {
      // eslint-disable-next-line
      return await createPublicForceMatchWithPassState();
    }

    return null;
  };

  const handleCloseRejectReasonModal = () => {
    setIsRejectReasonModalOpen(false);
  };

  const handleClick = () => {
    if (positionState === POSITION_STATES.IN_PROGRESS) {
      if (!isUserAuth && forceMatchToken) {
        return history.push(`${urlTo.register}?fmt=${forceMatchToken}`);
      }
      if (isUserAuth && userData) {
        createPublicForceMatch({
          variables: {
            user: userData.me.id,
            publicPositionToken: token,
            matchCreationType,
            inviter: referrerCode,
            matchState: MATCH_STATES.INTERESTED,
          },
        });
        return history.push(urlTo.profile);
      }
      sessionStorage.setItem(
        "ppi",
        JSON.stringify({
          ppt: token,
          type: matchCreationType,
          inviteCode: referrerCode,
        })
      );
      return history.push(urlTo.register);
    }
    if (isUserAuth && userData) {
      return history.push(urlTo.profile);
    }
    return history.push(urlTo.register);
  };

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading />;

  return (
    <>
      <ExternalRejectReasonModal
        open={isRejectReasonModalOpen}
        handleClose={handleCloseRejectReasonModal}
        publicPositionId={publicPosition.publicID}
      />
      <MobileView>
        <BarContainer>
          <TextAndButtonContainer>
            <Description>
              {isUserAuth
                ? PUBLIC_POSITION_STATE_TEXTS[positionState].userIsAuth
                    .description
                : PUBLIC_POSITION_STATE_TEXTS[positionState].userIsNotAuth
                    .description}
            </Description>
          </TextAndButtonContainer>
        </BarContainer>
        <Button
          style={{ boxShadow: "none" }}
          type="button"
          variant="primary"
          size="large"
          className="web"
          onClick={handleClick}
        >
          {isUserAuth
            ? PUBLIC_POSITION_STATE_TEXTS[positionState].userIsAuth.buttonText
            : PUBLIC_POSITION_STATE_TEXTS[positionState].userIsNotAuth
                .buttonText}
        </Button>

        <Button
          style={{ marginTop: "13px" }}
          type="button"
          size="large"
          className="web"
          variant="secondary"
          onClick={handlePassButtonClick}
        >
          Not Interested{" "}
          <span role="img" aria-label="thumbsdown">
            👎
          </span>
        </Button>
      </MobileView>
    </>
  );
}
