import React from "react";
import styled from "styled-components";

const ExpandMessageContainer = styled.div`
  margin: 5px 0;
  color: #aaaaaa;
  cursor: pointer;
  font-size: 14px;
`;

export default function ExpandMessage({ children, handleClick }) {
  return (
    <ExpandMessageContainer onClick={handleClick}>
      {children}
    </ExpandMessageContainer>
  );
}
