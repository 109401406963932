/* eslint-disable array-callback-return */

import React, { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { RowBlock, ColumnBlock, SkillitemCircle } from "../../BlockUi";
import { SelectField } from "../../Form/SelectField";
import FormScaffold from "../FormScaffold";
import { captureErrorWithData, debounce, isMobile } from "../../../helper";
import message from "../../../utils/message";
import { proExperiences } from "../../../utils/constants";
import IntercomLauncherMessage from "../../Modules/IntercomLauncherMessage";

const UPDATE_USER = gql`
  mutation UpdateUser($role: ID, $roles: [RolesInputType]) {
    updateUser(role: $role, roles: $roles) {
      id
    }
  }
`;

export default function Roles({ user, onUpdate, roleData }) {
  const [roleList, setRoleList] = useState(user.roles || []);
  const [itemStatus, setItemStatus] = useState();

  const [updateUser] = useMutation(UPDATE_USER);

  const handleSubmit = async (newState) => {
    const rolesData = newState.reduce((acc, val) => {
      acc.push({ role: val._id || val.role.id, experience: val.experience });
      return acc;
    }, []);

    try {
      await updateUser({
        variables: {
          role: rolesData[0].role,
          roles: rolesData,
        },
      });
      setItemStatus("success");
      onUpdate({ user: new Date() });
    } catch (error) {
      setItemStatus("error");
      captureErrorWithData(error);
    }
  };

  const debouncedSave = useCallback(
    debounce((newState) => {
      setItemStatus("loading");
      handleSubmit(newState);
    }, 500),
    []
  );
  const applyChange = (changes) => {
    setRoleList(changes);
    debouncedSave(changes);
  };

  const handleRole = (role, index) => {
    if (roleList.length === 1 && role._id === 0) {
      return message.error(
        "You need to have at least one role in order to get matches."
      );
    }
    setItemStatus("pending");
    if (role._id === 0) {
      const newList = [...roleList];
      newList.splice(index, 1);
      applyChange([...newList]);
    }

    if (role._id !== 0) {
      if (index >= roleList.length) {
        applyChange([...roleList, { ...role, experience: 1 } || 0]);
      } else {
        const updatedState = roleList.map((item, idx) => {
          return idx === index
            ? { ...role, experience: item.experience }
            : { ...item, experience: item.experience };
        });
        applyChange(updatedState);
      }
    }
  };

  const handleExperience = (value, index) => {
    setItemStatus("pending");
    const updatedStateExperience = roleList.map((role, idx) => {
      return idx === index ? { ...role, ...value } : role;
    });
    applyChange(updatedStateExperience);
  };

  const getRoleByIndex = (idx) => {
    return roleList.map(
      ({ role, _id, title, experience: experienceValue }, index) => {
        const { name, value } = proExperiences.filter(
          (i) => i.value === experienceValue
        )[0];

        if (index === idx) {
          const roles = {
            value: _id || role.id,
            title: title || role.title,
            name: isMobile() ? value : name,
          };

          if (experienceValue === 0 && isMobile()) {
            roles.name = "0-1";
          }

          if (experienceValue === 11 && isMobile()) {
            roles.name = "10+";
          }

          return roles;
        }
      }
    );
  };

  let status = "pending";
  if (itemStatus) {
    status = itemStatus;
  } else {
    status = roleList.length > 0 ? "success" : "pending";
  }

  const experienceWidth = isMobile() ? 90 : 125;

  return (
    <div>
      <FormScaffold
        status={status}
        label="Which roles have you specialized with in the past?"
        explanation="You can enter up to 3 roles"
        width={{ tablet: "90%", laptop: "80%", laptopL: "70%" }}
      >
        <ColumnBlock>
          {Array(3)
            .fill("")
            .map((_, index) => (
              <RowBlock
                key={index}
                wrap="no-wrap"
                justify="start"
                gridGap="10px"
                my={2}
              >
                <SkillitemCircle>{index + 1}</SkillitemCircle>

                <SelectField
                  name="role"
                  width={`calc(100% - ${experienceWidth + 60}px)`}
                  value={getRoleByIndex(index)}
                  onChange={(opt) => {
                    handleRole(
                      { _id: opt ? opt.id : 0, title: opt ? opt.title : "" },
                      index
                    );
                  }}
                  placeholder="Add Role"
                  options={
                    roleData?.allRole?.roles.filter((i) => {
                      const selectedRoleIds = roleList.map(
                        (s) => s.role?.id || s._id
                      );
                      if (!selectedRoleIds.includes(i.id)) {
                        return i;
                      }
                      return null;
                    }) || []
                  }
                  getOptionLabel={(option) => option.title || option.label}
                  getOptionValue={(option) => option.id || option.value}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? (
                      <IntercomLauncherMessage />
                    ) : (
                      "Type something to search"
                    )
                  }
                  isClearable
                />

                <SelectField
                  placeholder="Experience"
                  value={getRoleByIndex(index)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  isSearchable={false}
                  onChange={(selectedOption) => {
                    handleExperience(
                      { experience: selectedOption.value },
                      index
                    );
                  }}
                  options={proExperiences}
                  width={`${experienceWidth}px`}
                />
              </RowBlock>
            ))}
        </ColumnBlock>
      </FormScaffold>
    </div>
  );
}
