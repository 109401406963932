import React from "react";
import { Button } from "../../../Button";

export default function NextStepButton({
  steps,
  currentStepIndex,
  nextStep,
  isStepValid,
}) {
  return (
    <>
      {currentStepIndex < steps.length - 1 && (
        <Button
          variant="primary"
          width={{ tablet: "100%" }}
          onClick={nextStep}
          disabled={!isStepValid}
        >
          Continue
        </Button>
      )}
    </>
  );
}
