import React, { useState } from "react";
import * as Yup from "yup";
import Textarea from "../../../Form/Textarea";
import SubmitButton from "./SubmitButton";
import SkipLink from "./SkipLink";

const validationSchema = Yup.object().shape({
  bio: Yup.string()
    .trim()
    .min(1, "En az 1 karakter giriniz")
    .max(1000, "1000 karakterden daha fazla olamaz"),
});

export default function QuickFillAbout({ handleSubmit, next }) {
  const [bio, setBio] = useState("");
  const [isValid, setFormValid] = useState(false);
  const handleChange = async (event) => {
    const { value } = event.target;
    setBio(value);

    try {
      const validateResponse = await validationSchema.fields.bio.validate(
        value
      );
      if (validateResponse) {
        setFormValid(true);
      }
    } catch (err) {
      setFormValid(false);
    }
  };

  return (
    <>
      <Textarea
        value={bio}
        onChange={handleChange}
        style={{ marginBottom: "36px" }}
        rows="3"
      />
      <SubmitButton
        handleSubmit={(event) => handleSubmit(event, { bio })}
        isValid={isValid}
      />
      <SkipLink next={next}>Atla</SkipLink>
    </>
  );
}
