import styled from "styled-components";
import { space, layout } from "styled-system";
import device from "../../styles/device";
import patternLeft from "../../images/left-pattern.png";
import patternRight from "../../images/right-pattern.png";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top, right top;
  ${space}
  @media ${device.tablet} {
    background-image: url(${(props) =>
        props.background ? patternLeft : "none"}),
      url(${(props) => (props.background ? patternRight : "none")});
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  ${layout}
`;
export const BoxTitle = styled.span`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 18px;
  text-align: center;
  color: #0c084c;
  ${space}
`;

export const BoxSubTitle = styled.span`
  font-family: ${(props) => props.theme.family.demi};
  ${space}
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  text-align: center;
`;
export const InviteListItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MoneyBox = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  height: 61px;
  border-radius: 5px;
  background-color: #b9e8fd;
  font-size: 18px;
  font-weight: bold;
  color: #444444;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
`;

export const PartnerImg = styled.img`
  max-width: 150px;
  max-height: 80px;
  cursor: pointer;
  ${space}
`;

export const InviteLink = styled.div`
  font-size: 14px;
  color: #aaaaaa;
  border-radius: 5px;
  border: solid 1px #eeeeee;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  cursor: copy;
  ${layout}
`;

export const Label = styled.span`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 14px;
  font-weight: bold;
  color: #444444;
  text-align: left;
`;

export const Date = styled.span`
  font-size: 12px;
  font-style: italic;
  color: #aaaaaa;
`;

export const InviteAction = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${({ isTicked }) => (isTicked ? "black" : "#aaaaaa")};
  ${space}
`;

export const InvitedUser = styled.span`
  font-family: ${(props) => props.theme.family.demi};
  ${space}
  font-size: 14px;
  font-weight: 400;
  color: #444444;
`;

export const Circle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 9px;
  background-color: ${(props) => (props.color ? props.color : "#eeeeee")};
`;
