import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Button } from "tg-design";
import dayjs from "dayjs";

import {
  Container,
  SaveButtonContainer,
  Text,
  Title,
  TipText,
  TipListText,
  PrimaryTitle,
} from "../CommonStyles";
import { PARSE_RESUME, UPDATE_USER_FROM_RESUME } from "../queries";
import { UPDATE_USER } from "../../queries";
import message from "../../../../utils/message";
import {
  uuidGenerate,
  removeTypename,
  captureErrorWithData,
} from "../../../../helper";
import { BioFromImport } from "../BioFromImport";
import { WorkHistoryFromImport } from "../WorkHistoryFromImport";
import { EducationFromImport } from "../EducationFromImport";
import { LanguageFromImport } from "../LanguageFromImport";
import { OtherSkillsFromImport } from "../OtherSkillsFromImport";
import {
  ACTION_BUTTON_TITLES,
  techCategories,
} from "../../../../utils/constants";
import { PreviousImports } from "../PreviousImports";
import { SAVED_RESUMELOG_FOR_USER } from "../../../Profile/Sidebar/queries";
import UploadResume from "../../BasicInfos/UploadResume";
import PartialError from "../../../../pages/ErrorPage/PartialError";

export default function CvImport({ user, refetch, loading, setLoading }) {
  const [isUserResume, setIsUserResume] = useState(
    user?.resume?.resumeURL || null
  );
  const [resumeData, setResumeData] = useState();
  const [previousResumeData, setPreviousResumeData] = useState();
  const [isResumeSaving, setIsResumeSaving] = useState(false);
  const [formState, setFormState] = useState();

  const {
    data,
    error,
    refetch: logRefetch,
  } = useQuery(SAVED_RESUMELOG_FOR_USER, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setPreviousResumeData(data.savedResumeLogForUser);
    },
  });

  const [updateUser] = useMutation(UPDATE_USER);
  const [parseResume] = useMutation(PARSE_RESUME);
  const [updateUserFromResume] = useMutation(UPDATE_USER_FROM_RESUME);

  const history = useHistory();

  const formatResumeData = (data) => {
    const userSkills = user.otherSkills.map((i) => i.id);

    const resumeData = {
      id: user.id,
      bio: { value: data.bio || null, isChecked: true },
      workHistory: (data.workHistory || []).map((item) => {
        return {
          company: item.company || null,
          position: item.position || null,
          description: item.description || null,
          startDate: item.startDate
            ? dayjs(item.startDate).format("YYYY-MM-DD")
            : null,
          endDate: item.endDate
            ? dayjs(item.endDate).format("YYYY-MM-DD")
            : null,
          present: item.present,
          isChecked: true,
        };
      }),
      education: (data.education || [])
        .map((item) => {
          if (item?.length !== 0) {
            return {
              school: {
                id: item?.school?.id || null,
                name: item?.school?.name || null,
              },
              branch: {
                id: item?.branch?.id || null,
                label: item?.branch?.label || null,
              },
              startDate: Number(item?.startDate) || null,
              endDate: Number(item?.endDate) || null,
              type: item?.type || null,
              isChecked: true,
            };
          }
          return null;
        })
        .filter((i) => i),
      languages: (data.languages || [])
        .map((item) => {
          if (item.length !== 0) {
            return {
              language: {
                id: item.language.id || null,
                label: item.language.label || null,
              },
              level: item.level || null,
              isChecked: true,
            };
          }
          return null;
        })
        .filter((i) => i),
      otherSkills: (data.skills || [])
        .map((item) => {
          if (
            item.length !== 0 &&
            techCategories[item.categoryId] !== "language" &&
            techCategories[item.categoryId] !== "framework" &&
            !userSkills.includes(item._id)
          ) {
            return {
              value: item._id,
              title: item.title,
              isChecked: true,
            };
          }
          return null;
        })
        .filter((i) => i),
      uuid: data.uuid,
    };
    setFormState(resumeData);
    setResumeData(resumeData);
  };

  const handleStateChange = (tabName, index) => {
    if (index === 0 || index) {
      const newState = {
        ...formState,
        [tabName]: formState[tabName].map((item, i) => {
          if (index === i) {
            return { ...item, isChecked: !item.isChecked };
          }
          return item;
        }),
      };
      setFormState(newState);
    } else {
      const newState = {
        ...formState,
        [tabName]: {
          ...formState[tabName],
          isChecked: !formState[tabName].isChecked,
        },
      };
      setFormState(newState);
    }
  };
  const handleSave = async () => {
    const variables = removeTypename({
      id: formState.id,

      bio: formState.bio.isChecked ? formState.bio.value : null,
      workHistory: (formState.workHistory || [])
        .map(
          (item) =>
            item.isChecked && {
              company: item.company || null,
              position: item.position || null,
              description: item.description || null,
              startDate: item.startDate
                ? dayjs(item.startDate).format("YYYY-MM-DD")
                : null,
              endDate: item.endDate
                ? dayjs(item.endDate).format("YYYY-MM-DD")
                : null,
              present: item.present,
            }
        )
        .filter((i) => i),
      education: (formState.education || [])
        .map(
          (i) =>
            i.isChecked && {
              school: i.school.id,
              branch: i.branch.id,
              startDate: i.startDate,
              endDate: i.endDate,
              type: i.type,
            }
        )
        .filter((i) => i),
      languages: (formState.languages || [])
        .map((item) => {
          if (item.isChecked) {
            return {
              language: item.language.id,
              level: item.level,
            };
          }
          return null;
        })
        .filter((i) => i),
      otherSkills: (formState.otherSkills || [])
        .map((item) => {
          if (item.isChecked) {
            return item.value;
          }
          return null;
        })
        .filter((i) => i),
      uuid: formState.uuid,
    });

    if (
      !formState.bio.isChecked &&
      formState.workHistory?.every((i) => !i.isChecked) &&
      formState.education?.every((i) => !i.isChecked) &&
      formState.languages?.every((i) => !i.isChecked) &&
      formState.otherSkills?.every((i) => !i.isChecked)
    ) {
      message.error("You haven't selected anything.");
      return;
    }

    try {
      setIsResumeSaving(true);
      await updateUserFromResume({
        variables,
      });
      setIsResumeSaving(false);

      sessionStorage.removeItem("resume");

      setResumeData();
      message.success("User profile has been updated!");
      refetch();
      logRefetch();
    } catch (error) {
      message.error(error);
      captureErrorWithData(error);
      setIsResumeSaving(false);
    }
  };

  const handleImport = async () => {
    const uuid = uuidGenerate();
    setLoading(true);

    try {
      const res = await parseResume({ variables: { uuid } });
      formatResumeData({ ...res.data.parseResume, uuid });
      sessionStorage.setItem(
        "resume",
        JSON.stringify({ ...res.data.parseResume, uuid })
      );

      setLoading(false);
      history.replace();
    } catch (error) {
      if (!error?.graphQLErrors || error?.graphQLErrors?.length === 0) {
        captureErrorWithData(error);
      }

      setLoading(false);
      history.replace();
    }
  };

  const handleChange = async (field, val) => {
    try {
      await updateUser({
        variables: {
          [field]: val,
        },
      });
      refetch();
    } catch (err) {
      captureErrorWithData(err);
      message.error(err);
    }
  };

  useEffect(() => {
    setIsUserResume(user?.resume?.resumeURL || null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const cookie = JSON.parse(sessionStorage.getItem("resume"));

    if (cookie) {
      setResumeData(cookie);
      formatResumeData(cookie);
    }
    if (
      !cookie &&
      !resumeData &&
      history?.location?.state?.from ===
        ACTION_BUTTON_TITLES.IMPORT_RESUME_BUTTON
    ) {
      handleImport();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.savedResumeLogForUser) {
      setPreviousResumeData(data.savedResumeLogForUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <PartialError />;

  if (resumeData) {
    return (
      <>
        <PrimaryTitle>
          <b>Successfully imported profile from CV!</b> ✅
        </PrimaryTitle>
        <TipText>Some quick tips:</TipText>
        <ul style={{ marginBottom: "20px", paddingLeft: "20px" }}>
          <TipListText>
            Simply un-check any info you don’t want to be imported,
          </TipListText>
          <TipListText>
            Once saved, these will override the current info on your profile.
          </TipListText>
        </ul>

        <BioFromImport
          state={formState.bio}
          handleStateChange={(tabName) => handleStateChange(tabName)}
        />
        <WorkHistoryFromImport
          state={formState.workHistory}
          handleStateChange={(tabName, index) =>
            handleStateChange(tabName, index)
          }
        />
        <EducationFromImport
          state={formState.education}
          handleStateChange={(tabName, index) =>
            handleStateChange(tabName, index)
          }
        />
        <LanguageFromImport
          state={formState.languages}
          handleStateChange={(tabName, index) =>
            handleStateChange(tabName, index)
          }
        />
        <OtherSkillsFromImport
          state={formState.otherSkills}
          handleStateChange={(tabName, index) =>
            handleStateChange(tabName, index)
          }
        />
        <SaveButtonContainer mx={{ mobileS: -3, tablet: -4, laptop: -5 }}>
          <Button
            style={{
              background: isResumeSaving ? "#ddd" : "#8FDEAC",
              borderColor: isResumeSaving ? "#ddd" : "#8FDEAC",
              margin: "15px auto",
              minWidth: "250px",
            }}
            size="xs"
            disabled={isResumeSaving}
            loading={isResumeSaving}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </SaveButtonContainer>
      </>
    );
  }

  return (
    <>
      <Container>
        <>
          <Title>
            {!isUserResume
              ? "Add your CV to import profile!"
              : "Import from this file:"}
          </Title>
          <UploadResume
            file={user?.resume?.resumeURL || null}
            fileName={user?.resume?.fileName || null}
            id={user.id}
            parsing={loading}
            handleImportButtonClick={handleImport}
            onChange={(val) => handleChange("resume", val)}
          />
          {loading && (
            <Text style={{ marginTop: "10px" }}>
              This process usually takes a few minutes.. Hang tight
              <span role="img" aria-label="muscle">
                💪
              </span>
            </Text>
          )}
        </>
      </Container>
      {previousResumeData && (
        <PreviousImports previousData={previousResumeData} />
      )}
    </>
  );
}
