import React from "react";
import styled from "styled-components";

const StyledLi = styled.li`
  float: left;
  height: 30px;
  margin-right: 26px;
  z-index: 1;
  border-bottom: 4px solid #e3e3e3;

  &.active {
    &::after {
      content: "";
      background-color: ${(props) =>
        props.styles ? props.styles.list.active : "#0C084C"};
      height: 4px;
      width: 100%;
      position: absolute;
      bottom: -4px;
      border-radius: 4px;
    }
  }

  &:before {
    content: unset;
  }
`;

const StyledLink = styled.button`
  background: none;
  border: none;
  padding: 0px;
  color: #747474;
  cursor: pointer;
  display: flex;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;

  font-family: ${(props) => props.theme.family.bold};
  font-style: normal;
  font-size: 14px;
  line-height: 20px;

  &.active {
    color: ${(props) => (props.styles ? props.styles.link.active : "#0C084C")};
  }

  &:hover {
    color: ${(props) => (props.styles ? props.styles.link.hover : "#0C084C")};
  }

  &.is-disabled {
    color: #999;
  }
`;

const LinkText = styled.span`
  white-space: nowrap;
`;

const IconContainer = styled.span`
  margin-right: 3px;
`;

export default function TgHeaderTab({
  name,
  handleChange,
  currentTab,
  children,
  classname,
  disabled = false,
  icon = null,
  styles,
  ...props
}) {
  const activeClasses = [];

  if (currentTab === name) {
    activeClasses.push("active");
  }

  if (disabled) {
    activeClasses.push("is-disabled");
  }

  const IconComponent = icon;

  return (
    <StyledLi className={classname || activeClasses.join(" ")} styles={styles}>
      <StyledLink
        styles={styles}
        className={classname || activeClasses.join(" ")}
        onClick={() => handleChange(name)}
        disabled={disabled}
        {...props}
      >
        {icon && (
          <IconContainer isActive={currentTab === name}>
            <IconComponent />
          </IconContainer>
        )}
        <LinkText>{children}</LinkText>
      </StyledLink>
    </StyledLi>
  );
}
