import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { space } from "styled-system";
import { ColumnBlock } from "../BlockUi";
import { Button, btnSize } from "../Button";
import {
  UserIcon,
  ReactIcon,
  SettingsIcon,
  ArrowLeft,
  EducationIcon,
  CommandLineIcon,
  LightningIcon,
} from "../Icon";

export const IconWrap = styled.span`
  ${space}
`;

const Line = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 29px;
  margin-top: 34px;
`;

const Tab = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: ${(props) => (props.selected ? "64px" : "60px")};
  width: ${(props) => (props.selected ? "100%" : "97%")};
  background-color: #fff;
  padding: 13px 14px;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? "bold" : "600")};
  color: #0c084c;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: ${(props) => props.theme.family.demi};
  cursor: pointer;
  box-shadow: ${(props) => (props.selected ? " 0 7px 10px 0 #cccccc" : "none")};
  transition: ${(props) => (props.selected ? "all 0.2s 0s ease" : "none")};
`;

const IconField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: ${(props) => props.background || "#edf9fe"};
  margin-right: 10px;
  > svg {
    width: 17px;
    height: 18px;
  }
`;

const tablist = [
  {
    name: "import",
    title: "Import Profile",
    icon: LightningIcon,
    color: "#fff",
    background: "#72D1FB",
  },
  { name: "info", title: "Basic Info", icon: UserIcon },
  { name: "skills", title: "Skills & Experience", icon: ReactIcon },
  {
    name: "criterias",
    title: "Preferences & Criteria",
    icon: SettingsIcon,
  },
  {
    name: "education",
    title: "Education",
    icon: EducationIcon,
  },
  {
    name: "work-history",
    title: "Work History",
    icon: CommandLineIcon,
  },
];

export default function EditList({ handleClick, active }) {
  const history = useHistory();

  const Tabs = tablist.map((val) => {
    const Icon = val.icon;

    return (
      <Tab
        key={val.name}
        selected={active === val.name}
        onClick={() => handleClick(val.name)}
      >
        <IconField background={val.background || "#edf9fe"}>
          <Icon color={val.color || "#72D1FB"} />
        </IconField>
        {val.title}
      </Tab>
    );
  });

  return (
    <ColumnBlock
      display={{ mobileS: "none", tablet: "flex" }}
      width={{ tablet: "30%", laptop: "30%" }}
      style={{
        overflowY: "scroll",
        height: "100%",
        justifyContent: "flex-start",
        paddingRight: "10px",
      }}
    >
      <Button
        variant="secondary"
        width={{ tablet: "100%", laptop: btnSize.large }}
        onClick={() => history.push("/profile")}
        style={{ minHeight: "40px" }}
      >
        <IconWrap mr={3}>
          <ArrowLeft color="#444" width="12" height="10" />
        </IconWrap>
        View Profile
      </Button>
      <Line />
      <ColumnBlock align="center">{Tabs}</ColumnBlock>
    </ColumnBlock>
  );
}
