import React, { useState } from "react";
import styled from "styled-components";
import SkipLink from "./SkipLink";
import Switch from "../../../Form/ToggleSwitch";
import { sleep } from "../../../../helper";

const Heading = styled.div`
  padding: 20px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.isPointer ? "pointer" : "auto")};
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-size: ${(props) => (props.size === "sm" ? "12px" : "18px")};
  color: ${(props) =>
    props.active ? props.theme.colors.main[0] : props.theme.colors.font[1]};
`;

export default function QuickFillOpenToWork({ handleSubmit, next, user }) {
  const [active, setActive] = useState(!!user?.preferences?.openToWork);
  const handleChange = async (event) => {
    setActive(!active);
    await sleep(500);
    handleSubmit(event, {
      preferences: {
        openToWork: !active,
      },
    });
  };

  return (
    <>
      <Heading>
        <Title active={active}>Actively Looking</Title>
        <Switch onChange={handleChange} name="openToWork" active={active} />
      </Heading>
      <SkipLink next={next}>Skip</SkipLink>
    </>
  );
}
