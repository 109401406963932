import styled from "styled-components";
import { space, color } from "styled-system";

export const IconWrapper = styled.div`
  display: relative;
  ${space};
`;

export const Circle = styled.span`
  width: 15px;
  height: 15px;
  background-color: #8fdeac;
  border-radius: 50%;
  display: inline-block;
`;

export const CheckIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  width: 15px;
  height: 15px;
  padding: 2px;
`;

export const BoxDescription = styled.div`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #aaaaaa;
  ${color}
  ${space}
`;

export const Bold = styled.span`
  font-family: ${(props) => props.theme.family.demi};
  font-size: 14px;
  font-weight: 600;
  ${color}
`;

export const Hint = styled.span`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 12px;
  font-weight: 500;
  color: #aaaaaa;
  ${space}
`;

export const SuccessButton = styled.button`
  color: #fff;
  width: 120px;
  height: 40px;
  cursor: pointer;
  background: #fff;
  color: #aaaaaa;
  border-radius: 5px;
  ${space}
  font-size: 14px;
  border: 2px solid;
  border-color: ${(props) => props.theme.colors.green[0]};
  font-family: ${(props) => props.theme.family.demiBold};
  font-weight: bold;
  font-family: ${(props) => props.theme.family.bold};
  display: flex;
  align-items: center;
  justify-content: center;
`;
