import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  CriteriaFieldDescription,
  CriteriaFieldTitle,
  ColumnBlock,
} from "../BlockUi";
import { SelectField } from "../Form/SelectField";
import PartialError from "../../pages/ErrorPage/PartialError";

const GET_BENEFITS = gql`
  {
    allBenefits {
      id
      label
    }
  }
`;

const BenefitsCriteria = ({ selectedValues, handleOnChange }) => {
  const { data, error } = useQuery(GET_BENEFITS);

  if (error) return <PartialError />;
  return (
    <>
      <ColumnBlock>
        <CriteriaFieldTitle>
          What are the side benefits you expect from the company?
        </CriteriaFieldTitle>
        <CriteriaFieldDescription>
          You can make multiple selections.
        </CriteriaFieldDescription>
        {data && (
          <SelectField
            defaultValue={selectedValues.expected}
            onChange={handleOnChange}
            getOptionLabel={(option) => `${option.label}`}
            getOptionValue={(option) => `${option.id}`}
            isMulti
            placeholder="Select benefits..."
            options={data.allBenefits}
            closeMenuOnSelect={false}
          />
        )}
      </ColumnBlock>
    </>
  );
};

export default BenefitsCriteria;
