import React from "react";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import TeamIcon from "../../../images/noun-team.svg";
import CriteriaViewCard from "./Card";
import { ViewCardSubTitle } from "../CommonStyles";

export default function CompanySizeViewCard({ companySize }) {
  return (
    <CriteriaViewCard title="Company Size" image={TeamIcon}>
      <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
        <ViewCardSubTitle>Expected company size</ViewCardSubTitle>
        <RowBlock>
          <ul>
            {companySize &&
              companySize.expected
                .filter((e) => e !== null)
                .map(({ label }) => <li key={label}>{label}</li>)}
          </ul>
        </RowBlock>
      </ColumnBlock>
    </CriteriaViewCard>
  );
}
