import styled from "styled-components";
import { space } from "styled-system";
import device from "../../styles/device";

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;

  box-shadow: ${(props) => (props.shadow ? "0 1px 13px 2px #dddddd" : "none")};
  @media ${device.mobileL} {
    min-width: 520px;
  }

  @media ${device.tablet} {
    min-width: 640px;
  }

  @media ${device.laptop} {
    min-width: 724px;
  }
  ${space}
`;

export const IconWrapper = styled.div`
  background: #f4f6fc;
  border-radius: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px;
  ${space}
`;

export const Description = styled.span`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 14px;
  color: #444444;
`;

export const Info = styled.span`
  font-family: ${(props) => props.theme.family.demi};
  font-size: 14px;
  color: #444444;
`;

export const FormTitle = styled.div`
  width: 100%;
  border-bottom: 2px solid #eeeeee;
`;

export const SkipButton = styled.span`
  font-family: ${(props) => props.theme.family.medium};
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: underline;
  cursor: pointer;
`;
