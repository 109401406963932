import React from "react";
import {
  GroupTitle,
  PrimaryTitle,
  SecondaryTitle,
  SectionContainer,
} from "./CommonStyles";
import { ColumnBlock } from "../../BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";
import { LANGUAGE_LEVEL_TYPES_WITH_KEYS } from "../../../utils/constants";

export const LanguageFromImport = ({ state, handleStateChange }) => {
  if (state?.length === 0) return null;

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>Languages</GroupTitle>
      {state.map((item, index) => {
        return (
          <ColumnBlock key={index} style={{ marginTop: "5px" }}>
            <SectionContainer
              isChecked={item.isChecked}
              onClick={() => handleStateChange("languages", index)}
            >
              <div style={{ flex: "1" }}>
                <PrimaryTitle>{item.language.label}</PrimaryTitle>
                <SecondaryTitle>
                  {
                    LANGUAGE_LEVEL_TYPES_WITH_KEYS.find(
                      (i) => i.value === item.level
                    )?.label
                  }
                </SecondaryTitle>
              </div>
              <div>
                {item.isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
              </div>
            </SectionContainer>
          </ColumnBlock>
        );
      })}
    </div>
  );
};
