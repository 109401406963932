import React, { useEffect } from "react";
import styled from "styled-components";
import { Tooltip } from "tg-design";
import { ColumnBlock, RowBlock, Span } from "../BlockUi";
import { LocationIcon } from "../Icon";
import TGCertifiedIcon from "../../images/tg_certified.svg";
import { PositionTitle, SmallBox, CompanyName } from "./CommonModule";
import Avatar from "../Modules/Avatar";
import { MATCH_STATES, hiringSteps } from "../../utils/constants";
import { isPositionOpen, getMatchHeapInfo } from "../../helper";
import { heapIO } from "../../utils/heap";
import {
  getPreviousHistoryState,
  getCurrentHistoryState,
} from "./tabs/HiringProcessTab";

const matchesSentToHeap = [];

const Container = styled.div`
  background: white;
  border-radius: 5px;
  padding: 20px 17px 20px 17px;
  width: ${(props) => (props.active ? "100%" : "97%")};
  box-shadow: ${(props) => (props.active ? "0 10px 13px 0 #cccccc" : "none")};
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  opacity: ${(props) => (props.isPassive ? "0.6" : 1)};
`;

const ProgressBarContainer = styled.div`
  background: red;
  position: absolute;
  bottom: 0px;
  margin-left: -17px;
  margin-right: -17px;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  background-color: #f0f0f0;
`;

const Step = styled.div`
  background-color: ${(props) => props.color};
  width: ${(props) => `${props.width}%`};
  height: 10px;
  position: relative;
  margin-right: 5px;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #f0f0f0;
    display: block;
    left: 0px;
    position: absolute;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent ${(props) => props.color};
    display: block;
    right: -5px;
    position: absolute;
  }

  &:first-child {
    border-bottom-left-radius: 5px;

    &:before {
      display: none;
    }
  }

  &:nth-child(7n) {
    margin-right: 0;
    border-bottom-right-radius: 5px;

    &:after {
      display: none;
    }
  }
`;

const GREEN_COLOR = "#8fdeac";
const GRAY_COLOR = "#D0D0D0";

const getStepCountAndColor = (
  isMatchFailed,
  isMatchPassed,
  isPositionPassive,
  isPositionPassiveAndFilled,
  currentMatchState,
  previousMatchState
) => {
  // must check HiringSteps as well if u make any changes here.
  if (isMatchFailed) {
    const activeStepperStep = hiringSteps.find((i) =>
      i.completedState.includes(previousMatchState)
    );
    return {
      count: hiringSteps.indexOf(activeStepperStep) + 1,
      color: GRAY_COLOR,
    };
  }

  if (isMatchPassed) {
    const activeStepperStep = hiringSteps.find(
      (i) => i.key === MATCH_STATES.SENT
    );
    return {
      count: hiringSteps.indexOf(activeStepperStep) + 1,
      color: GRAY_COLOR,
    };
  }

  if (isPositionPassiveAndFilled) {
    const activeStepperStep = hiringSteps.find(
      (i) => i.key === MATCH_STATES.HIRED
    );
    return {
      count: hiringSteps.indexOf(activeStepperStep) + 1,
      color: GREEN_COLOR,
    };
  }

  if (isPositionPassive) {
    const activeStepperStep = hiringSteps.find((i) =>
      i.completedState.includes(currentMatchState)
    );
    return {
      count: hiringSteps.indexOf(activeStepperStep) + 1,
      color: GRAY_COLOR,
    };
  }

  const activeStepperStep = hiringSteps.find((i) =>
    i.completedState.includes(currentMatchState)
  );
  return {
    count: hiringSteps.indexOf(activeStepperStep) + 1,
    color: GREEN_COLOR,
  };
};

const getSteps = ({ count = 1, color }) => {
  const array = new Array(count).fill("");
  return array.map((step, index) => (
    <Step key={index} width={100 / 7} color={color} />
  ));
};

export default function MatchCard({
  location,
  companyLogo,
  positionTitle,
  companyName,
  handleSelect,
  active,
  state,
  positionState,
  history,
  matchId,
  matches,
  isEmpty,
  isExternalPosition,
}) {
  const isPositionPassive = !isPositionOpen(positionState);
  const isPositionPassiveAndFilled =
    isPositionPassive && state === MATCH_STATES.HIRED;
  const isMatchFailed = state === MATCH_STATES.FAILED;
  const isMatchPassed = state === MATCH_STATES.PASSED;

  const currentMatchState = !isEmpty
    ? getCurrentHistoryState({ history, state })
    : state;
  const previousMatchState = !isEmpty
    ? getPreviousHistoryState({ history })
    : MATCH_STATES.SENT;

  const steps = getStepCountAndColor(
    isMatchFailed,
    isMatchPassed,
    isPositionPassive,
    isPositionPassiveAndFilled,
    currentMatchState,
    previousMatchState
  );

  const getPositionLocation = (value) => {
    if (value && value.remote) {
      const remoteOptions = value.remote.split(",");

      if (remoteOptions.length === 1 && remoteOptions[0] === "full") {
        return "Remote";
      }
      if (
        remoteOptions.some(
          (option) => option === "half" || option === "full"
        ) &&
        remoteOptions.includes("full") &&
        value.expected &&
        value.expected.title
      ) {
        return `Remote or ${value.expected.title}`;
      }
      if (value.expected && value.expected.title) {
        return value.expected.title;
      }
    }
    return "Onsite";
  };

  const checkVisible = (element) => {
    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const matchCard = {
      matchId: element.id,
      isVisible: !(rect.bottom < 0 || rect.top - viewHeight >= 0),
    };

    const matchCardArray = [];

    matchCardArray.push(matchCard);
    matchCardArray.forEach((i) => {
      if (i.isVisible && !matchesSentToHeap.includes(i.matchId)) {
        matchesSentToHeap.push(i.matchId);
        const match = matches.filter((match) => match.id === i.matchId);
        heapIO.createCustomEvent(
          "Matches Card Seen",
          getMatchHeapInfo(match[0])
        );
      }
    });
  };

  const scrollHandler = () => {
    const element = document.getElementsByClassName("progress-bar");
    const elementArray = Array.prototype.slice.call(element);
    elementArray.map((item) => checkVisible(item));
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container
        active={active}
        onClick={handleSelect}
        isPassive={isPositionPassive}
      >
        <RowBlock wrap="nowrap" align="center" justify="center" width="100%">
          <ColumnBlock>
            <Avatar
              url={companyLogo}
              name={companyName}
              size="40px"
              fontSize="24px"
            />
          </ColumnBlock>
          <ColumnBlock align="start" flex="1" mx={3}>
            <RowBlock>
              <PositionTitle>{positionTitle}</PositionTitle>
            </RowBlock>
            <RowBlock mt="2px" wrap="nowrap">
              <ColumnBlock mr={3}>
                <CompanyName>{companyName}</CompanyName>
              </ColumnBlock>
              <ColumnBlock align="center">
                <SmallBox>
                  <LocationIcon />
                  <Span ml={2}>{getPositionLocation(location)}</Span>
                </SmallBox>
              </ColumnBlock>
            </RowBlock>
          </ColumnBlock>
          {!isExternalPosition && (
            <ColumnBlock align="center" mr={1}>
              <Tooltip
                text={
                  <div
                    style={{
                      margin: "0px 4px",
                    }}
                  >
                    Managed by TalentGrid
                  </div>
                }
              >
                <img src={TGCertifiedIcon} alt="TG Certified" />
              </Tooltip>
            </ColumnBlock>
          )}
        </RowBlock>
        <ProgressBarContainer className="progress-bar" id={matchId}>
          {getSteps({ count: steps.count, color: steps.color })}
        </ProgressBarContainer>
      </Container>
    </>
  );
}
