import React, { useState, useCallback, useRef } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import { space } from "styled-system";
import ReactCrop from "react-image-crop";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "tg-design";
import { EditIcon } from "../../Icon";
import { DropDown, DropDownItem } from "../../Modules/DropDown";
import DefaultAvatar from "../../../images/default-avatar.png";
import useModal from "../../Modules/useModal";
import { Button } from "../../Button";
import { Span } from "../../BlockUi";
import Spinner from "../../Modules/Loading";
import getCroppedImg from "./GetCroppedImg";
import "react-image-crop/dist/ReactCrop.css";
import { captureErrorWithData } from "../../../helper";
import message from "../../../utils/message";

const UPLOAD_IMAGE = gql`
  mutation UploadAvatar($image: Upload!) {
    uploadAvatar(image: $image) {
      imageUrl
    }
  }
`;

const DELETE_IMAGE = gql`
  mutation DeleteAvatar {
    deleteAvatar
  }
`;

const IconWrap = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 12px;
  left: 80px;
  box-shadow: 0 3px 4px 0 #cccccc;
  background-color: #ffffff;
`;
const Image = styled.img`
  width: 100px;
  border-radius: 50%;
  height: 100px;
`;

const Wrapper = styled.div`
  ${space}
  position: relative;
`;
export default function ProfileImage({ avatar, onChange, showEdit = true }) {
  const [uploadAvatar, { error, loading }] = useMutation(UPLOAD_IMAGE);
  const [deleteAvatar] = useMutation(DELETE_IMAGE);
  const [currentAvatarUrl, setCurrentAvatarUrl] = useState(avatar || null);

  const { showModal, setShowModal } = useModal();

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  let fileInput = useRef(null);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 30,
    y: 30,
    width: 110,
    height: 110,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectImage, setSelectImage] = useState();

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onCropComplete = async (cropImage) => {
    if (imgRef && cropImage.width && cropImage.height) {
      const croppedImage = await getCroppedImg(
        imgRef.current,
        cropImage,
        selectImage[0].name
      );
      setCompletedCrop(croppedImage);
    }
  };

  const onSelectFile = (e) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      setSelectImage(files);
      setShowModal();
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(files[0]);
    }
  };

  const onInputClick = (e) => {
    e.target.value = ""; // when choose same image, to trigger input onChange
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    if (selectImage[0].size > 10485760) {
      setShowModal();
      return message.error("This file is larger than our 10 MB size limit.");
    }
    try {
      const res = await uploadAvatar({ variables: { image: completedCrop } });
      if (res) {
        setCurrentAvatarUrl(res.data.uploadAvatar.imageUrl);
        onChange(res.data.uploadAvatar.imageUrl);
        setShowModal();
      }
    } catch (error) {
      captureErrorWithData(error);
    }
  };

  const removeAvatar = async () => {
    try {
      const res = await deleteAvatar();
      if (res) {
        setCurrentAvatarUrl(null);
        onChange(null);
      }
    } catch (error) {
      captureErrorWithData(error);
    }
  };

  const handleClick = () => {
    if (!avatar) {
      fileInput.click();
    }
  };

  const getDrowDownTitle = () => {
    if (showEdit) {
      return (
        <IconWrap>
          <EditIcon color="#aaa" onClick={handleClick} />
        </IconWrap>
      );
    }
  };
  const setVal = (val) => {
    fileInput = val;
  };

  return (
    <div>
      {showModal && (
        <Modal handleClose={setShowModal}>
          <ModalHeader>
            <div>Edit photo</div>
            <small>
              PNG, JPG and JPEG files with less than 10 MB size are supported.
            </small>
          </ModalHeader>
          <ModalBody>
            {error && (
              <Span color="red.0" fontWeight="bold">
                {error.message}
              </Span>
            )}
            <ReactCrop
              src={upImg}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onImageLoaded={onLoad}
              onComplete={onCropComplete}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" size="large" onClick={handleImageSubmit}>
              {loading && <Spinner width="24px" mr="5px" />}
              Save photo
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Wrapper px={{ mobileS: "27px", tablet: "0" }}>
        <Image src={currentAvatarUrl || DefaultAvatar} alt="" />
        <input
          type="file"
          accept="image/jpg, image/png, image/jpeg"
          multiple={false}
          onChange={onSelectFile}
          onClick={onInputClick}
          style={{ display: "none" }}
          ref={(val) => setVal(val)}
        />

        {avatar && showEdit ? (
          <DropDown title={getDrowDownTitle()}>
            <DropDownItem onClick={removeAvatar}>Remove photo</DropDownItem>
          </DropDown>
        ) : (
          getDrowDownTitle()
        )}
      </Wrapper>
    </div>
  );
}
