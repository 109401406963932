import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { BoxTitle, BoxText, RowBlock, Span } from "../../BlockUi";
import { BasicLink, Form } from "../../FormUi";
import CodeInput from "../../Form/CodeField";
import Timer from "../../Timer";

const CREATE_PIN = gql`
  mutation CreatePin($email: String!, $operation: String!) {
    createPin(email: $email, operation: $operation) {
      email
    }
  }
`;

const VERIFY_PIN = gql`
  query Pin($email: String!, $digit: Int!) {
    pin(email: $email, digit: $digit) {
      digit
    }
  }
`;

const CHANGE_EMAIL = gql`
  mutation ChangeEmail(
    $oldEmail: String!
    $oldEmailDigit: Int!
    $email: String!
    $emailDigit: Int!
  ) {
    changeEmail(
      oldEmail: $oldEmail
      oldEmailDigit: $oldEmailDigit
      email: $email
      emailDigit: $emailDigit
    ) {
      email
    }
  }
`;

export default function PinStep({
  handleSubmit,
  oldEmail,
  newEmail,
  oldDigit,
  setOldDigit,
  title,
  newEmailStep,
  refetch,
}) {
  const [verifyPin, { data, loading, error: verifyPinError }] =
    useLazyQuery(VERIFY_PIN);

  const [createPin] = useMutation(CREATE_PIN);
  const [changeEmail] = useMutation(CHANGE_EMAIL);
  const [error, setError] = useState(verifyPinError);
  const [showResendBtn, setShowResendBtn] = useState(true);

  const email = newEmail || oldEmail;

  const handleTimesUp = () => {
    sessionStorage.removeItem("resendPin");
    setShowResendBtn(true);
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      await createPin({ variables: { email, operation: "VALIDATE" } });
      sessionStorage.setItem("resendPin", 1);
      setShowResendBtn(false);
    } catch (err) {
      setError(err);
    }
  };

  const handlePinChange = async (val) => {
    if (val.length === 6) {
      try {
        if (newEmailStep) {
          await changeEmail({
            variables: {
              oldEmail,
              oldEmailDigit: parseInt(oldDigit, 10),
              email,
              emailDigit: parseInt(val, 10),
            },
          });
          await refetch();
          handleSubmit();
        } else {
          await verifyPin({
            variables: {
              email,
              digit: parseInt(val, 10),
            },
          });
        }
        setOldDigit(val);
        sessionStorage.removeItem("resendPin");
      } catch (err) {
        setError(err);
      }
    }
  };

  useEffect(() => {
    const resendPin = sessionStorage.getItem("resendPin");
    if (resendPin) {
      setShowResendBtn(false);
    }
    if (verifyPinError) {
      setError(verifyPinError);
    }
    if (data?.pin) {
      setError();
      handleSubmit();
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <BoxTitle fontSize="14px">{title}</BoxTitle>
      <BoxText fontSize="12px">
        We sent a verification code to {email}
        <br />
        Can you verify your old e-mail address first by entering the code?
      </BoxText>
      <Form alignItems="start" pt={{ mobileS: 4, laptop: 4, tablet: 4 }}>
        <CodeInput
          fields={6}
          type="number"
          onChange={handlePinChange}
          ml="-5px"
        />
        {!loading &&
          error &&
          error.graphQLErrors.map(({ message }, i) => (
            <Span key={i} color="red.0" fontWeight="bold">
              {message}
            </Span>
          ))}
        {loading && (
          <Span color="blue.0" fontWeight="bold">
            We are checking...
          </Span>
        )}
        <RowBlock mt="36px">
          <Span fontSize="12px" fontFamily="Avenir Next LT Pro Bold">
            <Timer
              initialMinute={2}
              initialSeconds={59}
              handleTimesUp={handleTimesUp}
              clear={!showResendBtn}
            />
          </Span>
        </RowBlock>
      </Form>
      {showResendBtn && (
        <RowBlock mt="8px" justifyContent="start">
          <BasicLink
            color="font.1"
            fontFamily="Avenir Next LT Pro Medium"
            fontSize="12px"
            onClick={handleResend}
          >
            Didn&apos;t receive the code? <u>Resend</u>
          </BasicLink>
        </RowBlock>
      )}
    </>
  );
}
