import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import gql from "graphql-tag";
import * as Yup from "yup";
import {
  BoxTitle,
  RowBlock,
  ColumnBlock,
  SkillitemCircle,
} from "../../../components/BlockUi";
import { Button } from "../../../components/Button";
import SkillSelect from "../../../components/SkillSelect";
import Loading from "../../../components/Modules/Loading";
import { ArrowIcon, InfoIcon } from "../../../components/Icon";
import { IconWrapper, BoxDescription } from "../CommonStyles";
import {
  SelectField,
  AsyncCreatableSelectField,
} from "../../../components/Form/SelectField";
import {
  captureErrorWithData,
  debounce,
  uuidGenerate,
  isMobile,
} from "../../../helper";
import message from "../../../utils/message";
import { experience } from "../../../utils/constants";
import PartialError from "../../ErrorPage/PartialError";
import InlineMessage from "../../../components/Modules/InlineMessage";

const GET_TECHNOLOGIES = gql`
  query allTechnology(
    $creatorToken: ID!
    $categoryIds: [Int]
    $search: String
    $limit: Int
    $exclude: [ID]
  ) {
    allTechnology(
      creatorToken: $creatorToken
      categoryIds: $categoryIds
      search: $search
      limit: $limit
      exclude: $exclude
    ) {
      technologies {
        id
        title
        categoryId
      }
    }
  }
`;

const GET_TECHNOLOGIES_BY_ROLE = gql`
  query role($id: String!) {
    role(id: $id) {
      id
      skills {
        id
        title
      }
    }
  }
`;

const CREATE_TECHNOLOGY = gql`
  mutation createTechnology($title: String!, $creatorToken: ID!) {
    createTechnology(title: $title, creatorToken: $creatorToken) {
      id
      title
    }
  }
`;

const validationSchema = Yup.array()
  .min(1)
  .max(5)
  .of(
    Yup.object()
      .shape({
        experience: Yup.number().required(),
        id: Yup.string().required(),
      })
      .required()
  );

export default function SkillSelectionStep({
  selectedSkills,
  onSelect,
  selectedRole,
  nextStep,
}) {
  let creatorToken = sessionStorage.getItem("creatorToken");
  if (!creatorToken) {
    creatorToken = uuidGenerate();
  }

  const [valid, setValid] = useState();
  const [userRole] = useState(null);

  const {
    loading,
    error: technologyError,
    refetch: searchTechnologies,
  } = useQuery(GET_TECHNOLOGIES, {
    skip: true,
  });

  const [
    getTechnologiesByRole,
    { data: relatedTechData, error: technologyByRoleError },
  ] = useLazyQuery(GET_TECHNOLOGIES_BY_ROLE, {
    variables: {
      id: selectedRole.id,
    },
  });

  const [createTechnology, { loading: createTechnologyLoading }] =
    useMutation(CREATE_TECHNOLOGY);

  const skillOnSelect = (skill, index) => {
    if (skill.id === 0) {
      skill.id = "";
      skill.experience = null;
    }
    onSelect(skill, index);
  };

  const skillOnCloud = (skill) => {
    const emptyIndex = selectedSkills.findIndex((s) => s.id === "");
    if (emptyIndex !== -1) {
      onSelect({ ...skill, experience: null }, emptyIndex);
    }
  };

  useEffect(() => {
    const validateForm = async () => {
      const currentSkills = selectedSkills.filter((skill) => skill.id !== "");
      try {
        const validateResponse = await validationSchema.isValid(currentSkills);
        setValid(validateResponse);
      } catch (err) {
        if (err?.name === "ValidationError") return;
        captureErrorWithData(err);
      }
    };

    validateForm();
  }, [selectedSkills]);

  useEffect(() => {
    if (userRole) {
      getTechnologiesByRole({ variables: userRole.id });
    } else {
      getTechnologiesByRole({ variables: selectedRole.id });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  const handleCreate = async (inputValue, index) => {
    try {
      if (selectedSkills.some((i) => i.title === inputValue)) {
        throw new Error("This skill already exists.");
      }
      const newTechnology = await createTechnology({
        variables: {
          title: inputValue,
          creatorToken,
        },
      });
      const newOption = {
        label: newTechnology.data.createTechnology.title,
        value: newTechnology.data.createTechnology.id,
      };
      skillOnSelect(
        {
          experience: null,
          id: newOption.value,
          title: newOption.label,
          isShow: true,
        },
        index
      );
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleSearch = useCallback(
    debounce(async (val, callback) => {
      try {
        const response = await searchTechnologies({
          search: val.value,
          limit: 20,
          creatorToken,
          exclude: val.exclude,
        });
        callback(response.data?.allTechnology?.technologies || []);
      } catch (err) {
        message.error("Something went wrong.");
        captureErrorWithData(err);
      }
    }, 500),
    []
  );

  const getSkillValues = (exp) => {
    if (exp || exp === 0) {
      const { name, value } = experience.filter((i) => i.value === exp)[0];
      const skills = {
        value: exp,
        name: isMobile() ? value : name,
      };
      if (exp === 8 && isMobile()) {
        skills.name = "8+";
      }
      return skills;
    }
    return exp;
  };

  if (loading) return <Loading width="50px" />;

  if (technologyError || technologyByRoleError) return <PartialError />;

  return (
    <>
      <BoxTitle>
        Which skills do you have that will help you make your mark?
      </BoxTitle>
      <BoxDescription
        color="font.1"
        mt={3}
        dangerouslySetInnerHTML={{
          __html: `This is your stage! Enter your top 5 skills to increase your chances for the best job matches. Here are some suggestions for you:`,
        }}
      />
      <ColumnBlock width="100%">
        {relatedTechData && (
          <RowBlock>
            <RowBlock mb={4}>
              <SkillSelect
                skills={relatedTechData.role.skills}
                handleSelectedSkill={skillOnCloud}
                selectedSkills={selectedSkills}
                mb={5}
              />
            </RowBlock>
          </RowBlock>
        )}

        <RowBlock mb={3}>
          <InlineMessage
            type="info"
            message={
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px" }}>
                  <InfoIcon color="#AAAAAA" />
                </div>
                <div>
                  Following skills are the ones you have the most experience in
                  and believe you are the best at. These will be showcased on
                  your profile.
                </div>
              </div>
            }
          />
        </RowBlock>

        {selectedSkills
          .filter((s) => s.isShow)
          .map((skill, index) => (
            <RowBlock
              key={index}
              width={{ mobileS: "100%", tablet: "530px" }}
              wrap="no-wrap"
              my={2}
              justify="space-between"
              px={{ mobileS: "20px", tablet: 0 }}
            >
              <SkillitemCircle>{index + 1}</SkillitemCircle>
              <AsyncCreatableSelectField
                autoFocus={skill.isFocus}
                value={selectedSkills.filter(
                  (value) => value.id && value.id === skill.id
                )}
                width={{
                  mobileS: "calc(100% - 140px)",
                  tablet: "calc(100% - 250px)",
                }}
                getOptionLabel={(option) => option.label || option.title}
                getOptionValue={(option) => option.id}
                onChange={(opt) => {
                  skillOnSelect(
                    {
                      ...skill,
                      id: opt ? opt.id : 0,
                      title: opt ? opt.title : "",
                    },
                    index
                  );
                }}
                onCreateOption={(inputValue) => handleCreate(inputValue, index)}
                isLoading={createTechnologyLoading}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                formatCreateLabel={(userInput) => `Add New:  ${userInput}`}
                isClearable
                placeholder="Add Language / Framework"
                noOptionsMessage={({ inputValue }) =>
                  inputValue ? "No results found" : "Type something to search"
                }
                selectedBgColorful={skill.id}
                valueAlign="center"
                loadOptions={(val, func) =>
                  handleSearch(
                    {
                      value: val,
                      exclude: selectedSkills
                        .filter((i) => i.id)
                        .map((i) => i.id),
                    },
                    func
                  )
                }
              />
              <SelectField
                placeholder="Experience"
                value={getSkillValues(skill.experience)}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.value}`}
                isSearchable={false}
                onChange={(selectedOption) => {
                  skillOnSelect(
                    { ...skill, experience: selectedOption.value },
                    index
                  );
                }}
                options={experience}
                width={{
                  mobileS: "100px",
                  tablet: "170px",
                }}
                selectedBgColorful
                valueAlign="center"
                textAlign="center"
              />
            </RowBlock>
          ))}
        <RowBlock mt={5}>
          <Button
            disabled={!valid}
            type="submit"
            variant="primary"
            size="large"
            onClick={nextStep}
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            Continue
            <IconWrapper ml={3}>
              <ArrowIcon direction="RIGHT" />
            </IconWrapper>
          </Button>
        </RowBlock>
      </ColumnBlock>
    </>
  );
}
