import React from "react";
import { LanguageLevelSelectField } from "tg-design";
import { getLanguageLevelType } from "../../../../../helper";
import { RowBlock } from "../../../../BlockUi";
import { FlagBlock } from "../../../../Form/PhoneField";

export default function MissingLanguageLevelStep({ onChange, state }) {
  return (
    <>
      <LanguageLevelSelectField
        placeholder="Select Level"
        label={
          <RowBlock justify="start">
            <b>{state.language.label}</b>
            <FlagBlock
              style={{ marginLeft: "5px" }}
              className={`flag ${state?.language?.code?.toLowerCase()}`}
            />
          </RowBlock>
        }
        containerStyle={{ width: "100%" }}
        value={getLanguageLevelType(state) || null}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? "No results found" : "Type something to search"
        }
        isSearchable={false}
        valueAlign="center"
        handleOnChange={({ selectedOption }) =>
          onChange({ value: selectedOption.value, type: "level" })
        }
        bgColor="#f5f6f8"
        controlStyle={{
          border: "2px  #abe6c1 solid",
        }}
        customStatusStyle={{ focused: "2px  #abe6c1  solid" }}
      />
    </>
  );
}
