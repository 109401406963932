import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 15px;

  &.info {
    font-family: ${(props) => props.theme.family.demi};
    font-size: 12px;
    line-height: 16px;
    background: #f1fbf5;
    letter-spacing: 0.4px;
    color: #444444;
  }
`;

const InlineMessage = ({ type, message, ...props }) => {
  return (
    <Container className={type} {...props}>
      {message}
    </Container>
  );
};

export default InlineMessage;
