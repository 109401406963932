import React from "react";
import { techCategories } from "../../utils/constants";
import DataGroup from "../Modules/DataGroup";
import { Container, Row } from "./CommonModule";

const categoryLabel = [
  "language",
  "framework",
  "architecture",
  "database",
  "cloud",
  "versioning",
  "cicd",
  "methodologies",
  "tool",
  "analytics",
];

const CATEGORY_TITLES = {
  language: "Programming language",
  framework: "Framework & libraries",
  tool: "Tools",
  cloud: "Cloud systems",
  versioning: "Versioning",
  architecture: "Architecture",
  database: "Database systems",
  analytics: "Analytics & reporting",
  methodologies: "Methodologies",
  cicd: "CI/CD Tools",
};

export default function TechnologiesUsed({ technologies }) {
  const regulatedData = technologies.reduce((acc, val) => {
    const category = techCategories[val.categoryId];
    if (!acc[category]) {
      acc[category] = [val.title];
    } else {
      acc[category].push(val.title);
    }
    return acc;
  }, {});
  const items = categoryLabel.map((key) => {
    if (regulatedData[key]) {
      return (
        <Row key={key} gridColumnGap="30px">
          <label htmlFor={key}>{CATEGORY_TITLES[key]}</label>
          <span>{regulatedData[key] && regulatedData[key].join(", ")}</span>
        </Row>
      );
    }
    return null;
  });

  return (
    <DataGroup title="Tech stack">
      <Container>{items}</Container>
    </DataGroup>
  );
}
