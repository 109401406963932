import React, { useState } from "react";
import styled from "styled-components";
import SubmitButton from "./SubmitButton";
import NextStepButton from "./NextStepButton";
import SkipLink from "./SkipLink";
import { ColumnBlock } from "../../../BlockUi";
import StepNavigation from "./StepNavigation";

const Title = styled.div`
  font-family: ${(props) => props.theme.family.bold};
  font-size: 16px;
  line-height: normal;
  color: #0f0757;
`;

export default function StepContainer({
  steps,
  nextQuickBox,
  handleSubmit,
  changeHook,
  state,
  setState,
  title,
  showSkipButton,
  isEveryStepNeedSave,
}) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [step, setStep] = useState(steps[currentStepIndex]);
  const [isStepValid, setStepValid] = useState(!step.validation);

  const nextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
      setStep(steps[currentStepIndex + 1]);
      setStepValid(!steps[currentStepIndex + 1].validation);
    }
  };

  const handleChange = async (data) => {
    const updateData = { ...state, [data.type]: data.value };

    if (typeof changeHook === "function") {
      changeHook(updateData, data);
    }

    setState(updateData);

    if (step.validation === null || step.validation === undefined) {
      setStepValid(true);
      return;
    }

    try {
      const validateResponse = await step.validation.isValid(updateData);
      setStepValid(validateResponse);
    } catch (err) {
      setStepValid(false);
    }
  };

  const saveCurrentStep = () => {
    handleSubmit();
    nextStep();
  };

  const Component = step.component;

  return (
    <>
      <Title style={{ marginBottom: "5px" }}>
        {title} ({currentStepIndex + 1}/{steps.length})
      </Title>
      <ColumnBlock mb={3}>
        <Component onChange={handleChange} state={state} setState={setState} />
      </ColumnBlock>
      {!isEveryStepNeedSave && (
        <NextStepButton
          steps={steps}
          currentStepIndex={currentStepIndex}
          nextStep={nextStep}
          isStepValid={isStepValid}
        />
      )}
      {!isEveryStepNeedSave && currentStepIndex === steps.length - 1 && (
        <SubmitButton handleSubmit={handleSubmit} isValid={isStepValid} />
      )}
      {isEveryStepNeedSave && (
        <SubmitButton handleSubmit={saveCurrentStep} isValid={isStepValid} />
      )}
      <StepNavigation steps={steps} currentIndex={currentStepIndex} />
      {showSkipButton && <SkipLink next={nextQuickBox}>Skip</SkipLink>}
    </>
  );
}
