import React from "react";
import styled from "styled-components";
import { space } from "styled-system";

const backgroundColors = {
  İ: "#72d1fb",
  V: "#8fdeac",
  B: "#fad156",
  A: "#72d1fb",
  X: "#8fdeac",
  H: "#fad156",
};

const AvatarLetter = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 #cccccc;
  ${space}
  background: ${(props) => backgroundColors[props.name[0]]};
  font-family: ${(props) => props.theme.family.bold};
  text-align: center;
  line-height: 1.8;
  color: #fff;
  font-size: ${(props) => props.fontSize};
  &:before {
    content: "${(props) => props.name[0]}";
`;

const AvatarImage = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 #cccccc;
  ${space}
  background-image: url(${(props) => props.url})
`;

export default ({ url, name, size, fontSize }) => (
  <>
    {url && <AvatarImage url={url} size={size} />}
    {!url && <AvatarLetter name={name} size={size} fontSize={fontSize} />}
  </>
);
