import React from "react";
import styled from "styled-components";
import DataGroup from "../Modules/DataGroup";
import { Container, Row } from "./CommonModule";
import PhotoGallery from "./modules/PhotoGallery";

const formatOfficePhotosForGalleryView = (officePhotos) => {
  if (officePhotos.length === 0) {
    return [];
  }
  return officePhotos.map((item) => {
    return {
      src: item.url,
      caption: item.description,
    };
  });
};

const StyledLabel = styled.label`
  min-width: 30%;
`;

const Content = styled.p`
  font-family: ${(props) => props.theme.family.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #444444;
  flex: 1;
`;

export default function CompanyInfo({ company }) {
  const isDefinedAnything =
    company?.contents?.place ||
    company?.contents?.culture ||
    company?.officePhotos?.length > 0;

  if (!isDefinedAnything) {
    return "";
  }

  return (
    <DataGroup title="Team & Culture">
      <Container>
        {company?.contents?.place && (
          <Row gridColumnGap="20px">
            <StyledLabel>Key highlights:</StyledLabel>
            <Content>{company.contents.place}</Content>
          </Row>
        )}
        {company?.contents?.culture && (
          <Row gridColumnGap="20px">
            <StyledLabel>Company culture :</StyledLabel>
            <Content>{company.contents.culture}</Content>
          </Row>
        )}
        {company.officePhotos && company.officePhotos.length > 0 && (
          <Row gridColumnGap="30px">
            <StyledLabel>Team & office photos:</StyledLabel>
            <PhotoGallery
              photos={formatOfficePhotosForGalleryView(company.officePhotos)}
            />
          </Row>
        )}
      </Container>
    </DataGroup>
  );
}
