import * as Yup from "yup";

export const schema = Yup.object().shape({
  workHistory: Yup.array().of(
    Yup.object().shape({
      company: Yup.string().trim().required(""),
      position: Yup.string().trim().required(""),
      startDate: Yup.date().required(""),
      present: Yup.boolean().required(""),
      endDate: Yup.mixed()
        .nullable("")
        .when("present", (present, schema) => {
          if (!present) {
            return schema.required("");
          }
          return schema.nullable("");
        }),
    })
  ),
});
