import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export default function PhotoGallery({
  photos,
  photoContainerStyles,
  ...props
}) {
  return (
    <Carousel
      className="image-carousel"
      renderThumbs={() =>
        photos.map((thumbnail, i) => (
          <Image key={i} src={thumbnail.src} alt={thumbnail.alt} />
        ))
      }
      {...props}
    >
      {photos.map(({ caption, src }, i) => (
        <div
          key={i}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...photoContainerStyles,
          }}
        >
          <Image src={src} alt={caption} />
        </div>
      ))}
    </Carousel>
  );
}
