import React, { useState } from "react";
import { ColumnBlock, RowBlock } from "../../BlockUi";
import clockIcon from "../../../images/noun-clock.svg";
import CriteriaViewCard from "./Card";
import { ViewCardSubTitle, NumberSpan } from "../CommonStyles";
import { ShowIcon } from "../../Icon";

export default function FreelanceViewCard({ freelance }) {
  const [showRate, setshowRate] = useState(false);

  return (
    <CriteriaViewCard title="Freelance Work" image={clockIcon}>
      <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
        <RowBlock>
          <ColumnBlock justifyContent={{ mobileS: "flex-start" }}>
            <ColumnBlock alignItems={{ mobileS: "flex-start" }}>
              <ViewCardSubTitle>Hourly rate:</ViewCardSubTitle>
              {showRate && (
                <RowBlock alignItems="center">
                  <ColumnBlock width="100px">
                    <NumberSpan>
                      {freelance.hourlyRate} {freelance.hourlyRateCurrency}
                    </NumberSpan>
                  </ColumnBlock>
                  <ColumnBlock>
                    <ShowIcon onClick={() => setshowRate(false)} />
                  </ColumnBlock>
                </RowBlock>
              )}
              {!showRate && (
                <RowBlock alignItems="center">
                  <ColumnBlock width="100px">●●●●●●●</ColumnBlock>
                  <ColumnBlock>
                    <ShowIcon onClick={() => setshowRate(true)} />
                  </ColumnBlock>
                </RowBlock>
              )}
            </ColumnBlock>
            <ColumnBlock
              mt={{ laptop: 4 }}
              alignItems={{ mobileS: "flex-start" }}
            >
              <ViewCardSubTitle>Available hrs per week:</ViewCardSubTitle>
              <NumberSpan>{freelance.availableWeeklyHour} hour(s)</NumberSpan>
            </ColumnBlock>
          </ColumnBlock>
        </RowBlock>
      </ColumnBlock>
    </CriteriaViewCard>
  );
}
