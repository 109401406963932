/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import * as Yup from "yup";

import {
  BoxTitle,
  RowBlock,
  SkillitemCircle,
} from "../../../components/BlockUi";
import Loading from "../../../components/Modules/Loading";
import { SelectField } from "../../../components/Form/SelectField";
import PartialError from "../../ErrorPage/PartialError";
import RoleSelect from "../../../components/RoleSelect";
import { BoxDescription, IconWrapper } from "../CommonStyles";
import { Button } from "../../../components/Button";
import { ArrowIcon, InfoIcon } from "../../../components/Icon";
import { captureErrorWithData, isMobile } from "../../../helper";
import { proExperiences } from "../../../utils/constants";
import IntercomLauncherMessage from "../../../components/Modules/IntercomLauncherMessage";
import InlineMessage from "../../../components/Modules/InlineMessage";

const GET_ROLES = gql`
  {
    allRole(limit: 0) {
      roles {
        id
        title
        isFeatured
        sort
        relatedProfession
      }
    }
  }
`;

const validationSchema = Yup.array()
  .min(1)
  .max(3)
  .of(
    Yup.object()
      .shape({
        experience: Yup.number().required(),
        id: Yup.string().required(),
      })
      .required()
  );

export default function RoleSelectionStep({
  selectedRoles,
  selectedProfession,
  onSelect,
  nextStep,
}) {
  const { loading, error, data } = useQuery(GET_ROLES);
  const [valid, setValid] = useState();

  let suggestedRoles = [];
  if (data) {
    suggestedRoles = data.allRole.roles
      .filter(
        (role) =>
          role.relatedProfession === selectedProfession && role.isFeatured
      )
      .sort((a, b) => a.sort - b.sort);
  }

  const roleOnCloud = (role) => {
    const emptyIndex = selectedRoles.findIndex((s) => s.id === "");
    if (emptyIndex !== -1) {
      onSelect({ ...role, experience: null }, emptyIndex);
    }
  };

  const roleOnSelect = (role, index) => {
    if (role.id === 0) {
      role.id = "";
      role.experience = null;
    }
    onSelect(role, index);
  };

  useEffect(() => {
    const validateForm = async () => {
      const currentRoles = selectedRoles.filter((role) => role.id !== "");
      try {
        const validateResponse = await validationSchema.isValid(currentRoles);
        setValid(validateResponse);
      } catch (err) {
        if (err?.name === "ValidationError") return;
        captureErrorWithData(err);
      }
    };

    validateForm();
  }, [selectedRoles]);

  const getRoleValues = (experience) => {
    if (experience || experience === 0) {
      const { name, value } = proExperiences.filter(
        (i) => i.value === experience
      )[0];
      const roles = {
        value: experience,
        name: isMobile() ? value : name,
      };
      if (experience === 0 && isMobile()) {
        roles.name = "0-1";
      }

      if (experience === 11 && isMobile()) {
        roles.name = "10+";
      }

      return roles;
    }
    return experience;
  };

  if (loading) return <Loading width="50px" />;

  if (error) return <PartialError />;

  return (
    <>
      {data && (
        <>
          <BoxTitle color="font.0">
            Which roles do you have professional experience in?
          </BoxTitle>
          <BoxDescription
            color="font.1"
            mt={3}
            dangerouslySetInnerHTML={{
              __html:
                "Add up to 3 roles to increase your chances for the best job matches. Here are some suggestions for you:",
            }}
          />
          {suggestedRoles.length > 0 && (
            <RowBlock mb={4}>
              <RoleSelect
                roles={suggestedRoles}
                handleSelectedRole={roleOnCloud}
                selectedRoles={selectedRoles}
                mb={5}
              />
            </RowBlock>
          )}

          <RowBlock mb={3}>
            <InlineMessage
              type="info"
              message={
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <InfoIcon color="#AAAAAA" />
                  </div>
                  <div>
                    First role is the role we get to know you and you&apos;d
                    prefer the most. You can also add alternative roles you are
                    experienced in to spice up your matches.
                  </div>
                </div>
              }
            />
          </RowBlock>

          {selectedRoles
            .filter((s) => s.isShow)
            .map((role, index) => (
              <RowBlock
                key={index}
                width={{ mobileS: "100%", tablet: "530px" }}
                wrap="no-wrap"
                my={2}
                justify="space-between"
                px={{ mobileS: "20px", tablet: 0 }}
              >
                <SkillitemCircle>{index + 1}</SkillitemCircle>
                <SelectField
                  autoFocus={role.isFocus}
                  value={selectedRoles.filter(
                    (value) => value.id && value.id === role.id
                  )}
                  width={{
                    mobileS: "calc(100% - 140px)",
                    tablet: "calc(100% - 250px)",
                  }}
                  options={
                    data?.allRole?.roles.filter((i) => {
                      const selectedRoleIds = selectedRoles.map((s) => s.id);
                      if (!selectedRoleIds.includes(i.id)) {
                        return i;
                      }
                      return null;
                    }) || []
                  }
                  getOptionLabel={(option) => option.label || option.title}
                  getOptionValue={(option) => option.id}
                  onChange={(opt) => {
                    roleOnSelect(
                      {
                        ...role,
                        id: opt ? opt.id : 0,
                        title: opt ? opt.title : "",
                      },
                      index
                    );
                  }}
                  placeholder={index === 0 ? "Main Role" : "Alternative Role"}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? (
                      <IntercomLauncherMessage />
                    ) : (
                      "Type something to search"
                    )
                  }
                  isClearable
                  selectedBgColorful={role.id}
                  valueAlign="center"
                  textAlign="center"
                />
                <SelectField
                  placeholder="Experience"
                  value={getRoleValues(role.experience)}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option.value}`}
                  isSearchable={false}
                  onChange={(selectedOption) => {
                    roleOnSelect(
                      { ...role, experience: selectedOption.value },
                      index
                    );
                  }}
                  options={proExperiences}
                  width={{
                    mobileS: "100px",
                    tablet: "170px",
                  }}
                  selectedBgColorful
                  valueAlign="center"
                  textAlign="center"
                />
              </RowBlock>
            ))}
          <RowBlock mt={5}>
            <Button
              disabled={!valid}
              type="submit"
              variant="primary"
              size="large"
              onClick={nextStep}
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              Continue
              <IconWrapper ml={3}>
                <ArrowIcon direction="RIGHT" />
              </IconWrapper>
            </Button>
          </RowBlock>
        </>
      )}
    </>
  );
}
