import React from "react";
import { GroupTitle, DescriptionText, SectionContainer } from "./CommonStyles";
import { ColumnBlock } from "../../BlockUi";
import { CheckedBoxIcon, UncheckedBoxIcon } from "../../Icon";
import ReadMore from "../../ReadMore";

export const BioFromImport = ({ state, handleStateChange }) => {
  if (!state.value) return null;

  const stateChange = (event) => {
    if (!(event.target instanceof HTMLAnchorElement)) {
      handleStateChange("bio");
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <GroupTitle>About</GroupTitle>
      <ColumnBlock>
        <SectionContainer
          isChecked={state.isChecked}
          onClick={(e) => stateChange(e)}
        >
          <DescriptionText>
            <ReadMore text={state.value} limit={250} />
          </DescriptionText>
          <div>
            {state.isChecked ? <CheckedBoxIcon /> : <UncheckedBoxIcon />}
          </div>
        </SectionContainer>
      </ColumnBlock>
    </div>
  );
};
